<template>
  <div class="menu-container" v-draggable>
    <div class="Detail-Header" style="position:relative;background-color:rgb(236, 236, 236);">
      <input type="checkbox" v-model="selectedTask.done" @change="updateTaskDone">
      <div for="" style="margin-right:10px;max-width:250px;overflow-x: hidden;white-space:nowrap;text-overflow:ellipsis;">
        {{ selectedTask.name }}
      </div>
      <div class="block">
        <label for="datepicker1" v-if="selectedTask.done==0&&isCharger(selectedTask)">
          <el-date-picker 
            v-model="value1" @class="date-picker"
            style="width: 0px; height: 0px; overflow: hidden; background: transparent; z-index:99;"
            type="datetime"
            size="small"
            id="datepicker1"
            placeholder="选择日期时间">
          </el-date-picker>
          开始时间：{{showTime(selectedTask.starttime)}}
        </label>
        <label for="datepicker2" v-if="selectedTask.done==0&&isCharger(selectedTask)">
          <el-date-picker
            v-model="value2" @class="date-picker"
            style="width: 0px; height: 0px; overflow: hidden; background: transparent; z-index:99;"
            type="datetime"
            size="small"
            id="datepicker2"
            placeholder="选择日期时间">
          </el-date-picker>
          结束时间：{{showTime(selectedTask.endtime)}}
        </label>
        <label for="" v-if="selectedTask.done==1||!isCharger(selectedTask)">
          开始时间：{{showTime(selectedTask.starttime)}}
        </label>
        <label for="" v-if="selectedTask.done==1||!isCharger(selectedTask)">
          结束时间：{{showTime(selectedTask.endtime)}}
        </label>
        <button  @click="submitTaskConfirmation" class="checkingbutton" v-if="computedSummitButton(selectedTask)">
          <i class="el-icon-video-play"></i>
        </button>
        <button  @click="approveTaskConfirmation" class="checkingbutton" v-if="computedApproveButton(selectedTask)">
          <i class="el-icon-s-check"></i>
        </button>
      </div>                           
      <span>{{ this.$route.query.name }}</span>
      <div style="display: inline; float: right; margin-left:auto;margin-right:10px;" >
        <button  @click="showTaskRecord" :class="{'selectedmodel':showTaskDetailOption==1}">笔记</button>
        <button  @click="showTaskreport" :class="{'selectedmodel':showTaskDetailOption==2}">简报</button>
        <button  @click="showTaskMsg" :class="{'selectedmodel':showTaskDetailOption==3}">讨论</button>
        <button  @click="showTaskApprove" :class="{'selectedmodel':showTaskDetailOption==5}">文件|审批</button>
        <button  @click="showTaskResorcesPlan" :class="{'selectedmodel':showTaskDetailOption==4}">资源</button>
      </div>
    </div>
    <div class="taskcontent-container" ref="dropContainer">
      <div class="summitdescrip" v-if="ifShowSummitDescript">
        <h3 style="text-align:center;margin-top:5px;margin-bottom:5px;">提交任务</h3>
        <div>
          <label>任务名称：{{ selectedTask.name }}</label>
        </div>
        <div>
          <!-- <input type="file" @change="handleUploadTaskFile($event)"> -->
          <input type="file" @change="selectTaskFilesFromMenu($event)" multiple>
        </div>
        <label>任务完成说明：</label>
        <textarea name="" id=""  rows="6" style="width:100%;" class="summittasktext" v-model="summitTaskDescrip"></textarea>
        <div style="float:right;">
          <button @click="summitTaskFromMenu">提交</button>
          <button @click="closeSummitTaskMenu">取消</button>
        </div>
      </div>
      <div class="approvetaskcontainer" v-if="ifShowApproveTaskMenu">
        <h3 style="text-align:center;margin-top:5px;margin-bottom:5px;">审批任务</h3>
        <div>
          <label>任务名称：{{ selectedTask.name }}</label>
        </div>
        <div>
          <table>
            <tr style="height:30px;">
              <td style="width:90px;">审批选项：</td>
              <td style="width:35px;height:30px;">
                同意
              </td>
              <td style="width:40px;">
                <input type="checkbox"  v-model="checkboxes[0]" @change="handleCheckboxChange(0)">
              </td>
              <td style="width:65px;">驳回修改</td>
              <td style="width:40px;">
                <input type="checkbox"  ref="checkbox_reedit"  v-model="checkboxes[1]" @change="handleCheckboxChange(1)">
              </td>
              <td style="width:50px;">不同意</td>
              <td style="width:40px;">
                <input type="checkbox"  v-model="checkboxes[2]" @change="handleCheckboxChange(2)">
              </td>
            </tr>
          </table>
        </div>
        <label>任务完成说明：</label>
        <textarea name="" id=""  rows="6" style="width:100%;" class="summittasktext" v-model="approveTaskDescript"></textarea>
        <div style="float:right;">
          <button @click="approveTaskFromMenu">提交</button>
          <button @click="closeApproveTaskMenu">取消</button>
        </div>
      </div>
      <div v-if="showTaskDetailOption==1"  class="record-container">
          <!-- <textarea v-model="selectedTask.record" @blur="updateTaskRecord" @paste="onPaste" 
            placeholder="千里之行，始于足下。。。" :readonly="!isCharger(selectedTask)"></textarea> -->
          <TinymceEditor v-model="selectedTask.record" ref="myEditor"></TinymceEditor>
      </div>
      <div v-if="showTaskDetailOption==3" class="taskmessage-container">
        <!-- 以下是添加评论模块 -->
        <TaskMessage :messages="taskContents" :username="username" @sendMessage="sendTaskMessage"
          :selectedProjectAcls="selectedProjectAcls" :selectedTask="selectedTask" @sendTaskMessageOfFile="sendTaskMessageOfFile">
        </TaskMessage>

      </div>

      <div v-if="showTaskDetailOption==2" class="taskreport-container">
        <div class="taskreportcontainer">
            <div style="display:flex;line-height: 30px;">
              <div style="margin-left:20px;font-size: 14px;font-weight: bold;">简报列表</div>
              <button  style="background-color: rgb(255, 255, 255);border:none;outline:none;line-height: 30px;" @click="showAddTaskReportMenu">
                <i class="el-icon-circle-plus-outline"></i>
              </button>
            </div>
            <div style="margin-top:10px; max-height:calc(100vh - 200px);overflow: auto;">
              <el-timeline :reverse="false">
                <el-timeline-item v-for="(item,index) in taskReports" :key="index" :timestamp="datetimeConvert(item.created_at)" 
                  placement="top" style="padding:0;margin:0px;">
                  <table style="width:calc(100% - 10px);">
                    <tr style="width:100%;">
                      <td style="width: 50px;">
                        <div style="height:30px;line-height: 30px; text-align: center; font-size: 12px;color:rgb(145, 170, 252);">
                          {{ item.reporter_nickname }}:
                        </div>
                      </td>
                      <td style="width:calc(100% - 300px);">
                        <div style="background-color: rgb(255, 255, 255); padding-left:10px;border-radius: 5px;box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 -2px 4px rgba(0, 0, 0, 0.1), 2px 0 4px rgba(0, 0, 0, 0.1), -2px 0 4px rgba(0, 0, 0, 0.1);">
                          <div style="display:flex;">
                            <div style="font-size: 12px;color:rgb(145, 170, 252);">
                              状态：{{ item.status }}
                            </div>
                            <div style="font-size: 12px;margin-left:10px;color:rgb(145, 170, 252);">
                              风险等级：{{ item.risklevel }}
                            </div>
                            <div style="margin-left:auto;margin-right:10px;position:relative;"  v-if="isShowTaskReportEditButton(item)">
                              <button style="background-color: rgb(255, 255, 255);border:none;outline:none;" @click="showEditTaskReport(item)">
                                <i class="el-icon-edit"></i>
                              </button>
                              <button style="background-color: rgb(255, 255, 255);border:none;outline:none;" @click="deleteTaskReport(item)">
                                <i class="el-icon-delete"></i>
                              </button>
                            </div>
                          </div>
                          <div  style="margin:0 10px 0 0;">{{item.progress}}</div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </el-timeline-item>
              </el-timeline>
            </div>
            <div v-if="ifShowEditTaskReportMenu" class="reportinput">
              <div style="width:100%;text-align: center;font-size: 16px;font-weight: bold;line-height: 40px;">{{ editTaskReportTitle }}</div>
              <div>
                <div style="display:flex;">
                  <div>进度状态：</div>
                  <div >
                    <el-select v-model="taskStatus" placeholder="请选择" size="small" style="width:100px;text-align: center;">
                      <el-option
                        v-for="item in taskStatusOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div style="margin-left:20px;">风险等级：</div>
                  <div >
                    <el-select v-model="taskRiskLevel" placeholder="请选择" size="small" style="width:100px;text-align: center;">
                      <el-option
                        v-for="item in taskRiskLevelOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>

              </div>
              <textarea  v-model="newTaskReportProgress"  placeholder="请输入简报内容。。。" class="taskreportprogress">
              </textarea>
              <div style="float:right;margin-top:10px;margin-right:10px;">
                <button @click="summitEditTaskReport">{{ editTaskReportButton }}</button>
                <button @click="cancelEditTaskReportMenu">取消</button>
              </div>
            </div>
          </div>
      </div>
      <div v-if="showTaskDetailOption==4" class="taskaproval-container">
        <div class="resource-header">
          <button @click="addResourcePlan"><i class="el-icon-plus"></i></button>
          <button><i class="el-icon-refresh-right"></i></button>
          <button><i class="el-icon-link"></i></button>
          <button><i class="el-icon-shopping-cart-full"></i></button>
          <!-- <button @click="deleteResourcePlan"><i class="el-icon-minus"></i></button> -->
        </div>
        <div style="padding-left:20px;margin-top:10px;height: calc(100vh - 200px); overflow-y: auto;">
          <table class="table-resource">
            <thead class="table-header">
              <tr>
                <th style="width: 30px;">序号</th>
                <th style="width: 80px;">资源名称</th>
                <th style="width: 40px;">资源类型</th>
                <th style="width: 30px;">单位</th>
                <th style="width: 50px;">需求数量</th>
                <th style="width: 30px;">投入类型</th>
                <th style="width: 30px;">准备时间</th>
                <th style="width: 40px;">开始时间</th>
                <th style="width: 40px;">结束时间</th>
                <th style="width: 40px;">资源状态</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in resPlanlist" :key="index" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }">
                <td>
                  <!-- <i class="el-icon-minus"></i> -->
                  {{ index +1}}
                </td>
                <td>
                  <div  style="display: flex; align-items: center;">
                    <!-- <input type="checkbox" style="display:inline;" v-model="item.checked"> -->
                    <i class="el-icon-minus" @click="deleteResourcePlan(item,index)"></i>
                    <input type="text" style="border:none;display:inline;" v-model="item.resname" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                      @focus="selectResName(index)" @change="updateSelectedTaskResPlan1(item)">
                  </div>
                </td>
                <td><input type="text" v-model="item.restype" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                  @change="updateSelectedTaskResPlan1(item)"></td>
                <td><input type="text" v-model="item.resunit" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                  @change="updateSelectedTaskResPlan1(item)"></td>
                <td><input type="text" v-model="item.respofreq" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                  @change="updateSelectedTaskResPlan1(item)"></td>
                <td style="padding:0;">
                  <select v-model="item.resinputtype" :class="{ 'selectedResPlanTr': selectedResPlanIndex == index }"
                    style="border:none;width:100%;padding:0;text-align:center;" @change="updateResPlanInputType($event, item)">   
                    <option value="1" style="padding:0px;margin:0px;">均布</option>  
                    <option value="2" style="padding:0px;margin:0px;">开始</option>  
                    <option value="3" style="padding:0px;margin:0px;">结束</option>
                    <option value="4" style="padding:0px;margin:0px;">自由</option>  
                  </select> 
                </td>
                <td>
                  <input type="text" v-model="item.resprepareday" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                  min="1" step="1" required @input="validatePositiveInteger($event, item)"  @change="updateResPlanPrepareDay($event, item)">
                </td>
                <!-- <td><input type="text" :value="showDate(item.respstarttime)" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                  @change="updateSelectedTaskResPlanStarttime($event,item)"></td>
                <td><input type="text" :value="showDate(item.respendtime)" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                  @change="updateSelectedTaskResPlanEndtime($event,item)"></td> -->
                
                <td style="padding:0px;width:40px;">
                  <input type="text" :value="showDate(item.respstarttime)" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                  @change="updateSelectedTaskResPlanStarttime($event,item)" v-if="item.resinputtype==4">
                  <input type="text" :value="computedResStartDate(item)" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                  v-else :readonly="item.resinputtype!==4">
                </td>
                <td>
                  <input type="text" :value="showDate(item.respendtime)" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                    @change="updateSelectedTaskResPlanEndtime($event,item)" v-if="item.resinputtype==4">
                  <input type="text"  :value="computedResEndDate(item)" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                    v-else :readonly="item.resinputtype!==4">
                </td>

                <td><input type="text" v-model="item.respstatus" :class="{ 'selectedResPlanTr': selectedResPlanIndex==index }"
                  @change="updateSelectedTaskResPlan1(item)"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>  
      
      <div v-if="showTaskDetailOption==5" class="taskfileaproval-container">
        <div class="file-container">

          <div style="min-height:200px;" @dragover.prevent
               @drop.prevent="handleDropTaskFile($event)">
            <div style="font-size:14px;">
              <label style="font-weight:bold;margin:10px 0 10px 0;">文件列表：</label>
              <button @click="unfolderTaskFiles" class="addtaskfilebutton">
                <i class="el-icon-folder" v-show="!ifShowTaskFileFolder"></i>
                <i class="el-icon-folder-opened" v-show="ifShowTaskFileFolder"></i>
              </button>
              <button @click="triggerFileInput" class="addtaskfilebutton">
                <i class="el-icon-document-add"></i>
              </button>
            </div>
            <div class="upload-area" v-show="!selectedTaskFiles||selectedTaskFiles.length==0"
               @dragover.prevent
               @drop.prevent="handleDropTaskFile($event)">
            
              <input type="file" @change="handleUploadTaskFile($event)" ref="fileInput" style="display: none;">
              <button @click="triggerFileInput" style="background-color: rgb(250, 250, 250);border:none;font-size: 16px;width:100%;margin-top:30px;">
                <i class="el-icon-plus" style="background-color: none;"></i>
              </button>
              <br>
              <label for="" style="font-size: 12px;">拖拽文件上传，点击上传文件</label>
              
            </div>
            <table class="taskFileContainer" v-show="selectedTaskFiles&&selectedTaskFiles.length>0&&ifShowTaskFileFolder">
              <tr>
                <td style="width: 40px; ">
                  序号
                </td>
                <td style="width: calc(100% - 100px);">文件名</td>
                <td colspan="2" style="text-align: center;">操作</td>
              </tr>
              <tr  v-for="(file,index) in selectedTaskFiles" :key="file.idftf">
                <td style="width: 30px; ">
                  {{ index+1 }}
                </td>
                <td style="width: calc(100% - 100px);">{{ file.filename }}</td>
                <td  class="taskfilebutton" @click="downloadTaskFile(file)"><i class="el-icon-download"></i></td>
                <td  class="taskfilebutton" @click="deleteTaskFileOfSql(file)"><i class="el-icon-close"></i></td>
              </tr>
            </table>
          </div>
          <div class="taskapproverecord">
            <label style="font-weight:bold;font-size: 14px;">任务审批记录：</label>
            <div style="margin-top:10px; max-height:300px;overflow: auto;">
            <!-- <div style="margin-top:10px; height:calc(100% - 250px);max-height:calc(100% - 250px);overflow: auto;"> -->
              <el-timeline :reverse="false">
                <el-timeline-item v-for="(item,index) in taskProcessMsgs" :key="index" :timestamp="datetimeConvert(item.timestamp)" 
                  placement="top" style="padding:0;margin:0px;">
                  <table style="width:calc(100% - 10px);">
                    <tr style="width:100%;">
                      <td style="width: 50px;">
                        <div style="width:45px;height:45px;border-radius: 50%; line-height: 45px; text-align: center;
                          background-color: rgb(145, 170, 252);font-size: 12px;">
                          {{ item.sendernickname }}
                        </div>
                      </td>
                      <td style="width:calc(100% - 300px);">
                        <div style="background-color: rgb(241, 243, 244); padding-left:10px;border-radius: 10px;">
                          <h5 style="margin:0;color:gray;font-weight: normal;">
                            <i class="el-icon-circle-close" v-if="item.checkoption=='rejected'" style="color:rgb(255, 0, 0);"></i>
                            <i class="el-icon-circle-check" v-if="item.checkoption=='checked'||item.checkoption=='checking'" style="color:rgb(0, 128, 0);"></i>
                            {{ computedMsg(item) }}
                          </h5> 
                          <p style="margin:0;">{{item.message}}</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </div>
      </div> 
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { mapActions} from 'vuex'
import { nextTick } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import TaskMessage from '../../task/TaskMessage.vue'
import TinymceEditor from '../../editor/TinymceEditor.vue'
import { MessageBox } from 'element-ui'





export default {
  components: {
    TaskMessage,
    TinymceEditor,
  },
  name: 'Detail',
  props: ['id','name','selectedTask','taskContents','selectedProjectAcls','selectedTaskResPlans','selectedTaskFiles','isTaskCharger',
  'taskProcessMsgs','taskReports'],
  data() {
    return {
      newTaskRecord:'',
      detailInfo: '',
      content: '',
      title: '',
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        value1: '',
        value2: '',
        text: '',

        // 显示图片的数据
        image: '',
        value2: '2023/6/28',

        //任务详情相关数据
        inputText:'',
        showInput: true,
        firstContent:'',
        fileIcon: require('../../../icon/message/文件.png'),
        holerTextInSpan: '请输入内容...',
        imageSrc: '../../../icon/message/文件.png',
        textarea2: `不错不错`,
        showTaskDetailOption: 1,
        aNewTask:{},

        //以下是任务记录相关的数据
        
        

        //添加评论相关的数据


        username:'',
        userinfo:null,

        taskRecord:'', //任务记录的数据,
        resPlanlist:[],
        selectedResPlanIndex: -1,


        ifShowTaskFileFolder:true,
        ifShowTaskApproveRecord:false,
        ifShowSummitDescript:false,

        ifShowApproveTaskMenu:false,

        selectedTaskFilesFromMenu:null,
        summitTaskDescrip:'任务已完成',

        approveTaskDescript:'',
        // selectedTaskFiles:'',
        // selectedTaskFiles:'',

        //以下是与任务简报相关的数据
        editTaskReportTitle:'',
        editTaskReportButton:'',
        ifShowEditTaskReportMenu:false,
        newTaskReportProgress:'',
        taskStatusOpts:[{
          value: '正常',
          label: '正常'
        }, {
          value: '超前',
          label: '超前'
        }, {
          value: '滞后',
          label: '滞后'
        }],
        taskStatus:'',
        taskRiskLevelOpts:[{
          value: '低',
          label: '低'
        }, {
          value: '中',
          label: '中'
        }, {
          value: '高',
          label: '高'
        }],
        taskRiskLevel:'',
        editTaskReportTitle:'添加任务简报',
        editTaskReportButton:'',
        selectedTaskReport:null,
        

    }
  },
  computed: {

    isShowTaskReportEditButton(){
      return (taskReport)=>{
        const date=new Date(taskReport.report_date)
        const today=new Date()
        // 判断是否为同一天  
        const isSameDay=(date.toDateString()==today.toDateString())
        if(isSameDay&&this.selectedTask.permit_level=='charger'){
          return true
        }else {
          return false
        }
      }
    },

    computedApproveButton(){
      return(task)=>{
        const username=localStorage.getItem('username')
        const myProjectAcl=this.selectedProjectAcls.find((item)=>{
          if(item.username==username){
            return item
          }
        })

        if(myProjectAcl&&task.taskstatus=='checking'&&myProjectAcl.permit_level=='charger'){
          return true
        } else {
          return false
        }
      }
    },

    computedSummitButton(){
      return(task)=>{
        const username=localStorage.getItem('username')
        const myProjectAcl=this.selectedProjectAcls.find((item)=>{
          if(item.username==username){
            return item
          }
        })

        // if(myProjectAcl&&task.taskstatus=='checking'&&myProjectAcl.permit_level=='charger'){
        //   return true
        // } else {
        //   return false
        // }

        if(task.taskstatus!=='checked'){
          return true
        }else {
          return false
        }
      }
    },


    isCharger(){
      return(task)=>{
        if(task.permit_level=='charger') {
          return true
        } else {
          return false
        }
      }
    },
    datetimeConvert(){
      return(datetime)=> {
        const date = new Date(datetime);
        const options = {timeZone: 'Asia/Shanghai', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
        const formattedTime = date.toLocaleString('zh-CN', options);
        return formattedTime;
      }
    },

    computedMsg(){
      return(msg)=>{
        if(msg.checkoption=='checking'){
          return '发起任务完成确认'
        } else if(msg.checkoption=='checked'){
          return '确认任务已完成'
        } else if(msg.checkoption=='rejected'){
          return '驳回了任务确认'
        }

      }
    },

    computedResStartDate() {
      return (item) => {
        var taskStarttime = new Date(this.selectedTask.starttime);
        var taskEndtime = new Date(this.selectedTask.endtime);
        if(item.resinputtype==1){
          taskStarttime.setDate(taskStarttime.getDate() - item.resprepareday);
        }else if(item.resinputtype==2){
          taskStarttime.setDate(taskStarttime.getDate() - item.resprepareday);
          // taskEndtime=taskStarttime;
        }else if(item.resinputtype==3){
          taskEndtime.setDate(taskEndtime.getDate() - item.resprepareday);
          taskStarttime=taskEndtime;
        }else {

        }
        const taskYear = taskStarttime.getFullYear();
        const taskMonth = taskStarttime.getMonth() + 1;
        const taskDay = taskStarttime.getDate();
    
        const formattedDate = `${taskYear}/${taskMonth}/${taskDay}`;
    
        return formattedDate;
      }
    },

    computedResEndDate() {
      return (item) => {
        var taskStarttime = new Date(this.selectedTask.starttime);
        var taskEndtime = new Date(this.selectedTask.endtime);
        if(item.resinputtype==1){
          // taskStarttime.setDate(taskStarttime.getDate() - item.resprepareday);
          taskEndtime.setDate(taskEndtime.getDate() - item.resprepareday);
        }else if(item.resinputtype==2){
          taskStarttime.setDate(taskStarttime.getDate() - item.resprepareday);
          taskEndtime=taskStarttime;
        }else if(item.resinputtype==3){
          taskEndtime.setDate(taskEndtime.getDate() - item.resprepareday);
          // taskStarttime=taskEndtime;
        }else {

        }
        const taskYear = taskEndtime.getFullYear();
        const taskMonth = taskEndtime.getMonth() + 1;
        const taskDay = taskEndtime.getDate();
    
        const formattedDate = `${taskYear}/${taskMonth}/${taskDay}`;
    
        return formattedDate;
      }
    },

    convertTextToHtml(){
      return (text)=>{
        const encodedString = text
        const parser = new DOMParser()
        const decodedString = parser.parseFromString('<!doctype html><body>' + encodedString, 'text/html').body.textContent
        return decodedString
      }
    },

    showDate() {
      return (dateTime) => {
        const taskDate = new Date(dateTime);
        const taskYear = taskDate.getFullYear();
        const taskMonth = taskDate.getMonth() + 1;
        const taskDay = taskDate.getDate();
    
        const formattedDate = `${taskYear}/${taskMonth}/${taskDay}`;
    
        return formattedDate;
      }
    },

    showTime() {
      return (dateTime) => {
        const today = new Date();
        const thisYear = today.getFullYear();
        const taskDate = new Date(dateTime);
        const taskYear = taskDate.getFullYear();
        const taskMonth = taskDate.getMonth() + 1;
        const taskDay = taskDate.getDate();
        const taskHours = taskDate.getHours();
        const taskMinutes = taskDate.getMinutes();
        const taskSeconds = taskDate.getSeconds();
    
        const todayOfStr = this.convertDate(today);
        var taskDateOfStr = this.convertDate(dateTime);
        var lastDate;
    
        // 检查时间是否为00:00:00
        const isMidnight = taskHours === 0 && taskMinutes === 0 && taskSeconds === 0;
    
        if (todayOfStr === taskDateOfStr) {
          lastDate = '今天';
        } else if (taskYear === thisYear) {
          lastDate = `${taskMonth}月${taskDay}日`;
        } else if (taskDateOfStr === '2999/12/31') {
          lastDate = '未定义';
        } else {
          lastDate = `${taskYear}-${taskMonth}-${taskDay}`;
        }
    
        // 如果时间不是00:00:00，添加时间信息
        if (!isMidnight) {
          const formattedTime = `${taskHours.toString().padStart(2, '0')}:${taskMinutes.toString().padStart(2, '0')}:${taskSeconds.toString().padStart(2, '0')}`;
          lastDate += ` ${formattedTime}`;
        }
    
        return lastDate;
      }
    },





    convertContent(){
      return (item)=>{
        if(item.type==='text') {
          return `${item.content}`
        } else if (item.type==='file'){
          const fileName=this.showFileName(item.file_name)
          const fileSize=this.showFileSize(item.file_size)
          const str=`<div contenteditable="false" style="width: 350px; height: 60px; position: relative; 
                     background: rgb(252, 240, 240);" ><div class="filename-container" style="position: absolute; top:8px; 
                     left: 60px; text-align: left;">${fileName}</div><div 
                     class="filesize-container" style="position: absolute; top:30px; left: 60px; text-align: left;"
                     >${fileSize}</div><img style="width: 30px; height: 30px; position:absolute; left: 10px; top:15px;margin: 0; 
                     " :src="imageSrc"></div>`
          return str
        }
      }
    },
    taskContents1:{
      get: function(){
        this.taskContents=this.getTaskContents(this.selectedTask)
        return this.taskContents
      },
      set: function(val){
        this.taskContents=val
      }
    },





  },
  methods: {
    ...mapActions({
      downloadTaskDetailFile:'downloadTaskDetailFile',
      updateTaskAclOfSql:'updateTaskAclOfSql',
      addTaskMessageToSql:'addTaskMessageToSql',
      getUserInfoBySearchText:'getUserInfoBySearchText',
      showPic:'showPic',
      addChatmsg:'addChatmsg',
      addResorcesPlanToSql:'addResorcesPlanToSql',
      getResPlanlistByTask:'getResPlanlistByTask',
      updateSelectedTaskResPlan:'updateSelectedTaskResPlan',
      deleteTaskResPlanByIdfresp:'deleteTaskResPlanByIdfresp',
      updateTaskOfSql:'updateTaskOfSql',
      addTaskReportToSql:'addTaskReportToSql',
      updateTaskReportByIdftr:'updateTaskReportByIdftr',
      deleteTaskReportByIdftr:'deleteTaskReportByIdftr',
    }),

    // 以下是与任务简报相关的函数
    showAddTaskReportMenu(){
      this.editTaskReportTitle='添加任务简报'
      this.editTaskReportButton='添加'
      this.ifShowEditTaskReportMenu=true
    },

    showEditTaskReport(taskReport){
      this.selectedTaskReport=taskReport
      this.editTaskReportTitle='修改任务简报'
      this.editTaskReportButton='提交'
      this.taskStatus=taskReport.status
      this.taskRiskLevel=taskReport.risklevel
      this.newTaskReportProgress=taskReport.progress
      this.ifShowEditTaskReportMenu=true
    },

    deleteTaskReport(taskReport){

      MessageBox.confirm('确定要删除任务简报吗？', '删除确认', {  
        confirmButtonText: '确定',  
        cancelButtonText: '取消',  
        type: 'warning'  
      }).then(() => {  
          this.$emit('deleteTaskReport', taskReport);  
          this.deleteTaskReportByIdftr(taskReport.idftr);  
      }).catch(() => {  
          // 用户点击取消时的处理  
          console.log('用户取消了删除操作');  
      });  

    },

    cancelEditTaskReportMenu(){
      this.editTaskReportTitle=''
      this.editTaskReportButton=''
      this.newTaskReportProgress=''
      this.taskStatus=''
      this.taskRiskLevel=''
      this.ifShowEditTaskReportMenu=false
    },

    summitEditTaskReport(){
       if(this.editTaskReportTitle=='修改任务简报'){
        this.selectedTaskReport.progress=this.newTaskReportProgress
        this.selectedTaskReport.status=this.taskStatus
        this.selectedTaskReport.risklevel=this.taskRiskLevel
        this.selectedTaskReport.reporter_username=this.username
        this.selectedTaskReport.report_date=this.convertDate(this.selectedTaskReport.report_date)
        this.selectedTaskReport.created_at=this.datetimeConvert(this.selectedTaskReport.created_at)
        this.selectedTaskReport.updated_at=this.datetimeConvert(this.selectedTaskReport.updated_at)
        
        this.$emit('editTaskReport',this.selectedTaskReport)
        this.updateTaskReportByIdftr(this.selectedTaskReport)
       }else if(this.editTaskReportTitle=='添加任务简报'){
        const now=new Date()
        const taskReport={
          idftr:uuidv4(),
          task_idft:this.selectedTask.idft,
          report_date:this.convertDate(now),
          status:this.taskStatus,
          progress:this.newTaskReportProgress,
          created_at:this.datetimeConvert(now),
          updated_at:this.datetimeConvert(now),
          reporter_username:this.username,
          risklevel:this.taskRiskLevel,
          reporter_nickname:this.userinfo.nickname
        }
        this.$emit('addTaskReport',taskReport)
        this.addTaskReportToSql(taskReport)
       } 
       this.cancelEditTaskReportMenu()
    },


    calculateDateDifference(starttime, endtime) {
      const startDate = new Date(starttime);
      const endDate = new Date(endtime);
    
      // 将时间部分设置为0，只比较日期部分
      const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    
      // 计算相差的天数
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
      // 如果日期相同，则返回1，否则返回实际相差天数
      return diffDays+1
    },


    unfolderTaskFiles(){
      this.ifShowTaskFileFolder=!this.ifShowTaskFileFolder
    },

    showTaskApprove(){
      this.showTaskDetailOption=5
    },

    showTaskApproveRecord(){
      this.ifShowTaskApproveRecord=!this.ifShowTaskApproveRecord
    },

    submitTaskConfirmation() {
      this.ifShowSummitDescript=true
    },

    approveTaskConfirmation(){
      this.ifShowApproveTaskMenu=true
    },

    downloadTaskFile(file){
      const urlObject = new URL(file.url);
      const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
      this.downloadPubfileFromServer(fileName,'downloadtaskfile',file.filename)

    },

    downloadPubfileFromServer(url,downloadUrl,newFileName) {
      const token = localStorage.getItem('token')
      const data = { url: url }
      axios({
        method: 'get',
        url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
        headers: {
          Authorization: token,
        },
        params: data,
        responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
          const link = document.createElement('a') // 创建一个 <a> 元素
          link.href = downloadUrl // 设置下载链接
          // link.setAttribute('download', url) // 设置下载文件的文件名
          link.setAttribute('download', newFileName)
          document.body.appendChild(link) // 将 <a> 元素添加到页面中
          link.click() // 模拟点击下载
          document.body.removeChild(link) // 下载完成后移除 <a> 元素
          window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
    },



    deleteTaskFileOfSql(file) {
      if (!file) {
        alert('请选择文件！');
      } else {
        const confirmed = confirm('确定要删除该文件吗？');
        if (confirmed) {
          const token = localStorage.getItem('token');
          axios({
            method: 'delete',
            url: 'https://api2.itaskid.com' + '/my/deletetaskfilebyidftf',
            headers: {
              Authorization: token,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: { idftf: file.idftf }
          })
            .then((response) => {
              this.$emit('deleteLocalTaskFile', file);
              this.deleteTaskFileOfSqlx(file.url);
              console.log('删除我的文件成功！');
            })
            .catch(error => {
              alert(error?.response?.data?.message || 'An error occurred.');
              console.log('删除我的文件失败！');
            });
        } else {
          // 用户选择取消删除的逻辑
          console.log('取消删除操作');
        }
      }
    },

    //这个是删除文件的路径
    deleteTaskFileOfSqlx(url) {
        const token = localStorage.getItem('token');
        axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/deletetaskfilebyurl',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: { url:url }
        })
        .then(response => {
          console.log('文件删除成功！');
        })
        .catch(error => {
          console.error('文件删除失败:', error);
        });
    },


    async getSelectTaskFiles(task_idft) {
      try {
        const token = localStorage.getItem('token');
        const data = { task_idft: task_idft };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/gettaskfilesbyidft',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
  
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },



    async handleDropTaskFile(event){
      const files = event.dataTransfer.files;
      const username = localStorage.getItem('username');
      const updatetime=this.convertDateTime1(new Date())
    
      if (files && files.length > 0) {
        for (const file of files) { // 使用 `for...of` 循环
          const fileSizeInKB = file ? ((file.size / 1024).toFixed(2) + "KB") : '';
          const newTaskFile = {
            idftf: uuidv4(),
            filename: file.name,
            size: fileSizeInKB,
            task_idft: this.selectedTask.idft,
            uploader: username,
            updatetime: updatetime,
            filestatus: '待确认',
            project:this.selectedTask.project
          };
          try {
            const url = await this.uploadTaskfile(file); // 使用 await
    
            newTaskFile.url=url
            this.addTaskFileData(newTaskFile)
              .then((result)=>{
                this.$emit('addLocalTaskFileData',newTaskFile)
              })
            
          } catch (error) {
            console.error('File upload failed', error);
          }
        }
      }
    },

    selectTaskFilesFromMenu(e){
      this.selectedTaskFilesFromMenu = e.target.files;
    },

    closeSummitTaskMenu(){
      this.ifShowSummitDescript=false
      this.selectedTaskFilesFromMenu=[]
    },

    closeApproveTaskMenu(){
      this.ifShowApproveTaskMenu=false
    },

    handleCheckboxChange(index){
      this.taskApproveOtion=index+1
      this.checkboxes = this.checkboxes.map((value, i) => {
        return i === index ? true : false;
      });
      this.inputnum = index + 1;
      switch(index){
        case 0:{
          this.approveTaskDescript='同意。'
          break;
        }
        case 1:{
          this.approveTaskDescript='请修改后重新提交。'
          break;
        }
        case 2: {
          this.approveTaskDescript='未完成，不同意确认。'
          break;
        }
        // case 3:{
        //   this.newPubFileSummary='请协助审批。'
        //   break;
        // }
      }
    },

    approveTaskFromMenu(){
      const username=localStorage.getItem('username')
      const taskProcessMsg={
        idftsm:uuidv4(),
        sender:username,
        task_idft:this.selectedTask.idft,
        message:this.approveTaskDescript,
        timestamp:new Date().toLocaleString(),
        // checkoption:'checked'
      }


      if(this.taskApproveOtion==1){
        this.updateTaskstatusOnSql(this.selectedTask.idft,'checked')
        taskProcessMsg.checkoption='checked'
        // this.addTaskSummitMsg(taskProcessMsgs)
      } else {
        this.updateTaskstatusOnSql(this.selectedTask.idft,'')
        taskProcessMsg.checkoption='rejected'
      } 

      this.addTaskSummitMsg(taskProcessMsg)
      this.ifShowApproveTaskMenu=false

      // this.

    },

    async summitTaskFromMenu(){
      this.ifShowSummitDescript=false
      const files=this.selectedTaskFilesFromMenu;
      const username=localStorage.getItem('username');
      if (files && files.length > 0) {
        for (const file of files) { // 使用 `for...of` 循环
          const fileSizeInKB = file ? ((file.size / 1024).toFixed(2) + "KB") : '';
          const newTaskFile = {
            idftf: uuidv4(),
            filename: file.name,
            size: fileSizeInKB,
            task_idft: this.selectedTask.idft,
            uploader: username,
            updatetime: '2024/5/30',
            filestatus: '待确认',
          };
          try {
            const url = await this.uploadTaskfile(file); // 使用 await
    
            newTaskFile.url=url
            this.$emit('addLocalTaskFileData',newTaskFile)
            this.addTaskFileData(newTaskFile)
            
            this.selectedTaskFilesFromMenu=[]
          } catch (error) {
            console.error('File upload failed', error);
          }
        }
      }


      const taskProcessMsgs={
        idftsm:uuidv4(),
        sender:username,
        task_idft:this.selectedTask.idft,
        message:this.summitTaskDescrip,
        timestamp:new Date().toLocaleString()
      }
            
      const myProjectAcl=this.selectedProjectAcls.find((item)=>{
        if(item.username==username){
          return item
        }
      })
      if(myProjectAcl.permit_level=='charger'){
        taskProcessMsgs.checkoption='checked'
        this.updateTaskstatusOnSql(this.selectedTask.idft,'checked')
      } else  {
        taskProcessMsgs.checkoption='checking'
        this.updateTaskstatusOnSql(this.selectedTask.idft,'checking')
      }

      this.addTaskSummitMsg(taskProcessMsgs)
    },

    async addTaskSummitMsg(msg){
      

      try {
        const token = localStorage.getItem('token');
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/addtasksummitmsg',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: msg
        });
    
        console.log('添加任务提交信息成功！');
        // return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }

    },

    updateTaskstatusOnSql(idft,statusStr) {
      const token = localStorage.getItem('token')
      axios({
        method: 'put',
        url: 'https://api2.itaskid.com' + '/my/updatetaskstatusbyidft',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {idft:idft,taskstatus:statusStr}
      })
        .then((response) => {
          console.log('修改任务状态成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    },

    async handleUploadTaskFile(event){
      const files = event.target.files;
      const username = localStorage.getItem('username');

      const updatetime=this.convertDateTime1(new Date())
      if (files && files.length > 0) {
        for (const file of files) { // 使用 `for...of` 循环
          const fileSizeInKB = file ? ((file.size / 1024).toFixed(2) + "KB") : '';
          const newTaskFile = {
            idftf: uuidv4(),
            filename: file.name,
            size: fileSizeInKB,
            task_idft: this.selectedTask.idft,
            uploader: username,
            updatetime: updatetime,
            filestatus: '待确认',
            project: this.selectedTask.project
          };
          try {
            const url = await this.uploadTaskfile(file); // 使用 await
    
            newTaskFile.url=url
            this.$emit('addLocalTaskFileData',newTaskFile)
            this.addTaskFileData(newTaskFile)
              .then((result)=>{
                // console.log(result)
              })
            
          } catch (error) {
            console.error('File upload failed', error);
          }
        }
      }
    },

    async addTaskFileData(newTaskFile) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/taskfiles',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newTaskFile
        });
    
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },


    async uploadTaskfile(file) {
      try {
        if (!file) {
          alert("请选择要上传的文件");
          return;
        }
        const token = localStorage.getItem('token')
        const formData = new FormData();
        const encodedFileName = encodeURIComponent(file.name);
        formData.append('file', file, encodedFileName);
        const response = await axios.post(
          'https://api2.itaskid.com' + '/my/uploadtaskfile',
          formData,
          {
            headers: {
              Authorization: token,
              'Content-Type': 'multipart/form-data'
            },
            data:formData
          }
        );
        // console.log(response.data.data.url)
        return response.data.data.url;
      } catch (error) {
        console.log(error);
      }
    },

    //把标准时间转换成普通的字符串时间
    convertDate(targetDate){
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}/${month}/${day}`;
      return formattedDate
    },

    convertDateTime1(targetDate) {
      if (targetDate == '') {
        targetDate = '2999/12/31 23:59:59';  // 设置默认时间为23:59:59
      }
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
    
      const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },



    inputContents(){
      
    },

    handleEnter(event,item){
      event.preventDefault
      
    },

  //   updateContent(event) {
  //   // 获取div元素
  //   const div = event.target;
    
  //   // 遍历div的所有子元素（pre标签）
  //   for (let i = 0; i < div.children.length; i++) {
  //     const pre = div.children[i];
  //     // 遍历pre的所有子元素（span标签）
      
  //     const span = pre.children[0];
  //     // 获取span的内容
  //     const spanContent = span.innerHTML;
  //     console.log(spanContent)
  //     const newContent={content:sapn}
      
  //     // 更新taskContents中对应的项
  //     // this.taskContents[i].content = spanContent;
  //     console.log(this.taskContents[i])
        
    
  //   }
  //       // 删除taskContents中多余的项
  //   if (div.children.length < this.taskContents.length) {
  //     this.taskContents.splice(div.children.length);
  //   }
    
    
  // },

    handleInput(event,item,index) {
    
      // this.$emit('updateTaskContent',item)
      
      const text=event.target
      

  },
  // ...其它方法



    handleInput1(event,item,index) {

        // 保存当前的光标位置
        // const selection = window.getSelection();
        // const range = selection.getRangeAt(0);
        // let cursorPosition = range.startOffset;

        // 更新数据
        item.content = event.target.textContent;

        // 恢复光标位置
        // this.$nextTick(() => {
        //     const textNode = event.target.firstChild;
        //     // 确保光标位置不超出文本节点的长度
        //     cursorPosition = Math.min(cursorPosition, textNode.length);

        //     const newRange = document.createRange();
        //     const newSelection = window.getSelection();
        //     newRange.setStart(textNode, cursorPosition);
        //     newRange.collapse(true);
        //     newSelection.removeAllRanges();
        //     newSelection.addRange(newRange);
        // });

    },


    addNewPre(event,item,index){
    
//     // const newContent = event.target.innerHTML
//     // const newItem={...item,content: newContent}
//     // console.log('newContent',newContent)
//     // this.$emit('updateTaskContent',newItem)

//     // 获取当前光标位置
//     const selection = document.getSelection();
//     const cursorPosition = selection.focusOffset;
    

//     // 分割光标前后的内容
//     item.content=event.target.textContent
//     const beforeCursor = event.target.textContent.substring(0, cursorPosition)
//     const afterCursor = event.target.textContent.substring(cursorPosition)
    


//     //更新当前元素的内容为光标前的内容
//     item.content = beforeCursor
//     this.$emit('updateTaskContent',item)

//     // 创建新的元素，内容为光标后的内容
//     const newTaskContent = {
//       task_idft:item.task_idft,
//       type:item.type,
//       file_name:'',
//       img_name: '',
//       content: afterCursor,
//       myorder:item.myorder+1
//     }
//     const myorder=item.myorder
//     this.$emit('addTaskContent',newTaskContent,index)

//     this.$nextTick(() => {
//     setTimeout(() => {
//         // 获取新的span元素
//         const newSpan = this.$el.querySelector(`.pre-taskcontent:nth-child(${index + 2}) #inputspan`);
//         console.log('newSpan',newSpan)

//         //将光标插入新的span
//         if (newSpan) {
//             const range = document.createRange();
//             if (!newSpan.textContent) {
//                 // 如果newSpan是空的，先在其中插入一个空的文本节点
//                 newSpan.appendChild(document.createTextNode(' '));
//             }
//             range.selectNodeContents(newSpan);
//             range.collapse(false);  // false means collapse to end.
//             const sel = window.getSelection();
//             sel.removeAllRanges();
//             sel.addRange(range);
//         }
//     }, 0);
// });

    },


    downloadFile(fileName){
      this.downloadTaskDetailFile(fileName)
    },

    showFileName(fileName){
        const fileName1=fileName.substring(fileName.indexOf("-") + 1);
        const maxLength = 20;
        let truncatedFileName = fileName1;
        if (fileName1.length > maxLength) {
          const extensionIndex = fileName1.lastIndexOf(".");
          const fileNameWithoutExtension = fileName1.substring(0, extensionIndex);
          const truncatedFileNameWithoutExtension = fileNameWithoutExtension.substring(0, 20) + "..." + fileNameWithoutExtension.slice(-1);
          truncatedFileName = truncatedFileNameWithoutExtension + fileName1.substring(extensionIndex);
          return truncatedFileName
        } else {
          return fileName1
        }
    },

    showFileSize(fileSize){
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;
        if (fileSize < megabyte) {
          return `${(fileSize / kilobyte).toFixed(2)} KB`
        } else {
          return `${(fileSize / megabyte).toFixed(2)} MB`
        }
    },

    async uploadFile(e){
      try {
        const token = localStorage.getItem('token')
        const formData = new FormData()
        const file=e.target.files[0]
        formData.append('file', file)
        const response = await axios.post('https://api1.itaskid.com' + '/my/uploadfile', formData, {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data;charset=utf-8'   //multipart/form-data
          }
        });
        
      } catch (error) {
        console.error(error)
      }
    },

    onDragOver(event) {
      event.preventDefault() // 阻止浏览器的默认行为
      event.stopPropagation()
    },


    onInput(event) {
      this.content = event.target.innerHTML;
    },

    onDrop(event) {
      event.preventDefault()
      const files = event.dataTransfer.files
      if (files.length > 0) {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          this.image = reader.result
        })
        reader.readAsDataURL(files[0])
      }
    },

//     handlePaste(event) {
//   const items = (event.clipboardData || event.originalEvent.clipboardData).items;
//   const span = document.getElementById('inputspan');
//   const selection = window.getSelection();
//   const range = selection.getRangeAt(0);
//   const divNode = document.createElement('div');
//   const imgNode = document.createElement('img');
//   imgNode.setAttribute('src', '');
//   imgNode.setAttribute('alt', 'Pasted image');
//   for (let i = 0; i < items.length; i++) {
//     const item = items[i];
//     if (item.type.indexOf('image') === 0) {
//       const file = item.getAsFile();
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         imgNode.setAttribute('src', e.target.result);
//         divNode.appendChild(imgNode); // 在div元素中插入图片节点
//         range.insertNode(divNode); // 在当前位置插入div节点
//         range.setStartAfter(divNode); // 将光标移动到div节点的后面
//       };
//       reader.readAsDataURL(file);
//     }
//   }
// },



    //任务详情相关方法
    // handleDrop(e) {
    //   e.preventDefault()
    //   const file = e.dataTransfer.files[0]
    //   const reader = new FileReader()
    //   let div=document.createElement('div')
    //   reader.onload = () => {
    //     let img = document.createElement('img')
    //     img.src = reader.result
    //     img.style.width='100%'
    //     div.appendChild(img)
    //     this.$refs.dropContainer.appendChild(div)
    //   }
    //   reader.readAsDataURL(file)
    // },

    // handleDropOfTask(files){
    //   console.log('filesOfZhouliu',files)
    // },

    onPaste(event) {
      event.preventDefault();
      let pasteText = (event.clipboardData || window.clipboardData).getData('text');
      let currentText = this.selectedTask.record || '';
      let cursorPosition = event.target.selectionStart || currentText.length;
      
      let newText = currentText.slice(0, cursorPosition) + pasteText + currentText.slice(cursorPosition);
      
      this.selectedTask.record = newText;
      this.$nextTick(() => {
          this.updateTaskRecord();
      });
    },
    addTaskContent(item){
      this.$emit('addTaskContent',item)
      // const targetIndex=this.taskContents.findIndex(i=>i.idfc==item.idfc)
      // nextTick(()=>{
      //   const preContents = document.querySelectorAll('.pre-taskcontent')
      //   // preContents[index+1].autofocus="true"
      //   // console.log(preContents)
      //   // // preContents[index].blur()
      //   var childNodes = preContents[targetIndex+1].childNodes;
      //   childNodes[0].focus()
      // })
    },
    handelInput(item){
      // console.log(item.content)
    },

    //这个问题还没修改好
    updateTaskContent(item){

      this.$emit('updateTaskContent',item)
    },


    //在span中添加placeholder的功能
    handleFocus() {
      if (this.holerTextInSpan === '请输入内容...') {
        this.holerTextInSpan = '';
      }
    },
    handleBlur() {
      if (this.holerTextInSpan === '') {
        this.holerTextInSpan = '请输入内容...';
      }
    },

    //以下是任务详情相关的增删改查的函数
    // getTaskContents(task){
    //   const token = localStorage.getItem('token')
    //   const username=localStorage.getItem('username')
    //   const user={charger: username}
    //   axios({
    //       method:'get',
    //       url: 'https://api2.itaskid.com' + '/my/taskcontents',
    //       headers: {
    //         Authorization: token,
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       params: {taskid: task.idft}
    //       })
    //     .then((response)=>{
    //       if (Array.isArray(response.data)){
    //         this.taskContents=response.data
    //       }else
    //       alert('账号未认证，无法获取数据！')
    //     })
    //     .catch(function (error) {
    //       console.log(error?.response?.data?.message || 'An error occurred.');
    //     })
    //   },
        //以下是任务详情相关的增删改查的函数
      // getTaskContents(task){
      //   const token = localStorage.getItem('token')
      //   const username=localStorage.getItem('username')
      //   const user={charger: username}
      //   axios({
      //       method:'get',
      //       url: 'https://api2.itaskid.com' + '/my/taskcontents',
      //       headers: {
      //         Authorization: token,
      //         'Content-Type': 'application/x-www-form-urlencoded'
      //       },
      //       params: {taskid: task.idft}
      //       })
      //     .then((response)=>{
      //       if (Array.isArray(response.data)){
      //         this.taskContents=response.data
      //       }else
      //       alert('账号未认证，无法获取数据！')
      //     })
      //     .catch(function (error) {
      //       console.log(error?.response?.data?.message || 'An error occurred.');
      //     })
      // },



     

      addTaskContentToSql(newContent){
        
        const token = localStorage.getItem('token')
        axios({
          method:'post',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newContent
          })
          .then((response)=>{
            

            console.log('添加任务内容成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
      },

      insertTaskContentToSql(newContent){
        const token = localStorage.getItem('token')
        axios({
          method:'delete',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: {taskid: newContent.taskid}
          })
          .then((response)=>{
            console.log('删除所有任务内容成功')
            for(var i=0;i<this.taskContents.length;i++){
              this.addTaskContentToSql(this.taskContents[i])
            }
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
          
      },

      updateTaskContentToSql(newContent){
        const token = localStorage.getItem('token')
        axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newContent
          })
          .then((response)=>{
            console.log('修改任务内容详情成功')
           
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
      },


      //以下是任务关系记录相关的函数
      updateTaskRecord(){
        this.$emit('updateTaskRecord',this.selectedTask)
      },

      updateTaskDone(){
        this.selectedTask.done=this.selectedTask.done?1:0
        this.$emit('updateTaskDone',this.selectedTask)
      },
      

      //以下是获取任务详情的函数
      getTaskContents(task){
      const token = localStorage.getItem('token')
      const username=localStorage.getItem('username')
      const user={charger: username}
      axios({
          method:'get',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {task_idft: task.task_idft}
          })
        .then((response)=>{
          if (Array.isArray(response.data)){
            this.taskContents=response.data
          }else
          alert('账号未认证，无法获取数据！')
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
      },
    //以上是任务相关的数据库增删改查


    //以下是任务记录相关的函数
    showTaskRecord(){
      this.showTaskDetailOption=1
    },

    showTaskreport(){
      this.showTaskDetailOption=2
    },
    

    //以下是任务评价相关的函数

    showTaskMsg(){
      this.showTaskDetailOption=3
    },

    showTaskResorcesPlan() {
      this.showTaskDetailOption=4
    },

    showTaskOffice(){
      this.showTaskDetailOption=2
      const text='yuning'
      this.getUserInfoBySearchText(text)
        .then((result)=>{
          this.showPic(result.avatar)
            .then((result)=>{
              // console.log('result',result)
            })
        })
      
    },

    showTaskApproval(){
      this.showTaskDetailOption=4
    },

    handleDragOver(){

    },

    handleDragLeave(){

    },

    handleKeyDown(){

    },

    //基于taskContent数据库的发送任务信息
    // sendTaskMessage(newTaskContent){
    //   const now=new Date()
    //   const idfc=uuidv4()
      
    //   // 发送消息给socket.io服务器
    //   const newTaskMessage={
    //     content: newTaskContent,
    //     task_idft: this.selectedTask.idft,
    //     sender: this.username,
    //     file_name:'',
    //     img_name:'',
    //     type: 'text',
    //     timestamp: now,
    //     file_size: 0,
    //     idfc: idfc
    //   }
  
    //   this.addTaskMessageToSql(newTaskMessage)
    //   // this.socket.emit('message', this.newMessage, (response) => {
    //   //   console.log('Server response:', response);
    //   // });
    //   this.newMessage = {}

    //   this.$emit('sendTaskMessage',newTaskMessage)
      
    // },

    sendTaskMessage(newTaskContent){
      const now=new Date()
      const idfc=uuidv4()
      
      // 发送消息给socket.io服务器
      const newTaskMsg={
        id:uuidv4(),
        sender: this.username, 
        receiver: this.selectedTask.idft, 
        sendernickname:this.userinfo.nickname,
        message: newTaskContent, 
        file_name:'', 
        type:'text',
        aboutwhat:'taskchat',
        img_name:'', 
        timestamp: new Date(), 
        file_size: 0, 
      }
      this.addChatmsg(newTaskMsg)
      this.$emit('sendTaskMessage',newTaskMsg)
      
    },

    // 向服务器添加发送文件的聊天记录
    async addChatmsgForFile(newMsg){
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://api2.itaskid.com' + '/my/chatmsgs', newMsg, {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async showPic1(imgname,url) {
      if(imgname==null||imgname=='null'||imgname==''){
        return undefined
      } else {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api1.itaskid.com' + `/my/${url}`,
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          // console.log('blobUrl',blobUrl)
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }
    }, 

    async sendTaskMessageOfFile(newMsg){
      newMsg.task_name=this.selectedTask.name
      newMsg.receiver=this.selectedTask.idft
      newMsg.sendernickname=this.userinfo.nickname
     

      if(newMsg.type=='file'){
        this.addChatmsg(newMsg)
      } else if(newMsg.type='img'){
        const result1 = await this.addChatmsgForFile(newMsg);
        // console.log('result1.file_name',result1)
        const result2 = await this.showPic1(result1.img_name, 'showmsgimg');
        newMsg.imgUrl = result2;
      }
      
      this.$emit('sendTaskMessage',newMsg)
    },


    // addResourcePlan(){
    //   const idfresp=uuidv4();
    //   // 将前端传入的时间字符串转换为中国标准时间
    //   const date = new Date(this.selectedTask.starttime);
    //   const chinaTime = new Date(date.getTime() + (8 * 60 * 60 * 1000)); // 转换为中国标准时间
    //   // 将中国标准时间格式化为 'YYYY-MM-DD HH:MM:SS' 格式
    //   const respstarttime = chinaTime.toISOString().slice(0, 19).replace('T', ' ');
    //   const newResPlan={
    //     idfresp:idfresp,
    //     idfress_id:'',
    //     resname:'',
    //     restype:'',
    //     respstarttime:respstarttime, 
    //     restype:'材料',
    //     respendtime:respstarttime,
    //     resunit:'',
    //     respofreq:1,
    //     resp_idft:this.selectedTask.idft,
    //     respstatus:'待链接',
    //     resprice:0,

    //   }
    //   this.resPlanlist.push(newResPlan)
    //   this.addResorcesPlanToSql(newResPlan)
    // },

    addResourcePlan(){
      const idfresp=uuidv4();
      // 将前端传入的时间字符串转换为中国标准时间
      const startdate = new Date(this.selectedTask.starttime);
      const startdateChinaTime = new Date(startdate.getTime() + (8 * 60 * 60 * 1000)); // 转换为中国标准时间
      // 将中国标准时间格式化为 'YYYY-MM-DD HH:MM:SS' 格式
      const respstarttime = startdateChinaTime.toISOString().slice(0, 19).replace('T', ' ');

      const enddate = new Date(this.selectedTask.endtime);
      const enddateChinaTime = new Date(enddate.getTime() + (8 * 60 * 60 * 1000)); // 转换为中国标准时间
      // 将中国标准时间格式化为 'YYYY-MM-DD HH:MM:SS' 格式
      const respendtime = enddateChinaTime.toISOString().slice(0, 19).replace('T', ' ');

      const newResPlan={
        idfresp:idfresp,
        idfress_id:'',
        resname:'',
        restype:'',
        respstarttime:respstarttime, 
        restype:'材料',
        respendtime:respendtime,
        resunit:'',
        respofreq:1,
        resp_idft:this.selectedTask.idft,
        respstatus:'待链接',
        resprice:0,
        resinputtype:1,
        resprepareday:0,
      }
      
      this.resPlanlist.push(newResPlan)
      this.addResorcesPlanToSql(newResPlan)
    },

    

    selectResName(index){
      this.selectedResPlanIndex=index
    },

    deleteResourcePlan(item,index){
      this.resPlanlist.splice(index,1)
      this.deleteTaskResPlanByIdfresp(item.idfresp)
    },

    updateSelectedTaskResPlan1(item){
      item.respstarttime=this.convertDate(item.respstarttime)
      item.respendtime=this.convertDate(item.respendtime)
      this.updateSelectedTaskResPlan(item)
    },


    updateSelectedTaskResPlanStarttime(e,item){
      if(item.resinputtype==4){
        const newTime=new Date(e.target.value)
        item.respstarttime=this.convertDate(newTime)
        item.respendtime=this.convertDate(item.respendtime)
        this.updateSelectedTaskResPlan(item)
      }else {
        return
      }

    },

    updateSelectedTaskResPlanEndtime(e,item){
      if(item.resinputtype==4){
        const newTime=new Date(e.target.value)
        item.respendtime=this.convertDate(newTime)
        item.respstarttime=this.convertDate(item.respstarttime)
        
        this.updateSelectedTaskResPlan(item)
      }else {
        return
      }
    },

    updateResPlanInputType(e,item){
      item.resinputtype=e.target.value

      var taskStarttime = new Date(this.selectedTask.starttime);
      var taskEndtime = new Date(this.selectedTask.endtime);

      
      if(item.resinputtype==4){
        item.respstarttime=this.convertDate(item.respstarttime)
        item.respendtime=this.convertDate(item.respendtime)
      }else if(item.resinputtype==1) {
        taskStarttime.setDate(taskStarttime.getDate() - item.resprepareday);
        const taskStartYear = taskStarttime.getFullYear();
        const taskStartMonth = taskStarttime.getMonth() + 1;
        const taskStartDay = taskStarttime.getDate();
        const taskStartDate = `${taskStartYear}/${taskStartMonth}/${taskStartDay}`;

        taskEndtime.setDate(taskEndtime.getDate() - item.resprepareday);
        const taskEndYear = taskStarttime.getFullYear();
        const taskEndMonth = taskStarttime.getMonth() + 1;
        const taskEndDay = taskStarttime.getDate();
        const taskEndDate = `${taskEndYear}/${taskEndMonth}/${taskEndDay}`;
        item.respstarttime=taskStartDate
        item.respendtime=taskEndDate
      }else if(item.resinputtype==2){
        taskStarttime.setDate(taskStarttime.getDate() - item.resprepareday);
        const taskStartYear = taskStarttime.getFullYear();
        const taskStartMonth = taskStarttime.getMonth() + 1;
        const taskStartDay = taskStarttime.getDate();
        const taskStartDate = `${taskStartYear}/${taskStartMonth}/${taskStartDay}`;
        item.respstarttime=taskStartDate
        item.respendtime=taskStartDate
      }else if(item.resinputtype==3){
        taskEndtime.setDate(taskEndtime.getDate() - item.resprepareday);
        const taskEndYear = taskStarttime.getFullYear();
        const taskEndMonth = taskStarttime.getMonth() + 1;
        const taskEndDay = taskStarttime.getDate();
        const taskEndDate = `${taskEndYear}/${taskEndMonth}/${taskEndDay}`;
        item.respstarttime=taskEndDate
        item.respendtime=taskEndDate
      }else {
        item.respstarttime=this.convertDate(item.respstarttime)
        item.respendtime=this.convertDate(item.respendtime)
        
      }

      this.updateSelectedTaskResPlan(item)



    },

    updateResPlanPrepareDay(e,item){
      var taskStarttime = new Date(this.selectedTask.starttime);
      var taskEndtime = new Date(this.selectedTask.endtime);

      
      if(item.resinputtype==4){
        item.respstarttime=this.convertDate(item.respstarttime)
        item.respendtime=this.convertDate(item.respendtime)
        item.resprepareday=0
      }else if(item.resinputtype==1) {
        taskStarttime.setDate(taskStarttime.getDate() - item.resprepareday);
        const taskStartYear = taskStarttime.getFullYear();
        const taskStartMonth = taskStarttime.getMonth() + 1;
        const taskStartDay = taskStarttime.getDate();
        const taskStartDate = `${taskStartYear}/${taskStartMonth}/${taskStartDay}`;

        taskEndtime.setDate(taskEndtime.getDate() - item.resprepareday);
        const taskEndYear = taskStarttime.getFullYear();
        const taskEndMonth = taskStarttime.getMonth() + 1;
        const taskEndDay = taskStarttime.getDate();
        const taskEndDate = `${taskEndYear}/${taskEndMonth}/${taskEndDay}`;
        item.respstarttime=taskStartDate
        item.respendtime=taskEndDate
      }else if(item.resinputtype==2){
        taskStarttime.setDate(taskStarttime.getDate() - item.resprepareday);
        const taskStartYear = taskStarttime.getFullYear();
        const taskStartMonth = taskStarttime.getMonth() + 1;
        const taskStartDay = taskStarttime.getDate();
        const taskStartDate = `${taskStartYear}/${taskStartMonth}/${taskStartDay}`;
        item.respstarttime=taskStartDate
        item.respendtime=taskStartDate
      }else if(item.resinputtype==3){
        taskEndtime.setDate(taskEndtime.getDate() - item.resprepareday);
        const taskEndYear = taskStarttime.getFullYear();
        const taskEndMonth = taskStarttime.getMonth() + 1;
        const taskEndDay = taskStarttime.getDate();
        const taskEndDate = `${taskEndYear}/${taskEndMonth}/${taskEndDay}`;
        item.respstarttime=taskEndDate
        item.respendtime=taskEndDate
      }else {
        item.respstarttime=this.convertDate(item.respstarttime)
        item.respendtime=this.convertDate(item.respendtime)
        
      }

      this.updateSelectedTaskResPlan(item)



    },

    validatePositiveInteger(event, item) {  
        const value = event.target.value;  
        // 检查输入是否为正整数  
        if (value && (value < 0 || !Number.isInteger(Number(value)))) {  
            // 如果不是正整数，清空输入框或设置为默认值  
            event.target.value = '';  
            item.resprepareday = ''; // 或者设置为其他默认值  
            alert('请输入正整数'); // 提示用户  
        } else {  
            item.resprepareday = value; // 更新模型  
        }  
    }, 



    



  },
  mounted() {
    const username=localStorage.getItem('username')
    this.username=username



    const token = localStorage.getItem('token')
    const key=`user|${this.username}`
    const userinfo=localStorage.getItem(key)
    this.userinfo=JSON.parse(userinfo)
    
    this.resPlanlist=this.selectedTaskResPlans
    

    

    document.addEventListener("DOMContentLoaded", function() {
      tinymce.init({
        selector: '#mytextarea'
      });
    });

  },
  watch: {
    'selectedTask.record':{
      deep: true,
      handler(newVal){
        this.updateTaskRecord()
      }
    },
    value1: function(newVal, oldVal) {
      if(this.selectedTask.duration){
        const newDate = new Date(this.value1);
        newDate.setDate(date.getDate() +this.selectedTask.duration);
        this.value2=this.convertDate(newDate)
      } else {
        this.value2=this.value1
        this.selectedTask.duration=1
      }
      // this.value2=this.value1
      this.$emit('upDateStarttime',newVal)
    },

    value2: function(newVal, oldVal) {
      this.$emit('upDateEndtime',newVal)
    },
    // '$route.params.idft':function(){
    //   const idft=this.$route.params.idft
    //   console.log('idft',this.selectedTask)
    //   this.taskContents=this.getTaskContents(this.selectedTask)
    // },

    selectedTaskResPlans: function(newVal,oldVal) {
      this.resPlanlist=newVal
    }
  },
  directives: {
    'blur-input': {
      bind(el, binding, vnode) {
        const handler = binding.value;
        el.addEventListener('blur', handler);
      },
      unbind(el, binding, vnode) {
        const handler = binding.value;
        el.removeEventListener('blur', handler);
      }
    }
  },
}
</script>
<style lang="less" scoped>
  .Detail-Header{
    display: flex;
    border-bottom: 1px solid #B3C0D1;
    text-align: left;
    span {
      margin-left: 30px;
    }
    .block {
      display: inline;
      background: none !important;
      border:none !important;
      // position: relative; /* 确保这是一个定位元素 */
      // z-index: 1000; /* 高z-index值确保它在顶层 */
    }
    .date-picker {
      input {
        background: rgb(243,244,247);
        border:none !important;
      }
        
      }

    .dateselect {
      background: none !important;
      border:none !important;
      width: 20px;
    }

    .selectedmodel {
      color: rgb(71, 114, 250);
    }
  }
  .Detail-body {
    input[type="text"] {
      width: 100%;
      height: auto;
      text-align: left;
      vertical-align: top;
    }
  }

  .wrapper {
  width: 300px;
  height: 300px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

// 显示图片的CSS
  .wrapper img {
    max-width: 100%;
    max-height: 100%;
  }

  //任务详情相关CSS
  .taskcontent-container {
    /* border: 2px dashed gray; */
    height: calc(100vh - 140px);

    // 1123修改
    // height: calc(100% - 5px);
    // min-height: 500px;
    border: none;
    padding: 5px 0 5px 0;
    text-align: left;
    vertical-align: top;
    cursor: pointer;

    // .msgfile-container {
    //   display: flex;
    //   height: 80px;
    //   width: 280px;
    //   font-size: 14px;
    //   border-radius: 5px;
    //   margin:0px;
    //   background-color: rgb(252, 240, 240);
    // }

    div.fileinfo-container {
      width: 190px;
      word-wrap: break-word;
      // display: flex;
      flex-direction: column;
      position: relative;
      div.filename-container {
        position:absolute;
        top: 20px;
        left: -100px;
      }

      div.filesize-container {
        position:absolute;
        top: 40px;
        left: -75px;
      }


    }



    pre.pre-taskcontent {
      margin: 0px 5px;
      text-align: left;
      font-size: 14px;
      height: auto;
      min-height: 20px;
      white-space: pre-wrap;   //pre-wrap
      padding:0px;
      height:fit-content;

      span {
        // display: block;
        outline: none;
        padding:0;
        margin:0;
        text-align: left;
      }

      textarea {
        width:calc(100% - 10px);
        // height: 300px;
        border:none;
        outline: none;
        padding:0;
        margin:0;
        text-align: left;
        height: auto;
        overflow-y:visible;
        
      }
    
    }
  
  }
.taskcontent-container:focus {
  outline: none;
}
a:hover {
  cursor:pointer;
}

.record-container {
  width:calc(100% - 15px);
  height:calc(100% - 25px);
  z-index:999;
  // height:500px;

  textarea {
    width:100%;
    height: calc(100% - 5px);
    outline: none;
    border:none;
    margin-left:10px;
    line-height: 1.5;
  }

  .toolbar-container {
    width:100%;
    height:30px;
    border-bottom: solid 0.5px rgb(205, 205, 205);
    border-radius: 5px;
  }
}

//以下是与任务信息相关的CSS设置
.taskmessage-container {
  width:100%;
  outline:none;
  border:none;
  
}

.el-icon-attract:hover {
  color: rgb(71, 114, 250);
}

.table-resource {
  border-collapse: collapse; /* 合并边框 */
  width:calc(100% - 50px);
  font-size: 12px;
  text-align: center;

  td, th {
    border: 1px solid black; /* 设置单元格边框 */
    padding: 0px; /* 设置单元格内边距 */
    text-align: center; /* 居中文本 */
    
  }

  .table-header {
    position: sticky;
    top: 0;
    background-color: #f1f1f1;
  }

  input[type="text"] {
    width: calc(100% - 5px); /* 输入框宽度100% */
    height: 100%;
    border: none; /* 移除输入框边框 */
    font-size: 12px;
    text-align: center;
  }

  input:focus {
    outline: none; /* 移除输入框焦点 */
  }

  .selectedResPlanTr {
    color: rgb(71, 114, 250);

    td {
      color: rgb(71, 114, 250);
    }
  }




}

.resource-header {

  padding-left:20px;

  button {
    border:solid 0.5px grey;
    background: rgb(255, 255, 255);
    width:60px;
  }
  button:hover {
    color: rgb(71, 114, 250);
  }

}


.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 70%;
  border: 1px solid gray;
  z-index: 999;
}


.file-container {
  margin:0 10px 10px 10px;
  .upload-area {
     border: 2px dashed #ccc;
     padding: 20px 20px 20px 20px;
     text-align: center;
     height: 120px;
   }
   ul {
     list-style: none;
   }
   li {
     cursor: pointer;
     margin: 10px 0;
   }

   .addtaskfilebutton {
    background-color: rgb(255, 255, 255);
    border:none;
   }

   .addtaskfilebutton:hover {
    color:rgb(71, 114, 250);
   }
}


.taskfileaproval-container {
  position:relative;
}

.approvebutton {
  position:absolute;
  top:calc(100vh - 200px);
  // left:calc(100% - 210px);
  // float: right;
}



.summitdescrip{
  position:absolute;
  // top:calc(50% - 50px);
  top:calc(50% - 100px);
  // top:calc(50vh - 65px);
  // top:calc(50vh - 65px);
  width:400px;
  height:270px;
  left:calc(50% - 200px);
  background-color: rgb(255, 255, 255);
  border:0.5px solid gray;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* 添加边框阴影效果 */
  padding: 10px; /* 可根据需要调整内边距 */
  z-index: 1000;
}

.approvetaskcontainer {
  position:absolute;
  // top:calc(50% - 50px);
  top:calc(50% - 100px);
  // top:calc(50vh - 65px);
  // top:calc(50vh - 65px);
  width:400px;
  height:270px;
  left:calc(50% - 200px);
  background-color: rgb(255, 255, 255);
  border:0.5px solid gray;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* 添加边框阴影效果 */
  padding: 10px; /* 可根据需要调整内边距 */
  z-index: 1000;

}

.summittasktext {
  width:100%;
  height:100px;
  border:0.5px gray solid;
}

.summittasktext:focus {
  outline: 0.5px solid rgba(64, 158, 255,0.7);
  border:none;
}

.checkingbutton {
  background-color: rgb(236, 236, 236);
  border:none;
  font-size: 14px;
  margin-left:15px;
}

.checkingbutton:hover {
  color:rgba(64, 158, 255,0.7);
}

el-timeline {
  margin-left:0;
}

.taskFileContainer {
  font-size: 14px;
  margin-left: 10px; 
  margin-right: 10px;
  border-collapse: collapse;
  width:calc(100% - 10px);
  max-height:120px;
  overflow: auto;

  tr {
    border-bottom: 0.5px solid gray;
    // padding: 8px;
  }

  .taskfilebutton {
    width:40px;
    text-align: center;
  }

  .taskfilebutton:hover {
    color:rgb(71, 114, 250);
  }

}

.taskreportcontainer {
  position:relative;

  .reportinput {
    position:absolute;
    padding: 10px;
    border-radius: 5px;
    top:calc(50% - 50px);
    left:calc(50% - 200px);
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Adding shadow */  
  }

  .taskreportprogress {
    width:100%;
    min-height:100px;
    margin-top:10px; 
    outline:rgb(228, 231, 237);
  }

  .taskreportprogress:focus {  
    border-color: lightblue; /* 设置焦点时的边框颜色为浅蓝色 */  
    outline: none; /* 去掉默认的轮廓 */  
  } 
}





</style>