<template>
  <!-- <div style="display: flex;"> -->
    <!-- <el-col style="width: 220px; border-right: 1px solid rgba(200, 200, 200, 0.9); flex-shrink: 0; "> -->
      <div @contextmenu.prevent @mousedown.right.prevent="disableContextMenu">
        <!-- <div class="taskgroup-header" style="text-align: left; margin-bottom: 0px">
          <ul style="margin:0 0 5px 18px;padding: 0px">         
            <li @click="goToAllFile('myself')">
              <div class="header-link">
                <i class="el-icon-date"></i>
                我的文件
              </div>
            </li>
            <li  @click="goToAllFile('share')">
              <div class="header-link">
                <i class="el-icon-date"></i>
                共享文件
              </div>
            </li>
            <li @click="goToAllFile('publish')">
              <div class="header-link">
                <i class="el-icon-suitcase"></i>
                发布|订阅
              </div>              
            </li>        
          </ul>
        </div> -->
        <div class="taskgroup-body">          
          <div style="text-align: left; margin-top: 5px 5px;height: 42px;">
              <button style="border: none; background: none;height: 42px;" @click.stop=toggle()>
                <i class="el-icon-arrow-right" v-show="!expended"></i>
                <i class="el-icon-arrow-down" v-show="expended"></i>
              </button>
              <label for="" style="padding-left: 0;height: 42px;" @click.stop="selectAllProject">所有项目</label>
          </div>
          <div style="text-align: left;" v-show="expended" ref="task-group">
              <div style="margin-left: 27px;">
                <i class="el-icon-folder-opened"></i>
                <label @click.stop="sendNoGroupName">收集箱</label>
              </div>
              <div v-for="(items, group) in groupedItems" :key="group">
                <h4 style="user-select: none;">
                  <span v-if="!isGroupExpanded(group)" @click.stop="toggleGroup(group)">
                    <i class="el-icon-arrow-down"></i>
                    <i class="el-icon-folder-opened"></i>
                  </span>
                  <span v-else @click.stop="toggleGroup(group)">
                    <i class="el-icon-arrow-right"></i>
                    <i class="el-icon-folder"></i>
                  </span>
                  <label for="" @click.stop="sendGroupName(group)">{{ group }}</label>
                </h4>
                <!-- <ul v-show="!isGroupExpanded(group)"> -->
                <ul v-show="!isGroupExpanded(group)" >
                  <!-- <li v-for="item in items" :key="item.idfp" @contextmenu.prevent="projectEditMenu($event,item)" >
                    <i class="el-icon-s-order"></i>
                    <router-link :to="{
                      name: 'project',
                      params: {
                        idfp: item.idfp
                      }
                    }">
                      {{ item.name }}
                    </router-link>
                  </li> -->

                  <li v-for="item in items" :key="item.idfp" 
                    @contextmenu.prevent="projectEditMenu($event,item)" @click.stop="goToFile(item.idfp)">
                    <!-- <i class="el-icon-s-order"></i> -->
                    <i class="el-icon-edit" style="margin-bottom: 8px" v-if="item.permit_level=='charger'"></i>
                    <i class="el-icon-view" style="margin-bottom: 8px" v-if="item.permit_level=='viewer'"></i>
                    <i class="el-icon-s-promotion" style="margin-bottom: 8px" v-if="item.permit_level=='parter'"></i>
                    <!-- <router-link :to="{
                      name: 'project',
                      params: {
                        idfp: item.idfp
                      }
                    }">
                      {{ item.name }}
                    </router-link> -->
                    <span >
                      {{ item.name }}
                    </span>
                    
              
                  </li>
                  <!-- 添加编辑项目的右键菜单 -->
                  <!-- v-if="showProjEditMenu"  -->
                  <!-- <EditProjectMenu 
                    v-if="showProjEditMenu"
                    :projeditMenuitems="projeditMenuitems" 
                    :event="event" 
                    :parentNode="parentNode"
                    @projeditMenuitemClick="projeditMenuitemClicked">
                  </EditProjectMenu> -->
                
                 
                </ul>
                
              </div>
              <!-- <EditProject
                      v-if="showProjEdit"
                      @cancelEditPro="cancelEditPro"
                      @closeEditProj="closeEditProj"
                      @updateLocalItems="editLocalItems"
                      v-bind:myProjects="items">
                      

              </EditProject> -->
          </div>        
        </div>
      </div>
    <!-- </el-col> -->
  <!-- </div> -->
</template>

<script>
// import AddProjectMenu from '../menu/task/AddProjectMenu.vue'
// import EditProject from '../menu/task/EditProject.vue'
// import EditProjectMenu from '../menu/task/EditProjectMenu.vue'
import axios from 'axios'
// import TaskList from './TaskList.vue'
import { mapState , mapMutations,mapActions} from 'vuex'
import { eventBus } from '../../main.js'
export default {
  name: 'ProjectGroup',
  components: {
    // TaskList,
    // AddProjectMenu,
    // EditProjectMenu,
    // EditProject
  },
  data () {
    return {
      isShowMenu: false,
      expended: true,
      isContextMenuOpen:[{general: 'false'}],
      idfg: 4,
      items: [],
      itemsAll:[],
      projectdata:[],
      nextId: 5,
      expandedGroups: {},
      newItemText: '',
      newGroup: '',
      is_group: true,
      username:'',
      token:'',
      newProject:[],
      myProjectList:[],
      //以下是处理项目编辑菜单的函数
      showProjEditMenu: false,
      projeditMenuitems: null,
      event: null,
      parentNode: null,
      selectedProj:'',
      //以上是处理项目编辑菜单的函数

      //以下是项目编辑的数据
      showProjEdit:false,
      showwhat:'pubfile',
    }
  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
      updateSelectProjName: 'updateSelectProjName',
      updateUser: 'updateUser'
    }),

    ...mapActions({
      // addTaskToSql:'addTaskToSql',
      // getMyTaskAclList:'getMyTaskAclList',
      // getTaskinfoByIdft: 'getTaskinfoByIdft',
      getMyProjectAclList:'getMyProjectAclList',
      getMyProjectListByIdfp:'getMyProjectListByIdfp',
    }),

    //点击不同的项目，显示不同的项目
    selectAllProject(){
      this.$emit('selectAllProject')
    },

    sendGroupName(group){
      const selectProjects=this.myProjects.filter(item=>item.groupname==group)
      this.$emit('selectGroupTasks',group,selectProjects)
    },

    sendNoGroupName(){
      const proName=`${this.username}nogroup`
      this.$emit('selectNoGroupTasks',proName)
    },

    //以下是新增项目菜单的处理函数
    showAddProMenu(){
      this.isShowMenu=!this.isShowMenu
      this.switchMenuMask()
      
    },
    shutMenu(){
      this.isShowMenu=false
    },
    //这个函数是把前端对服务器的增删改查在组件未更新前先更新本地化数据，以达到视图及时更新的目的
    updateLocalItems(newItem) {  
      this.items.push(newItem)
    },

    //以下是处理编辑菜单的函数
    projectEditMenu(event,item) {
      
      this.projeditMenuitems = [
        { id:1,label: '编辑' }, 
        { id:2,label: '置顶' },
        { id:3,label: '复制' },
        { id:4,label: '共享协作' }, 
        { id:5,label: '归档' },
        { id:6,label: '删除' }
      ]
      this.showProjEditMenu=true
      this.switchMenuMask()
      this.event={...event}  
      
      const top=(window.innerHeight-event.clientY)<189?(event.clientY-189):event.clientY
      const left=(window.innerWidth-event.clientX)<76?(event.clientX-76):event.clientX
      this.parentNode ={clientY:top,clientX:left}
      eventBus.$on('closeContexMenu', () => {
        this.showProjEditMenu=false
      })
      //更新vuex中被选中的项目名
      this.updateSelectProjName(item)
    },
    projeditMenuitemClicked(item) {
      //在这里处理右键菜单对应的选项
      this.showProjEditMenu=false
      //以下是项目编辑右键菜单的处理分支
      switch (item.id){
        case 1: {
          this.showProjEdit=true
          break
        }
        case 2: {
          this.switchMenuMask()
          break
        }
        case 3: {
          this.switchMenuMask()
          break
        }
        case 4: {
          this.switchMenuMask()
          break
        }
        case 5: {
          this.switchMenuMask()
          break
        }
        case 6: {
          const id=`${this.user.username}nogroup`
          const targetDataIndex=this.items.findIndex(item=>item.idfp===this.$store.state.selectProjIdfp)
          this.items.splice(targetDataIndex, 1)
          this.deleteProject(this.selectProjIdfp)
          this.updateSelectProjName({idfp:id,name:'未分类',charger:this.user.username,groupname:'头部'})
          this.switchMenuMask()
          break
        }
      }
    },
    //以上是编辑项目菜单的函数
   

    //以下是编辑项目的处理函数
    cancelEditPro(){
      this.showProjEdit=false
      this.switchMenuMask()
    },
    closeEditProj(){
      this.showProjEdit=false
      this.switchMenuMask()
    },
    editLocalItems(projectinfo) {
      console.log('projectinfo',projectinfo)
      this.updateProject(projectinfo)
      const targetDataIndex = this.items.findIndex(item => item.idfp === projectinfo.idfp)
      const targetData = this.items[targetDataIndex];  //targetData是编辑前的items中对应idfp的数据，编辑项目idfp不改变
      // targetData.name = projectinfo.name;  //已经修改了项目名称
      if (projectinfo.groupname === this.$store.state.selectProjGroup) {
        // 没有修改分组
        targetData.name = projectinfo.name;
      } else {
        // 修改了分组
        // targetData.groupname = projectinfo.groupname;
        const groupData = this.items.filter(item => item.groupname === projectinfo.groupname);
        if (groupData.length === 0) {
          targetData.groupname = projectinfo.groupname
          targetData.name = projectinfo.name
          const newData=targetData
          this.items.splice(targetDataIndex, 1)  
          //this.items.splice(targetDataIndex, 1)操作后，targetData为空，所以必须用newData接收
          this.items.unshift(newData)
        } else {   //改变分组后要选择插入的地方，
          const index = this.items.indexOf(groupData[0])
          targetData.groupname = projectinfo.groupname
          targetData.name = projectinfo.name
          const newData=targetData
          this.items.splice(targetDataIndex, 1)
          if(index>targetDataIndex) {
            this.items.splice(index-1, 0, newData);
          }else {
            this.items.splice(index, 0, newData);
          }
        }
      }
      
    },
    //以上是编辑项目的处理函数

    goToProject(id,project){
      localStorage.setItem('selectedProj',id)
      const selectedProj=localStorage.getItem('selectedProj')
      const currentRoute = this.$router.currentRoute
      if (currentRoute.name !== 'project' || currentRoute.params.idfp !== id){
        this.$router.push({
        name: 'project',
        params: {
          idfp: id
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
      let selectedProjIndex
      if(id=="inbox"||id=="today"||id=="week") {
        selectedProjIndex=this.myProjectList.findIndex(item => item.idfp === this.usernogroup)
        const selectedProj=this.myProjectList[selectedProjIndex]
        this.updateSelectProjName(selectedProj)
      } else {
        // selectedProjIndex=this.myProjectList.findIndex(item => item.idfp === id)
        selectedProjIndex=this.items.findIndex(item => item.idfp === id)
        const selectedProj=this.items[selectedProjIndex]
        this.updateSelectProjName(selectedProj)
      }
      }
      
    },

    // goToCategory(id){
    //   // localStorage.setItem('selectedProj',id)
    //   // const selectedProj=localStorage.getItem('selectedProj')
    //   // const currentRoute = this.$router.currentRoute

    //     this.$router.push({
    //     name: 'filecategory',
    //     params: {
    //       idff: id
    //     }
    //   })

      
    // },
    goToAllFile(filetype) {
      switch (filetype) {
        case 'publish': {
          this.showwhat='publish'
          this.$router.push({
          name: 'filepublish',
          params:{
            idff: 'all'
          }
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
            throw err
            }
          })
          break
        }

        case 'share': {
          this.showwhat='share'
          this.$router.push({
          name: 'fileshare',
          params:{
            idff: 'all'
          }
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
            throw err
            }
          })
          break          
        }

        case 'myself': {
          this.showwhat='myself'
          this.$router.push({
          name: 'filemyself',
          params:{
            idff: 'all'
          }
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
            throw err
            }
          })
          break          
        }        
      }

    },

    goToFile(idfp) {
      this.$emit('selectProjectTasks',idfp)
    },

    // 以下是操作服务器数据库的方法
    getUsername() {
      // const token = localStorage.getItem('token')
      axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
        headers: { Authorization: this.token} // 在 headers 中添加 Authorization Token
      })
      .then(response => {
        this.username = response.data.username;
        const user={username: this.username}
        this.updateUser(user)
      })
      .catch(error => {
        console.log(error);
      });
    },

    deleteProject(idfp){
      // const token = localStorage.getItem('token')
      axios.delete('https://api2.itaskid.com' + '/my/deleteproject', { 
        headers: { 
          Authorization: this.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        }, // 在 headers 中添加 Authorization Token
        data:{idfp}
      })
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.log(error);
      });
    },

    updateProject(project){
      // const token = localStorage.getItem('token')
      axios({
          method:'patch',
          url: 'https://api2.itaskid.com' + '/my/updateproject',
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: {idfp:project.idfp, name: project.name, groupname: project.groupname}
          })
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.log(error);
      });
    },
    // 以上是操作服务器数据库的方法
    disableContextMenu(event) {
      // 禁用默认行为
      event.preventDefault();
    },


    toggle(){
      this.expended=!this.expended
    },


    toggleGroup(group) {
      // this.expandedGroups[group] = !this.expandedGroups[group]
      this.$set(this.expandedGroups, group, !this.expandedGroups[group])
    },
    isGroupExpanded(group) {
      return this.expandedGroups[group]===true
    },

    
    async getMyProjects() {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');
    
      try {
        const response = await axios.get('https://api2.itaskid.com' + '/my/taskgroup', {
          headers: {
            Authorization: token
          },
        });
    
        const usernogroup = `${username}nogroup`;
        if (Array.isArray(response.data)) {
          this.itemsAll = response.data;
          this.myProjectList = response.data.filter(item => item.charger == username);
          this.items = this.myProjectList.filter(item => (!(item.idfp == usernogroup)));
        } else {
          alert('账号未认证，无法获取数据！');
        }
      } catch (error) {
        console.log(error);
      }
    },


    async getProjectinfoByIdfp(idfp) {
      const token = localStorage.getItem('token');
      const data={idfp: idfp}
      const username = localStorage.getItem('username');
    
      try {
        const response = await axios.get('https://api2.itaskid.com' + '/my/getprojectinfobyidfp', {
          headers: {
            Authorization: token
          },
          params:data
        });
        if (Array.isArray(response.data)) {
          return response.data
        } else {
          alert('账号未认证，无法获取数据！');
        }
      } catch (error) {
        console.log(error);
      }
    },    

  },
  computed: {
    ...mapState({
      selectProjName: 'selectProjName',
      selectProjGroup: 'selectProjGroup',
      selectProjIdfp: 'selectProjIdfp',
      user:'user'
    }),
    usernogroup: function(){
      // const username=localStorage.getItem('username')
      return `${this.username}nogroup`
    },
    usertoday: function(){
      // const username=localStorage.getItem('username')
      return `${this.username}today`
    },
    userweek: function(){
      // const username=localStorage.getItem('username')
      return `${this.username}week`
    },
    day: function() {
      var today = new Date(); // 获取当前日期
      return today.getDate(); // 返回当前日期中的日
    },

    groupExpanded(){
    return this.isGroupExpanded()
    },
    groupedItems() {
      const groups = {}
      this.items.forEach(item => {
        if (!groups[item.groupname]) {
          groups[item.groupname] = []
        }
        groups[item.groupname].push(item)
      })
      return groups
    },

  },


  mounted(){
    const token = localStorage.getItem('token')
    const username=localStorage.getItem('username')
    this.token=token
    this.username=username
    // this.getProjectinfoByIdfp('yuningnogroup')
    //   .then((result)=>{
    //     console.log('project',result)
    //   })
    
  },
  created() {
    // this.getMyProjects()
    const username=localStorage.getItem('username')
    let projectAcllist=[]
    const usernogroup=`${username}nogroup`
    this.getMyProjectAclList(username)
      .then((result)=>{
        projectAcllist=result
        
        const promises=projectAcllist.map(async (projectacl)=>{
          const project=await this.getMyProjectListByIdfp(projectacl.project_idfp)
          
          projectacl.name=project[0].name
          projectacl.groupname=project[0].groupname
          projectacl.status=project[0].status
          projectacl.is_delete=project[0].is_delete
          projectacl.createdTime=project[0].createdTime
          projectacl.creater=project[0].creater
          projectacl.permit_type=project[0].permit_type
          projectacl.idfp=project[0].idfp
        })
        return Promise.all(promises)
      })
        .then(()=>{
          this.myProjects=projectAcllist
          this.items=this.myProjects.filter(item=>(!(item.idfp==usernogroup)))
        })
  }    //错误，还不知道如何修改
}
</script>

<style lang="less" scoped>
.addproject-menu {
  position: absolute;
  height: 0;
  width: 0;
  overflow: visible;
}
.el-col {
  max-height: 100% !important;
  overflow: auto !important;
}
.taskgroup-header {
  border-bottom: 1px solid gray;
  margin-top:0px;
  margin-bottom:0px;
  text-align: left;
  div {
    line-height:30px;
  }
  button {
    border: 0;
    background: none;
    padding-left: 10px;
  }
  .header-link {
    line-height: 30px;
  }
  .today {
     font-size: 14px;
  }
  label:hover {
    background-color: gray;
    cursor: pointer;
  }
  .icon {
    position: relative; 
    display: inline-block;
    margin-top: 7px;
    margin-right:5px;
    background-image: url('../../assets/calendar.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    width: 16px;
    height: 16px;
    .today{
      position: absolute;
      top: 50%; 
      left: 50%;
      transform: translate(-50%, -40%);
      line-height: 18px; 
      text-align: center;
    }
}
}
.taskgroup-body {
  border-bottom: none;
  // height: 470px;
  height:calc(100vh - 100px);
  overflow:auto;
  ul {
    margin: 0px 0 0px 0;
    padding-left: 20px; 
  }
  
  label:hover {
    background-color: gray;
    cursor: pointer;
  }
  h4 {
    height: 30px !important;
    line-height: 30px;
    padding-left: 10px;
    margin: 0 0;
    font-weight: normal;
  }
  button {
    background-color: transparent;
    border: none;
  }
}

li {
    height: 30px;
    list-style: none;
    padding-left: 10px;
    line-height: 30px;
  }
  li:hover {
    background-color: rgb(200, 200, 200);
    cursor: pointer;
  }

.context-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.el-main {
  line-height: 20px !important;
}


.menu-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.5);
  z-index: 99;
}
::-webkit-scrollbar {
  width: 8px;
  height: 200px;
  background: none;
}
</style>