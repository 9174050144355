<template>
  <div class="chat-container" @contextmenu="disableContextMenu" style="height: calc(100vh - 400px);">
    <div class="chat-records" style="height: calc(100vh - 380px); border-bottom: 1px solid gray;
      overflow:auto;" ref="chatRecords"  contenteditable="false">
      <ul v-for="message in messages" :key="message.id" class="message" style="text-align: left; margin: 20px; padding: 0;">
        <div class="msg-time" style="margin-bottom: 5px;">
          {{ formatTimestamp(message.timestamp) }}
        </div>
        <li class="msg-container">
          <label :class="{'right': message.sender === username, 'left': message.sender !== username}" 
            style="width: 35px; height: 35px;">
            <img :src="showAvatar(message)" alt="av"  v-if="showAvatar(message)!==undefined"
            :style="{ width: '35px', height: '35px', borderRadius: '5%' }">
            <div class="filemsg-altavatar" v-if="showAvatar(message)==undefined">
              {{ showAltNickname(message.nickname) }}
            </div>
          </label>
          
          <div >
            <div style="margin-bottom: 3px;" class="taskmsg-sender" 
              :class="{'right': message.sender === username}" 
              v-if="!(message.sender === username)">
              {{ message.nickname }}
            </div>
            <div @contextmenu.prevent="handleMsgContextMenu(message,$event)">
              <p :class="{'right': message.sender === username, 'left': message.sender !== username}" 
                style="line-height: 1.5; white-space: pre-wrap;" v-if="message.type==='text'" ref="msgcontainer">
                <span v-html="formatMessage(message.message)" style="text-align: left;"></span>
              </p>
              <img :src="message.imgUrl" v-if="isImgFile(message.file_name)" @click="showImage(message)" 
                :class="{'right': message.sender === username, 'left': message.sender !== username}">
              <div class="msgfile-container" :class="{'right': message.sender === username, 'left': message.sender !== username}"
                v-if="message.type==='file'" @click="downloadMsgFile(message.file_name,'downloadmsgfile')">
                <div class="filename-container" style="text-align: left;">
                  <div >
                    {{ showFileName(message.file_name) }}
                  </div>
                  <div style="font-size: 12px; color: rgb(150, 150, 150)">
                    {{ showFileSize(message.file_size) }}
                  </div>
                </div>
                <img style="width: 50px; height: 50px; margin: 15px 15px;" src="../../icon/message/文件.png">
              </div>
            </div>
          </div>

        </li>
        <EditMsgMenu v-if="showEditMsgMenu" :parentNode="parentNode" @msgEditMenuitemClick="msgEditMenuitemClick">
        </EditMsgMenu>
        
      </ul>
      <PubTaskMenu v-if="showPubTaskMenu" :selectedMessage="selectedMessage" @closePubTaskMenu="closePubTaskMenu"></PubTaskMenu>
      <div v-if="showModal" @click="hideImage" class="modal"  @wheel="handleMouseWheel" >
        <img :src="clickedImgUrl" class="modal-image" :style="{ transform: `scale(${scale})` }"  v-draggable />
      </div>
    </div>
    <div class="input-container" style="height: 200px;border-bottom:solid gray 0.5px;">
      <div class="toolbar" style="height: 25px;">
        <!-- <button @click="toggleEmojiPicker" style="float: left;">😀</button> -->
        <button @click="toggleFilePicker" style="float: left;">📁</button>
        <input type="file" ref="fileInput" style="display: none;" @change="handleFileChange">
      </div>
      <div @paste="handlePaste">
        <textarea v-model="newText"  @dragover="handleDragOver" @dragleave="handleDragLeave"  @drop="handleFileDrop"  @keydown="handleKeyDown"
          style="width: calc(100% - 10px); height: 142px; border: none; outline: none; white-space: pre-wrap;">
        </textarea>
      </div>
      <div style="height: 25px;">
        <button @click="sendMessage" style="float: right; width: 100px;">发送(S)</button>
      </div>
    </div>
  </div>
</template>

<script>
import EditMsgMenu from '../menu/message/EditMsgMenu.vue'
import axios from 'axios'
import { mapMutations,mapActions} from 'vuex'
import Vue from 'vue';
import draggableDirective from '../../plugin/draggable.js'
import { eventBus } from '../../main.js'
import PubTaskMenu from '../menu/message/PubTaskMenu.vue'

export default {
  directives: {
    draggable: draggableDirective
  },
  components: {
    EditMsgMenu,
    PubTaskMenu
  },
  name: 'FileMessage',
  props: ['id','chatto','messages','avatarUrl_user','avatarUrl_friend','selectedProjectAcls'],
  data(){
    return {
      username:'',
      newText:'',
      showModal:false,
      showSendFile:false,
      scale: 1,
      clickedImgUrl:'',
      parentNode:null,
      event:null,
      showEditMsgMenu:false,
      selectedMessage:null,
      showPubTaskMenu:false,
    }
  },
  
  computed: {
    // showAltNickname(){
    //   return(nickname)=>{
    //     const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
    //     const altNikname = matchResult ? matchResult[0] : ''
    //     return altNikname
    //   }
    // },

    showAltNickname(){
      return(nickname)=>{
        if (typeof nickname !== 'undefined') {
          const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
          const altNickname = matchResult ? matchResult[0] : '';
          return altNickname;
        } else {
          return '';
        }
      }
    },
    formatMessage() {
      return (message) => {
        console.log('message',message)
        const urlRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+))/g;
        return message.replace(urlRegex, (match) => {
          if (match.startsWith('www')) {
            return `<a href="http://${match}" target="_blank" style="color: rgb(87,107,149); text-decoration: none;text-decoration: underline;">${match}</a>`;
          } else {
            return `<a href="${match}" target="_blank" style="color: rgb(87,107,149); text-decoration: none;text-decoration: underline;">${match}</a>`;
          }
        });
      };
    },

    showAvatar(){
      return(message)=>{
        const projectacl = this.selectedProjectAcls.find(item => item.username === message.sender);
        return projectacl.avatarUrl
      }
    },

    showUserAvatar(){
      return(username)=>{
        this.getUserAvatarUrl(username)
          .then((avatarUrl)=>{
            return avatarUrl
          })
      }
    },

    showFileName(){
      return(fileName)=> {
        const fileName1=fileName.substring(fileName.indexOf("-") + 1);
        const maxLength = 20;
        let truncatedFileName = fileName1;
        if (fileName1.length > maxLength) {
          const extensionIndex = fileName1.lastIndexOf(".");
          const fileNameWithoutExtension = fileName1.substring(0, extensionIndex);
          const truncatedFileNameWithoutExtension = fileNameWithoutExtension.substring(0, 20) + "..." + fileNameWithoutExtension.slice(-1);
          truncatedFileName = truncatedFileNameWithoutExtension + fileName1.substring(extensionIndex);
          return truncatedFileName
        } else {
          return fileName1
        }
        
      }
    },
    showFileSize(){
      return(fileSize)=> {
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;
        if (fileSize < megabyte) {
          return `${(fileSize / kilobyte).toFixed(2)} KB`;
        } else {
          return `${(fileSize / megabyte).toFixed(2)} MB`;
        }
      }
    },

    formatTimestamp() {
      return (timestamp)=>{
        const date = new Date(timestamp);
        const now = new Date();
        const diff = (now - date) / (1000 * 60 * 60 * 24); // 计算时间差，单位为天
      
        if (diff < 1) {
          // 如果是今天，则只显示时间
          return date.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'});
        } else if (diff < 2) {
          // 如果是昨天，则显示成‘昨天 08:25’
          return `昨天 ${date.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'})}`;
        } else if (diff < 7) {
          // 如果是本周，但又不是今天和昨天，则显示成‘周三 08:25’
          const weekdays = ['日', '一', '二', '三', '四', '五', '六'];
          return `周${weekdays[date.getDay()]} ${date.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'})}`;
        } else {
          // 如果不是本周，则显示成‘2023年5月20日 08:25’
          return date.toLocaleString('zh-CN', {year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit'});
        }
      }
      
     },

    isImgFile() {
      return(filename)=>{
        if(filename=='') {
          return false
        } else {
          const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i
          return imageExtensions.test(filename)
        }
      }
    },

    showUserAvatar(){
      return (username)=> {
        
      }
    },
    


  },

  created() {


  },
  mounted(){
    this.$refs.chatRecords.scrollTop = this.$refs.chatRecords.scrollHeight

    const username=localStorage.getItem('username')
    this.username=username
    

  },

  watch:{
    messages: function(){
      this.$nextTick(() => {
        this.$refs.chatRecords.scrollTop = this.$refs.chatRecords.scrollHeight; // 滚动条滚动到底部
      });
    }
  },


  
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
    }),

    ...mapActions({
      getUserInfoBySearchText: 'getUserInfoBySearchText',
      showPic:'showPic',
    }),
    toggleEmojiPicker(){

    },

    toggleFilePicker(){
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      // 处理文件选择
      const files = event.target.files;
      if (files.length > 0) {
        this.sendFilesToFile(files)
        // 这里可以添加更多处理文件的逻辑
      }
    },

    handleKeyDown(event){
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // 阻止默认的换行行为
        this.sendMessage(); // 发送消息
      }
    },

    sendMessage(){
      if(this.newText.trim() !== ''){
        this.$emit('sendMessage',this.newText)
        this.newText=''
      }
    },


    //处理任务信息的函数
    async getUserAvatarUrl(username) {
      try {
        const result = await this.getUserInfoBySearchText(username);
        const avatarUrl = await this.showPic(result.avatar);
        return avatarUrl;
      } catch (error) {
        // 处理错误
        console.error(error);
        throw error;
      }
    },




      // this.$nextTick(() => {
      //   this.$refs.chatRecords.scrollTop = this.$refs.chatRecords.scrollHeight; // 滚动条滚动到底部
      // });

    //禁止默认右键菜单
    disableContextMenu(event) {
      event.preventDefault();
    },

    //显示和隐藏图片函数
    showImage(message) {
      this.clickedImgUrl=message.imgUrl
      this.showModal = true;

    },
    hideImage(event) {
      if (event.target.classList.contains('modal-image')) {
        return;
      } else {
        this.showModal = false;
        this.scale=1
      }
    },

    //滑动中轮以扩大或缩小图片显示
    handleMouseWheel(event) {
      event.preventDefault(); // 阻止默认滚动行为
  
      // 根据鼠标滚轮的delta值，计算缩放比例
      const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
      this.scale += delta * 0.1; // 根据需要调整缩放速度
    },

    //处理拖入和拖放函数
    handleDragOver(event){
      event.preventDefault()
      event.target.style.background = 'rgba(235, 235, 235, 0.7)'; // 拖拽进入时的背景
    },

    handleDragLeave(event){
      event.preventDefault()
      event.target.style.background = 'transparent';
    },

    handleFileDrop(event){
      event.preventDefault()
      const files = event.dataTransfer.files;
      this.sendFilesToFile(files)
    },


    async uploadFiles(files) {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const encodedFileName = encodeURIComponent(files[i].name);
        formData.append('files', files[i], encodedFileName);
      }
    
      try {
        const response = await axios.post('https://api2.itaskid.com' + '/my/uploadmsgfiles', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        });
        // console.log(response.data);
        return response.data;
        // 处理上传成功的逻辑
      } catch (error) {
        console.error(error);
        throw error;
        // 处理上传失败的逻辑
      }
    },

    sendFilesToFile(dropFiles){
      this.$emit('sendFilesToFile',dropFiles)
    },



    //发送文件/图片相关的函数
    isImgFile1(filename) {
      if(filename==='') {
        return false
      } else {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i
        return imageExtensions.test(filename)
      }
    },

    //下载聊天记录中文件的处理函数
    downloadMsgFile(url,downloadUrl){
      const token = localStorage.getItem('token')
      const data = { url: url }
      axios({
        method: 'get',
        url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
        headers: {
          Authorization: token,
          // 'Access-Control-Allow-Origin': '*',
        },
        params: data,
        responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
          const link = document.createElement('a') // 创建一个 <a> 元素
          link.href = downloadUrl // 设置下载链接
          link.setAttribute('download', url) // 设置下载文件的文件名
          document.body.appendChild(link) // 将 <a> 元素添加到页面中
          link.click() // 模拟点击下载
          document.body.removeChild(link) // 下载完成后移除 <a> 元素
          window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
    },

    //聊天记录的右键菜单
    handleMsgContextMenu(message,event){
      
      event.preventDefault()
      event.stopPropagation()
      this.selectedMessage=message
      this.showEditMsgMenu=true
      this.switchMenuMask()
      const top=(window.innerHeight-event.clientY)<230?(event.clientY-230):event.clientY+10
      const left=(window.innerWidth-event.clientX)<112?(event.clientX-112):event.clientX+10
      this.parentNode ={clientY:top,clientX:left}
      eventBus.$on('closeContexMenu', () => {
        this.showEditMsgMenu=false
      })
      this.selectedMessage=message
    },


    //处理往输入框黏贴文字或者图片的处理函数
    handlePaste(event){
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;

      for (const item of items) {
        if (item.type.indexOf('image') !== -1) {
          // 处理黏贴的图片
          console.log('黏贴的是图片');
        } else {
          // 处理黏贴的文本
          console.log('黏贴的是文本');
        }
      }
    },

    //处理聊天记录的处理函数
    msgEditMenuitemClick(item){
      
      switch (item.id){
        case 1: {
          
          break
        }
        case 2: {
          
          break
        }
        case 3: {
          this.showPubTaskMenu=true

          
          break
        }
        case 4: {
          break
        }
        case 5: {
          break
        }
        case 6: {
          this.deleteMsg(this.selectedMessage)
          break
        }                
      }
      this.showEditMsgMenu=false
    },

    //关闭通过聊天记录发布任务的函数
    closePubTaskMenu(){
      this.showPubTaskMenu=false
      this.switchMenuMask()
    },

    deleteMsg(message){
      this.$emit('deleteMessage',message)
      const saverUrl=this.username==message.savebysender?'updatemsgsendersaverbyid':'updatemsgreceiversaverbyid'
      if(message.savebysender&&message.savebyreceiver){
        this.deleteMySqlMsgbyId(message.id,saverUrl)
      }else {
        this.deleteBothSqlMsgbyId(message.id)
        if(message.file_name) {
          this.deleteSqlMsgFilebyName(message.file_name)
        }
      }
    },

    deleteMySqlMsgbyId(id,saverUrl){
      const token = localStorage.getItem('token')
      axios({
        method:'put',
        url: 'https://api1.itaskid.com' + `/my/${saverUrl}`,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {id:id}
        })
        .then((response)=>{
          // console.log(response.data)
          console.log('删除我的聊天记录成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除聊天记录失败！')
        })
    },

    deleteBothSqlMsgbyId(id){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api1.itaskid.com' + `/my/deletemsgbyid`,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {id:id}
        })
        .then((response)=>{
          // console.log(response.data)
          console.log('删除双方的聊天记录成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除聊天记录失败！')
        })
    },    

    deleteSqlMsgFilebyName(fileName){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/deletemsgfilebyname',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {file_name:fileName}
        })
        .then((response)=>{
          // console.log(response.data)
          console.log('删除聊天记录文件成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除聊天记录文件失败！')
        })
    },




  },
}
</script>

<style lang="less" scoped>

.chat-container {
  border: none;
  
}


.name {
  display: block;
}

.last-message {
  display: block;
}




li {
    list-style: none;
  }


.context-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.el-main {
  line-height: 20px !important;
}


.menu-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.5);
  z-index: 99;
}
::-webkit-scrollbar {
  width: 8px;
  height: 50px;
  background: none;
}

.info {
  display: inline;
  flex-direction: column;
}

.avatar {
  margin-right: 10px;
}

.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}


  .message {     //ok
    // white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    flex: 1; /* 占据剩余空间 */
  }

  .right {
  text-align: left;
  float: right;
  // margin-left:10px;
  margin: 0 0 0 10px;
  background-color: #eaf6ff;
  }
  
  .left {
    text-align: left;
    float: left;
    // margin-right:10px;
    margin: 0 10px 0 0;
    background-color: #f2f2f2;
    white-space: pre-wrap;
  }

  label.left {
    margin-top: 15px;
  }

  .msg-time {    //ok
    display: flex;
    justify-content: center;
    height: 20px;
    line-height: 20px;
    background-color: rgb(218, 218, 218);
    color: rgb(250,250,250);
    width: fit-content;
    margin: 0 auto;
    white-space: pre-wrap;
    // pointer-events: none;
  }
  .msg-container {
    background-color: none;

    .taskmsg-sender:hover {
      background-color: none;
    }

    img {
      height: 150px;
      border-radius: 3px;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      color: rgb(35,35,35);
      border-radius: 5px;
      max-width: 40%;
      padding:5px;
    }

    div.filename-container {
      width: 190px;
      word-wrap: break-word;
      display: flex;
      margin: 0;
      flex-direction: column;

    }
    
    p.left {
      background-color: rgb(235,235,235);
      padding-left:5px;
    }

    p.right {
      background-color: rgb(149, 236, 105);
      padding-left:5px;
    }

    .msgfile-container {
      display: flex;
      height: 80px;
      width: 280px;
      font-size: 14px;
      border-radius: 5px;
      padding-left:10px;
    }

    a:hover {
      text-decoration: underline;
    }

  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: auto;
  }

  .modal-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }

  a {
    color: rgb(87, 107, 149); 
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline !important;
    color:red !important;
  }

  .filemsg-altavatar {
    width: 35px;
    height: 35px;
    border-radius: 5%;
    background: rgb(144, 82, 245);
    color:rgb(255, 255, 251);
    font-size: 14px;
    line-height: 35px;
    text-align: center;
  }
</style>