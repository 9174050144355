<template>
  <div class="menu-container" v-draggable>
      <div class="menu">
      <h3 style="text-align: center;">加载项目</h3>
      <form>
        <!-- <div class="form-group">
          <input type="text" id="project-name" v-model="pubFileName" placeholder="文件名称" autocomplete="off">
          <input type="file" @change="handlePubFileUpload($event)" ref="fileInput"/>
        </div>
        <br> -->
        <div class="form-group">
          <label for="project-folder">文件夹</label>
          <select id="project-folder" v-model="projectFolder">
            <option v-for="folder in folders" :key="folder.id"
            >
              {{ folder.name }}
            </option>
          </select>
          <!-- <button type="button" @click="addFolder">新建</button> -->
        </div>
        <div class="form-group">
          <label for="project">项&nbsp;&nbsp;&nbsp;目</label>
          <select id="project" v-model="loadedProject" style="margin-left:22px; ">
            <option v-for="project in pubProjects" :key="project.id" :value="project">
              {{ project.name }}
            </option>
          </select>
          <!-- <button type="button" @click="addFolder">新建</button> -->
        </div>
        <!-- <div class="form-group">
          <label for="project-folder">发布至</label>
          <select id="project-folder" v-model="projectFolder" style="width: 230px; margin-right: 10px; ">
            <option v-for="folder in folders"  :key="folder.id"
            >
              {{ folder.name }}
            </option>
          </select>
        </div> -->
      </form>
      <br>
      <!-- <div>
        <textarea name="" id="" cols="30" rows="10" placeholder="请输入文件简述。。。" 
          style="width: calc(100% - 15px);margin: 0 5px;border: none;" v-model="newPubFileSummary">
        </textarea>
      </div> -->
      <div class="menu-buttons">
        <!-- <button type="button" @click="pubNewFile" :disabled="saveOrNot">发布</button> -->
        <button type="button" @click="loadProject">加载</button>
        <button type="button" @click="resetForm" style="margin-left: 10px">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations,mapActions} from 'vuex'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
// import qs from 'qs'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "LoadProjectMenu",
    data() {
        return {
            pubFileName: '',
            projectName: "",
            projectType: "type1",
            projectFolder: null,
            pubProject:{},
            loadedProject:{},
            selectedProject:{},
            pubProjects:'',
            pubFileFolder:'',
            myProjectlist: [],
            projects:'',
            username:'',
            saveOrNot:true,
            newPubFile:null,
            newPubFileSummary:'',
            uploadFile: {},
            myProjectAclList:[],
        };
    },
    computed: {
      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        // for(var i=0; i<this.myProjectlist.length;i++) {
        //   if(!myfolderobj[this.myProjectlist[i].groupname]) {
        //     myfolderobj[this.myProjectlist[i].groupname]=i+1
        //     const folder={id: Id, name: this.myProjectlist[i].groupname}
        //     myfolder.push(folder)
        //     Id++
        //   }
        // }

        for(var i=0; i<this.myProjectAclList.length;i++) {
          if(!myfolderobj[this.myProjectAclList[i].groupname]) {
            myfolderobj[this.myProjectAclList[i].groupname]=i+1
            const folder={id: Id, name: this.myProjectAclList[i].groupname}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },
      pubProjects1:function(){
        if(this.projectFolder==null){
          return this.myProjectlist
        } else {
          // const projects=this.myProjectlist.filter(project=>project.groupname==this.pubFileFolder)
          const projects=this.myProjectAclList.filter(project=>project.groupname==this.pubFileFolder)
          return projects
        }
     },
    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      },
      projectFolder: function(){
        // this.pubProjects=this.myProjectlist.filter(project=>project.groupname==this.projectFolder)
        this.pubProjects=this.myProjectAclList.filter(project=>project.groupname==this.projectFolder)
      }
    },
    prop: ["menuVisible"],
    methods: {
      // closeMenu(){
      //   this.$emit('closeMenu')
      //   this.switchMenuMask()
      // },
      ...mapMutations({
          switchMenuMask: "switchMenuMask",
          updateSelectProjName: "updateSelectProjName"
      }),

      ...mapActions({
        getMyProjectAclList:'getMyProjectAclList',
      }),
      addFolder() {
          const folderName = prompt("请输入文件夹名称");
          if (folderName) {
              const newFolder = { id: this.folders.length + 1, name: folderName };
              this.folders.push(newFolder);
              this.projectFolder= newFolder;
          }
      },

      handlePubFileUpload(e){
        let file = e.target.files[0];
        this.uploadFile= e.target.files[0]
        const username=localStorage.getItem('username')
        
        const idff=uuidv4()
        const fileSizeInKB = (file.size / 1024).toFixed(2) + " KB"
        this.pubFileName = file.name
        this.newPubFile={idff:idff, project:this.pubProject,folder:this.projectFolder,name:this.pubFileName,publisher:username,
          approver: '',pubtime:'', size: fileSizeInKB, downloadtimes:0,summary:this.newPubFileSummary
        } 
        

        
      },
      async loadProject(){
        this.$emit("loadProject",this.loadedProject)
      },

      handleFileUpload(file) {
        if (!file) {
          console.log("请选择要上传的文件");
          return;
        }
        const token = localStorage.getItem('token')
        const formData = new FormData();
        formData.append('file', file);
    
        axios.post('https://api2.itaskid.com' + '/my/uploadpubfile', formData, {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          // console.log(response.data)
          return response.data.url
        })
        .catch(error => {
          console.log(error);
        });
      },

      async handleFileUpload1(file) {
        try {
          if (!file) {
            console.log("请选择要上传的文件");
            return;
          }
          const token = localStorage.getItem('token')
          const formData = new FormData();

          const encodedFileName = encodeURIComponent(file.name);
          formData.append('file', file, encodedFileName);
          const response = await axios.post(
            'https://api2.itaskid.com' + '/my/uploadpubfile',
            formData,
            {
              headers: {
                Authorization: token,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          // console.log(response.data.data.url)
          return response.data.data.url;
        } catch (error) {
          console.log(error);
        }
      
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();
      },


      async saveProject() {
        try {
          // 以下是保存新增的项目
          const token = localStorage.getItem('token')
          if (!this.username) {
            // 如果用户未登录，跳转到登录页面
            this.$router.push('/login');
            return;
          }
          const projectinfo = {
            name: this.projectName,
            groupname: this.projectFolder.name,
            charger: this.username,
          };
          // 发送 POST 请求
          const response = await axios({
            method: 'post',
            url: 'https://api2.itaskid.com' + '/my/addproject',
            headers: {
              Authorization: token,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: projectinfo
          });
      
          const newProj = response.data;
          this.$emit('updateLocalItems', newProj);
          this.updateSelectProjName(newProj)
          if (!response.data.status) {
            // 根据需求进行逻辑处理
          } else {
            alert(response.data.message);
          }
        } catch (error) {
          alert(error.response.data.message);
        }
        
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();
      },
      resetForm() {
          // this.projectName = "";
          // this.projectType = "type1";
          // this.projectFolder = null;
          this.$emit("closeLoadProjectMenu");
          this.switchMenuMask();
          

      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },

      convertDateFormat(data){
        const date = new Date(data)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        let hour = date.getHours()
        const minute = date.getMinutes()
        // 将小时转换为12小时制
        // let period = "am"
        // if (hour >= 12) {
        //   // hour -= 12
        //   period = "pm"
        // }
        // const formattedDate = `${year}/${month}/${day} ${hour}:${minute}${period}`
        const formattedDate = `${year}/${month}/${day} ${hour}:${minute}`
        return formattedDate
      }
    },
    mounted(){
      
      // this.getUsername();
      // const token = localStorage.getItem('token')
      // axios.get('https://api2.itaskid.com' + '/my/taskgroup',{
      //   headers: {
      //     Authorization: token
      //   },
      // })
      //   .then((response)=>{
      //     console.log('respones',response)
      //     if (Array.isArray(response.data)){
      //       const usernogroup=`${this.username}nogroup`
      //       const usertoday=`${this.username}today`
      //       const userweek=`${this.username}week`
      //       this.myProjectlist=response.data.filter(item => 
      //       item.charger==this.username&&(!(item.idfp==usernogroup)&&!(item.idfp==usertoday)&&!(item.idfp==userweek)))
      //       this.pubProjects=this.myProjectlist
      //       console.log('this.pubProjects',this.pubProjects)

      //     }else
      //     alert('账号未认证，无法获取数据！')
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   })

      // this.pubProjects=this.myProjectAclList
      
      const username=localStorage.getItem('username')
      this.getMyProjectAclList(username)
      .then((result)=>{
        this.myProjectAclList=result
        this.pubProjects=result


      })
      
      

    },

    components: { Row }
 }
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 400px;
  height: 210px;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  input #project-name {
    width: 410px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  input {
    width: 405px;
    height: 30px;
    padding: 0;
  }
  .form-group {
  width: 450px;
  margin: 10px 0;
  height: 30px;
  padding-left: 20px;
  }
  .menu-buttons {
    margin-right: 20px;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 290px;
    height: 30px;
    border:1px solid gray;
    border-radius: 5px;
    
  }

}


  
</style>