<template>
  <div class="taskgroup-container">
    <div style="border-bottom: 0.5px solid gray;">
      <h4 style="margin-top: 10px;margin-bottom: 10px;text-align: center;font-size: 16px;">添加项目模板</h4>
    </div>
    <div style="display: flex;max-height:calc(100% - 80px);min-height:calc(100% - 80px);">
      <div style="border-right: 1px solid rgba(200, 200, 200, 0.9); width: 100%;height:100%;">
        <div>
          <button class="tempOptionButton" style="border-right:solid 0.5px grey;" :class="{ active: selectedButton === 'intro' }"
            @click="gotoTempProjectIntro">
            项目简介
          </button>
          <button class="tempOptionButton" :class="{ active: selectedButton === 'tasklist' }" @click="gotoTempProjectTasklist">
            任务列表
          </button>
        </div>
        <div style="font-size: 14px;margin:5px;" class="project-container-leftcol" v-if="selectedButton === 'intro'&&selectedTempProject">
          <div style="width:100%;height:40px;">
            <label for="" style="font-weight: bold;">项目名称：</label>
            <input type="text" v-model="selectedTempProject.name" class="input-projectname" style="width:calc(100% - 80px);">
          </div>
          <div style="display: flex;height:40px;">
            <div style="width:50%">
              <label for="" style="font-weight: bold;">项目状态：</label>
              <el-select v-model="selectedTempProject.status" placeholder="请选择" size="small" style="width: 100px;">
                <el-option
                  v-for="item in tempProjectStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="width:50%">
              <label for="" style="font-weight: bold;padding-left:13px;">版本号：</label>
              <input type="text" v-model="selectedTempProject.version" class="input-projectname" style="width:calc(100% - 80px);">
            </div>
          </div>
          <div>
            <div style="width:100%">
              <div style="height:30px;">
                <label for="" style="font-weight: bold;">项目简述：</label>
              </div>
              <textarea class="projectdescription" v-model="selectedTempProject.description">
              </textarea>
            </div>
            <div style="width:100%">
              <div style="height:30px;">
                <label for="" style="font-weight: bold;">使用说明：</label>
              </div>
              <textarea class="projectdescription" v-model="selectedTempProject.usage_instruction">
              </textarea>
            </div>
          </div>
        </div>
        <div class="project-container-leftcol" v-if="selectedButton === 'tasklist'">
          <div v-for="task1 in tasklist1()" :key="task1.idftt" ref="task-ul">  
            <div class="task-container">
              <button v-show="hasNoChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)" style="margin-right:3px;">
                <i class="el-icon-arrow-right"></i>
              </button>
              <button v-show="hasNoChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                <i class="el-icon-arrow-down"></i>
              </button>
              <button  v-show="!hasNoChild(task1)">
              </button>
              <input type="checkbox" class="task-done" v-model="task1.checked" @change="tickTempTask(task1)">
              <label class="input-taskname">{{ task1.name }}</label>
              <label for="date-picker" class="task-starttime" v-show="showTaskTime(task1)">
                {{ showTime(task1.starttime) }}
              </label>
            </div>
            <div  v-for="task2 in taskListWithLevel(2,task1)" :key="task2.idftt" ref="task-li" 
              v-show="task1.expanded" style="padding-left: 10px;">
              <div  class="task-container">
                <button v-show="hasNoChild(task2)&&!task2.expanded" @click="toggleNode($event,task2)">
                  <i class="el-icon-arrow-right"></i>
                </button>
                <button v-show="hasNoChild(task2)&&task2.expanded" @click="toggleNode($event,task2)">
                  <i class="el-icon-arrow-down"></i>
                </button>
                <button  v-show="!hasNoChild(task2)">
                </button>
                <input type="checkbox" class="task-done" v-model="task2.checked" @change="tickTempTask(task2)">
                <label class="input-taskname">{{ task2.name }}</label>
                <label for="" class="task-starttime" v-show="showTaskTime(task2)">
                  {{ showTime(task2.starttime) }}
                </label>
              </div>
              <div v-for="task3 in taskListWithLevel(3,task2)" :key="task3.idftt"  
                ref="task-ul" v-show="task2.expanded" style="padding-left:10px;">
                <div  class="task-container">
                  <button v-show="hasNoChild(task3)&&!task3.expanded"  @click="toggleNode($event,task3)">
                    <i class="el-icon-arrow-right"></i>
                  </button>
                  <button v-show="hasNoChild(task3)&&task3.expanded"  @click="toggleNode($event,task3)">
                    <i class="el-icon-arrow-down"></i>
                  </button>
                  <button  v-show="!hasNoChild(task3)">
                  </button>
                  <input type="checkbox" class="task-done" v-model="task3.checked" @change="tickTempTask(task3)">
                  <label class="input-taskname">{{ task3.name }}</label>
                  <label for="" class="task-starttime" v-show="showTaskTime(task3)">
                    {{ showTime(task3.starttime) }}
                  </label>
                </div>
                <div v-for="task4 in taskListWithLevel(4,task3)" :key="task4.idftt"  
                  ref="task-li" v-show="task3.expanded" style="padding: 0 0 0 10px">
                  <div  class="task-container">
                    <button v-show="hasNoChild(task4)&&!task4.expanded" @click="toggleNode($event,task4)">
                      <i class="el-icon-arrow-right"></i>
                    </button>
                    <button v-show="hasNoChild(task4)&&task4.expanded" @click="toggleNode($event,task4)">
                      <i class="el-icon-arrow-down"></i>
                    </button>
                    <button  v-show="!hasNoChild(task4)">
                    </button>
                    <input type="checkbox" class="task-done" v-model="task4.checked" @change="tickTempTask(task4)">
                    <label class="input-taskname">{{ task4.name }}</label>
                    <label for="" class="task-starttime" v-show="showTaskTime(task4)">
                      {{ showTime(task4.starttime) }}
                    </label>
                  </div>
                  <div v-for="task5 in taskListWithLevel(5,task4)" :key="task5.idftt"  
                    ref="task-ul" v-show="task4.expanded" style="padding: 0 0 0 10px">
                    <div  class="task-container">
                      <button v-show="hasNoChild(task5)&&!task5.expanded" @click="toggleNode($event,task5)">
                        <i class="el-icon-arrow-right"></i>
                      </button>
                      <button v-show="hasNoChild(task5)&&task5.expanded" @click="toggleNode($event,task5)">
                        <i class="el-icon-arrow-down"></i>
                      </button>
                      <button  v-show="!hasNoChild(task5)">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                      <input type="checkbox" class="task-done" v-model="task5.checked" @change="tickTempTask(task5)">
                      <label class="input-taskname">{{ task5.name }}</label>
                      <label for="" class="task-starttime" v-show="showTaskTime(task5)">
                        {{ showTime(task5.starttime) }}
                      </label>
                    </div>
                    <div v-for="task6 in taskListWithLevel(6,task5)" :key="task6.idftt" 
                      v-show="task5.expanded" style="padding: 0 0 0 10px">
                      <div  class="task-container">
                        <input type="checkbox" class="task-done" v-model="task6.checked" @change="tickTempTask(task6)">
                        <label class="input-taskname">{{ task6.name }}</label>
                        <label for="" class="task-starttime" v-show="showTaskTime(task6)">
                          {{ showTime(task6.starttime)}}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div style="display: flex;height: 30px;text-align: right;">
      <button  style="margin-left: auto;margin-right: 5px;"  type="button" class="menubutton" @click="addTempProjectAndTasksToSql">添加</button>
      <button type="button" class="menubutton" style="margin-right:5px;" @click="closeMenu">取消</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState , mapMutations, mapActions} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
export default {
  components: {
  },
  props: ['items','addTempProjectOrTasks','addTempProjectTasks','addedTempProject'],
  data () {
    return {

      tempProjectTitle:'',
      tempProjects:[],
      selectedTempProjectName:'',
      selectedGroupName:'',
      clickedIdfp:'',
      selectedButton:'intro',
      tasklist:[],
      tasklistSelected:[],
      selectedTempProject:null,
      tasklistAclFromTemp:[],
      tempProjectStatusOptions:[
        {label:'未发布',value:'未发布'},
        {label:'已发布',value:'已发布'}
      ],
    }
  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
    }),

    ...mapActions({

    }),

   extractAndFindDuplicates(arr) {  
    // 检查数组是否为空  
    if (arr.length === 0) return []; // 空数组返回空数组  

    // 提取每个元素的 idftt 值  
    const idfttValues = arr.map(item => item.idftt);  

    // 用于存储出现过的 idftt 值  
    const seen = {};  
    // 用于存储重复的 idftt 值  
    const duplicates = [];  

    // 遍历 idftt 值数组  
    idfttValues.forEach(value => {  
        // 如果该值已经出现过，且不在 duplicates 中，则添加到 duplicates  
        if (seen[value]) {  
            if (!duplicates.includes(value)) {  
                duplicates.push(value);  
            }  
        } else {  
            // 记录该值的出现  
            seen[value] = true;  
        }  
    });  

    return duplicates; // 返回重复的 idftt 值数组  
},

    toggleNode(event,task){
      event.stopPropagation()
      task.expanded=!task.expanded
    },


    gotoTempProjectIntro(){
      this.selectedButton='intro'
    },

    gotoTempProjectTasklist(){
      this.selectedButton='tasklist'
    },


    tickTempTask(task) {  
        // 定义一个递归函数来处理子任务的勾选状态  
        const updateChildTasks = (taskId, checked) => {  
            this.tasklist.forEach(item => {  
                if (item.parent === taskId) {  
                    item.checked = checked;  
                    // 递归更新子任务  
                    updateChildTasks(item.idftt, checked);  
                }  
            });  
        };  
    
        // 定义一个函数来更新父任务的勾选状态  
        const updateParentTasks = (taskId) => {  
            const parentTask = this.tasklist.find(item => item.idftt === taskId);  
            if (parentTask) {  
                // 检查当前父任务的所有子任务  
                const childTasks = this.tasklist.filter(item => item.parent === parentTask.idftt);  
                // 检查是否有任何子任务被勾选  
                const anyChecked = childTasks.some(item => item.checked);  
                parentTask.checked = anyChecked;  
                // 递归更新父任务  
                updateParentTasks(parentTask.parent);  
            }  
        };  
    
        // 更新当前任务的勾选状态  
        if (task.checked) {  
            // 勾选任务，更新子任务  
            updateChildTasks(task.idftt, true);  
            // 勾选父任务及其所有父任务  
            updateParentTasks(task.parent);  
        } else {  
            // 取消勾选任务，更新子任务  
            updateChildTasks(task.idftt, false);  
            // 不更新父任务的状态  
        }  
    
        // 更新选中的任务列表  
        this.tasklistSelected = this.tasklist.filter(item => item.checked);  
        console.log('this.tasklistSelected', this.tasklistSelected);   
    },

    convertDate(targetDate) {
      if (targetDate == '') {
        targetDate = '2999/12/31 23:59:59';  // 设置默认时间为23:59:59
      }
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
    
      const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },



    closeMenu(){
      this.$emit('closeAddTempProjectMenu')
    },

    formatDateTime(dateTime) {
      const date=new Date(dateTime)
      const year = date.getFullYear();  
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始  
      const day = String(date.getDate()).padStart(2, '0');  
      const hours = String(date.getHours()).padStart(2, '0');  
      const minutes = String(date.getMinutes()).padStart(2, '0');  
  
      return `${year}/${month}/${day} ${hours}:${minutes}`;  
    },  


    addTempProjectAndTasksToSql(){
      if(this.selectedTempProject.name==''){
        alert('请输入项目名称！')
      }else if(this.selectedTempProject.version==''){
        alert('请输入版本号！')
      }else if(this.selectedTempProject.description==''){
        alert('请输入项目简述！')
      }else {
        console.log('this.selectedTempProject',this.selectedTempProject)
        const nowTime=new Date()
        this.selectedTempProject.createdtime=this.formatDateTime(new Date())
        this.selectedTempProject.updatetime=this.formatDateTime(new Date())
        this.selectedTempProject.price=0
        this.addTempProjectToSql(this.selectedTempProject)
          .then((res)=>{
            this.tasklistSelected.forEach((task)=>{
              task.starttime=this.formatDateTime(task.starttime)
              task.endtime=this.formatDateTime(task.endtime)
              task.project=this.selectedTempProject.idfp
              this.addTempTaskToSql(task)
            })
          })

      }

      this.$emit('closeAddTempProjectMenu')
       
    },

    //向服务器添加项目,并同时添加任务权限
    async addTempTaskToSql(newTask) {
      const token = localStorage.getItem('token')
      try {
        const response = await axios({
          method: 'post',
          // url: 'https://api2.itaskid.com' + '/my/addtemptask',addtemptaskfromtasklist
          url: 'https://api2.itaskid.com' + '/my/addtemptask',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newTask
        });
  
        return response.data;
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },

    //向服务器添加项目,并同时添加任务权限
    async addTempProjectToSql(newProject) {
      try {
        const token = localStorage.getItem('token')
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/addtempproject',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newProject
        });

        console.log('response.data',response.data)
  
        return response.data;
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },






    //以下是新增项目菜单的处理函数
    showAddProMenu(){
      this.isShowMenu=!this.isShowMenu
      this.switchMenuMask()
      
    },
    shutMenu(){
      this.isShowMenu=false
    },
    //这个函数是把前端对服务器的增删改查在组件未更新前先更新本地化数据，以达到视图及时更新的目的
    updateLocalItems(newItem) {  
      this.items.push(newItem)
    },

    //以上是编辑项目菜单的函数
   

    //以下是编辑项目的处理函数
    cancelEditPro(){
      this.showProjEdit=false
      this.switchMenuMask()
    },
    closeEditProj(){
      this.showProjEdit=false
      this.switchMenuMask()
    },


    //以下是共享项目的处理函数
    cancelSharePro(){
      this.showShareProjectMenu=false
      this.switchMenuMask()
    },



    closeAddProjectMenu(){
      this.isShowMenu=false
    },


    searchTempProject(){
      this.getTempProjectsBySearchText(this.tempProjectTitle)
        .then((result)=>{
          this.tempProjects = result
        })
    },

    // 以上是操作服务器数据库的方法
    disableContextMenu(event) {
      // 禁用默认行为
      event.preventDefault();
    },


    toggle(){
      this.expended=!this.expended
    },


    toggleGroup(group) {
      // this.expandedGroups[group] = !this.expandedGroups[group]
      this.$set(this.expandedGroups, group, !this.expandedGroups[group])
    },
    isGroupExpanded(group) {
      return this.expandedGroups[group]===true
    },


  },
  computed: {
    ...mapState({
      selectProjName: 'selectProjName',
      selectProjGroup: 'selectProjGroup',
      selectProjIdfp: 'selectProjIdfp',
      user:'user'
    }),

    formattedTempProjectName(){
      return (project)=>{
        const ProjName=`${project.name}(版本号${project.version})`
        return ProjName
      }
    },

    formattedTime() {
      return (originalTime)=>{
        // 创建一个 Date 对象  
        const date = new Date(originalTime);  
        
        // 转换为中国标准时间（UTC+8）  
        const options = {  
          year: 'numeric',  
          month: '2-digit',  
          day: '2-digit',  
          hour: '2-digit',  
          minute: '2-digit',  
          second: '2-digit',  
          hour12: false, // 24小时制  
          timeZone: 'Asia/Shanghai' // 设置为中国时区  
        };  
  
        // 使用 toLocaleString 方法格式化日期  
        const formattedDate = date.toLocaleString('zh-CN', options);  
  
        // 将格式化后的日期转换为 YYYY-MM-DD HH:mm:ss 格式  
        return formattedDate.replace(/\//g, '-').replace(' ', ' '); // 替换斜杠为连字符
      }  
  
    },

    computedInputDisabled(){
      if(this.addTempProjectOrTasks=='task'){
        return true
      }else {
        return false
      }
    },

    groupedItems() {
      const groups = {}

      // 确保 this.items 是一个数组  
      if (!Array.isArray(this.items)) {  
        return groups; // 如果不是数组，返回空对象  
      } 

      this.items.forEach(item => {
        if (!groups[item.prjgroup_altname]) {
          groups[item.prjgroup_altname] = []
        }
        groups[item.prjgroup_altname].push(item)
      })
      return groups
    },

    

    hasNoChild:function() {
      return function(task) {
        const noChilds = this.tasklist.filter(i => i.parent === task.idftt);
        if (noChilds.length < 1) {
          return 0;
        } else {
          return 1;
        }
      }
    },

    showTaskTime(){
      return (task)=>{
        
        const today=new Date()
        const thisYear=today.getFullYear()
        const taskDate=new Date(task.starttime)
        var taskYear=taskDate.getFullYear()
        var taskMonth=taskDate.getMonth()+1
        var taskDay=taskDate.getDate()
        const todayOfStr=this.convertDate(new Date())
        var taskDateOfStr=this.convertDate(task.starttime)
        var lastDate
        if(todayOfStr==taskDateOfStr){
          lastDate=true
        } else if(taskYear==thisYear){
          lastDate=true
        }else if(taskDateOfStr=='2999/12/31'){
          lastDate=false
        }else {
          lastDate=true
        }
        return lastDate
      }
    },

    showTime(){
      return (dateTime)=>{
        const today=new Date()
        const thisYear=today.getFullYear()
        const taskDate=new Date(dateTime)
        const date = new Date(dateTime);
        const year = date.getFullYear();
        var taskYear=taskDate.getFullYear()
        var taskMonth=taskDate.getMonth()+1
        var taskDay=taskDate.getDate()
        const todayOfStr=this.convertDate(new Date())
        var taskDateOfStr=this.convertDate(dateTime)
        var lastDate
        if(todayOfStr==taskDateOfStr){
          lastDate='今天'
        } else if(taskYear==thisYear){
          lastDate=`${taskMonth}月${taskDay}日`
        }else if(year=='2999'){
          lastDate=''
        }else {
          lastDate=`${taskYear}-${taskMonth}-${taskDay}`
        }
        return lastDate
      }
    },

    tasklist1(){
      return()=>{
        if(Array.isArray(this.tasklist) && this.tasklist.length > 0){
          const tasklist1=this.tasklist.filter(task=>task.level==1)
          tasklist1.sort((a, b) => new Date(a.starttime) - new Date(b.starttime));
          return tasklist1
        }else {
          return []
        }

      }
    },


    taskListWithLevel() {   
      return (level, parentTask) => {  
          const filteredTasks = this.tasklist.filter(task =>   
              task.parent === parentTask.idftt && task.level == level 
          );  
          // 按照 starttime 排序  
          // filteredTasks.sort((a, b) => new Date(a.starttime) - new Date(b.starttime));  
          return filteredTasks;  
      };  
    },

    isprojectClicked(){
      return(idfp)=>{
        if(idfp==this.clickedIdfp) {
          return true
        }else {
          return false
        }
      }
    },

    
  },


  mounted(){
    this.tasklist=this.addTempProjectTasks
    this.tasklistSelected=this.addTempProjectTasks
    this.selectedTempProject=this.addedTempProject
    // console.log('duplicates',this.extractAndFindDuplicates(this.tasklist))
    // const groups = {}
    //   this.items.forEach(item => {
    //     if (!groups[item.prjgroup_altname]) {
    //       groups[item.prjgroup_altname] = []
    //     }
    //     groups[item.prjgroup_altname].push(item)
    //   })
    //   console.log('groups',groups)
  },
  created() {

  }    //错误，还不知道如何修改
}
</script>

<style lang="less" scoped>
.addproject-menu {
  position: absolute;
  height: 0;
  width: 0;
  overflow: visible;
}
.el-col {
  max-height: 100% !important;
  overflow: auto !important;
}
.taskgroup-header {
  border-bottom: 1px solid gray;
  height: 30px;
  margin-top:0px;
  margin-bottom:0px;
  text-align: center;
  padding:5px;

}
.taskgroup-body {
  border-bottom: 1px solid gray;
  height: calc(100vh - 205px);
  border-bottom: none;
  overflow: auto;

  ul {
    padding:0px 0px 0px 15px;
  }

}

li {
    height: 30px;
    list-style: none;
    padding-left: 10px;
    line-height: 30px;
    margin-right: 5px;
  }
  .project-container {
    line-height: 30px;
    background-color: transparent;
  }

.context-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.el-main {
  line-height: 20px !important;
}


.menu-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.5);
  z-index: 99;
}
::-webkit-scrollbar {
  width: 8px;
  height: 200px;
  background: none;
}

div.inbox-container:hover {
  cursor: pointer;
  color: rgb(71, 114, 250); 
}

.projectclicked {
  color: rgb(71, 114, 250); 
}

.prjgroup-title {
  /* 让文本在需要时换行 */
  word-wrap: break-word; /* 或 overflow-wrap: break-word; */
  word-break: break-all;

  /* 禁止文本被选择 */
  user-select: none;

  /* 以下是额外的样式，确保内容不会超出容器宽度 */
  // max-width: 100%; /* 限制最大宽度，防止溢出 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 在文本溢出时显示省略号 */
}

.badget {
    position:absolute;
    font-size: 12px;
    padding-top:1px;
    width:22px;
    height:14px;
    border-radius: 6px;
    left:70px;
    top:-8px;
    // background-color: rgb(245, 108, 108);
  }

  .searchtempprojectinput { 
    margin:0;
    width: calc(100% - 5px);
    height: 30px;
    border: none;
    background-color: rgba(250, 250, 250, 0.9);
    border-radius: 5px;
  }

  input.searchtempprojectinput:focus {
    outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
  }

  .taskgroup-container {
    position: absolute;
    left: 50%;
    top: calc(50% + 30px);
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 1);
    width: 40%;
    height: calc(100% - 80px);
    border:none;
    z-index: 100;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 这里可以调整阴影的参数 */
  }

  .projectclicked {
    color: rgb(71, 114, 250); 
  }

  .menubutton {
    border:solid 0.5px gray;
    width:60px;
  }

.project-container-leftcol {
  // overflow: auto;
  overflow-y: auto; /* 竖向滚动条 */ 
  height:calc(100vh - 200px);
  // max-height:calc(100% - 75px);
  margin-right:5px;
  max-height:calc(100% - 75px);

  .taskinputclicked {
    color: rgb(71, 114, 250);
  }

  .task-container {
    height: 30px;
    display: flex;
    
    .el-icon-arrow-right {
      line-height: 5px;
    }

    .el-icon-arrow-down {
      line-height: 5px;
    }    


    .input-taskname { 
      flex: 1;
      float: left;
      line-height: 29px;
      height: 29px;
      background-color: transparent;
      border: none;
      outline:none;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 0px;
      padding: 0px !important;
      border-bottom: 1px solid rgba(200, 200, 200, 0.9);
    }
    .task-starttime {
      height: 29px;
      line-height: 30px;
      float: right;
      flex: 0 0 auto;
      text-align: right;
      border-bottom: 1px solid rgba(200, 200, 200, 0.9);
      font-size: 12px;
      color: rgb(71, 114, 250)
      
    }
  
  }

  .task-done {
    margin: 3px;
  }

  button {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 3px
  }
  .task-title {
    line-height: 30px;
    display: inline-block;
    text-align: left;
    border-bottom: 0.5px solid rgba(200, 200, 200, 0.9);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .task-title:focus {
    outline: none;
  }



  ul {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
    margin: 0;
  }

  li {
    list-style: none;
    padding-inline-start: 10px;
    
  }


  button {
    border:none;
    background: none;
    color: gray;
  }
  button:hover {
    color: black;
  }
  .routerlink {
    height: 30px;
  }


}

.tempOptionButton {
  width:50%;
  background: rgb(240, 240, 240);
  border: none;
  height:30px;
  
}

.tempOptionButton.active {  
  // background-color: rgb(66, 171, 242); /* 选中时的背景色 */  
  color: rgb(66, 171, 242); /* 选中时的字体颜色 */  
}


  .input-projectname { 
    // flex: 1;
    // float: left;
    line-height: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    outline:none;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0px;
    padding: 0px !important;
    border-bottom: 1px solid rgba(200, 200, 200, 0.9);
  }

  .projectdescription {
    width:calc(100% - 10px);
    min-height:100px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    overflow:auto;
  }
  .projectdescription:focus {
    outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
  }
</style>