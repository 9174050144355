
<template>
  <div style="text-align: left;">
    <div class="pubfiledetail-container" v-if="localFile">
      <div style="float: left; width: 100%;">
        <div style="padding: 0 0 0 10px; border-bottom: 1px solid gray;">
          <label for="" style="font-weight:bold;">共享文件:</label>
          <label style="margin-left:10px;">{{ selectedFile?.filename }}</label>

          <div style="display: inline; margin-right:20px;float:right;">
            <button class="savebutton" @click="switchView(2)" :style="{backgroundColor: ViewOption === 2 ? 'rgb(145, 170, 252)' : ''}">
              讨论
            </button>
            <button class="savebutton" @click="switchView(1)" :style="{backgroundColor: ViewOption === 1 ? 'rgb(145, 170, 252)' : ''}">
              备忘
            </button>
          </div>
        </div>
        <div style="height: calc(100vh - 150px);" v-if="ViewOption === 2">
          <FileMessage :messages="fileChatMsgs" :selectedProjectAcls="selectedProjectAcls" @sendMessage="sendFileMessage"
            @sendFileMessageOfFile="sendFileMessageOfFile" @sendFilesToFile="sendFilesToFile">
          </FileMessage>
        </div>
        <div style="height: calc(100vh - 140px);" v-if="ViewOption === 1">
          <textarea v-model="localFile.summary" class="shareFileSummary" @change="updateShareFileSummary">
          </textarea>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import { mapState , mapMutations,mapActions} from 'vuex'
import  axios from 'axios'
import FileMessage from './FileMessage.vue'
export default {
  name: 'ShareFileDetail',
  components: {
    FileMessage
  },
  data() {
    return {
      // sharedFileSummary:'',
      publisher: '',
      fileDetails: '',
      pubfiles: [],
      filetype: '',
      filename:'',
      filesize: '',
      filedownloadtimes:0,
      filesummary:'',
      filepublisher:'',
      filepubtime: '',
      fileProject: '',
      fileFolder: '',
      localFile:null,
      ViewOption:1,
      myFileAcls:[
        {message:'haod',updatetime:'2023/12/5 14:00'},
        {message:'xx',updatetime:'2023/12/3 15:37'}
      ],
      reverse:false,




    }
  },
  props:['selectedFile', 'fileChatMsgs','selectedProjectAcls'],
  computed:{
    processedLocalFile() {
      // 对localFile进行处理，返回处理后的数据
      // 例如：
      if (this.localFile) {
        return {
          filename: this.localFile.filename,
          filesize: this.localFile.size,
          // 其他属性...
        };
      } else {
        return null;
      }
    },
    computedMsg(){
      return(msg)=>{
        var senderStrs=msg.sender.split('|')
        const roler=senderStrs[0]
        const sender=senderStrs[1]
        if(roler=='publisher'){
          if(sender==this.selectedFile.approved){
            const str=this.selectedFile.isfolder==1?'创建了新的文件夹。':'发布了文件'
            return str
          } else {
            return '发起文件审批：'
          }
        } else if(roler=='checker'){
          return '审核人意见：'
        } else if(roler=='approver'){
          return '审批人意见：'
        } else if(roler=='published'){
          return '发起人发布了文件：'
        } else {
          return ''
        }
      }
    },
    computedSender(){
      return(senderMsg)=>{
        var senderStrs=senderMsg.split('|')
        const sender=senderStrs[1]
        return sender
      }
    },

    computedNickname(){
      return(username)=>{
        this.getNicknameByUsername(username)
          .then((result)=>{
            return result
          })
      }
    },

    datetimeConvert(){
      return(datetime)=> {
    const date = new Date(datetime);
    const options = {timeZone: 'Asia/Shanghai', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
    const formattedTime = date.toLocaleString('zh-CN', options);
    return formattedTime;
  }
    },

  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
    }),
    ...mapActions({
      getFileinfoByIdff:'getFileinfoByIdff',
      // getNicknameByUsername:'getNicknameByUsername',
      getChatmsgsByReceiverAndAboutwhat:'getChatmsgsByReceiverAndAboutwhat',
      addChatmsg:'addChatmsg',
      updateSharefileByIdfsf:'updateSharefileByIdfsf',
      updateSharefileSummaryByIdfsf:'updateSharefileSummaryByIdfsf',
    }),    

    switchView(n){
      this.ViewOption=n
      switch(n){
        case 2: {
          console.log('xx',this.fileChatMsgs,this.selectedProjectAcls)
          break
        }
      }
    },

    updateShareFileSummary(){
      this.localFile.updatetime=this.datetimeConvert(this.localFile.updatetime)
      this.updateSharefileSummaryByIdfsf(this.localFile)
    },

    sendFileMessageOfFile(fileMsg){
      this.$emit('sendFileMessage',fileMsg)
    },


    sendFilesToFile(files){
      this.$emit('sendFilesToFile',files)
    },

    sendFileMessage(fileMsg){
      this.$emit('sendFileMessage',fileMsg)
      // const now=new Date()

      
      // // 发送消息给socket.io服务器
      // const newFileMessage={
      //   message: fileMsg,
      //   receiver: this.selectedFile.idff,
      //   sender: this.username,
      //   file_name:this.selectedFile.filename,
      //   img_name:'',
      //   type: 'text',
      //   timestamp: now,
      //   file_size: 0,
      //   aboutwhat:'filechat'
      // }

      // this.addChatmsg(newFileMessage)
      // this.socket.emit('message',newFileMessage,(response)=>{
      //   console.log('Server response:',response)
      // })
  
      // this.addTaskMessageToSql(newFileMessage)
      // this.socket.emit('message', this.newMessage, (response) => {
      //   console.log('Server response:', response);
      // });
      // this.newMessage = {}

      // this.$emit('sendTaskMessage',newTaskMessage)
      
    },

    async getNicknameByUsername(username) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/getnicknamebyusername', {
        headers: {
          Authorization: token,
          'Content-Type':'application/x-www-form-urlencoded'
        },
        params: { username: username }
      });
      // if (Array.isArray(response.data)) {
      //   return response.data;
      // } else {
      //   alert('账号未认证，无法获取数据s！');
      // }
      if (response.data) {
        return response.data.data;
      } else {
        alert('账号未认证，无法获取数据s！');
      }
    } catch (error) {
      console.log(error);
    }
  },



  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(params) {
        const idfp = params.idfp;
        const idfsf = params.idfsf
        
        // console.log('FileDetailparams',params)
        // this.getFileinfoByIdff(idff)
        //   .then((result)=>{
            
        //     this.localFile=result
        //     console.log('this.localFile',this.localFile)
        //   })

      }
    },

    'selectedFile':function(){
      this.localFile=this.selectedFile  
      // this.sharedFileSummary=this.selectedFile.summary
    },

    // 'localFile' :function(){
    //   // this.updateSharefileByIdfsf(this.localFile)
    //   console.log('s',this.localFile)
    // }
  },
  mounted(){
    const username=localStorage.getItem('username')
    this.localFile=this.selectedFile
    // this.sharedFileSummary=this.selectedFile.summary
    
    this.getNicknameByUsername('yuning')
      .then((result)=>{
        // console.log('nickname',result.nickname)
      })
    

    
    // this.getChatmsgsByReceiverAndAboutwhat(this.selectedFile.idff,'filechat')
    //   .then((result)=>{
    //     console.log('this.fileChatMsgs',result)
    //   })
  },
}
</script>

<style lang="less" scoped>
  h4 {
    height: 40px;
    margin: 0;
  }

  .pubfiledetail-container {
    display: inline-block;
    text-align: left;
    width: 100%;

    .filename-container {
      word-wrap: break-word;
      width:100%;
      font-size: 14px;
    }
  }

  .filenametitle {
    word-wrap: break-word; 
  }


.savebutton {
  margin-left: 5px;
  float:right;
  width: 50px;
  height: 22px;
  padding:0;
  border-radius: 5px;
  border:none;
  background-color: rgb(235, 235, 235);
}

.el-timeline {
  padding:0;
}

.el-card_body {
  width:300px;
  height:80px;
}

.el-timeline-item {
  height: 80px;
}

.shareFileSummary {
  height: calc(100vh - 120px);
  width: calc(100vw - 850px);
  border:none;
}

.shareFileSummary:focus {
  outline:solid rgba(64, 158, 255,0.7) 0.5px;
  border:none;
}


</style>