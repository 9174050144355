<template class="myaside">
  <div>
    <div class="box">
      <el-button style="background-color: #B3C0D1;">
        <i class="el-icon-circle-check"></i><br>
        <p>任务</p>
      </el-button>
    </div>
    <div class="box">
      <el-button style="background-color: #B3C0D1;">
        <i class="el-icon-date"></i><br>
        <p>日历</p>
      </el-button>
    </div>
    <div class="box">
      <el-button style="background-color: #B3C0D1;">
        <i class="el-icon-edit-outline"></i><br>
        <p>计划</p>
      </el-button>
    </div>
    <div class="box">
      <el-button style="background-color: #B3C0D1;">
        <i class="el-icon-user"></i><br>
        <p>通讯</p>
      </el-button>
    </div>
    <div class="box">
      <el-button style="background-color: #B3C0D1;">
        <i class="el-icon-suitcase"></i><br>
        <p>文件</p>
      </el-button>
    </div>
    <div class="box">
      <el-button style="background-color: #B3C0D1;">
        <i class="el-icon-setting"></i><br>
        <p>设置</p>
      </el-button>
    </div>
  </div>

</template>
<script>
  export default {
    name: 'MyAside',
  }
</script>

<style lang="less" scoped>
  .el-button {
    width: 60px;
    height: 60px;
    margin:0.5px 2.5px;
    border: none !important;
  }

  .el-button:hover {
    background-color: rgb(127, 127, 127) !important;
  }

  .el-button:focus {
    background-color: rgb(127, 127, 127) !important;
  }

  div .box {
    border: none;
  }


  i {
    font-size: 18px;
  }

  p {
    font-size: 12px;
    font-weight: bold;
  }



  
</style>