<template>
  <div class="menu-container" v-draggable>
      <div class="menu">
      <h4 style="text-align: center; margin:13px 0px;">新建共享文件/新建文件夹</h4>
      <form>
        <div class="form-group">
          <input type="text" id="project-name" v-model="shareFileName" placeholder="文件或文件夹名称" 
            autocomplete="off" class="borderstyle">
          <input type="text" id="project-name" v-model="shareFileUrl" placeholder="输入共享链接地址"  style="margin-top:10px;"
            autocomplete="off" class="borderstyle">
        </div><br>
        <div style="margin:10px 0 0 20px;">
          <label for="">新建文件夹</label>
          <input type="checkbox" style="width:20px;margin-left:10px;" v-model="ifCreateFolder">
        </div>
        <div class="form-group">
          <label for="project-folder">项目组</label>
          <el-select v-model="projectFolder" placeholder="请选择"  filterable remote
            style="width:290px; height:30px;margin-left:20px;">
            <el-option class="el-option"
            v-for="folder in folders" :key="folder.id"
              :label="folder.name"
              :value="folder.name">
            </el-option>
          </el-select>
        </div>
        <div class="form-group">
          <label for="project">项&nbsp;&nbsp;&nbsp;目</label>
          <el-select v-model="pubProjectIdfp" placeholder="请选择" filterable remote @change="computedPubProject()"
            style="width:290px; height:30px;margin-left:22px;">
            <el-option class="el-option"
              v-for="project in pubProjects" :key="project.id"
              :label="project.name"
              :value="project.idfp">
            </el-option>
          </el-select>
        </div>
        <div class="form-group">
          <label for="project-folder">文件夹</label>
          <el-select v-model="shareFileFolderIdfsf" placeholder="请选择" filterable remote @change="changeFolder"
            style="width:290px;margin-left:20px;">
            <el-option class="el-option"
              v-for="folder in allMyShareFileFolders"
              :key="folder.idfsf"
              :label="folder.filename"
              :value="folder.idfsf">
            </el-option>
          </el-select>
        </div>
      </form>
      <div class="menu-buttons">
        <button type="button" @click="shareNewFile">新建</button>
        <button type="button" @click="resetForm" style="margin-left: 10px">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations, mapActions} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
// import qs from 'qs'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "AddShareFileMenu",
    props:['allMyShareFileFolders','selectedParentFolder','myProjectlist','selectedFile'],
    data() {
        return {
            shareFileName: '',
            shareFileUrl: '',
            shareFileFolderLevel:'',
            projectName: "",
            pubProjectIdfp:'',
            projectType: "type1",
            projectFolder: null,
            pubProjectName:'',
            pubProjects:[],
            pubProjectParters:[],
            pubProject:null,
            shareFileFolderIdfsf:'',
            // myProjectlist: [],
            projects:'',
            username:'',
            saveOrNot:true,
            newPubFile:null,
            ifCreateFolder:false,
            selectedParentFolder1:null,
        };
    },
    computed: {
      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        for(var i=0; i<this.myProjectlist.length;i++) {
          if(!myfolderobj[this.myProjectlist[i].groupname]) {
            myfolderobj[this.myProjectlist[i].groupname]=i+1
            const folder={id: Id, name: this.myProjectlist[i].groupname}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },
      
    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      },
      projectFolder: function(){
        this.pubProjects=this.myProjectlist.filter(project=>project.groupname==this.projectFolder)
      },

      ifCreateFolder: function(){
        if(this.ifCreateFolder){
          this.shareFileUrl=''
        }
      },


      shareFileFolderIdfsf: function(){
        if(!this.shareFileFolderIdfsf){
          this.shareFileFolderLevel=1
        }else {
          const parentFile=this.allMyShareFileFolders.find((item)=>{
            if(item.idfsf==this.shareFileFolderIdfsf){
              return item
            }
          })
          this.shareFileFolderLevel=parentFile.file_level+1
        }
      },


    },
    methods: {
      ...mapMutations({
          switchMenuMask: "switchMenuMask",
          updateSelectProjName: "updateSelectProjName"
      }),
      ...mapActions({
        getProjectAclListByIdfp:'getProjectAclListByIdfp',
        getMyProjectAclList:'getMyProjectAclList',
        getMyProjectListByIdfp:'getMyProjectListByIdfp',
      }),

      changeFolder(){
        var folder
        if(this.shareFileFolderIdfsf==''){
          folder=null
          console.log('folder',folder)
        } else {
          this.selectedParentFolder1=this.allMyShareFileFolders.find(item=>item.idff==this.shareFileFolderIdfsf)
        }
      },


      computedPubProject(){
        this.pubProject=this.pubProjects.find(item=>item.name==this.pubProjectName)
     
        this.getProjectAclListByIdfp(this.pubProjectIdfp)
          .then((result)=>{
            this.pubProjectParters=result
          })
      },


      shareNewFile(){
        const file_parent=this.shareFileFolderIdfsf==''?'rootfile':this.shareFileFolderIdfsf
        const username=localStorage.getItem('username')
        const pubtime=this.convertDateFormat(new Date())
        const fileExtension = this.shareFileName.split('.').pop()   
        const file_level=!this.shareFileFolderLevel?1:this.shareFileFolderLevel
        console.log('file_level',file_level)
        const isfolder=this.ifCreateFolder?1:0
        const newShareFile={
          idfsf:uuidv4(),
          filename:this.shareFileName,
          url:this.shareFileUrl,
          project:this.pubProjectIdfp,
          sharer:username,
          updatetime:pubtime,
          summary:'',
          file_parent:file_parent,
          file_level:file_level,
          isfolder:isfolder,
          file_expanded:1,

        }
        this.$emit("shareNewFile",newShareFile)
        this.$emit("closeFilePubMenu");
        this.switchMenuMask();
        // }

      },

      resetForm() {
        console.log('qing',this.projectFolder,this.pubProjectIdfp,this.shareFileFolderIdfsf)
        this.$emit("closeFilePubMenu");
        this.switchMenuMask();
          

      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },

      convertDateFormat(data){
        const date = new Date(data)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        let hour = date.getHours().toString().padStart(2, '0')
        const minute = date.getMinutes().toString().padStart(2, '0')
        const formattedDate = `${year}/${month}/${day} ${hour}:${minute}`
        return formattedDate
      }
    },
    mounted(){
      this.pubProjects=this.myProjectlist
      this.selectedParentFolder1=this.selectedParentFolder
      this.shareFileFolderIdfsf=this.selectedFile?this.selectedFile.idfsf:''
      this.pubProjectIdfp=this.selectedFile?this.selectedFile.project:''
      const selectedProject=this.myProjectlist.find((item)=>{
        if(this.selectedFile&&item.idfp==this.selectedFile.project){
          return item
        }
      })
      
      this.projectFolder=selectedProject?selectedProject.groupname:null


      // if(this.selectedParentFolder!==null){
          
      //     this.shareFileFolderIdfsf=this.selectedParentFolder.idfpf
      //     this.pubProjectIdfp=this.selectedParentFolder.project
      //     this.myProjectlist.map((project)=>{
      //       if(project.idfp==this.pubProjectIdfp){
      //         this.projectFolder=project.groupname
      //       }
      //     })

      //     this.computedPubProject()
          
      //   }


      // this.getUsername();
      // const token = localStorage.getItem('token')
      // axios.get('https://api2.itaskid.com' + '/my/taskgroup',{
      //   headers: {
      //     Authorization: token
      //   },
      // })
      //   .then((response)=>{
      //     if (Array.isArray(response.data)){
      //       const usernogroup=`${this.username}nogroup`
      //       const usertoday=`${this.username}today`
      //       const userweek=`${this.username}week`
      //       this.myProjectlist=response.data.filter(item => 
      //       item.charger==this.username&&(!(item.idfp==usernogroup)&&!(item.idfp==usertoday)&&!(item.idfp==userweek)))
      //       this.pubProjects=this.myProjectlist

      //     }else
      //     alert('账号未认证，无法获取数据！')
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   })

      const username=localStorage.getItem('username')
      const usernogroup=`${username}nogroup`
      let projectAcllist=[]
      this.getMyProjectAclList(username)
        .then((result)=>{
          projectAcllist=result
          if(projectAcllist&&projectAcllist.length>0) {
            const promises=projectAcllist.map(async (projectacl)=>{
              const project=await this.getMyProjectListByIdfp(projectacl.project_idfp)
              projectacl.name=project[0].name
              projectacl.groupname=project[0].groupname
              projectacl.status=project[0].status
              projectacl.is_delete=project[0].is_delete
              projectacl.createdTime=project[0].createdTime
              projectacl.creater=project[0].creater
              projectacl.permit_type=project[0].permit_type
              projectacl.idfp=project[0].idfp
            })
            return Promise.all(promises)
          }
        })
          .then(()=>{
            // this.myProjectlist=projectAcllist.filter(item=>(!(item.idfp==usernogroup)))
            // this.pubProjects=this.myProjectlist
            // this.items=this.myProjects
            // this.myProjects.forEach((project)=>{
            //   this.myProjectIdfpArray.push(project.idfp)
            // })
          })
      
      

    },

    components: { Row }
 }
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 400px;
  height: 350px;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  input #project-name {
    width: 410px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  // input {
  //   width: 405px;
  //   height: 30px;
  //   padding: 0;
  // }
  .form-group {
     width: 380px;
     margin: 10px 0;
     height: 35px;
     padding-left: 20px;

     input {
      width:350px;
      height: 30px;
     }


  }
  .menu-buttons {
    margin-right: 20px;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}

.borderstyle {
  border:0.5px solid rgb(207, 210, 216);
  border-radius: 3px;
}

.borderstyle:focus {
  outline: 0.5px solid rgb(111, 181, 254);
  
}









  
</style>