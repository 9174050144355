<template>
  <div ref="menu" class="menu" :style="{top: top + 'px', left: left + 'px'}">
    <table class="friendtable" style="margin: 20px 30px;">
      <tr>
        <td rowspan="3">
          <img :src="searchUser.avatarUrl" alt="Avatar" :style="{ width: '65px', height: '65px', borderRadius: '10%' }"
            v-if="searchUser.avatarUrl!==undefined"> 
          <div v-if="searchUser.avatarUrl==undefined" class="friendmsg-altavatar" style="width:65px; height:65px;">
           {{ showAltNickname(searchUser.nickname) }}
          </div> 
        </td>
        <td style="width: 20px;" rowspan="4"></td>
        <td style="height: 25px;width: 120px; font-size: 16px; color: rgb(22, 22, 22); padding-top: 15px;">
          {{ searchUser.nickname }}
        </td>
      </tr>
      <tr>
        <td>
          地区:&nbsp;&nbsp;{{ searchUser.location }}
        </td>
      </tr>
      <tr style="height:10px;"></tr>
      <tr style="height:37px;">
        <td style="text-align: center;" colspan="2">
          <button style="width: 90px; line-height: 30px; font-size: 16px; border:none; background-color: rgb(56, 205, 127); 
            color: rgb(255, 255, 255); border-radius: 5px;" @click="addFriendship">
            添加好友
          </button>
        </td>
        <td style="text-align: center;" colspan="2">
          <button style="width: 90px; line-height: 30px; font-size: 16px; border:none; 
            background-color: rgb(56, 205, 127); color: rgb(255, 255, 255); border-radius: 5px;">
            发送文件
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapMutations} from 'vuex'
export default {
  name: 'FriendInfo',
  props: {
    parentNode: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    },    
    searchUser: {
      type: Object,
      required: true
    }, 
  },
  data() {
    return {
      isOpen: false,
      top: 0,
      left: 0,
      avatarUrl:'',
      friendCommentName:'',
      friendNickName:'',
      account:'',
      friendlocation:'',
      friendCommentName:'',
      friendOrigin:'',


    }
  },
  mounted() {
    this.calculatePosition()
    
  },

  computed: {
    showAltNickname(){
      return(nickname)=>{
        const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
        const altNikname = matchResult ? matchResult[0] : ''
        return altNikname
      }
    },
  },

  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask'
    }),
    calculatePosition() {
      this.left=this.parentNode.clientX
      this.top=this.parentNode.clientY
    },
    menuItemClick(item) {
      this.$emit('addFriendshipMenuitemClick', item)
    },
    addFriendship(){
      this.$emit('addFriendship')
    },
  },
}
</script>

<style scoped>
.menu {
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  border: none;

}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  padding: 6px 12px;
  line-height: 1.5;
  font-size: small;
  cursor: pointer;
}

li:hover {
  background-color: #f5f5f5;
}

.friendmsg-altavatar {
  width: 65px;
  height: 65px;
  border-radius: 10%;
  background: rgb(144, 82, 245);
  color:rgb(255, 255, 251);
  font-size: 20px;
  line-height: 65px;
  text-align: center;
  display: inline;
  margin: 5px 0px 0px 5px;
  padding:18px 14px 18px 14px;
}
</style>
