<template>
  <div ref="menu" class="menu" :style="{top: top + 'px', left: left + 'px'}">
    <ul>
      <li v-for="(item, index) in projeditMenuitems" :key="index" @click="menuItemClick(item)">
        {{item.label}}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations} from 'vuex'
export default {
  name: 'EditProjectMenu',
  props: {
    projeditMenuitems: {
      type: Array,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    parentNode: {
      type: Object,
      required: true
    },

  },
  data() {
    return {
      isOpen: false,
      top: 0,
      left: 0
    }
  },
  mounted() {
    this.calculatePosition()
    
  },

  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask'
    }),
    calculatePosition() {
      this.left=this.parentNode.clientX
      this.top=this.parentNode.clientY
    },
    menuItemClick(item) {
      this.$emit('projeditMenuitemClick', item)
    }
  },
}
</script>

<style scoped>
.menu {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 999;

}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  padding: 6px 12px;
  line-height: 1.5;
  font-size: small;
  cursor: pointer;
}

li:hover {
  background-color: #f5f5f5;
}
</style>
