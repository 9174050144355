<template>
  <div class="menu-container" v-draggable>
      <div class="menu">
      <h3 style="text-align: center;">添加项目</h3>
      <form>
        <div class="form-group" style="display: flex;">
          <input type="text" id="project-name" v-model="projectName" placeholder="    添加项目" autocomplete="off" style="width:calc(100% - 155px);">
          <button type="button"  style="width: 100px;margin-left:10px;" @click="loadTemplateProject">载入项目模板</button>
        </div>
        <div class="form-group">
          <label for="project-type">类型&nbsp;&nbsp;&nbsp;&nbsp;</label>
          <select id="project-type" v-model="projectType">
            <option value="tree">任务树</option>
            <option value="table">任务表</option>
            <option value="graphic">任务图</option>
          </select>
        </div>
        <div class="form-group">
          <label for="project-folder">文件夹</label>
          <select id="project-folder" v-model="projectFolder" style="width: 230px; margin-right: 10px">
            <option v-for="folder in folders" :value="folder" :key="folder.id"
            >
              {{ folder.name }}
            </option>
          </select>
          <button type="button" @click="addFolder">新建</button>
        </div>
      </form>
      <div class="menu-buttons">
        <button type="button" @click="addProject" :disabled="saveOrNot">保存</button>
        <button type="button" @click="resetForm" style="margin-left: 10px">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations, mapActions} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        console.log(el)
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "AddProjectMenu",
    data() {
        return {
            projectName: "",
            projectType: "tree",
            projectFolder: null,
            myProjectlist: '',
            username:'',
            saveOrNot:true,
        };
    },
    computed: {
      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        for(var i=0; i<this.myProjectlist.length;i++) {
          if(!myfolderobj[this.myProjectlist[i].groupname]) {
            myfolderobj[this.myProjectlist[i].groupname]=i+1
            const folder={id: Id, name: this.myProjectlist[i].groupname}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },
      saveOk: function(){
        if(this.projectName&&this.projectFolder) {
          return true
        }else
        return false
      }

      
 
    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      }
    },
    props: ['menuVisible','projectNameFromParent'],
    methods: {
      // closeMenu(){
      //   this.$emit('closeMenu')
      //   this.switchMenuMask()
      // },
      ...mapMutations({
        switchMenuMask: "switchMenuMask",
        updateSelectProjName: "updateSelectProjName"
      }),
      ...mapActions({
        addProjectToSql: "addProjectToSql",
      }),
      addFolder() {
          const folderName = prompt("请输入文件夹名称");
          if (folderName) {
              const newFolder = { id: this.folders.length + 1, name: folderName };
              this.folders.push(newFolder);
              this.projectFolder= newFolder;
          }
      },

      async addProject(){
        if (!this.username) {
          // 如果用户未登录，跳转到登录页面
          this.$router.push('/login');
          return;
        }
        const projectinfo = {
          idfp:uuidv4(),
          name: this.projectName,
          groupname: this.projectFolder.name,
          charger: this.username,
        }
        this.addProjectToSql(projectinfo)
          .then((result)=>{
            const newProj = result
            const newProjectAcl={
              username: this.username,
              project_idfp: newProj.idfp,
              permit_level:'charger',
              name:projectinfo.name,
              groupname:projectinfo.groupname,
              charger:this.username,
              project_altname:this.projectName,
              prjgroup_altname:this.projectFolder.name,
              creater:this.username

            }
            this.$emit('addProjectWithAclToSql',newProjectAcl,newProj)
            this.$emit('closeAddProjectMenu')
          })
        
      },


      loadTemplateProject(){
        this.$emit('loadTemplateProject','project')
      },

      // async addProject() {
      //   try {
      //     // 以下是保存新增的项目
      //     const token = localStorage.getItem('token');
      //     if (!this.username) {
      //       // 如果用户未登录，跳转到登录页面
      //       this.$router.push('/login');
      //       return;
      //     }
      //     const projectinfo = {
      //       name: this.projectName,
      //       groupname: this.projectFolder.name,
      //       charger: this.username,
      //     };
      //     // 发送 POST 请求
      //     const response = await axios({
      //       method: 'post',
      //       url: 'https://api2.itaskid.com' + '/my/addproject',
      //       headers: {
      //         Authorization: token,
      //         'Content-Type': 'application/x-www-form-urlencoded'
      //       },
      //       data: projectinfo
      //     });
      
      //     const newProj = response.data;
      //     this.$emit('updateLocalItems', newProj);
      //     this.updateSelectProjName(newProj)
      //     if (!response.data.status) {
      //       // 根据需求进行逻辑处理
      //     } else {
      //       alert(response.data.message);
      //     }
      //   } catch (error) {
      //     alert(error.response.data.message);
      //   }
        
      //   // 以上是保存新增的项目
      //   this.$emit("closeMenu");
      //   this.switchMenuMask();
      // },

      resetForm() {
          this.projectName = "";
          this.projectType = "type1";
          this.projectFolder = null;
          this.$emit("closeMenu");
          this.switchMenuMask();

      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },
    },

    created(){
      
      
    },
    mounted(){
      this.projectName=this.projectNameFromParent
      // this.getUsername();
      const username=localStorage.getItem('username')
      this.username=username
      const token = localStorage.getItem('token')
      axios.get('https://api2.itaskid.com' + '/my/taskgroup',{
        headers: {
          Authorization: token
        },
      })
        .then((response)=>{
          if (Array.isArray(response.data)){
            const usernogroup=`${this.username}nogroup`
            const usertoday=`${this.username}today`
            const userweek=`${this.username}week`
            this.myProjectlist=response.data.filter(item => 
            item.charger==this.username&&(!(item.idfp==usernogroup)&&!(item.idfp==usertoday)&&!(item.idfp==userweek)))

          }else
          alert('账号未认证，无法获取数据！')
        })
        .catch(function (error) {
          console.log(error);
        })

    },

    components: { Row }
}
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 450px;
  height: 270px;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  input #project-name {
    width: 410px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  input {
    width: 405px;
    height: 30px;
    padding: 0;
  }
  .form-group {
  width: 450px;
  margin: 10px 0;
  height: 30px;
  padding-left: 20px;
  }
  .menu-buttons {
    margin-right: 20px;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}


  
</style>