<template>
  <div class="menu-container" v-draggable>
      <div class="menu">
      <h4 style="text-align: center; margin:13px 0px;">发布文件/新建文件夹</h4>
      <form>
        <div class="form-group">
          <input type="text" id="project-name" v-model="pubFileName" placeholder="文件或文件夹名称" 
            autocomplete="off" class="borderstyle">
          <input type="file" @change="handlePubFileUpload($event)" style="margin-top:5px;" ref="fileInput"/>
        </div>
        <br>
        <div class="form-group">
          <label for="project-folder">项目组</label>
          <el-select v-model="projectFolder" placeholder="请选择"  filterable remote
            style="width:290px; height:30px;margin-left:20px;">
            <el-option class="el-option"
            v-for="folder in folders" :key="folder.id"
              :label="folder.name"
              :value="folder.name">
            </el-option>
          </el-select>
          <!-- <button type="button" @click="addFolder" style="margin-left:10px;height:38px;width:80px;">新建</button> -->
        </div>
        <div class="form-group">
          <label for="project">项&nbsp;&nbsp;&nbsp;目</label>
          <el-select v-model="pubProjectIdfp" placeholder="请选择" filterable remote @change="computedPubProject()"
            style="width:290px; height:30px;margin-left:22px;">
            <el-option class="el-option"
              v-for="project in pubProjects" :key="project.id"
              :label="computedPrjName(project)"
              :value="project.idfp">
            </el-option>
          </el-select>
        </div>
        <div class="form-group">
          <label for="project-folder">审核人</label>
          <el-select v-model="pubFileCheckers" placeholder="请选择" @change="methodPubFileCheckers" filterable remote  multiple 
            style="width:290px; height:30px;margin-left:20px;" collapse-tags>
            <!-- v-for="parter in pubProjectParters" -->
            <el-option class="el-option"
              v-for="parter in allPubFileCheckers"
              :key="parter.idfpacl"
              :label="parter.nickname"
              :value="parter.username">
            </el-option>
          </el-select>
        </div>
        <div class="form-group">
          <label for="project-folder">审批人</label>
          <el-select v-model="pubFileApprover" placeholder="请选择" filterable remote
            style="width:290px;margin-left:20px;">
            <el-option class="el-option"
              v-for="parter in pubFileApprovers"
              :key="parter.idfpacl"
              :label="parter.nickname"
              :value="parter.username">
            </el-option>
          </el-select>
        </div>
        <div class="form-group" style="position:relative;">
          <label for="project-folder">文件夹</label>
          <el-select v-model="pubFileFolderIdff" placeholder="请选择" filterable remote @change="changeFolder"
            style="width:290px;margin-left:20px;">
            <el-option class="el-option"
              v-for="folder in allMyPubFileFolders"
              :key="folder.idff"
              :label="folder.filename"
              :value="folder.idff">
            </el-option>
          </el-select>
          <button  style="position:absolute;width:20px; left:350px;top:8px; background: none;border:none;" v-if="pubFileFolderIdff"
            @click="clearFolderIdff">
            <i class="el-icon-circle-close"></i>
          </button>
          
        </div>
        <div style="margin:10px 0 0 20px;">
          <label for="">直接发布</label>
          <input type="checkbox" style="width:20px;margin:0 30px 0 10px;" v-model="ifPubFileDirect">
          <label for="">新建文件夹</label>
          <input type="checkbox" style="width:20px;margin-left:10px;" v-model="ifCreateFolder">
        </div>
      </form>
      <!-- <br> -->
      <div style="margin-top:10px;">
        <textarea name="" id="" cols="30" rows="7" placeholder="请输入文件简述。。。"  class="borderstyle"
          style="width: calc(100% - 15px);margin: 0 5px;" v-model="newPubFileSummary">
        </textarea>
      </div>
      <div class="menu-buttons">
        <!-- <button type="button" @click="pubNewFile" :disabled="ifCanPubFile">发布</button> -->
        <button type="button" @click="pubNewFile">发布</button>
        <button type="button" @click="resetForm" style="margin-left: 10px">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations, mapActions} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
// import qs from 'qs'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "AddProjectMenu",
    props:['allMyPubFileFolders','selectedParentFolder','myProjectlist'],
    data() {
        return {
            pubFileName: '',
            projectName: "",
            pubProjectIdfp:'',
            projectType: "type1",
            projectFolder: null,
            pubFileApprover:'',
            pubFileChecker:null,
            pubFileCheckers:[],
            pubProjectName:'',
            pubProjects:[],
            pubProjectParters:[],
            pubProject:null,
            pubFileFolderIdff:'',
            // myProjectlist: [],
            projects:'',
            username:'',
            saveOrNot:true,
            newPubFile:null,
            newPubFileSummary:'',
            uploadFile: null,
            ifPubFileDirect:false,
            ifCreateFolder:false,
            selectedParentFolder1:null,
        };
    },
    computed: {
      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        for(var i=0; i<this.myProjectlist.length;i++) {
          if(!myfolderobj[this.myProjectlist[i].groupname]) {
            myfolderobj[this.myProjectlist[i].groupname]=i+1
            // const folder={id: Id, name: this.myProjectlist[i].groupname}
            const groupName=this.myProjectlist[i].prjgroup_altname?this.myProjectlist[i].prjgroup_altname:this.myProjectlist[i].groupname
            const folder={id: Id, name: groupName,idfpacl:this.myProjectlist[i].idfpacl}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },

      computedPrjName(){
        return(project)=>{
          if(project.project_altname){
            return project.project_altname
          }else{
            return project.name
          }
        }
      },
      
      pubFileApprovers: function(){
        const username=localStorage.getItem('username')
        const pubFileApprovers = this.pubProjectParters.filter(item => (!this.pubFileCheckers.includes(item.username))&&!(item.username==username));
        return pubFileApprovers
      },

      // allMyPubFileFolders: function(){
      //   const username=localStorage.getItem('username')
      //   const allMyPubFileFolders = this.pubProjectParters.filter(item => (!this.pubFileCheckers.includes(item.username))&&!(item.username==username));
      //   return pubFileApprovers
      // },

      allPubFileCheckers: function(){
        const username=localStorage.getItem('username')
        const allPubFileCheckers = this.pubProjectParters.filter(item => !(item.username==username));
        return allPubFileCheckers
      },


      // pubProjects1:function(){
      //   if(this.projectFolder==null){
      //     return this.myProjectlist
      //   } else {
      //     const projects=this.myProjectlist.filter(project=>project.groupname==this.pubFileFolder)
      //     return projects
      //   }
      // },   

      ifCanPubFile:function(){
        return()=>{
          if(this.pubProjectIdfp==''||this.pubFileName==''){
            return false
          } else {
            // if(this.ifPubFileDirect||((!this.ifPubFileDirect)&&(!this.pubFileApprover))){
            //   return true
            // } else {
            //   return false
            // }
            return true
          }
        }
      },


    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      },
      projectFolder: function(){
        this.pubProjects=this.myProjectlist.filter(project=>(project.groupname==this.projectFolder)||(project.prjgroup_altname==this.projectFolder))
      },

      // selectedParentFolder:function(){
      //   if(this.selectedParentFolder==null){
          
      //     // this.pubFileFolderIdff=this.selectedParentFolder.idff
      //     console.log('sgdsfd')
      //   }else {
      //     this.pubFileFolderIdff=this.selectedParentFolder.idff
      //     console.log('sgdsfd',this.pubFileFolderIdff)

      //   }
      // }
    },
    methods: {
      // closeMenu(){
      //   this.$emit('closeMenu')
      //   this.switchMenuMask()
      // },
      ...mapMutations({
          switchMenuMask: "switchMenuMask",
          updateSelectProjName: "updateSelectProjName"
      }),
      ...mapActions({
        getProjectAclListByIdfp:'getProjectAclListByIdfp',
        getMyProjectAclList:'getMyProjectAclList',
        getMyProjectListByIdfp:'getMyProjectListByIdfp',
        // getFormatFromMIME:'getFormatFromMIME',
      }),

      changeFolder(){
        var folder
        if(this.pubFileFolderIdff==''){
          folder=null
          console.log('folder',folder)
        } else {
          this.selectedParentFolder1=this.allMyPubFileFolders.find(item=>item.idff==this.pubFileFolderIdff)
        }
      },
      clearFolderIdff(){
        this.pubFileFolderIdff=''
      },
      // addFolder() {
      //     const folderName = prompt("请输入文件夹名称");
      //     if (folderName) {
      //         const newFolder = { id: this.folders.length + 1, name: folderName };
      //         this.folders.push(newFolder);
      //         this.projectFolder= newFolder;
      //     }
      // },

      computedPubProject(){
        this.pubProject=this.pubProjects.find(item=>item.name==this.pubProjectName)
     
        this.getProjectAclListByIdfp(this.pubProjectIdfp)
          .then((result)=>{
            this.pubProjectParters=result
          })
      },

      getPartersFromProject(idfp){

      },

      handlePubFileUpload(e){
        let file = e.target.files[0];
        this.uploadFile= e.target.files[0]
        const username=localStorage.getItem('username')
        const fileSizeInKB = (file.size / 1024).toFixed(2) + " KB"
        this.pubFileName = file.name
        this.newPubFile={
          idff:'',
          filename: this.pubFileName,
          project:'',
          type:'',
          folder:this.projectFolder,
          publisher:username,
          approver: '',
          checker:'',
          pubtime:'', 
          size: fileSizeInKB, 
          downloadtimes:0,
          summary:this.newPubFileSummary
        } 
      },

      methodPubFileCheckers(){
        const pubFileApprovers = this.pubProjectParters.filter(item => !this.pubFileCheckers.includes(item.username));
      },

      getFormatFromMIME(mimeType) {
        const mimeToFormatMap = {
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
          'image/jpeg': 'jpg',
          'image/png': 'png',
          'application/pdf': 'pdf',
          'text/plain': 'txt',
          'application/zip': 'zip',
          'application/x-rar-compressed': 'rar',
          'image/svg+xml': 'svg',
          'application/autocad': 'dwg',
          // 其他 MIME 类型对应的文件格式简写
        };
    
        return mimeToFormatMap[mimeType] || 'unknown'; // 如果找不到对应的简写，返回'unknown'
      },

      async pubNewFile(){
          const file_parent=this.pubFileFolderIdff==''?'rootfile':this.pubFileFolderIdff
          const username=localStorage.getItem('username')
          const pubtime=this.convertDateFormat(new Date())
          const fileExtension = this.pubFileName.split('.').pop()   
          // const typestrs=this.uploadFile?(this.uploadFile.type.split('/')):''
          // const type=(typestrs=='')?'':typestrs[1]
          
          
          const type=(this.uploadFile==null)?'':this.getFormatFromMIME(this.uploadFile.type)
          const checkerStr = this.pubFileCheckers.join(';')
          const file_level=!this.selectedParentFolder1?1:(this.selectedParentFolder1.file_level+1)
          const approver=(!this.ifPubFileDirect)?this.pubFileApprover:username
          const approved=(!this.ifPubFileDirect)?'':username
          const checker=(!this.ifPubFileDirect)?checkerStr:''
          const filestatus=this.ifPubFileDirect?'已发布':'待审核'
          const isfolder=this.ifCreateFolder?1:0
          const fileSizeInKB=this.uploadFile?((this.uploadFile.size/1024).toFixed(2)+"KB"):''
          const newPubFile={
            idff:uuidv4(),
            filename:this.pubFileName,
            project:this.pubProjectIdfp,
            type:type,
            publisher:username,
            pubtime:pubtime,
            downloadtimes:0,
            subscriber:null,
            folder:this.projectFolder,
            approver:approver,
            checker:checker,
            filestatus:filestatus,
            checked:'',
            approved:approved,
            file_parent:file_parent,
            file_level:file_level,
            isfolder:isfolder,
            summary:this.newPubFileSummary,
            size:fileSizeInKB
          }
          if(this.uploadFile){
            const xx=await this.handleFileUpload1(this.uploadFile)
            newPubFile.url=xx
          }else {
            newPubFile.url=''
          }
          this.$emit("pubNewFile",newPubFile,this.newPubFileSummary)
          this.$emit("closeFilePubMenu");
          this.switchMenuMask();
        // }

      },

      handleFileUpload(file) {
        if (!file) {
          console.log("请选择要上传的文件");
          return;
        }
        const token = localStorage.getItem('token')
        const formData = new FormData();
        formData.append('file', file);
    
        axios.post('https://api2.itaskid.com' + '/my/uploadpubfile', formData, {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          // console.log(response.data)
          return response.data.url
        })
        .catch(error => {
          console.log(error);
        });
      },

      async handleFileUpload1(file) {
        try {
          if (!file) {
            alert("请选择要上传的文件");
            return;
          }
          const token = localStorage.getItem('token')
          const formData = new FormData();

          const encodedFileName = encodeURIComponent(file.name);
          formData.append('file', file, encodedFileName);
          const response = await axios.post(
            'https://api2.itaskid.com' + '/my/uploadpubfile',
            formData,
            {
              headers: {
                Authorization: token,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          // console.log(response.data.data.url)
          return response.data.data.url;
        } catch (error) {
          console.log(error);
        }
      
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();
      },


      async saveProject() {
        try {
          // 以下是保存新增的项目
          const token = localStorage.getItem('token')
          if (!this.username) {
            // 如果用户未登录，跳转到登录页面
            this.$router.push('/login');
            return;
          }
          const projectinfo = {
            name: this.projectName,
            groupname: this.projectFolder.name,
            charger: this.username,
          };
          // 发送 POST 请求
          const response = await axios({
            method: 'post',
            url: 'https://api2.itaskid.com' + '/my/addproject',
            headers: {
              Authorization: token,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: projectinfo
          });
      
          const newProj = response.data;
          this.$emit('updateLocalItems', newProj);
          this.updateSelectProjName(newProj)
          if (!response.data.status) {
            // 根据需求进行逻辑处理
          } else {
            alert(response.data.message);
          }
        } catch (error) {
          alert(error.response.data.message);
        }
        
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();
      },
      resetForm() {
          // this.projectName = "";
          // this.projectType = "type1";
          // this.projectFolder = null;
          this.$emit("closeFilePubMenu");
          this.switchMenuMask();
          

      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },

      convertDateFormat(data){
        const date = new Date(data)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        let hour = date.getHours().toString().padStart(2, '0')
        const minute = date.getMinutes().toString().padStart(2, '0')
        const formattedDate = `${year}/${month}/${day} ${hour}:${minute}`
        return formattedDate
      }
    },
    mounted(){
      this.pubProjects=this.myProjectlist
      this.selectedParentFolder1=this.selectedParentFolder
      if(this.selectedParentFolder!==null){
          
          this.pubFileFolderIdff=this.selectedParentFolder.idff
          this.pubProjectIdfp=this.selectedParentFolder.project
          this.myProjectlist.map((project)=>{
            if(project.idfp==this.pubProjectIdfp){
              this.projectFolder=project.groupname
            }
          })

          this.computedPubProject()
          
        }


      // this.getUsername();
      // const token = localStorage.getItem('token')
      // axios.get('https://api2.itaskid.com' + '/my/taskgroup',{
      //   headers: {
      //     Authorization: token
      //   },
      // })
      //   .then((response)=>{
      //     if (Array.isArray(response.data)){
      //       const usernogroup=`${this.username}nogroup`
      //       const usertoday=`${this.username}today`
      //       const userweek=`${this.username}week`
      //       this.myProjectlist=response.data.filter(item => 
      //       item.charger==this.username&&(!(item.idfp==usernogroup)&&!(item.idfp==usertoday)&&!(item.idfp==userweek)))
      //       this.pubProjects=this.myProjectlist

      //     }else
      //     alert('账号未认证，无法获取数据！')
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   })

      const username=localStorage.getItem('username')
      const usernogroup=`${username}nogroup`
      let projectAcllist=[]
      this.getMyProjectAclList(username)
        .then((result)=>{
          projectAcllist=result
          if(projectAcllist&&projectAcllist.length>0) {
            const promises=projectAcllist.map(async (projectacl)=>{
              const project=await this.getMyProjectListByIdfp(projectacl.project_idfp)
              projectacl.name=project[0].name
              projectacl.groupname=project[0].groupname
              projectacl.status=project[0].status
              projectacl.is_delete=project[0].is_delete
              projectacl.createdTime=project[0].createdTime
              projectacl.creater=project[0].creater
              projectacl.permit_type=project[0].permit_type
              projectacl.idfp=project[0].idfp
            })
            return Promise.all(promises)
          }
        })
          .then(()=>{
            // this.myProjectlist=projectAcllist.filter(item=>(!(item.idfp==usernogroup)))
            // this.pubProjects=this.myProjectlist
            // this.items=this.myProjects
            // this.myProjects.forEach((project)=>{
            //   this.myProjectIdfpArray.push(project.idfp)
            // })
          })
      
      

    },

    components: { Row }
 }
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 400px;
  height: 550px;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  input #project-name {
    width: 410px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  // input {
  //   width: 405px;
  //   height: 30px;
  //   padding: 0;
  // }
  .form-group {
     width: 380px;
     margin: 10px 0;
     height: 35px;
     padding-left: 20px;

     input {
      width:350px;
      height: 30px;
     }


  }
  .menu-buttons {
    margin-right: 20px;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}

.borderstyle {
  border:0.5px solid rgb(207, 210, 216);
  border-radius: 3px;
}

.borderstyle:focus {
  outline: 0.5px solid rgb(111, 181, 254);
  
}









  
</style>