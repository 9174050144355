<template>
  <div style="width: 600px;margin: auto;">
    <!-- <span style="float: left;">XX</span> -->
    <el-input placeholder="请输入内容" v-model="input3" class="input-with-select" width="300px">
      <!-- placeholder="请选择" -->
      <el-select v-model="selectFromParent" slot="prepend"   style="width: 100px;">  
        <el-option label="任务" value="1"></el-option>
        <el-option label="信息" value="2"></el-option>
        <el-option label="文件" value="3"></el-option>
        <el-option label="联系人" value="4"></el-option>
      </el-select>
      <el-button slot="append" icon="el-icon-search"></el-button>
    </el-input>
</div>
</template>

<script>
export default {
  prop:['selectFromParent'],
  name: 'MyHeader',
  data() {
    return {
      input1: '',
      input2: '',
      input3: '',
      select: ''
    }
  },
  // watch: {
  //   selectFromParent(newVal) {
  //     console.log('selectFromParent属性的值变为:', newVal);
  //   }
  // },
}
</script>
  
<style lang="less" scoped>

  .el-select .el-input {
    width: 80px;
    height: 36px;
  }

  .el-button {
    height: 36px;
  }
  div .box {
    width: 600px;
    margin: auto;
  }
</style>