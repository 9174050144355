<template>  
  <div class="threed-menu">  
    <!-- 面板标题 -->  
    <div class="menu-title">选择任务状态</div>  

    <!-- 四个象限 -->  
    <div class="quadrant top-left">  
      <div class="quadrant-title">不重要|紧急</div>  
      <div class="risk-buttons">  
        <button  
          v-for="(risk, index) in riskLevels"  
          :key="risk"  
          class="risk-button"  
          :class="{ selected: isSelected('1', '2', 3 - index) }"  
          @click.stop="selectStatus('1', '2', 3 - index)"  
        >  
          {{ risk }}  
        </button>  
      </div>  
    </div>  
    <div class="quadrant top-right">  
      <div class="quadrant-title">重要|紧急</div>  
      <div class="risk-buttons">  
        <button  
          v-for="(risk, index) in riskLevels"  
          :key="risk"  
          class="risk-button"  
          :class="{ selected: isSelected('2', '2', 3 - index) }"  
          @click.stop="selectStatus('2', '2', 3 - index)"  
        >  
          {{ risk }}  
        </button>  
      </div>  
    </div>  
    <div class="quadrant bottom-left">  
      <div class="quadrant-title">不重要|不紧急</div>  
      <div class="risk-buttons">  
        <button  
          v-for="(risk, index) in riskLevels"  
          :key="risk"  
          class="risk-button"  
          :class="{ selected: isSelected('1', '1', 3 - index) }"  
          @click.stop="selectStatus('1', '1', 3 - index)"  
        >  
          {{ risk }}  
        </button>  
      </div>  
    </div>  
    <div class="quadrant bottom-right">  
      <div class="quadrant-title">重要|不紧急</div>  
      <div class="risk-buttons">  
        <button  
          v-for="(risk, index) in riskLevels"  
          :key="risk"  
          class="risk-button"  
          :class="{ selected: isSelected('2', '1', 3 - index) }"  
          @click.stop="selectStatus('2', '1', 3 - index)"  
        >  
          {{ risk }}  
        </button>  
      </div>  
    </div>  
  </div>  
</template>  

<script>  
export default {  
  name: "StatusSelector",  
  props: {  
    position: {  
      type: Object,  
      required: true,  
      default: () => ({ x: 0, y: 0 }),  
    },  
    selectedTask: {  
      type: Object,  
      required: true,  
    },  
  },
  
  mounted(){
    const defaultStatus = "0-0-0"; // 修改默认值为不重要 | 不紧急 | 低风险
    this.selectedStatus=this.selectedTask.threedstatus || defaultStatus;
    console.log('selectedStatus',this.selectedStatus)
  },
  data() {  
    return {  
      riskLevels: ["高", "中", "低"], // 风险等级 
      selectedStatus:'', 
    };  
  },  
  computed: {  
    // 解析 selectedTask.threedstatus 或设置默认值  
    // selectedStatus() {  
    //   const defaultStatus = "0-0-0"; // 修改默认值为不重要 | 不紧急 | 低风险  
    //   return this.selectedTask.threedstatus || defaultStatus;  
    // },  
  },
  
  watch:{
    selectedTask: {  
      handler(val) {  
        console.log('selectedTask changed:', val);  
        console.log('threedstatus:', val.threedstatus); // 访问 threedstatus 
        const defaultStatus = "0-0-0"; // 修改默认值为不重要 | 不紧急 | 低风险  
        this.selectedStatus=this.selectedTask.threedstatus || defaultStatus; 
      },  
      deep: true, // 开启深度监视  
    },  
  },
  methods: {  
    // 判断按钮是否为选中状态  
    isSelected(importance, urgency, risk) {
      const composedStatus = `${importance}-${urgency}-${risk}`; // 组合成字符串
      // const [selectedImportance, selectedUrgency, selectedRisk] =  
      //   this.selectedStatus.split("-");  
      return (  
        composedStatus === this.selectedStatus
        // importance === selectedImportance &&  
        // urgency === selectedUrgency &&  
        // Number(risk) === Number(selectedRisk)  
      );  
    },  
    // 选择状态并触发事件  
    selectStatus(importance, urgency, risk) {  
      const status = `${importance}-${urgency}-${risk}`;
      this.selectedStatus = status;
      console.log('status',status)  
      this.$emit("statusSelected", status);  
    },  
  },  
};  
</script>  

<style scoped>  
.threed-menu {  
  position: absolute;  
  width: 320px;  
  height: 340px;  
  background: white;  
  border: 1px solid #ccc;  
  border-radius: 8px;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  
  z-index: 1000;  
  display: flex;  
  flex-wrap: wrap;  
  padding: 10px;  
  padding-top: 40px; /* 为标题留出空间 */  
  box-sizing: border-box;  
  top: calc(50% - 200px);  
  left: calc(50% - 200px);  
}  

/* 面板标题 */  
.menu-title {  
  position: absolute;  
  top: 10px;  
  left: 50%;  
  transform: translateX(-50%);  
  font-size: 16px;  
  font-weight: bold;  
  color: #333;  
}  

/* 四个象限 */  
.quadrant {  
  width: calc(50% - 10px); /* 每个象限宽度减去间隙 */  
  height: calc(50% - 10px); /* 每个象限高度减去间隙 */  
  margin: 5px; /* 左、右、下方间隙 */  
  display: flex;  
  flex-direction: column;  
  align-items: center;  
  justify-content: space-between; /* 按钮均匀分布 */  
  position: relative;  
  padding: 10px;  
  box-sizing: border-box;  
}  
.top-left {  
  background: rgb(251, 194, 27); /* 不重要 | 紧急 */  
}  
.top-right {  
  background: rgb(229, 62, 49); /* 重要 | 紧急 */  
}  
.bottom-left {  
  background: rgb(48, 127, 243); /* 不重要 | 不紧急 */  
}  
.bottom-right {  
  background: rgb(44, 158, 75); /* 重要 | 不紧急 */  
}  

/* 标题样式 */  
.quadrant-title {  
  font-size: 14px;  
  font-weight: bold;  
  margin-bottom: 8px;  
  text-align: center;  
  color: #fff; /* 标题文字颜色 */  
}  

/* 风险按钮样式 */  
.risk-buttons {  
  display: flex;  
  flex-direction: column; /* 按钮竖向排列 */  
  gap: 5px;  
  align-items: center;  
  width: 100%;  
}  
.risk-button {  
  width: 80%;  
  height: 30%;  
  border: 1px solid #fff; /* 按钮边框 */  
  border-radius: 4px;  
  background: transparent; /* 按钮背景透明，与象限颜色一致 */  
  cursor: pointer;  
  font-size: 14px;  
  color: #fff; /* 按钮文字颜色 */  
}  
.risk-button:hover {  
  background: rgba(255, 255, 255, 0.2); /* 鼠标悬停效果 */  
}  

/* 选中状态样式 */  
.risk-button.selected {  
  background: rgba(255, 255, 255, 0.4); /* 选中状态背景 */  
  border-color: #fff;  
  font-weight: bold;  
}  
</style>