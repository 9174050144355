<template>
  <div ref="menu" class="menu" :style="{top: top + 'px', left: left + 'px'}" >
    <ul style="position: relative;" v-for="(item,index) in TaskeditMenuitems" :key="index">
      <li  @click="menuItemClick(item)" @mouseover="handleMouseover(index)">
        <label>{{item.label}}</label>
      </li>
    </ul>
    <div v-if="showUserList()" class="user-list">
      <li style="position:relative; list-style-type: none;">
        <i class="el-icon-user" style="margin-left: 10px; font-size: 20px;padding:0;margin-left:0px;"></i>
        <label style="margin-left:5px;font-size:14px;">取消指派</label>
      </li>
      <ul v-for="(taskAcl, index) in selectedTaskAcls" :key="index">
        <li @click="appointTask(taskAcl)">
          <img :src="taskAcl.avatarUrl">
          <span>{{ taskAcl.nickname }}</span>
          <select id="project-type" v-model="taskAcl.permit_level" @change="updateTaskAcl(taskAcl)">
            <option value="charger">可编辑</option>
            <option value="viewer">可视</option>
            <option value="parter">不可视</option>
          </select>
        </li>
      </ul>
    </div>
    <!-- <div v-if="showProjectList()" class="user-list">

    </div> -->
    
  </div>
</template>

<script>
import { mapState,mapMutations,mapActions} from 'vuex'
export default {
  name: 'EditTaskMenu',

  props: {
    // projeditMenuitems: {
    //   type: Array,
    //   required: true
    // },
    // event: {
    //   type: Object,
    //   required: true
    // },
    parentNode: {
      type: Object,
      required: true
    },
    // showProjEditMenu: {
    //   type: Boolean,
    //   required: true
    // }
    selectedTask:{
      type: Object,
      required: true
    },
    usernogroup:{
      type:String
    },
    // shareUsers:{
    //   type: Object,
    //   required: true
    // },

    selectedTaskAcls: {
      type: Array
    },
    // TaskeditMenuitems: {
    //   type: Array
    // },
  },
  data() {
    return {
      TaskeditMenuitems:[
        { id:1,label: '添加子任务' }, 
        { id:2,label:'指派给'},
        { id:3,label: '标签' }, 
        { id:4,label: '复制链接' }, 
        { id:5,label: '创建副本' },
        { id:6,label: '删除' }
      ],
      isOpen: false,
      top: 0,
      left: 0,
      ifShowUsers:false,
      hoverOption:0,
    }
  },
  computed :{
    // showOptionMenu(){
    //   return(item,index)=>{
    //     if(item.permit_level=='charger'){
    //       return true
    //     } else if (index==0||index==4||index==5||index==6) {
    //       return true
    //     } else {
    //       return false
    //     }
    //   }
    // }
    showUserList(){
      return()=>{
        if((this.selectedTask.permit_level=='charger')&&(this.hoverOption===2)){
          return true
        } else {
          false
        }
      }
    },

    showProjectList(){
      return()=>{
        if((this.selectedTask.permit_level=='charger')&&(this.hoverOption===2)){
          return true
        } else {
          false
        }
      }
    },

  },
  mounted() {
    this.calculatePosition()
  },

  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask'
    }),

    ...mapActions({
      getTaskAclsByTaskIdfp:'getTaskAclsByTaskIdfp',
    }),


    handleMouseover(index){
      this.hoverOption=index+1
    },
    closeUsers(){
      this.ifShowUsers=false
    },
    calculatePosition() {
      this.left=this.parentNode.clientX
      this.top=this.parentNode.clientY
    },
    menuItemClick(item) {
      this.$emit('taskeditMenuitemClick', item)
    },
    handleTaskSelected(task) {
    
    },

    showTask(task){  
      task.name='yingyu'
    },


    appointTask(taskAcl){

    },

    updateTaskAcl(taskAcl){
      this.$emit('updateTaskAcl',taskAcl)
    },
  },


}
</script>

<style lang="less" scoped>
.menu {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 999;

}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  padding: 6px 12px;
  line-height: 1.5;
  font-size: small;
  cursor: pointer;
}

li:hover {
  background-color: #f5f5f5;
}

.user-list {
  background: rgb(255, 255, 255);
  text-align: left;
  width: 180px;
  height: auto;
  position: absolute;
  left: 96px;
  top: 35px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  img {

    width: 20px;
    height:20px; 
    border-radius: 50%;
    margin-top:5px;
  }
  span {
    // height: 25px;
    // line-height: 25px;
    position: absolute;
    left: 25px;
    top: 0px;
    text-align: left;
    margin-left:5px;

  }
  select {
    // position: absolute;
    float: right;
    margin-top:13px;
    margin-right:5px;
    border:none;
  }
  li {
    position: relative; 
    height: 40px;
    line-height: 40px;
    padding:0;
    margin-left:10px;
  }
}
</style>
