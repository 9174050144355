<template>  
    <div class="task-pie-chart">  
      <!-- 饼图容器 -->  
      <div ref="chart" class="chart-container"></div>  
  
      <!-- 图例 -->  
      <div class="legend">  
        <div class="legend-item" v-for="(color, index) in legendColors" :key="index">  
          <div class="legend-color" :style="{ backgroundColor: color }"></div>  
          <span>{{ legendLabels[index] }}</span>  
        </div>  
      </div>  
  
      <!-- 任务列表 -->  
      <div class="overlay" v-if="showTaskList" @click="closeTaskList"></div>  
      <div class="task-list" v-if="showTaskList">  
        <h3>任务列表</h3>  
        <table style="border-collapse: collapse; width: 100%; text-align: left; font-size: 12px; text-align: center;">  
          <thead style="position: sticky; top: 0; background: white; z-index: 10;">  
            <tr>  
              <th style="border: 0.5px solid grey; padding: 2px; width: 40px;">序号</th>  
              <th style="border: 0.5px solid grey; padding: 2px; width: 150px; overflow-x: hidden;">任务名称</th>  
              <th style="border: 0.5px solid grey; padding: 2px;">简报内容</th>  
            </tr>  
          </thead>  
          <tbody v-for="(task, index) in taskList" :key="index">    
            <tr>  
              <td style="border: 0.5px solid grey; padding: 2px;">{{ index + 1 }}</td>  
              <td style="border: 0.5px solid grey; padding: 2px;" @mouseup.stop="goToTaskFromTaskPie(task)"  @mousedown.stop="getTaskDatasFromTaskPie(task)">
                {{ task.name }}
              </td>  
              <td style="border: 0.5px solid grey; padding: 2px; line-height: 20px; text-align: left;">  
                {{ computedTaskProgressOfReport(task) }}  
              </td>  
            </tr>  
          </tbody>  
        </table>  
      </div>  
    </div>  
  </template>  
  
  <script>  
  import * as echarts from "echarts";  
  
  export default {  
    name: "TaskPieChart",  
    props: {  
      allTasks: {  
        type: Array,  
        required: true,  
        default: () => [],  
      },
      
      selectedPrjTasksReportsOfToday:{
        type:Array,
      }
    },  
    data() {  
      return {  
        taskList: [], // 当前选中的任务列表  
        showTaskList: false, // 是否显示任务列表  
        legendColors: ["rgb(47, 124, 238)", "rgb(44, 158, 75)", "rgb(251, 194, 27)", "rgb(229, 62, 49)"],  
        legendLabels: ["不重要|不紧急", "不重要|紧急", "重要|不紧急", "重要|紧急"],  
        taskData: [],  
        riskData: [],  
      };  
    },
    
    computed: {

      computedTaskProgressOfReport(){
        return(task)=>{
          const taskReport=this.selectedPrjTasksReportsOfToday.find(item=>task.idft==item.task_idft)
          if(taskReport){
            return taskReport.progress
          }else {
            return ''
          }
        }
      },
    },
    mounted() {  
      this.calculateData();  
      this.initChart();  
    },  
    methods: {  
      calculateData() {  
        // 定义任务类型映射  
        const typeMap = {  
          "1-1": "不重要/不紧急",  
          "1-2": "不重要/紧急",  
          "2-1": "重要/不紧急",  
          "2-2": "重要/紧急",  
        };  
  
        // 计算 taskData  
        const taskTypes = ["不重要/不紧急", "不重要/紧急", "重要/不紧急", "重要/紧急"];  
        this.taskData = taskTypes.map(type => {  
          const tasks = this.allTasks.filter(task => {  
            const [importance, urgency] = task.threedstatus.split("-"); // 解析 threedstatus  
            const taskTypeKey = `${importance}-${urgency}`;  
            return typeMap[taskTypeKey] === type;  
          });  
          return {  
            name: type,  
            value: tasks.length,  
            color: this.legendColors[taskTypes.indexOf(type)],  
            tasks, // 保留完整任务对象  
          };  
        });  
  
        // 计算 riskData  
        const riskLevels = ["低风险", "中风险", "高风险"];  
        this.riskData = [];  
        this.taskData.forEach(task => {  
          riskLevels.forEach((risk, riskIndex) => {  
            const tasks = task.tasks.filter(t => {  
              const [, , riskLevel] = t.threedstatus.split("-"); // 解析风险等级  
              return riskIndex + 1 === parseInt(riskLevel, 10); // 匹配风险等级  
            });  
            if (tasks.length > 0) {  
              this.riskData.push({  
                name: `${task.name}-${risk}`,  
                value: tasks.length,  
                color: task.color,  
                riskLabel: risk,  
                tasks,  
              });  
            }  
          });  
        });  
      },  
      initChart() {  
        const chart = echarts.init(this.$refs.chart);  
  
        // 内层饼图数据  
        const innerData = this.taskData.map(item => ({  
          value: item.value,  
          name: item.name,  
          itemStyle: { color: item.color },  
        }));  
  
        // 外层饼图数据  
        const outerData = this.riskData.map(item => ({  
          value: item.value,  
          name: item.name,  
          itemStyle: { color: item.color },  
          label: {  
            show: true,  
            position: "outside",  
            formatter: `{b}\n{c} (${item.riskLabel})`, // 显示风险等级  
            fontSize: 12,  
            color: "#333",  
          },  
        }));  
  
        // 配置项  
        const option = {  
          tooltip: {  
            trigger: "item",  
            formatter: params => {  
              const percentage = params.percent.toFixed(2);  
              return `${params.name}<br>任务数量: ${params.value}<br>比例: ${percentage}%`;  
            },  
          },  
          series: [  
            {  
              name: "任务类型",  
              type: "pie",  
              radius: ["0%", "40%"], // 内层饼图  
              label: {  
                show: false, // 隐藏文字  
              },  
              data: innerData,  
            },  
            {  
              name: "风险等级",  
              type: "pie",  
              radius: ["50%", "70%"], // 外层饼图  
              label: {  
                show: true, // 显示风险等级标签  
              },  
              data: outerData,  
              itemStyle: {  
                borderWidth: 2,  
                borderColor: "#fff", // 增加分段空隙  
              },  
            },  
          ],  
        };  
  
        // 绑定点击事件  
        chart.on("click", params => {  
          if (params.seriesName === "任务类型") {  
            // 根据任务类型筛选 allTasks 中的任务  
            const taskType = params.name; // 获取点击的任务类型名称  
            this.taskList = this.allTasks.filter(task => {  
              const typeMap = {  
                "1-1": "不重要/不紧急",  
                "1-2": "不重要/紧急",  
                "2-1": "重要/不紧急",  
                "2-2": "重要/紧急",  
              };  
              const [importance, urgency] = task.threedstatus.split("-"); // 解析 threedstatus  
              const taskTypeKey = `${importance}-${urgency}`;  
              return typeMap[taskTypeKey] === taskType;  
            });  
          } else if (params.seriesName === "风险等级") {  
            // 根据风险等级和任务类型筛选 allTasks 中的任务  
            const [taskType, riskLevel] = params.name.split("-"); // 获取任务类型和风险等级  
            this.taskList = this.allTasks.filter(task => {  
              const typeMap = {  
                "1-1": "不重要/不紧急",  
                "1-2": "不重要/紧急",  
                "2-1": "重要/不紧急",  
                "2-2": "重要/紧急",  
              };  
              const [importance, urgency, risk] = task.threedstatus.split("-"); // 解析 threedstatus  
              const taskTypeKey = `${importance}-${urgency}`;  
              return typeMap[taskTypeKey] === taskType && parseInt(risk, 10) === ["低风险", "中风险", "高风险"].indexOf(riskLevel) + 1;  
            });  
          }  
          this.showTaskList = true; // 显示任务列表
    
        });  
  
        // 渲染图表  
        chart.setOption(option);  
      },  
      closeTaskList() {  
        this.showTaskList = false; // 关闭任务列表  
      },
      
      goToTaskFromTaskPie(task){
        this.$emit('goToTaskFromTaskPie',task)
      },

      getTaskDatasFromTaskPie(task){
        console.log('sdsdsd',task)
        this.$emit('getTaskDatasFromTaskPie',task)
      },
    },  
  };  
  </script>  
  
  <style scoped>  
  .task-pie-chart {  
    width: 100%;  
    position: relative;  
  }  
  .chart-container {  
    width: 100%;  
    height: 400px;  
  }  
  .task-list {  
    position: absolute;  
    width: 600px;  
    max-height: calc(100vh - 150px);  
    top: 20px;  
    left: -300px;  
    background: #fff;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  
    border-radius: 8px;  
    padding: 10px;  
    z-index: 999;  
    overflow-y: auto;  
  }  
  .task-list ul {  
    list-style: none;  
    padding: 0;  
    margin: 0;  
  }  
  .task-list li {  
    margin: 5px 0;  
  }  
  .overlay {  
    position: fixed;  
    top: 0;  
    left: 0;  
    width: 100%;  
    height: 100%;  
    background: rgba(0, 0, 0, 0.5);  
    z-index: 99;  
  }  
  .legend {  
    display: flex;  
    justify-content: space-around;  
    margin-top: 20px;  
  }  
  .legend-item {  
    display: flex;  
    align-items: center;  
  }  
  .legend-color {  
    width: 16px;  
    height: 16px;  
    margin-right: 8px;  
    border-radius: 50%;  
  }  
  </style>