<template>
  <div style="display: flex;">
    <el-col style="width: 350px; border-right: 1px solid rgba(200, 200, 200, 0.9); 
      flex-shrink: 0; text-align: left; height: calc(100vh - 100px);">
      <div style="height: 100%;">
        <div style="height: 30px; line-height: 30px;padding-bottom: 5px;display: flex;position:relative;">
          <i class="el-icon-circle-close" v-show="searchText" @click="clearInput" 
            style="margin: 7px 0 0 -20px;position: absolute;left:310px;">
          </i>
          <i class="el-icon-search" style="height: 25px;position:absolute;left:5px;top:6px;"></i>
          <input type="text" v-model="searchText" placeholder="搜索" class="search-input" @change="searchItems($event)">
          <div style="width:28px;height:28px;background-color: rgb(236, 236, 236);text-align: center;margin-left:auto;
            margin-right:5px;border-radius: 5px;">
            <i class="el-icon-plus" style="height:25px;"></i>
          </div>
        </div>
        <div style=" overflow:auto; height: calc(100% - 50px);">
          <div  v-if="searchText!==''">
            <!-- <div v-if="searchChatContacters?.length">
              <div class="searchItemsLabel">聊天对象</div>
              <ul style="text-align: left; margin: 10px 0 0 0; padding: 0px;">
                <li v-for="(contacter,index) in searchChatContacters" :key="contacter.idfcc" style="text-align: left;"  @click="selectSearchContacter(contacter,index)">
                  <table >
                    <tr style="height:20px;line-height: 20px;">
                      <td rowspan="2" v-if="contacter.contact_type=='manchat'">
                        <img :src="contacter.contact_avatar" alt="av" :style="{ width: '35px', height: '35px', borderRadius: '5%' }"
                          v-if="contacter.contact_avatar">
                        <div v-if="!contacter.contact_avatar||contacter.contact_avatar==''" class="msg-altavatar">
                          {{ showContacterAltNickname(contacter.contact_nickname) }}
                        </div>
                      </td>
                      <td rowspan="2"  v-if="contacter.contact_type=='taskchat'" style="height:40px;line-height:40px;width:40px;">
                        <div class="msg-altavatar" style="background-color: rgb(19, 155, 72);">
                          <i class="iconfont icon-icon-task" style="font-size:18px;color:rgb(250, 250, 250);"></i>
                        </div>
                      </td>
                      <td rowspan="2"  v-if="contacter.contact_type=='filechat'" >
                        <div class="msg-altavatar" style="background-color: rgb(252, 213, 63);">
                          <i class="iconfont icon-wenjian" style="font-size:18px;color:rgb(250, 250, 250);"></i>
                        </div>
                      </td>
                      <td style="max-width: 220px;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;">
                        {{ contacter.contact_nickname }}
                      </td>
                    </tr>
                    <tr style="height:20px;line-height: 20px;">
                      <td class="message-container">
                        <div class ="message">
                          {{ computedLastMsg(contacter) }} 
                        </div>
                      </td>
                    </tr>
                  </table>
                </li>
              </ul>
            </div>    -->
            <div v-if="searchUsers?.length">
              <div class="searchItemsLabel">好友</div>
              <ul style="text-align: left; margin: 0px; padding: 0px;">
                <li v-for="(user,index) in searchUsers" :key="index" style="text-align: left;" @click="goToFriendChatContacter(user)">
                  <table>
                    <tr>
                      <td rowspan="2">
                        <img :src="user.avatarUrl" alt="av" :style="{ width: '40px', height: '40px', borderRadius: '5%' }"
                          v-if="user.avatarUrl">
                        <div v-if="!user.avatarUrl" class="msg-altavatar">
                          {{ showAltNickname1(user.nickname) }}
                          <!-- {{ user.nickname }} -->
                        </div>
                      </td>
                      <td>&nbsp;&nbsp;{{ user.nickname }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;&nbsp;用户名：{{ user.username }}</td>
                    </tr>
                  </table>
                </li>
              </ul>
            </div>
            <div v-if="searchFiles?.length">
              <div class="searchItemsLabel">发布文件</div>
              <div>
                <ul style="text-align: left; margin: 0px; padding: 0px;">
                  <li v-for="file in searchFiles" :key="file.idff" style="text-align: left;" @click="goToFileChatContacter(file,'pubfile')">
                    <table style="font-size:14px;">
                      <tr>
                        <td >
                          <i class="el-icon-document"></i>
                        </td>
                        <td>&nbsp;&nbsp;{{ file.filename }}</td>
                      </tr>
                    </table>
                  </li>
                </ul>
              </div>

            </div>
            <div v-if="searchShareFiles?.length">
              <div class="searchItemsLabel">共享文件</div>
              <ul style="text-align: left; margin: 0px; padding: 0px;">
                <li v-for="file in searchShareFiles" :key="file.idfsf" style="text-align: left;" @click="goToFileChatContacter(file,'sharefile')">
                  <table>
                    <tr>
                      <td >
                        <i class="el-icon-document"></i>
                      </td>
                      <td>&nbsp;&nbsp;{{ file.filename }}</td>
                    </tr>
                  </table>
                </li>
              </ul>
            </div>
            <div v-if="searchTaskFiles?.length">
              <div class="searchItemsLabel">任务文件</div>
              <ul style="text-align: left; margin: 0px; padding: 0px;">
                <li v-for="file in searchTaskFiles" :key="file.idftf" style="text-align: left;" @click="goToFileChatContacter(file,'taskfile')">
                  <table>
                    <tr>
                      <td >
                        <i class="el-icon-document"></i>
                      </td>
                      <td>&nbsp;&nbsp;{{ file.filename }}</td>
                    </tr>
                  </table>
                </li>
              </ul>
            </div>
            <div  v-if="searchTasks?.length > 0">
              <div class="searchItemsLabel">任务</div>
              <ul style="text-align: left; margin: 0px; padding: 0px;">
                <li v-for="task in searchTasks" :key="task.idft" style="text-align: left;"  @click="goToTaskChatContacter(task)">
                  <table style="font-size: 14px;">
                    <tr>
                      <td>
                        <i class="el-icon-s-claim"></i>
                      </td>
                      <td>&nbsp;&nbsp;{{ task.name }}</td>
                    </tr>
                  </table>
                </li>
              </ul>
            </div>
          </div>

          <div v-if="searchText==''">
            <ul style="text-align: left; margin: 10px 0 0 0; padding: 0px;">
              <li @click="selectAssistant(lastAssistantMsg)"  
                style="text-align: left;"  :class="{selectedFileMsg:selectedFriendMsgIndex==-2}">
                <table class="msg-container">
                  <tr>
                    <td rowspan="2">
                      <img  src="@/assets/icon/小秘书头像3.png" alt="Your Image" 
                        :style="{ width: '40px', height: '40px', borderRadius: '5%' }">
                    </td>
                    <td>小秘书</td>
                  </tr>
                  <tr>
                    <td class="message-container">
                      <!-- <div class ="message" v-if="assistentMsgOption(assistantMsg.message)==1">
                        {{assistantMsg.message}}
                      </div> -->
                      <div class ="message">
                        {{lastAssistantMsg.message}}
                      </div>
                    </td>
                  </tr>
                </table>
              </li>
              <li v-for="(contacter,index) in allMyChatContacters" :key="contacter.idfcc" style="text-align: left;"
                @click="selectFriendContacter(contacter,index)" @contextmenu.prevent="handleContactersMenu($event,contacter)">
                <table >
                  <tr style="height:20px;line-height: 20px;">
                    <td rowspan="2" v-if="contacter.contact_type=='manchat'">
                      <img :src="contacter.contact_avatar" alt="av" :style="{ width: '35px', height: '35px', borderRadius: '5%' }"
                        v-if="contacter.contact_avatar">
                      <div v-if="!contacter.contact_avatar||contacter.contact_avatar==''" class="msg-altavatar">
                        {{ showContacterAltNickname(contacter.contact_nickname) }}
                      </div>
                    </td>
                    <td rowspan="2"  v-if="contacter.contact_type=='taskchat'" style="height:40px;line-height:40px;width:40px;">
                      <div class="msg-altavatar" style="background-color: rgb(19, 155, 72);">
                        <i class="iconfont icon-icon-task" style="font-size:18px;color:rgb(250, 250, 250);"></i>
                      </div>
                    </td>
                    <td rowspan="2"  v-if="contacter.contact_type=='filechat'" >
                      <div class="msg-altavatar" style="background-color: rgb(252, 213, 63);">
                        <i class="iconfont icon-wenjian" style="font-size:18px;color:rgb(250, 250, 250);"></i>
                      </div>
                    </td>
                    <td style="max-width: 220px;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;">
                      {{ contacter.contact_nickname }}
                    </td>
                  </tr>
                  <tr style="height:20px;line-height: 20px;">
                    <td class="message-container">
                      <div class ="message">
                        {{ computedLastMsg(contacter) }} 
                      </div>
                    </td>
                  </tr>
                </table>
              </li>
              <EditFriendshipMenu 
                v-if="showEditContacterMenu"
                :friendshipeditMenuitems="friendshipeditMenuitems" 
                :parentNode="parentNode"
                @chatContacterEditMenuClick="chatContacterEditMenuClick">
              </EditFriendshipMenu>
            </ul>

          </div>

          <!-- <ul v-if="optionView==2"  style="text-align: left; padding: 0px; margin: 10px 0 0 0; " >
            <li v-for="(fileMsg,index) in filterLastFileMsgs" :key="index" :class="{selectedFileMsg:selectedFileMsgIndex==index}">
              <table  @click="selectFileMsgs(fileMsg,index)">
                <tr >
                  <td rowspan="2" v-show="fileMsg.fileidfsf">
                    <i class="el-icon-share"></i>
                  </td>
                  <td rowspan="2" v-show="fileMsg.fileidff">
                    <i class="el-icon-s-promotion"></i>
                  </td>
                  <td style="width:210px; border-bottom: 0.5px sold gray;"  class="filemsgname">
                      {{ fileMsg.receiverName }}
                  </td>
                </tr>
                <tr>
                  <td class="message-container">
                    <div class ="message">
                      <label for="">{{ lastfilemsgsender(fileMsg) }}:</label>
                      {{ fileMsg.message }}
                    </div>
                  </td>
                </tr>
              </table>
            </li>
          </ul> -->


          <!-- <ul v-if="optionView==3"  style="text-align: left; padding: 0px; margin: 10px 0 0 0; " >
            <li v-for="(taskMsg,index) in filterLastTaskMsgs" :key="index" :class="{selectedFileMsg:selectedTaskMsgIndex==index}">
              <table  @click="selectTaskMsgs(taskMsg,index)">
                <tr >
                  <td rowspan="2">
                    <input type="checkbox" v-model="taskMsg.done" disabled>
                  </td>
                  <td style="width:210px; border-bottom: 0.5px sold gray;"  class="filemsgname">
                      {{ taskMsg.task_name }}
                  </td>
                </tr>
                <tr>
                  <td class="message-container">
                    <div class ="message">
                      <label for="">{{ lasttaskmsgsender(taskMsg) }}:</label>
                      {{ taskMsg.message }}
                    </div>
                  </td>
                </tr>
              </table>
            </li>
          </ul> -->
        </div>
      </div>
    </el-col>
    <el-col style="border-right: 1px solid rgba(200, 200, 200, 0.9); width: calc(100% - 360px); height: calc(100vh - 100px);">
      <router-view
      :chatto="chatto" :messages="filterMessages" :username="username" :avatarUrl_user="avatarUrl_user" :avatarUrl_friend="avatarUrl_friend"
        @sendMessage="sendMessage" :selectedFriendInfo="selectedFriendInfo" @handleDropOfFriend="handleDropOfFriend" :userinfo="userinfo"
        @deleteMessage="deleteMessage" @handleDropOfAssistant="handleDropOfAssistant" :myAllFileMsgs="myAllFileMsgs" 
        :optionView="optionView" :selectedProjectAcls="selectedProjectAcls" :friendUsername="friendUsername" :myTaskMsgs="selectedTaskMsgs"  
        :ifShowMsgsRecord="ifShowMsgsRecord" :isAssistant="isAssistant" :selectedTaskMsg="selectedTaskMsg" @handleTaskDrop="handleTaskDrop"
        @sendTaskMessageOfFile="sendTaskMessageOfFile" @handleFileDrop="handleFileDrop" :selectedFileMsg="selectedFileMsg" :userNickname="userNickname"
        @aprovelFileFromMsg="aprovelFileFromMsg" :myFriendsAvatars="myFriendsAvatars" :selectedChatContacter="selectedChatContacter">
      </router-view>
      <!-- <router-view v-if="optionView==3">

      </router-view> -->

      <!-- //以前的发送文件 -->
      <!-- <SendFile v-if="showSendFileMenu" :friendAvatarUrl="avatarUrl_friend" @cancelSendFilesToFriend="cancelSendFilesToFriend" 
        @sendFilesToFriend="sendFilesToFriend" :sendFriendName="selectedFriendInfo.friend_commentname" :sendFiles="sendFiles"
        >
      </SendFile> -->
     
      <!-- 现在的发送文件 -->
      <SendFile v-if="showSendFileMenu"  @cancelSendFilesToFriend="cancelSendFilesToFriend" :selectedChatContacter="selectedChatContacter"
        @sendFilesToFriend="sendFilesToFriend" :sendFiles="sendFiles"
        >
      </SendFile>
      <PubFileMenu  v-if="showPubFileMenu" @closeFilePubMenu="closeFilePubMenu" @pubNewFile="pubNewFile" :myProjectlist="myProjectlist"
        :allMyPubFileFolders="allMyPubFileFolders" :selectedParentFolder="selectedParentFolder" :pubingFile="pubingFile">
      </PubFileMenu>
    </el-col>
  </div>
</template>

<script>
import axios from 'axios'
import SendFile from '../menu/message/SendFile.vue'
import PubFileMenu from '../menu/message/PubFileMenu.vue'
import EditContacterMenu from '../menu/message/EditContacterMenu.vue'
import io from 'socket.io-client'
import EditFriendshipMenu from '../menu/message/EditFriendshipMenu.vue'
import { mapState , mapMutations,mapActions} from 'vuex'
import { eventBus } from '../../main.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    SendFile,
    EditFriendshipMenu,
    PubFileMenu,
    EditContacterMenu
  },
  
  data () {
    return {
      optionView:1,
      isAssistant:0,
      ifShowSearchInput:false,
      chats: [
        {
          id: 1,
          name: '小明',
          avatar: 'avatar1.png',
          lastMessage: '今天天气挺好的'
        },
      ],
      friendships:[],
      messages: [
      ],
      searchText: '',
      // newMessage: {},
      newText:'',
      showEmojiPicker: false,
      showFilePicker: false,
      chatto: '',
      socket: null,
      username:'',
      userNickname:'',
      friendName:'',
      localFriendName:'',
      avatar_user:'',
      avatarUrl_user:'',
      avatarUrl_friend:'',
      friendUsername:'',
      selectedFriendInfo:null,
      showSendFileMenu:false,
      sendFiles:[],
      userinfo:null,
      showEditContacterMenu:false,
      friendshipeditMenuitems:[],
      parentNode: null,
      filterFriendships:[],
      selectedContacterByRight:null,
      filterMessages:[],

      //与我相关的文件有关的数据
      myProjectlist:[],
      allMyPubFiles:[],
      allMyShareFiles:[],
      myAllFileMsgs:[],
      myAllShareFileMsgs:[],
      myAllPubFileMsgs:[],
      selectedFileMsgIndex:-1,
      selectedFileMsg:null,
      filterLastFileMsgs:[],
      selectedProjectAcls:[],

      lastAssistantMsg:{message:'老板，有事可以找我。',file_name:'测试文件',aboutwhat:'',timestamp:new Date()},

      ifShowMsgsRecord:false,

      //与我相关的好友聊天记录
      filterLastFriendMsgs:[],
      myAllFriendMsgs:[],
      selectedFriendMsgIndex:-1,

      selectedFriendship:null,


      //与我相关的任务有关的聊天数据
      myAllTaskMsgs:[],
      filterLastTaskMsgs:[],
      selectedTaskMsgIndex:-1,
      selectedTaskMsg:null,
      selectedTaskMsgs:[],


      //以下是与搜索框相关的数据
      searchUsers:[],
      searchFiles:[],
      searchTasks:[],
      searchShareFiles:[],
      searchTaskFiles:[],
      myAllVisibleTasks:[],
      searchChatContacters:[],


      //与任易宝宝有关的数据
      assistantMsgs:[
        {message:'老板，有事可以找我。',file_name:'测试文件',aboutwhat:'',timestamp:new Date()}
      ],

      //与通过助手发布文件相关的数据
      showPubFileMenu:false,
      selectedParentFolder:null,
      allMyPubFileFolders:[],
      myProjectlist:[],
      pubingFile:null,
      selectedFriendFromConnect:null,


      //与聊天对象相关的函数
      allMyChatContacters:[],
      allMyFriendChatContacters:[],
      allMyTaskChatContacters:[],
      allMyFileChatContacters:[],
      selectedFriendContacter:null,
      myFriendsAvatars:[],
      selectedChatContacter:null,
      event: null,
      parentNode: null,
    }
  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
      updateSelectProjName: 'updateSelectProjName',
      updateUser: 'updateUser'
    }),

    ...mapActions({
      getMyProjectAclList:'getMyProjectAclList',
      getMyProjectListByIdfp:'getMyProjectListByIdfp',
      getPubfilesByProject:'getPubfilesByProject',
      getPubfilesOfNoFolderByProject:'getPubfilesOfNoFolderByProject',
      getSharefilesOfNoFolderByProject:'getSharefilesOfNoFolderByProject',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      getUserInfoByUsername:'getUserInfoByUsername',
      getTasksByIdfp:'getTasksByIdfp',
      // getTaskContents:'getTaskContents',
      getMsgsByReceiver:'getMsgsByReceiver',
      getTaskMsgsByReceiver:'getTaskMsgsByReceiver',
      getMyTaskAclList:'getMyTaskAclList',
      // getTaskinfoByIdft: 'getTaskinfoByIdft',
      addTaskContentToSql:'addTaskContentToSql',
      getMyAssitantMsgs:'getMyAssitantMsgs',
      addTaskToSql:'addTaskToSql',
      getMyProjectAclList:'getMyProjectAclList',
      addTaskAclToSql:'addTaskAclToSql',
      getAllMyChatContactersWithMsgs:'getAllMyChatContactersWithMsgs',
    }),

    async handleSocketMessge(){
      this.socket.on('message', (message) => {
        // const contactUsernames = this.allMyChatContacters.map(contact => contact.contact_username);
        let ifMyMessages=false
        this.allMyChatContacters.find((contact)=>{
          if(contact.contact_username==message.receiver||(contact.contact_username==message.sender&&message.aboutwhat=='manchat')){
            console.log('duifou',contact.contact_username==message.sender&&message.aboutwhat=='manchat')
            console.log('Received messagex:', message);
            contact.last_interaction=this.datetimeConvert(message.timestamp)
            contact.contact_lastmsgtime=this.datetimeConvert(contact.contact_lastmsgtime)
            this.updateChatContacterByIdfcc(contact)
            ifMyMessages=true
          }
        })
        // if (contactUsernames.includes(message.receiver)||contactUsernames.includes(message.sender)) {
        if (ifMyMessages) {
          // console.log('Received message:', message);
          // contact.contact_lastmsgtime=message.timestamp
  
          if(message.type=='img'){
            this.showPic(message.file_name,'showmsgimg')
              .then((result)=>{
                message.imgUrl=result
                this.allMyChatContacters.find(contacter => {
                  if (contacter.contact_username === message.receiver||(contacter.contact_username === message.sender&&message.sender!==this.username)) {
                    contacter.chatmsgs.push(message);
                    this.selectedChatContacter=contacter
                    this.filterMessages=contacter.chatmsgs
                    this.selectFriendContacter(contacter)
                  }
                })
              })
          }else {
            this.allMyChatContacters.find(contacter => {
              if (contacter.contact_username === message.receiver||(contacter.contact_username === message.sender&&message.sender!==this.username)) {
                contacter.chatmsgs.push(message);
                this.selectedChatContacter=contacter
                this.filterMessages=contacter.chatmsgs
                this.selectFriendContacter(contacter)
              }
            })
          }
          

        }else if(message.receiver==this.username){
          console.log('zaizhelima')
          const newFriendContacter={
            idfcc:uuidv4(),
            myusername:this.username,
            contact_username:message.sender,
            last_interaction:this.datetimeConvert(new Date()),
            in_pin:0,
            is_muted:0,
            contact_type:'manchat',
            contact_nickname:message.sender_nickname,
            contact_avatar:message.sender_avatar,
            contact_remarkname:'',
            contact_lastmsg:'',
            contact_lastmsgtime:this.datetimeConvert(new Date()),
            contact_ifshow:1
          }

          if(message.type=='img'){
            this.showPic(message.file_name,'showmsgimg')
              .then((result)=>{
                message.imgUrl=result
                this.allMyChatContacters.find(contacter => {
                  if (contacter.contact_username === message.receiver||(contacter.contact_username === message.sender&&message.sender!==this.username)) {
                    contacter.chatmsgs.push(message);
                    this.selectedChatContacter=contacter
                    this.filterMessages=contacter.chatmsgs
                    this.selectFriendContacter(contacter)
                  }
                })
              })
          }else {
            this.allMyFriendChatContacters.push(newFriendContacter)
            this.allMyChatContacters.push(newFriendContacter)
            this.addChatContacter(newFriendContacter)
              .then((result)=>{
                this.selectSearchContacter(newFriendContacter);
              })
          }
        }

      });
    },

    async getMyAllChatcontacters() {
      let allChatcontacters=[]  

      //以下是从veux或者重新获取chatContacters
      if (this.$store.state.ifNeedGetChatContacters) {
        console.log('i am true')
        allChatcontacters=await this.getAllMyChatContactersWithMsgs(this.username);
  
        for (const contacter of allChatcontacters) {  
          const chatmsgs = contacter.chatmsgs;  
          if (chatmsgs && chatmsgs.length > 0) {  
            for (const msg of chatmsgs) {  
              if (msg.type === 'img') {  
                msg.imgUrl = await this.showPic(msg.file_name, 'showmsgimg');  
              }  
            }  
          }  
        }  

        this.$store.commit('setIfNeedGetChatContacters', false); // 更新 Vuex 状态
        this.$store.commit('setMyChatContacters', allChatcontacters)
      }else{
        allChatcontacters=this.$store.state.myChatContacters
        console.log('i am false')
      }


      let allMyChatContacters=[]

      const newData=this.$route.query.data
      if(newData){
        const selectedChatContacter = allChatcontacters.find(contact => contact.idfcc==newData.idfcc);
        if(!selectedChatContacter){
          allChatcontacters.push(newData);
          allMyChatContacters =allChatcontacters?.sort((a, b) => {  
          // 假设 last_interaction 是一个时间戳或可被转换为时间的字符串  
            return new Date(b.last_interaction) - new Date(a.last_interaction);  
          });
          this.allMyChatContacters = allMyChatContacters; 
          this.selectFriendContacter(newData);
        }else {
          selectedChatContacter.last_interaction=this.datetimeConvert(new Date())
          //这里要更新下last_interaction
          this.updateChatContacterLastInteraction(selectedChatContacter.idfcc,selectedChatContacter.last_interaction)

          allMyChatContacters =allChatcontacters?.sort((a, b) => {  
          // 假设 last_interaction 是一个时间戳或可被转换为时间的字符串  
            return new Date(b.last_interaction) - new Date(a.last_interaction);  
          });
          this.allMyChatContacters = allMyChatContacters;
          this.selectFriendContacter(selectedChatContacter);
        }
      }else {

        allMyChatContacters =allChatcontacters?.sort((a, b) => {  
        // 假设 last_interaction 是一个时间戳或可被转换为时间的字符串  
          return new Date(b.last_interaction) - new Date(a.last_interaction);  
        });
        this.allMyChatContacters = allMyChatContacters;
        this.selectFriendContacter(this.allMyChatContacters[0]);

      }

      // 过滤出好友聊天联系人  
      const allMyFriendChatContacters = allMyChatContacters.filter(item => item.contact_type === 'manchat');  
    
      if (allMyFriendChatContacters.length > 0) {  
        // 创建 promises 数组  
        const promises = allMyFriendChatContacters.map(async (contacter) => {  
          // 处理头像并推送到 myFriendsAvatars  
          contacter.contact_avatar = await this.showPic(contacter.contact_avatar, 'showavatar');  
          this.myFriendsAvatars.push({ username: contacter.contact_username, avatar: contacter.contact_avatar });  
        });  
    
        // 等待所有 promises 完成  
        await Promise.all(promises);  
    
        // 所有遍历操作完成后，输出 allMyFriendChatContacters 的值  
        this.allMyFriendChatContacters = allMyFriendChatContacters;  
      }  
    
      // 处理其他类型的联系人  
      this.allMyTaskChatContacters = allMyChatContacters.filter(item => item.contact_type === 'taskchat');  
      this.allMyFileChatContacters = allMyChatContacters.filter(item => item.contact_type === 'filechat');
    },

    // 获取我所有的聊天对象  
    async getAllMyChatContactsAndUpdateLastMsg(username) {  
      try {  
        const token = localStorage.getItem('token'); // 从localStorage获取token  
        const data = { myusername: username }; // 使用传入的username  
    
        // 使用axios进行GET请求  
        const response = await axios.get('https://api2.itaskid.com/my/getchatcontacterandupdatelastmsg', {  
          headers: {  
            Authorization: token,  
            'Content-Type': 'application/x-www-form-urlencoded'  
          },  
          params: data // 将数据作为查询参数传递  
        });  
    
        // 检查响应数据格式  
        if (Array.isArray(response.data)) {  
          return response.data; // 返回聊天对象数组  
        } else {  
          throw new Error('Unexpected response format'); // 报告不符合预期的数据格式  
        }  
      } catch (error) {  
        console.error(error);  
        throw new Error(error.response?.data?.message || 'An error occurred.'); // 抛出错误信息  
      }  
    },

        // 获取我所有的聊天对象  
        async getAllMyChatContacts(username) {  
      try {  
        const token = localStorage.getItem('token'); // 从localStorage获取token  
        const data = { myusername: username }; // 使用传入的username  
    
        // 使用axios进行GET请求  
        const response = await axios.get('https://api2.itaskid.com/my/getchatcontacter', {  
          headers: {  
            Authorization: token,  
            'Content-Type': 'application/x-www-form-urlencoded'  
          },  
          params: data // 将数据作为查询参数传递  
        });  
    
        // 检查响应数据格式  
        if (Array.isArray(response.data)) {  
          return response.data; // 返回聊天对象数组  
        } else {  
          throw new Error('Unexpected response format'); // 报告不符合预期的数据格式  
        }  
      } catch (error) {  
        console.error(error);  
        throw new Error(error.response?.data?.message || 'An error occurred.'); // 抛出错误信息  
      }  
    },

    getFilterLastFriendMsgs(){
      let allMyMsgsWithFriends = {}
      var allMyMsgs=this.myAllFriendMsgs
        allMyMsgs.forEach(msg => {
          const friendName = msg.sender === this.username ? msg.receiver : msg.sender;
          if (!allMyMsgsWithFriends[friendName]) {
            allMyMsgsWithFriends[friendName] = [];
          }
          allMyMsgsWithFriends[friendName].push(msg);
        });

        
        for (const friend in allMyMsgsWithFriends) {
          allMyMsgsWithFriends[friend].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          allMyMsgsWithFriends[friend] = allMyMsgsWithFriends[friend].slice(0, 1)[0];
        }
  
        // 获取对象的所有属性
        const properties = Object.keys(allMyMsgsWithFriends);
        
        // 获取每个属性的值，形成一个数组
        var propertyValues = properties.map(property => allMyMsgsWithFriends[property]);
        propertyValues=propertyValues.sort((a,b)=>new Date(b.timestamp) - new Date(a.timestamp));
        this.filterLastFriendMsgs=propertyValues
    },

    // switchOptionView(n){
    //   this.optionView=n
    //   if(n==2){
    //     this.filterMessages=[]
    //     const msgs=this.filteredLastFileMsgs(this.myAllFileMsgs)
        
    //     if(this.filterLastFileMsgs?.length>0){
    //       this.selectFileMsgs(this.filterLastFileMsgs[0],0)
    //     }
    //   } else if(n==1){
    //     this.filterMessages=[]
    //     if(this.filterFriendships?.length>0){
    //       this.selectFriendship(this.filterFriendships[0],0)
    //     }
    //   } else if(n==3){
    //     if(this.filterLastTaskMsgs?.length>0){
    //       this.selectTaskMsgs(this.filterLastTaskMsgs[0],0)
    //     }
    //   }
    // },

    filteredLastFileMsgs(fileMsgs){
        const result = Object.values(fileMsgs.reduce((acc, obj) => {
          if (!acc[obj.receiver] || obj.timestamp > acc[obj.receiver].timestamp) {
            acc[obj.receiver] = obj;
          }
          return acc;
        }, {}));
        const sortedResult = result.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        return sortedResult
    },

    hiddenSearchInput(){
      this.ifShowSearchInput=false
      this.searchText=''
      this.searchFiles=[]
      this.searchTasks=[]
      this.searchUsers=[]
    },

    searchItemsIncludeStr(str){
      

    },

    clearInput(){
      this.searchText=''
    },

    async searchItems(){
      this.searchFiles=await this.getFilesBySearchText(this.searchText,this.username)
      this.searchShareFiles= await this.getShareFilesBySearchText(this.searchText,this.username)
      this.searchTaskFiles= await this.getTaskFilesBySearchText(this.searchText,this.username)
      console.log('this.searchTaskFiles',this.searchTaskFiles)

      this.searchTasks= this.myAllVisibleTasks.filter(task => task?.name?.includes(this.searchText));
      this.searchUsers = await this.getFriendinfoBySearchText(this.searchText,this.userinfo.id);


      // 如果有搜索到的用户，处理头像  
      // if (this.searchUsers?.length > 0) {  
      //   // 创建 promises 数组  
      //   const promises = this.searchUsers.map(async (user) => {  
      //     // 处理头像并推送到 myFriendsAvatars  
      //     user.avatarUrl = await this.showPic(user.avatar, 'showavatar');  
      //   });  
    
      //   // 等待所有 promises 完成  
      //   await Promise.all(promises);
        
      //   console.log('searchUsers',this.searchUsers)
    
      //   // 所有遍历操作完成后，输出 searchUsers 的值  
      //   // this.allMyFriendChatContacters = this.searchUsers; // 如果需要保存处理后的用户信息  
      // }


      if(!this.searchUsers?.length&&!this.searchTasks?.length&&!this.searchFiles?.length&&!this.searchShareFiles?.length){
        this.searchText=[]
        alert('无相关搜索内容！')
      }

    },


    async searchItems1(e){
      const searchStr = e.target.value;
      let users = [];
      
      try {
        const result = await this.getFriendinfoBySearchText(searchStr,this.userinfo.id);
        const resultxx= await this.getSameProjectParterinfoBySearchText(searchStr,this.username)

        const resultArray = Array.isArray(result) ? result : [];
        const resultxxArray = Array.isArray(resultxx) ? resultxx : [];

        // 创建一个 Map 来存储唯一的对象
        const uniqueMap = new Map();

        [...resultArray, ...resultxxArray].forEach(item => {
          uniqueMap.set(item.id, item);
        });
        
        // 将 Map 的值转换回数组，这个数组现在包含了唯一的对象
        const combinedUniqueResults = Array.from(uniqueMap.values());
      
        if(combinedUniqueResults?.length > 0) {
          users = combinedUniqueResults;
          const promises = users.map(async (user) => {
            const useravatar = await this.showPic(user.avatar, 'showavatar');
            user.receiver_avatarUrl = useravatar;
            return user;
          });
          
          const results = await Promise.all(promises);
          this.searchUsers = results;
        } else {
          this.searchUsers = [];
        }

        // this.searchTasks = await this.getTasksBySearchText(searchStr,this.username);
        this.searchFiles=await this.getFilesBySearchText(searchStr,this.username)
        this.searchShareFiles= await this.getShareFilesBySearchText(searchStr,this.username)
        // this.searchTasks = this.myAllVisibleTasks==[]?[]:this.myAllVisibleTasks.filter(task => task.name.includes(searchStr));

        // 过滤任务，确保每个任务都是有效的对象，并且有 name 属性  
        this.searchTasks = this.myAllVisibleTasks && Array.isArray(this.myAllVisibleTasks)  
          ? this.myAllVisibleTasks.filter(task => task && typeof task.name === 'string' && task.name.includes(searchStr))  
          : [];  
  



        if(!this.searchUsers.length&&!this.searchTasks.length&&!this.searchFiles.length&&!this.searchShareFiles.length){
          this.searchText=[]
          alert('无相关搜索内容！')
        }
      } catch (error) {
        console.error('Error occurred: ', error);
      }
    },

    async addChatContacter(newChatContacter) {  
      const token = localStorage.getItem('token');  
    
      try {  
        const response = await axios({  
          method: 'post',  
          url: 'https://api2.itaskid.com/my/addchatcontacter',  
          headers: {  
            Authorization: token,  
            'Content-Type': 'application/x-www-form-urlencoded'  
          },  
          data: newChatContacter
        });  
    
        console.log('添加聊天对象成功', response.data);  
        return response.data; // 返回成功的响应数据  
      } catch (error) {  
        alert(error?.response?.data?.message || 'An error occurred.');  
      }  
    },




    // async addChatContacter(newChatContacter) {  
    //   const token = localStorage.getItem('token'); // 使用 localStorage 获取 token  
    
    //   try {  
    //     // 使用 uni.request 发送 POST 请求  
    //     const response = await new Promise((resolve, reject) => {  
    //       uni.request({  
    //         method: 'POST',  
    //         url: 'https://api2.itaskid.com/my/addchatcontacter',  
    //         header: {  
    //           Authorization: token,  
    //           'Content-Type': 'application/x-www-form-urlencoded'  
    //         },  
    //         data: newChatContacter,  
    //         success: (res) => {  
    //           resolve(res);  
    //         },  
    //         fail: (err) => {  
    //           reject(err);  
    //         }  
    //       });  
    //     });  
    
    //     console.log('添加聊天对象关系成功!', response);  
    //     return response.data; // 返回添加成功后的任务关系数据  
    //   } catch (error) {  
    //     alert(error?.response?.data?.message || 'An error occurred.'); // 使用 alert 显示错误信息  
    //   }  
    // },

    goToFriendChatContacter(user){

      const friendChatContacter = this.allMyFriendChatContacters.find(item=>item.contact_username === user.username);
      if(friendChatContacter){
        this.selectSearchContacter(friendChatContacter);
      }else {
        const newFriendContacter={
          idfcc:uuidv4(),
          myusername:this.username,
          contact_username:user.username,
          last_interaction:this.datetimeConvert(new Date()),
          in_pin:0,
          is_muted:0,
          contact_type:'manchat',
          contact_nickname:user.nickname,
          contact_avatar:user.avatar,
          contact_remarkname:'',
          contact_lastmsg:'',
          contact_lastmsgtime:this.datetimeConvert(new Date()),
          contact_ifshow:1,
          chatmsgs:[],
        }
        this.allMyFriendChatContacters.push(newFriendContacter)
        this.allMyChatContacters.push(newFriendContacter)
        this.addChatContacter(newFriendContacter)
          .then((result)=>{
            this.selectSearchContacter(newFriendContacter);
          })
      }
    },

    goToTaskChatContacter(task){
      const taskChatContacter = this.allMyTaskChatContacters.find(item=>item.contact_username === task.idft);
      if(taskChatContacter){
        this.selectSearchContacter(taskChatContacter);
      }else {
        const newTaskContacter={
          idfcc:uuidv4(),
          myusername:this.username,
          contact_username:task.idft,
          last_interaction:this.datetimeConvert(new Date()),
          in_pin:0,
          is_muted:0,
          contact_type:'taskchat',
          contact_nickname:task.name,
          contact_avatar:'',
          contact_remarkname:'',
          contact_lastmsg:'',
          contact_lastmsgtime:this.datetimeConvert(new Date()),
          contact_ifshow:1,
          chatmsgs:[],
        }
        this.allMyTaskChatContacters.push(newTaskContacter)
        this.allMyChatContacters.push(newTaskContacter)
        this.addChatContacter(newTaskContacter)
          .then((result)=>{
            this.selectSearchContacter(newTaskContacter);
          })
      }
    },

    goToFileChatContacter(file,filetype){
      var contact_username=''
      if(filetype=='pubfile'){
        contact_username=file.idff
      }else if(filetype=='taskfile'){
        contact_username=file.idftf
      }else if(filetype=='sharefile'){
        contact_username=file.idfsf
      }
      const fileChatContacter = this.allMyFileChatContacters.find(item=>item.contact_username === contact_username);
      if(fileChatContacter){
        fileChatContacter.filetype=filetype
        this.selectSearchContacter(fileChatContacter);
      }else {
        const newFileContacter={
          idfcc:uuidv4(),
          myusername:this.username,
          contact_username:contact_username,
          last_interaction:this.datetimeConvert(new Date()),
          in_pin:0,
          is_muted:0,
          contact_type:'filechat',
          contact_nickname:file.filename,
          contact_avatar:'',
          contact_remarkname:'',
          contact_lastmsg:'',
          contact_lastmsgtime:this.datetimeConvert(new Date()),
          contact_ifshow:1,
          filetype:filetype,
          chatmsgs:[],
        }
        this.allMyFileChatContacters.push(newFileContacter)
        this.allMyChatContacters.push(newFileContacter)
        this.addChatContacter(newFileContacter)
          .then((result)=>{
            this.selectSearchContacter(newFileContacter);
          })
      }
    },

    


    // sendMsgToFriend(friend){
    //   const filterFriend = this.filterLastFriendMsgs.find(item => item.sender === friend.username || item.receiver === friend.username);
    //   if (filterFriend) {
        
    //     this.filterLastFriendMsgs = this.filterLastFriendMsgs.filter(item => item !== filterFriend); // 从原来的位置删除
    //     this.filterLastFriendMsgs.unshift(filterFriend); // 将filterFriend放到队首
    //     this.selectFriendship(filterFriend,0);
    //   }else {

    //     const newFriendMsg={
    //       sender:this.username,
    //       receiver:friend.username,
    //       receiver_nickname:friend.nickname,
    //       sender_nickname:this.userinfo.nickname,
    //       message:'',
    //       timestamp:new Date(),
    //       sender_avatarUrl:this.avatarUrl_user,
    //       receiver_avatarUrl:friend.receiver_avatarUrl,
    //       sender_avatar:this.userinfo.avatar,
    //       receiver_avatar:friend.avatar
    //     }

    //     this.selectedFriendship=newFriendMsg
    //     this.filterLastFriendMsgs.unshift(newFriendMsg)
    //     this.myAllFriendMsgs.push(newFriendMsg)
    //     this.selectFriendship(newFriendMsg,0)
    //   }
    //   this.optionView=1
    //   this.hiddenSearchInput()
    // },

    // sendMsgToFriend1(friend){   //专门给从联系人跳转信息模块使用
    //   const filterFriend = this.filterLastFriendMsgs.find(item => item.sender === friend.username || item.receiver === friend.username);
    //   if (filterFriend) {

    //     this.filterLastFriendMsgs = this.filterLastFriendMsgs.filter(item => item !== filterFriend); // 从原来的位置删除
    //     this.filterLastFriendMsgs.unshift(filterFriend); // 将filterFriend放到队首
    //     this.selectFriendship(filterFriend,0);
    //   }else {

    //     friend.sender=this.username
    //     friend.receiver=friend.username
    //     friend.receiver_nickname=friend.nickname
    //     friend.sender_nickname=this.username
    //     friend.message=''
    //     friend.timestamp=new Date()
    //     this.filterLastFriendMsgs.unshift(friend)

    //     this.myAllFriendMsgs.push(friend)
    //     this.selectFriendship(friend,0)
    //   }
    //   this.optionView=1
    //   this.hiddenSearchInput()
    // },

    // sendMsgToFile(file){
    //   // this.chatto=file.filename
    //   const filterFileMsg = this.filterLastFileMsgs.find(item => item.receiver==file.idff);

    //   if(filterFileMsg){
    //     this.filterLastFileMsgs = this.filterLastFileMsgs.filter(item => item !== filterFileMsg); // 从原来的位置删除
    //     this.filterLastFileMsgs.unshift(filterFileMsg); 

    //     // this.filterLastTaskMsgs.unshift(filterTaskMsg); // 将filterFriend放到队首
        
    //     this.selectFileMsgs(this.filterLastFileMsgs[0],0);
    //   }else {
    //     const newFileMsg={
    //       id:uuidv4(),
    //       aboutwhat:'filechat',
    //       file_name:file.filename,
    //       file_size:file.size,
    //       img_name:'',
    //       message:'',
    //       project:file.project,
    //       receiver:file.idff,
    //       fileidff:file.idff,
    //       savebyreceiver:1,
    //       savebysender:1,
    //       sender:this.username,
    //       timestamp:new Date(),
    //       type:'text',
    //       sendernickname:'',
    //       task_name:'',
    //       receiverName:file.filename,
    //       receiverUrl:file.url
    //     }
    //     this.filterLastFileMsgs.unshift(newFileMsg)
    //     this.myAllFileMsgs.push(newFileMsg)
    //     this.selectFileMsgs(newFileMsg,0)

    //   }
    //   this.optionView=2
    //   this.hiddenSearchInput()

    // },

    // sendMsgToShareFile(file){
    //   // this.chatto=file.filename
    //   const filterFileMsg = this.filterLastFileMsgs.find(item => item.receiver==file.idfsf);

    //   if(filterFileMsg){
    //     this.filterLastFileMsgs = this.filterLastFileMsgs.filter(item => item !== filterFileMsg); // 从原来的位置删除
    //     this.filterLastFileMsgs.unshift(filterFileMsg); 

    //     // this.filterLastTaskMsgs.unshift(filterTaskMsg); // 将filterFriend放到队首
        
    //     this.selectFileMsgs(this.filterLastFileMsgs[0],0);
    //   }else {
    //     const newFileMsg={
    //       id:uuidv4(),
    //       aboutwhat:'filechat',
    //       file_name:file.filename,
    //       file_size:file.size,
    //       img_name:'',
    //       message:'',
    //       project:file.project,
    //       receiver:file.idfsf,
    //       fileidfsf:file.idfsf,
    //       savebyreceiver:1,
    //       savebysender:1,
    //       sender:this.username,
    //       timestamp:new Date(),
    //       type:'text',
    //       sendernickname:'',
    //       task_name:'',
    //       receiverName:file.filename,
    //       receiverUrl:file.url
    //     }
    //     this.filterLastFileMsgs.unshift(newFileMsg)
    //     this.myAllFileMsgs.push(newFileMsg)
    //     this.selectFileMsgs(newFileMsg,0)

    //   }
    //   this.optionView=2
    //   this.hiddenSearchInput()

    // },

    // sendMsgToTask(task){
    //   const filterTaskMsg = this.filterLastTaskMsgs.find(item => item.receiver==task.idft);
    //   if(filterTaskMsg){
    //     this.filterLastTaskMsgs = this.filterLastTaskMsgs.filter(item => item !== filterTaskMsg); // 从原来的位置删除
    //     this.filterLastTaskMsgs.unshift(filterTaskMsg); 
    //     // this.filterLastTaskMsgs.unshift(filterTaskMsg); // 将filterFriend放到队首
        
    //     this.selectTaskMsgs(this.filterLastTaskMsgs[0],0);
    //   }else {
    //     const newTaskMsg={
    //       id:uuidv4(),
    //       aboutwhat:'taskchat',
    //       file_name:"",
    //       file_size:0,
    //       img_name:'',
    //       message:'',
    //       project:task.project,
    //       receiver:task.idft,
    //       savebyreceiver:1,
    //       savebysender:1,
    //       sender:this.username,
    //       timestamp:new Date(),
    //       type:'text',
    //       sender_nickname:this.userinfo.nickname,
    //       task_name:task.name,
    //       sender_avatar:this.userinfo.avatar,
    //       sender_avatarUrl:''
    //     }
    //     this.showPic(this.userinfo.avatar,'showavatar')
    //       .then((result)=>{
    //         newTaskMsg.sender_avatarUrl=result

    //         this.filterLastTaskMsgs.unshift(newTaskMsg)
    //         this.myAllTaskMsgs.push(newTaskMsg)
    //         this.selectTaskMsgs(newTaskMsg,0)
    //       })
    //   }
    //   this.optionView=3
    //   this.hiddenSearchInput()

    // },

    showSearchInput(n){
      this.ifShowSearchInput=n>0?true:false
      this.optionView=n
    },

    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker
    },
    toggleFilePicker() {
      this.showFilePicker = !this.showFilePicker
    },



    async sendMessage(newText) {     
      
      if (newText.trim() !== '') {
        // 发送消息给socket.io服务器

        const now=new Date()
        const worldnow=this.convertChinaTimeToWorld(now)


        if(this.optionView==1){
          // const sender_avatar=this.selectedFriendship.sender==this.username?this.this.selectedFriendship.sender_avatar:

          if(this.isAssistant==0){

            const sender_nickname=this.userinfo.nickname
            const receiver_nickname=this.selectedChatContacter.contact_nickname

            const newMessage={
              id:uuidv4(),
              sender: this.username, 
              receiver: this.selectedChatContacter.contact_username, 
              message: newText, 
              file_name:'', 
              type:'text',
              aboutwhat:'manchat',
              sockettype:'manchat',
              img_name:'', 
              timestamp: new Date(), 
              file_size: 0, 
              savebysender: 1, 
              savebyreceiver: 1,
              sender_nickname:sender_nickname,
              receiver_nickname:receiver_nickname
            }

            newMessage.sender_avatar=this.userinfo.avatar
            newMessage.receiver_avatar=this.selectedChatContacter.contact_avatar
            
            // if(this.selectedFriendship.sender==this.username){
            //   newMessage.sender_avatar=this.selectedFriendship.sender_avatar
            //   newMessage.receiver_avatar=this.selectedFriendship.receiver_avatar
            // } else {
            //   newMessage.sender_avatar=this.selectedFriendship.receiver_avatar
            //   newMessage.receiver_avatar=this.selectedFriendship.sender_avatar
            // }

   
  
            const sender_avatarUrl=await this.showPic(newMessage.sender_avatar,'showavatar')
            const receiver_avatarUrl=await this.showPic(newMessage.receiver_avatar,'showavatar')
            newMessage.sender_avatarUrl=sender_avatarUrl
            newMessage.receiver_avatarUrl=receiver_avatarUrl
            this.addChatmsg(newMessage)
              .then((result)=>{
                // newMessage.id=result.id
                // this.socket.send(JSON.stringify(newMessage));
                this.socket.emit('message', newMessage)
              })
  
            // this.filterMessages.push(newMessage)
            // this.selectedFriendContacter.contact_lastmsg=newText
            //以下是刷新选中的好友关系的最新聊天记录
            // this.friendships = this.friendships.map(friendship => {
            //   if (friendship.id === this.selectedFriendInfo.id) {
            //     return { ...friendship, friend_lastmsg: newText }
            //   }
            //   return friendship;
            // });
            // this.updateFriendshipLastmsg(this.selectedFriendInfo.user_id, this.selectedFriendInfo.friend_id,newText)

          } else {
            
            const newMessage={
              id:uuidv4(),
              sender: this.username, 
              receiver: 'task|create', 
              message: newText, 
              file_name:'', 
              type:'text',
              aboutwhat:'assistantchat',
              img_name:'', 
              timestamp: new Date(), 
              file_size: 0, 
              savebysender: 1, 
              savebyreceiver: 1,
              sender_nickname:'我',
              receiver_nickname:'任意宝宝',
            }


            this.addChatmsg(newMessage)
              .then((result)=>{
                // newMessage.id=result.id
                this.lastAssistantMsg=newMessage
                this.assistantMsgs.push(newMessage)
                const idft=uuidv4()
                const newTask={
                  idft:idft,
                  name:newMessage.message,
                  duration:0,
                  starttime:'2999/12/31',
                  endtime:'2999/12/31',
                  pretask:'',
                  level:1,
                  parent:'roottask',
                  project:`${this.username}nogroup`,
                  haschild:0,
                  done:0,
                  expanded:1,
                }

                const newTaskAcl={
                  idftacl:uuidv4(),
                  permit_level:'charger',
                  username:this.username,
                  task_idft:idft,
                  project_idfp:`${this.username}nogroup`,
                  taclcreater:this.username
                }
                this.addTaskAclToSql(newTaskAcl)
                this.addTaskToSql(newTask)
                  .then(()=>{
                    const newMsg={
                      id:uuidv4(),
                      sender:  'task|reminder',
                      receiver: this.username, 
                      message: `已创建任务：${newText}`, 
                      file_name:'', 
                      type:'text',
                      aboutwhat:'assistantchat',
                      img_name:'', 
                      timestamp: new Date(), 
                      file_size: 0, 
                      savebysender: 1, 
                      savebyreceiver: 1,
                      sender_nickname:'我',
                      receiver_nickname:'任意宝宝',
                    }

                    this.addChatmsg(newMsg)
                      .then((resultx)=>{
                        // newMsg.id=resultx.id
                        this.lastAssistantMsg=newMsg
                        this.assistantMsgs.push(newMsg)
                      })

                  })

                



              })
          }
          


        } else if(this.optionView==2){
          const newMessage={
            id:uuidv4(),
            sender: this.username, 
            sender_nickname:this.userinfo.nickname,
            receiver: this.selectedChatContacter.contact_username, 
            message: newText, 
            file_name:this.selectedChatContacter.contact_nickname, 
            type:'text',
            aboutwhat:'filechat',
            // fileidff:this.selectedFileMsg.fileidff?this.selectedFileMsg.fileidff:'',
            // fileidfsf:this.selectedFileMsg.fileidfsf?this.selectedFileMsg.fileidfsf:'',
            img_name:'', 
            timestamp: new Date(), 
            file_size: 0, 
            savebysender: 1, 
            savebyreceiver: 1,
            // receiverName:this.selectedFileMsg.receiverName
          }

          const sender_avatarUrl = await this.showPic(this.userinfo.avatar,'showavatar')
          newMessage.sender_avatarUrl=sender_avatarUrl
          this.addChatmsg(newMessage)
            .then((result)=>{
              // newMessage.id=result.id

              // this.socket.send(JSON.stringify(newMesssage))
              this.socket.emit('message', newMessage)

            })
        } else if(this.optionView==3){
          const newTaskMsg={
            id:uuidv4(),
            sender: this.username, 
            receiver: this.selectedChatContacter.contact_username, 
            // task_name: this.selectedTaskMsg.task_name,
            // task_done: 0,
            sender_nickname:this.userinfo.nickname,
            message: newText, 
            file_name:'', 
            type:'text',
            aboutwhat:'taskchat',
            img_name:'', 
            timestamp: new Date(), 
            file_size: 0, 
            savebysender: 1, 
            savebyreceiver: 1,
            sender_avatar:this.userinfo.avatar,
            sender_avatarUrl:'',
          }
          const sender_avatarUrl = await this.showPic(this.userinfo.avatar,'showavatar')
          newTaskMsg.sender_avatarUrl=sender_avatarUrl
          
          this.addChatmsg(newTaskMsg)
            .then((result)=>{
              // newTaskMsg.id=result.id

              // this.socket.send(JSON.stringify(newTaskMsg))
              this.socket.emit('message', newTaskMsg)
            })
        }


      }
    },

    async sendTaskMessageOfFile(newMsg) {     
      newMsg.receiver=this.selectedTaskMsg.receiver
      newMsg.task_name=this.selectedTaskMsg.task_name
      const sender_nickname=(this.selectedFriendship.sender==this.username)?this.selectedFriendship.sender_nickname:this.selectedFriendship.receiver_nickname
      const receiver_nickname=(this.selectedFriendship.sender==this.username)?this.selectedFriendship.receiver_nickname:this.selectedFriendship.sender_nickname
      newMsg.sender_nickname=sender_nickname
      newMsg.receiver_nickname=receiver_nickname
      if(newMsg.type=='img'){
        const imgUrl=await this.showPic(newMsg.file_name, 'showmsgimg')
        newMsg.imgUrl=imgUrl
      }
      this.addChatmsg(newMsg)
        .then((result)=>{
          // newMsg.id=result.id
          // this.socket.send(JSON.stringify(newMsg))
          this.socket.emit('message', newMsg)
        })
    },

    aprovelFileFromMsg(newAssitantMsg,fileMsg){
      this.addChatmsg(newAssitantMsg)
        .then((result)=>{
          // newAssitantMsg.id=result.id
          // this.socket.send(JSON.stringify(newAssitantMsg))
          this.socket.emit('message', newAssitantMsg)
        })
      
      this.addChatmsg(fileMsg)
        .then((result)=>{
          // fileMsg.id=result.id
          // this.socket.send(JSON.stringify(fileMsg))
          this.socket.emit('message', fileMsg)
        })

    },

    convertChinaTimeToWorld(chinaTime){
      // 获取中国时间的时间戳
      const chinaTimestamp = new Date(chinaTime).getTime();

      //创建世界时间对象
      const utcTime = new Date(chinaTimestamp);

      const utcTimeString = utcTime.toISOString()
      return utcTimeString
    },

    datetimeConvert(datetime){
      const date = new Date(datetime);
      const options = {timeZone: 'Asia/Shanghai', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
      const formattedTime = date.toLocaleString('zh-CN', options);
      return formattedTime;
    },

    computedfilterLastFileMsgs(msgs){
        const result = Object.values(msgs.reduce((acc, obj) => {
          if (!acc[obj.receiver] || obj.timestamp > acc[obj.receiver].timestamp) {
            acc[obj.receiver] = obj;
          }
          return acc;
        }, {}));
        const sortedResult = result.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        return sortedResult
    },

    computedfilterLastTaskMsgs(msgs){
        const result = Object.values(msgs.reduce((acc, obj) => {
          if (!acc[obj.receiver] || obj.timestamp > acc[obj.receiver].timestamp) {
            acc[obj.receiver] = obj;
          }
          return acc;
        }, {}));
        const sortedResult = result.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        return sortedResult
    },

    // selectFriendship(friend,index){
    //   this.isAssistant=0
    //   this.selectedFriendMsgIndex=index

    //   this.$router.push({
    //     name: 'msgrecord',
    //     params: {
    //       id: friend.id
    //     }
    //   }).catch(err => {
    //     if (err.name !== 'NavigationDuplicated') {
    //       throw err
    //     }
    //   })
    //   this.friendUsername=(friend.sender==this.username)?friend.receiver:friend.sender
    //   this.chatto=(friend.sender==this.username)?friend.receiver_nickname:friend.sender_nickname
    //   this.selectedFriendInfo=friend
    //   this.selectedFriendship=friend
    //   const selectedFriendInfo=JSON.stringify(friend)
    //   localStorage.setItem('selectedFriendInfo',selectedFriendInfo)
    //   this.avatarUrl_friend=friend.sender==this.username?friend.receiver_avatarUrl:friend.sender_avatarUrl
    //   this.filterMessages=this.myAllFriendMsgs.filter(msg=>{
    //     if((msg.sender==this.friendUsername)||(msg.receiver==this.friendUsername)){
    //       // const resul=await this.showPic(msg.file_name, 'showmsgimg')
    //       // msg.imgUrl = resul
    //       return msg
    //     }
    //   }).sort((a, b) => {  
    //     return new Date(a.timestamp) - new Date(b.timestamp); // 升序排序  
    //   });
    // },


    selectFriendContacter(contacter,index){
      this.isAssistant=0
      this.selectedFriendMsgIndex=index
      this.selectedChatContacter=contacter
      if(contacter.contact_type=='manchat'){
        this.optionView=1
      } else if(contacter.contact_type=='filechat'){
        this.optionView=2
      } else if(contacter.contact_type=='taskchat'){
        this.optionView=3
      }

      this.$router.push({
        name: 'msgrecord',
        params: {
          // id: friend.id
          id: contacter.idfcc
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
      this.friendUsername=contacter.contact_username
      this.chatto=contacter.contact_nickname
      if(contacter.contact_avatar){
        this.avatarUrl_friend=contacter.contact_avatar
      }else {
        this.avatarUrl_friend=''
      }
      if(!contacter.chatmsgs||contacter.chatmsgs.length==0){
        this.filterMessages=[]
      }else {
        this.filterMessages=contacter.chatmsgs.slice().sort((a, b) => {
          return new Date(a.timestamp) - new Date(b.timestamp); // 升序排序
        });
      }

      this.allMyChatContacters = [  
        ...this.allMyChatContacters  
          .filter(contact => contact.in_pin === 1) // 先筛选出 in_pin = 1 的数据  
          .sort((a, b) => new Date(b.last_interaction) - new Date(a.last_interaction)), // 降序排序  
        ...this.allMyChatContacters  
          .filter(contact => contact.in_pin === 0) // 再筛选出 in_pin = 0 的数据  
          .sort((a, b) => new Date(b.last_interaction) - new Date(a.last_interaction)) // 降序排序  
      ];
    },

    selectSearchContacter(contacter){
      this.isAssistant=0
      this.selectedChatContacter=contacter
      this.searchText=''

      contacter.last_interaction=this.datetimeConvert(new Date())

      this.updateChatContacterLastInteraction(contacter.idfcc,contacter.last_interaction)

      // this.allMyChatContacters=this.allMyChatContacters.sort((a, b) => {
      //   return new Date(b.last_interaction) - new Date(a.last_interaction); // 降序排序
      // });

      // 按照 in_pin 和 last_interaction 进行排序  
      this.allMyChatContacters = [  
        ...this.allMyChatContacters  
          .filter(contact => contact.in_pin === 1) // 先筛选出 in_pin = 1 的数据  
          .sort((a, b) => new Date(b.last_interaction) - new Date(a.last_interaction)), // 降序排序  
        ...this.allMyChatContacters  
          .filter(contact => contact.in_pin === 0) // 再筛选出 in_pin = 0 的数据  
          .sort((a, b) => new Date(b.last_interaction) - new Date(a.last_interaction)) // 降序排序  
      ];  
      
      if(contacter.contact_type=='manchat'){
        this.optionView=1
      } else if(contacter.contact_type=='filechat'){
        this.optionView=2
      } else if(contacter.contact_type=='taskchat'){
        this.optionView=3
      }

      this.$router.push({
        name: 'msgrecord',
        params: {
          // id: friend.id
          id: contacter.idfcc
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
      this.friendUsername=contacter.contact_username
      this.chatto=contacter.contact_nickname
      if(contacter.contact_avatar){
        this.avatarUrl_friend=contacter.contact_avatar
      }else {
        this.avatarUrl_friend=''
      }
      if(!contacter.chatmsgs||contacter.chatmsgs.length==0){
        this.filterMessages=[]
      }else {
        this.filterMessages=contacter.chatmsgs.slice().sort((a, b) => {
          return new Date(a.timestamp) - new Date(b.timestamp); // 升序排序
        });
      }
    },

    // async selectFriendship(friend, index) {
    //   this.isAssistant = 0;
    //   this.selectedFriendMsgIndex = index;
    
    //   try {
    //     await this.$router.push({
    //       name: 'msgrecord',
    //       params: {
    //         id: friend.id
    //       }
    //     });
    //   } catch (err) {
    //     if (err.name !== 'NavigationDuplicated') {
    //       throw err;
    //     }
    //   }
    
    //   this.friendUsername = (friend.sender == this.username) ? friend.receiver : friend.sender;
    //   this.chatto = (friend.sender == this.username) ? friend.receiver_nickname : friend.sender_nickname;
    //   this.selectedFriendInfo = friend;
    //   this.selectedFriendship = friend;
    //   const selectedFriendInfo = JSON.stringify(friend);
    //   localStorage.setItem('selectedFriendInfo', selectedFriendInfo);
    //   this.avatarUrl_friend = friend.sender == this.username ? friend.receiver_avatarUrl : friend.sender_avatarUrl;
    
    //   // 使用map和Promise.all来处理数组中的异步操作
    //   this.filterMessages = await Promise.all(this.myAllFriendMsgs.map(async (msg) => {
    //     if (msg.sender == this.friendUsername || msg.receiver == this.friendUsername) {
    //       const result = await this.showPic(msg.file_name, 'showmsgimg');
    //       msg.imgUrl = result;
    //       // console.log('msg',msg)
    //       return msg;
    //     }
    //     return null; // 对于不满足条件的项，返回null
    //   })).then(results => results.filter(msg => msg !== null)); // 过滤掉null值
    
      
    // },

    selectAssistant(){
      this.optionView=1
      this.isAssistant=1
      this.selectedFriendMsgIndex=-2
      this.chatto='AI助手'
      this.filterMessages=this.assistantMsgs
    },

    selectTaskMsgs(taskMsg,index){
      
      this.$router.push({
        name: 'msgrecord',
        params: {
          id: taskMsg.id
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
      this.chatto=taskMsg.task_name
      this.selectedTaskMsgIndex=index
      this.selectedTaskMsg=taskMsg
      this.selectedTaskMsgs=this.myAllTaskMsgs.filter(item=>item.receiver==taskMsg.receiver)
      // this.getProjectPartersFromTask(taskMsg.project)
      //   .then((result)=>{
      //     this.selectedProjectAcls=result
      //     this.ifShowMsgsRecord=true

      //     this.selectedTaskMsgs.map((msg)=>{
      //       const sender=result.find(item=>item.username==msg.sender)
      //       msg.sendernickname=sender.nickname
      //     })
      //   })

      //此处有问题

    },

    selectFileMsgs(fileMsg,index){
      this.selectedFileMsg=fileMsg
      
      this.chatto=fileMsg.receiverName
      this.$router.push({
        name: 'msgrecord',
        params: {
          id: fileMsg.id
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })

      
      this.selectedFileMsgIndex=index
      // this.selectedFileMsg=fileMsg
      // this.chatto=fileMsg.file_name
      this.filterMessages=this.myAllFileMsgs.filter(msg=>msg.receiver==fileMsg.receiver)
      this.getProjectPartersFromTask(fileMsg.project)
        .then((result)=>{
          this.selectedProjectAcls=result
          this.ifShowMsgsRecord=true

        })
    },




    // 添加某个任务
    async addChatmsg(newMsg) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/chatmsgs',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newMsg
        });
        console.log('数据库新增的聊天记录',response.data);
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },

    // 向服务器添加发送文件的聊天记录
    async addChatmsgForFile(newMsg){
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://api2.itaskid.com' + '/my/chatmsgs', newMsg, {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getMsgs(sender, receiver) {
      try {
        const token = localStorage.getItem('token');
        const data = { sender: sender, receiver: receiver };
        const response = await axios.get('https://api2.itaskid.com' + '/my/chatmsgs', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        
        if (Array.isArray(response.data)) {
        
          return response.data
        } else {
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getAllMyFriendChatMsgs(username) {
      try {
        const token = localStorage.getItem('token');
        const data = { sender: username, receiver: username };
        const response = await axios.get('https://api2.itaskid.com' + '/my/getallmyfriendchatmsgs', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        
        if (Array.isArray(response.data)) {
        
          return response.data
        } else {
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    // async getFriendinfoBySearchText(searchText,userId) {
    //   try {
    //     const token = localStorage.getItem('token');
    //     const data = { searchText: searchText,userId:userId};
    //     const response = await axios.get('https://api2.itaskid.com' + '/my/getfriendinfobysearchstr', {
    //       headers: {
    //         Authorization: token,
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       params: data
    //     });
        
    //     if (Array.isArray(response.data.data)) {
    //       return response.data.data
    //     } else {
    //       // alert('meiy')
    //     }
    //   } catch (error) {
    //     console.log(error?.response?.data?.message || 'An error occurred.');
    //   }
    // },

    async getFriendinfoBySearchText(searchText, userId) {  
      try {  
        const token = localStorage.getItem('token');  
        const data = { searchText: searchText, userId: userId };  
        const response = await axios.get('https://api2.itaskid.com' + '/my/getfriendinfobysearchstr', {  
          headers: {  
            Authorization: token,  
            'Content-Type': 'application/x-www-form-urlencoded'  
          },  
          params: data  
        });  
    
        if (Array.isArray(response.data.data)) {  
          // 获取好友信息  
          const friends = response.data.data;  
    
          // 创建 promises 数组以处理头像  
          const promises = friends.map(async (friend) => {  
            // 获取头像 URL  
            friend.avatarUrl = await this.showPic(friend.avatar, 'showavatar');  
            return friend; // 返回处理后的好友对象  
          });  
    
          // 等待所有头像处理完成  
          const processedFriends = await Promise.all(promises);  
          return processedFriends; // 返回处理后的好友列表  
        } else {  
          // alert('meiy')  
          return []; // 如果没有好友信息，返回空数组  
        }  
      } catch (error) {  
        console.log(error?.response?.data?.message || 'An error occurred.');  
        return []; // 在发生错误时返回空数组  
      }  
    },

    async getSameProjectParterinfoBySearchText(searchText,username) {
      try {
        const token = localStorage.getItem('token');
        const data = { searchText: searchText,username:username};
        const response = await axios.get('https://api2.itaskid.com' + '/my/getsameprojectparterinfobysearchstr', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        
        if (Array.isArray(response.data.data)) {
          return response.data.data
        } else {
          // alert('meiy')
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getTasksBySearchText(text,username) {
      try {
        const token = localStorage.getItem('token');
        const data = { searchText: text,username:username};
        const response = await axios.get('https://api2.itaskid.com' + '/my/gettasksbysearchtext', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        
        if (Array.isArray(response.data.data)) {
        
          return response.data.data
        } else {
          // alert('没有该任务！')
          return []
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getFilesBySearchText(text,username) {
      try {
        const token = localStorage.getItem('token');
        const data = { searchText: text,username:username};
        const response = await axios.get('https://api2.itaskid.com' + '/my/getfilesbysearchstr', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        
        if (Array.isArray(response.data.data)) {
          return response.data.data
        } else {
          // alert('没有该任务！')
          return []
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getShareFilesBySearchText(text,username) {
      try {
        const token = localStorage.getItem('token');
        const data = { searchText: text,username:username};
        const response = await axios.get('https://api2.itaskid.com' + '/my/getsharefilesbysearchstr', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        
        if (Array.isArray(response.data.data)) {
          return response.data.data
        } else {
          // alert('没有该任务！')
          return []
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },


    async getTaskFilesBySearchText(text,username) {
      try {
        const token = localStorage.getItem('token');
        const data = { searchText: text,username:username};
        const response = await axios.get('https://api2.itaskid.com' + '/my/gettaskfilesbysearchstr', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });

        console.log('response',response)
        
        if (Array.isArray(response.data.data)) {
          return response.data.data
        } else {
          // alert('没有该任务！')
          return []
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getFriendships(id,token) {
      try {
        const data = { user_id: id };
        const response = await axios.get('https://api2.itaskid.com' + '/my/friendships', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          return response.data
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }

      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.')
      }
    },


    async showPic(imgname,url) {
      if(!imgname||imgname=='null'||imgname==''){
        return undefined
      } else {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api1.itaskid.com' + `/my/${url}`,
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }
    }, 

    async getFriendshipsByUserId(id,token) {
      try {
        const data = { user_id: id };
        const response = await axios.get('https://api2.itaskid.com' + '/my/friendshipsbyuserid', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          return response.data
        } else {
          // alert('账号未认证，无法获取数据！');
        }

      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.')
      }
    },  

    //发送文件/图片相关的函数
    isImgFile(filename) {
      if(filename==='') {
        return false
      } else {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i
        return imageExtensions.test(filename)
      }
    },

    async handleDropOfFriend(files){
      this.sendFiles=files
      const avatarUrl = await this.showPic(this.selectedChatContacter.contact_avatar,'showavatar');
      this.selectedChatContacter.contact_avatarUrl=avatarUrl
      this.showSendFileMenu=true
      console.log('contacter',this.selectedChatContacter)
    },


    handleDropOfAssistant(files){
      this.showPubFileMenu = true;
      this.pubingFile=files
    },

    handleFileDrop(files){
      // this.pubingFile=files
      this.sendFilesToFile(files)
      
    },

    handleTaskDrop(files){
      this.sendFilesToTask(files)
    },

    // async handleDropOfAssistant(files) {

    //   let projectAcllist = [];
    //   try {
    //     const result = await this.getMyProjectAclList(this.username);
    //     projectAcllist = result;
    //     if (projectAcllist.length > 0) {
    //       const promises = projectAcllist.map(async (projectacl) => {
    //         const project = await this.getMyProjectListByIdfp(projectacl.project_idfp);
    //         projectacl.name = project[0].name;
    //         projectacl.groupname = project[0].groupname;
    //         projectacl.status = project[0].status;
    //         projectacl.is_delete = project[0].is_delete;
    //         projectacl.createdTime = project[0].createdTime;
    //         projectacl.creater = project[0].creater;
    //         projectacl.permit_type = project[0].permit_type;
    //         projectacl.idfp = project[0].idfp;
    //       });
    //       await Promise.all(promises);
    //     }
    //     const usernogroup=`${this.username}nogroup`
    //     this.myProjectlist=projectAcllist.filter(item=>(!(item.idfp==usernogroup)))
    //     this.showPubFileMenu = true;
    //   } catch (error) {
    //     // Handle any errors here
    //     console.error(error);
    //   }
    // },

    closeFilePubMenu(){
      this.showPubFileMenu=false
    },

    async addPubfile(newPubfile) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/pubfiles',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newPubfile
        });
    
        // console.log('数据库新增的发布文件信息', response.data);
        console.log('新增发布文件成功！');
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },


    //这个函数是否正确还需要验证。
    pubNewFile(newPubFile,newMsg){
      const username=localStorage.getItem('username')
      // this.getMyPubFiles(username)
      this.addPubfile(newPubFile)
        .then((result)=>{
          const pubfile=result
          const checkers=pubfile.checker.split(';')
          const approver=pubfile.approver

          const newAssitantMsg={
            id:uuidv4(),
            sender:'file|approve',
            message:'老板，有以下文件需要您审批。',
            timestamp:new Date(),
            file_name: pubfile.filename,
            type:'text',
            aboutwhat:'assistantchat',
            fileidff:pubfile.idff
          }

          if(pubfile.filestatus=='已发布'){

          } else if(checkers?.length>0){
   
            checkers.forEach((checker)=>{
              newAssitantMsg.receiver=checker
              this.addChatmsg(newAssitantMsg)
            })
          } else if(checkers.length==0&&approver!=''){
            newAssitantMsg.receiver=approver
            this.addChatmsg(newAssitantMsg)
          }

          const fileMsg={
            id:uuidv4(),
            receiver:newPubFile.idff,
            fileidff:newPubFile.idff,
            sender: `publisher|${username}`,
            timestamp: new Date(),
            file_name: newPubFile.filename,
            message:newMsg,
            type:'text',
            img_name:'',
            file_size:0,
            aboutwhat:'fileprocess'
          }
          this.addChatmsg(fileMsg)


          const myNewAssitantMsg={
            id:uuidv4(),
            sender:'file|reminder',
            message:`文件"${newPubFile.filename}"已发起审批`,
            receiver:username,
            timestamp:new Date(),
            file_name: pubfile.filename,
            type:'text',
            aboutwhat:'assistantchat',
            fileidff:pubfile.idff
          }

          this.addChatmsg(myNewAssitantMsg)


        })

    },


    cancelSendFilesToFriend(){
      this.showSendFileMenu=false
      this.sendFiles=[]
    },

    async sendFilesToTask(sendfiles) {
      // const sender_nickname=(this.selectedFriendship.sender==this.username)?this.selectedFriendship.sender_nickname:this.selectedFriendship.receiver_nickname
      // const receiver_nickname=(this.selectedFriendship.sender==this.username)?this.selectedFriendship.receiver_nickname:this.selectedFriendship.sender_nickname
      try {
        const files = await this.uploadFiles(sendfiles);
        const promises = files.map(async (file) => {
          const isImg=this.isImgFile(file.filename)
          let newMsg={
            id: uuidv4(),
            sender: this.username,
            receiver: this.selectedChatContacter.contact_username,
            fileidff: '',
            fileidfsf: '',
            savebysender: 1,
            receiverName: this.selectedChatContacter.contact_nickname,
            savebyreceiver: 1,            
            file_name: file.filename,
            type: '',
            aboutwhat:'taskchat',
            nickname:this.userinfo.nickname,
            img_name: '',
            file_size: file.size,
            imgUrl: '', // 添加 imgUrl 属性
            timestamp: new Date()            
          }
          if(isImg) {
            newMsg.type='img'
            newMsg.message=`图片：${file.filename}`
            // const result1 = await this.addChatmsgForFile(newMsg);
            const result2 = await this.showPic(file_name, 'showmsgimg');
            newMsg.imgUrl = result2;
            this.socket.emit('message', newMsg)
          }else {
            newMsg.type='file'     
            newMsg.message=`文件：${file.filename}`
            this.socket.emit('message', newMsg)
            this.addChatmsg(newMsg);
          }
        })
        await Promise.all(promises)
      } catch (error) {
        console.error(error);
      }
    },
    
    async sendFilesToFile(sendfiles) {
      // const sender_nickname=(this.selectedFriendship.sender==this.username)?this.selectedFriendship.sender_nickname:this.selectedFriendship.receiver_nickname
      // const receiver_nickname=(this.selectedFriendship.sender==this.username)?this.selectedFriendship.receiver_nickname:this.selectedFriendship.sender_nickname
      try {
        const files = await this.uploadFiles(sendfiles);
        const promises = files.map(async (file) => {
          const isImg=this.isImgFile(file.filename)
          let newMsg={
            id: uuidv4(),
            sender: this.username,
            receiver: this.selectedChatContacter.contact_username,
            fileidff: '',
            fileidfsf: '',
            savebysender: 1,
            receiverName: this.selectedChatContacter.contact_nickname,
            savebyreceiver: 1,            
            file_name: file.filename,
            type: '',
            aboutwhat:'filechat',
            nickname:this.userinfo.nickname,
            img_name: '',
            file_size: file.size,
            imgUrl: '', // 添加 imgUrl 属性
            timestamp: new Date()            
          }
          if(isImg) {
            newMsg.type='img'
            newMsg.message=`图片：${file.filename}`
            // const result1 = await this.addChatmsgForFile(newMsg);
            const result2 = await this.showPic(file_name, 'showmsgimg');
            newMsg.imgUrl = result2;
            this.socket.emit('message', newMsg)
          }else {
            newMsg.type='file'     
            newMsg.message=`文件：${file.filename}`
            this.socket.emit('message', newMsg)
            this.addChatmsg(newMsg);
          }
        })
        await Promise.all(promises)
      } catch (error) {
        console.error(error);
      }
    },

    //20250123以前的，没有增加chatContacter
    // async sendFilesToFriend(fileMsg) {
    //   this.showSendFileMenu = false;
    //   const sender_nickname=(this.selectedFriendship.sender==this.username)?this.selectedFriendship.sender_nickname:this.selectedFriendship.receiver_nickname
    //   const receiver_nickname=(this.selectedFriendship.sender==this.username)?this.selectedFriendship.receiver_nickname:this.selectedFriendship.sender_nickname
          
    //   try {
    //     const files = await this.uploadFiles(this.sendFiles);
    //     const promises = files.map(async (file) => {
    //       const isImg=this.isImgFile(file.filename)
          
    //       let newMsg={
    //         id: uuidv4(),
    //         sender: this.username,
    //         receiver: this.friendUsername,
    //         savebysender: 1,
    //         savebyreceiver: 1,
    //         sender_nickname:sender_nickname,
    //         receiver_nickname:receiver_nickname,            
    //         // message: '文件来了',
    //         file_name: file.filename,
    //         type: '',
    //         aboutwhat:'manchat',
    //         img_name: '',
    //         file_size: file.size,
    //         imgUrl: '', // 添加 imgUrl 属性
    //         timestamp: new Date()            
    //       }
    //       if(isImg) {
    //         newMsg.type='img'
    //         newMsg.message=`图片：${file.filename}`
    //         const result1 = await this.addChatmsgForFile(newMsg);
    //         const result2 = await this.showPic(result1.file_name, 'showmsgimg');
    //         newMsg.imgUrl = result2;
    //         // this.socket.send(JSON.stringify(newMsg))
    //         this.socket.emit('message', newMsg)
    //         // this.filterMessages.push(newMsg)

    //         //以下是刷新选中的好友关系的最新聊天记录
    //         // this.friendships = this.friendships.map(friend => {
    //         //   if (friend.id === this.selectedFriendInfo.id) {
    //         //     return { ...friend, friend_lastmsg: '[图片]' }
    //         //   }
    //         //   return friend;
    //         // });
    //         this.selectedFriendInfo.friend_lastmsg='[图片]'
    //         this.updateFriendshipLastmsg(this.selectedFriendInfo.user_id, this.selectedFriendInfo.friend_id,'[图片]')

    //       }else {
    //         newMsg.type='file'     
    //         newMsg.message=`文件：${file.filename}`
    //         // this.socket.send(JSON.stringify(newMsg));
    //         this.socket.emit('message', newMsg)
    //         this.addChatmsg(newMsg);
    //         // this.filterMessages.push(newMsg)
            
    //         //以下是刷新选中的好友关系的最新聊天记录
    //         // this.friendships = this.friendships.map(friend => {
    //         //   if (friend.id === this.selectedFriendInfo.id) {
    //         //     return { ...friend, friend_lastmsg: '[文件]' }
    //         //   }
    //         //   return friend;
    //         // });
    //         this.selectedFriendInfo.friend_lastmsg='[文件]'
    //         this.updateFriendshipLastmsg(this.selectedFriendInfo.user_id, this.selectedFriendInfo.friend_id, '[文件]')
    //       }
    //     })
    //     await Promise.all(promises)
    //     if(fileMsg) {
    //       const leftMsg={
    //         id: uuidv4(),
    //         sender: this.username,
    //         receiver: this.friendUsername,
    //         savebysender: 1,
    //         savebyreceiver: 1,             
    //         message: fileMsg,
    //         aboutwhat:'manchat',
    //         sender_nickname:sender_nickname,
    //         receiver_nickname:receiver_nickname,  
    //         file_name: '',
    //         type: 'text',
    //         img_name: '',
    //         timestamp: new Date()         
    //       }
    //       this.addChatmsg(leftMsg)
    //       // this.filterMessages.push(leftMsg)
    //       // this.socket.send(JSON.stringify(leftMsg));
    //       this.socket.emit('message', leftMsg)
    //       //以下是刷新选中的好友关系的最新聊天记录
    //       this.friendships = this.friendships.map(friend => {
    //         if (friend.id === this.selectedFriendInfo.id) {
    //           return { ...friend, friend_lastmsg: fileMsg }
    //         }
    //         return friend;
    //       });
    //     }

    //   } catch (error) {
    //     console.error(error);
    //     // 处理上传失败的逻辑
    //   }
    // },


    async sendFilesToFriend(fileMsg) {
      this.showSendFileMenu = false;
      const sender_nickname=this.userinfo.nickname
      const receiver_nickname=this.selectedChatContacter.contact_nickname
          
      try {
        const files = await this.uploadFiles(this.sendFiles);
        const promises = files.map(async (file) => {
          const isImg=this.isImgFile(file.filename)
          
          let newMsg={
            id: uuidv4(),
            sender: this.username,
            receiver: this.selectedChatContacter.contact_username,
            savebysender: 1,
            savebyreceiver: 1,
            sender_nickname:sender_nickname,
            receiver_nickname:receiver_nickname,            
            // message: '文件来了',
            file_name: file.filename,
            type: '',
            aboutwhat:'manchat',
            img_name: '',
            file_size: file.size,
            imgUrl: '', // 添加 imgUrl 属性
            timestamp: new Date()            
          }
          if(isImg) {
            newMsg.type='img'
            newMsg.message=`图片：${file.filename}`
            const result1 = await this.addChatmsgForFile(newMsg);
            const result2 = await this.showPic(result1.file_name, 'showmsgimg');
            newMsg.imgUrl = result2;
            // this.socket.send(JSON.stringify(newMsg))
            this.socket.emit('message', newMsg)
            // this.filterMessages.push(newMsg)

            //以下是刷新选中的好友关系的最新聊天记录
            // this.friendships = this.friendships.map(friend => {
            //   if (friend.id === this.selectedFriendInfo.id) {
            //     return { ...friend, friend_lastmsg: '[图片]' }
            //   }
            //   return friend;
            // });

            // this.selectedFriendInfo.friend_lastmsg='[图片]'
            // this.updateFriendshipLastmsg(this.selectedFriendInfo.user_id, this.selectedFriendInfo.friend_id,'[图片]')

          }else {
            newMsg.type='file'     
            newMsg.message=`文件：${file.filename}`
            // this.socket.send(JSON.stringify(newMsg));
            this.socket.emit('message', newMsg)
            this.addChatmsg(newMsg);
            // this.filterMessages.push(newMsg)
            
            //以下是刷新选中的好友关系的最新聊天记录
            // this.friendships = this.friendships.map(friend => {
            //   if (friend.id === this.selectedFriendInfo.id) {
            //     return { ...friend, friend_lastmsg: '[文件]' }
            //   }
            //   return friend;
            // });

            // this.selectedFriendInfo.friend_lastmsg='[文件]'
            // this.updateFriendshipLastmsg(this.selectedFriendInfo.user_id, this.selectedFriendInfo.friend_id, '[文件]')
          }
        })
        await Promise.all(promises)
        if(fileMsg) {
          const leftMsg={
            id: uuidv4(),
            sender: this.username,
            receiver: this.selectedChatContacter.contact_username,
            savebysender: 1,
            savebyreceiver: 1,             
            message: fileMsg,
            aboutwhat:'manchat',
            sender_nickname:sender_nickname,
            receiver_nickname:receiver_nickname,  
            file_name: '',
            type: 'text',
            img_name: '',
            timestamp: new Date()         
          }
          this.addChatmsg(leftMsg)
          // this.filterMessages.push(leftMsg)
          // this.socket.send(JSON.stringify(leftMsg));
          this.socket.emit('message', leftMsg)
          //以下是刷新选中的好友关系的最新聊天记录
          // this.friendships = this.friendships.map(friend => {
          //   if (friend.id === this.selectedFriendInfo.id) {
          //     return { ...friend, friend_lastmsg: fileMsg }
          //   }
          //   return friend;
          // });
        }

      } catch (error) {
        console.error(error);
        // 处理上传失败的逻辑
      }
    },

    async uploadFiles(files) {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const encodedFileName = encodeURIComponent(files[i].name);
        formData.append('files', files[i], encodedFileName);
      }
    
      try {
        const response = await axios.post('https://api2.itaskid.com' + '/my/uploadmsgfiles', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        });
        // console.log(response.data);
        return response.data;
        // 处理上传成功的逻辑
      } catch (error) {
        console.error(error);
        throw error;
        // 处理上传失败的逻辑
      }
    },

    updateFriendshipLastmsg(userId,friendId,lastmsg){    //更新好友关系最新聊天记录的处理函数
      const newMsg={user_id:userId, friend_id:friendId,friend_lastmsg: lastmsg}
      const token=localStorage.getItem('token')
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/updatefriendshiplastmsg',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newMsg
          })
          .then((response)=>{
            // console.log(response.data)
            console.log('修改好友关系最新聊天记录成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },

    updateChatContacterLastInteraction(idfcc,last_interaction){    //更新好友关系最新聊天记录的处理函数
      const newData={idfcc:idfcc, last_interaction:last_interaction}
      const token=localStorage.getItem('token')
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/updatechatcontacterlastinteraction',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newData
          })
          .then((response)=>{
            // console.log(response.data)
            console.log('修改聊天对象最新交互时间成功！',response.data)
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },

    updateChatContacterByIdfcc(contacter){    //更新好友关系最新聊天记录的处理函数
      const token=localStorage.getItem('token')
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/updatechatcontacterbyidfcc',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: contacter
          })
          .then((response)=>{
            // console.log(response.data)
            console.log('修改聊天对象最新交互时间成功！',response.data)
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },

    //聊天记录的处理函数
    //删除本地化的聊天记录
    deleteMessage(message){
      this.filterMessages=this.filterMessages.filter(item=>item.id!==message.id)
    },

    deleteChatContacterByIdfcc(contacter){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/deletechatcontacterbyidfcc',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: contacter
        })
        .then((response)=>{
          // console.log(response.data)
          console.log(response.data)
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除聊天记录失败！')
        })
    },

    //删除
    deleteSqlMsgbyId(id){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/deletemsgbyid',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {id:id}
        })
        .then((response)=>{
          // console.log(response.data)
          console.log('删除聊天记录成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除聊天记录失败！')
        })
    },


    //好友关系列表右键的处理函数
    
    handleContactersMenu(event,contacter){
      console.log('contacter',contacter)
      this.selectedContacterByRight=contacter
      this.friendshipeditMenuitems=[
        { id:1,label: '置顶' }, 
        { id:2,label: '标为未读' },
        { id:3,label: '消息免打扰' },
        // { id:4,label: '不显示聊天' }, 
        { id:5,label: '删除聊天' },
      ],
      this.showEditContacterMenu=true
      this.switchMenuMask() 
      const top=(window.innerHeight-event.clientY)<189?(event.clientY-189):event.clientY
      const left=(window.innerWidth-event.clientX)<76?(event.clientX-76):event.clientX
      this.parentNode ={clientY:top+20,clientX:left}
      eventBus.$on('closeContexMenu', () => {
        this.showEditContacterMenu=false
      })
    },

    //处理好友聊天记录的右键点击函数
    chatContacterEditMenuClick(item){
      
      //在这里处理右键菜单对应的选项
      this.showEditContacterMenu=false
      //以下是项目编辑右键菜单的处理分支
      switch (item.id){
        case 1: {

          this.selectedContacterByRight.in_pin=1
          this.selectedContacterByRight.contact_lastmsgtime=this.datetimeConvert(this.selectedContacterByRight.contact_lastmsgtime)
          this.selectedContacterByRight.last_interaction=this.datetimeConvert(new Date())
          this.updateChatContacterByIdfcc(this.selectedContacterByRight)
          this.selectFriendContacter(this.selectedContacterByRight)  
          break
        }
        case 2: {
          
          break
        }
        case 3: {
          
          break
        }
        case 4: {
          
          break
        }
        case 5: {
          this.allMyChatContacters=this.allMyChatContacters.filter(item=>item.idfcc!==this.selectedContacterByRight.idfcc)
          this.deleteChatContacterByIdfcc(this.selectedContacterByRight)
          break
        }
      }
      this.switchMenuMask()
    },

    deleteAllChatMsgsOfSelectedFriend(friendship){
      
    },

    //与获取文件聊天记录相关的函数
    //获取我参与项目的已发布文件、我发起的文件和待我审批的文件
    getMyPubFiles(username, pubfiles) {
      var myPubFiles = []
      pubfiles.forEach((file) => {
        var checkers = []
        if (!(file.checker == '')) {
          checkers = file.checker.split(';')
        }
        if (!(file.approver == '')) {
          checkers.push(file.approver)
        }
        if (file.filestatus == '已发布' || checkers.includes(username) || file.publisher == username) {
          myPubFiles.push(file)
        }
      })
      return myPubFiles
    },

    async getProjectPartersFromTask(projectIdfp) {
      try {
        // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
        const selectedProjectAcls = await this.getProjectAclListByIdfp(projectIdfp);
       
        const promises = selectedProjectAcls.map(async (projectacl) => {
          const result = await this.getUserInfoByUsername(projectacl.username);
          const avatarUrl = await this.showPic(result.avatar,'showavatar');
          
          const nickname = result.nickname;
          projectacl.avatarUrl = avatarUrl;
          projectacl.nickname = nickname;
        });
        await Promise.all(promises);
        
        this.selectedProjectAcls = selectedProjectAcls;
        return selectedProjectAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },


    //根据文件idff获取相应的文件程序信息记录
    async getChatmsgsByReceiverAndAboutwhat(receiver, aboutwhat) {
      try {
        const token = localStorage.getItem('token');
        const data = { receiver: receiver, aboutwhat: aboutwhat };
        const response = await axios.get('https://api2.itaskid.com' + '/my/getchatmsgsbyreceiverandaboutwhat', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });

        if (Array.isArray(response.data)) {

          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    //根据文件idfsf获取相应的文件聊天记录
    async getChatmsgsByReceiverAndAboutwhat1(receiver, aboutwhat) {
      try {
        const token = localStorage.getItem('token');
        const data = { receiver: receiver, aboutwhat: aboutwhat };
        const response = await axios.get('https://api2.itaskid.com' + '/my/getchatmsgsbyreceiverandaboutwhat1', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });

        if (Array.isArray(response.data)) {

          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getMyFileChatMsgs(){

    },


    //以下是得到与我相关的任务，即我参与的项目有权限查看到的任务
        //以下是与任务聊天记录相关的函数

    //以下是与任务聊天记录相关的函数
    // async runAndMergeArrays() {
    //   var myAllTasks=[]
    //   const myTaskAcllist = await this.getTasksByTaskacl();
    //   var tasklistByProjectAcl=[]
    //   this.getMyProjectAclList(this.username)
    //     .then((result1)=>{
    //       const result=result1.filter(item=>item.permit_level!=='parter')   //这里有一点问题
    //       const n=result.length
    //       var i
    //       var promises = []
    //       for(i=0;i<n;i++){
    //         var promise=this.getTasksByIdfp(result[i].project_idfp)
    //         promises.push(promise)
    //       }
    //       return Promise.all(promises)
    //     })
    //     .then((results) => {
    //       results.forEach((resultx) => {
    //         tasklistByProjectAcl=tasklistByProjectAcl.concat(resultx)
            
            
    //       })

    //       const mergedArray = this.mergeArrays(myTaskAcllist, tasklistByProjectAcl);
          
          
    //       mergedArray.sort((a, b) => a.orderid - b.orderid)
          
    //       myAllTasks=mergedArray
    //       this.myAllVisibleTasks=myAllTasks
    //       var taskMsgPromises=[]
    //       for(var i=0;i<mergedArray.length;i++){
            
    //         var promise=this.getTaskMsgsByReceiver(mergedArray[i].idft)
    //         taskMsgPromises.push(promise)
    //       }
    //       return Promise.all(taskMsgPromises)
    //     })
    //     .then((taskMsgs)=>{
    
    //       var myTaskMsgs=[]
    //       for(var i=0;i<taskMsgs.length;i++){
    //         if(taskMsgs[i].length>0){
    //           taskMsgs[i].map((msg)=>{
    //             msg.task_name=myAllTasks[i].name
    //             msg.task_done=myAllTasks[i].done
    //             msg.project=myAllTasks[i].project
                
    //           })
    //           myTaskMsgs=myTaskMsgs.concat(taskMsgs[i])
    //         }
    //       }
    //       this.myAllTaskMsgs=myTaskMsgs

    //       this.filterLastTaskMsgs=this.computedfilterLastTaskMsgs(myTaskMsgs)
          
          
    //     })
    // },

    async getMyAllTasks() {  
      var myAllTasks = [];  
      const myTaskAcllist = await this.getTasksByTaskacl();  
      var tasklistByProjectAcl = [];  
  
      try {  
        const result1 = await this.getMyProjectAclList(this.username); // 使用 await 替代嵌套的 then  
        const result = result1.filter(item => item.permit_level !== 'parter');  
        const n = result.length;  
    
        // 创建 promises 数组  
        var promises = [];  
        for (var i = 0; i < n; i++) {  
          var promise = this.getTasksByIdfp(result[i].project_idfp);  
          promises.push(promise);  
        }  
  
        // 等待所有 promises 完成  
        const results = await Promise.all(promises);  
        results.forEach((resultx) => {  
          tasklistByProjectAcl = tasklistByProjectAcl.concat(resultx);  
        });  
    
        const mergedArray = this.mergeArrays(myTaskAcllist, tasklistByProjectAcl);  
        mergedArray.sort((a, b) => a.orderid - b.orderid);  
        myAllTasks = mergedArray;  
        this.myAllVisibleTasks = myAllTasks;  
    
        // 这里删除了获取任务消息的部分  
        // this.myAllTaskMsgs = myTaskMsgs; // 也删除了这行代码  
      } catch (error) {  
        console.error('Error in runAndMergeArrays:', error); // 捕获并打印错误  
      }  
    },

    async runAndMergeArrays() {  
      var myAllTasks = [];  
      const myTaskAcllist = await this.getTasksByTaskacl();  
      var tasklistByProjectAcl = [];  
    
      try {  
        const result1 = await this.getMyProjectAclList(this.username); // 使用 await 替代嵌套的 then  
        const result = result1.filter(item => item.permit_level !== 'parter');  
        const n = result.length;  
    
        // 创建 promises 数组  
        var promises = [];  
        for (var i = 0; i < n; i++) {  
          var promise = this.getTasksByIdfp(result[i].project_idfp);  
          promises.push(promise);  
        }  
    
        // 等待所有 promises 完成  
        const results = await Promise.all(promises);  
        results.forEach((resultx) => {  
          tasklistByProjectAcl = tasklistByProjectAcl.concat(resultx);  
        });  
    
        const mergedArray = this.mergeArrays(myTaskAcllist, tasklistByProjectAcl);  
        mergedArray.sort((a, b) => a.orderid - b.orderid);  
        myAllTasks = mergedArray;  
        this.myAllVisibleTasks = myAllTasks;  
    
        // 获取任务消息  
        var taskMsgPromises = [];  
        for (var i = 0; i < mergedArray.length; i++) {  
          var promise = this.getTaskMsgsByReceiver(mergedArray[i].idft); // 确保传递正确的上下文  
          taskMsgPromises.push(promise);  
        }  
    
        // 等待所有任务消息的 promises 完成  
        const taskMsgs = await Promise.all(taskMsgPromises);  
        var myTaskMsgs = [];  
    
        for (var i = 0; i < taskMsgs.length; i++) {  
          // 检查 taskMsgs[i] 是否是有效的数组  
          if (Array.isArray(taskMsgs[i]) && taskMsgs[i].length > 0) {  
            const processedMsgs = await Promise.all(taskMsgs[i].map(async (msg) => {  
              msg.task_name = myAllTasks[i]?.name; // 使用可选链操作符避免 undefined  
              msg.task_done = myAllTasks[i]?.done; // 使用可选链操作符避免 undefined  
              msg.project = myAllTasks[i]?.project; // 使用可选链操作符避免 undefined  
              return msg;  
            }));  
            myTaskMsgs = myTaskMsgs.concat(processedMsgs);  
          }  
        }  
    
        this.myAllTaskMsgs = myTaskMsgs;  
      } catch (error) {  
        console.error('Error in runAndMergeArrays:', error); // 捕获并打印错误  
      }  
    },

    // async runAndMergeArrays() {
    //   var myAllTasks = []
    //   const myTaskAcllist = await this.getTasksByTaskacl();
    //   var tasklistByProjectAcl = []
    //   this.getMyProjectAclList(this.username)
    //     .then(async (result1) => {  // 嵌套的异步操作
    //       const result = result1.filter(item => item.permit_level !== 'parter')
    //       const n = result.length
    //       var promises = []
    //       for (var i = 0; i < n; i++) {
    //           var promise = this.getTasksByIdfp(result[i].project_idfp)
    //           promises.push(promise)
    //       }
    //       return Promise.all(promises)
    //     })
    //     .then(async (results) => {  // 再次嵌套的异步操作
    //       results.forEach((resultx) => {
    //           tasklistByProjectAcl = tasklistByProjectAcl.concat(resultx)
    //       })
    //       const mergedArray = this.mergeArrays(myTaskAcllist, tasklistByProjectAcl);
    //       mergedArray.sort((a, b) => a.orderid - b.orderid)
    //       myAllTasks = mergedArray
    //       this.myAllVisibleTasks = myAllTasks
    //       var taskMsgPromises = []
    //       for (var i = 0; i < mergedArray.length; i++) {
    //         var promise = this.getTaskMsgsByReceiver(mergedArray[i].idft)
    //         taskMsgPromises.push(promise)
    //       }
    //       return Promise.all(taskMsgPromises)
    //     })
    //     .then(async (taskMsgs) => {  // 处理每个msg的异步操作
    //       var myTaskMsgs = []
    //       for (var i = 0; i < taskMsgs.length; i++) {
    //         if (taskMsgs[i] && Array.isArray(taskMsgs[i]) && taskMsgs[i].length > 0) {
    //           const processedMsgs = await Promise.all(taskMsgs[i].map(async (msg) => {
    //               msg.task_name = myAllTasks[i].name;
    //               msg.task_done = myAllTasks[i].done;
    //               msg.project = myAllTasks[i].project;
    //               return msg;
    //           }));
    //           myTaskMsgs = myTaskMsgs.concat(processedMsgs);
    //         }
    //       }
    //       this.myAllTaskMsgs = myTaskMsgs;
    //       console.log('this.myAllTaskMsgs',this.myAllTaskMsgs)
    //       this.filterLastTaskMsgs = this.computedfilterLastTaskMsgs(myTaskMsgs);
    //     });
    // },

        // 假设这是一个方法定义在一个对象或类中
    async getMyAllFileMsgs() { // 确保这个方法是异步的
      const username = this.username;
      const usernogroup = `${username}nogroup`;
      let projectAcllist = [];
    
      projectAcllist = await this.getMyProjectAclList(username);
    
      if (projectAcllist&&projectAcllist.length > 0) {
        await Promise.all(projectAcllist.map(async (projectacl) => {
          const project = await this.getMyProjectListByIdfp(projectacl.project_idfp);
          Object.assign(projectacl, project[0]); // 简化属性复制
        }));
      }
    
      this.myProjectlist = projectAcllist.filter(item => item.idfp !== usernogroup);
      let pubfiles = [];
    
      await Promise.all(this.myProjectlist.map(async (project) => {
        const resultFiles = await this.getPubfilesOfNoFolderByProject(project.idfp);
        if (resultFiles?.length > 0) {
          pubfiles = [...pubfiles, ...resultFiles]; // 使用扩展运算符简化数组合并
        }
      }));
    
      this.allMyPubFiles = this.getMyPubFiles(username, pubfiles);
      let msg = [];
    
      await Promise.all(this.allMyPubFiles.map(async (file) => {
        const msgls = await this.getChatmsgsByReceiverAndAboutwhat(file.idff, 'filechat');
    
        await Promise.all(msgls.map(async (msg) => {
          msg.receiverName = file.filename;
          msg.receiverUrl=file.url
          msg.receiverIdff=file.idff
          if (msg.type === 'img') {
            const imgUrl = await this.showPic(msg.file_name, 'showmsgimg');
            msg.imgUrl = imgUrl;
          }
        }));
    
        if (msgls !== undefined && msgls.length > 0) {
          this.myAllFileMsgs = [...this.myAllFileMsgs, ...msgls]; // 使用扩展运算符简化数组合并
          this.filterLastFileMsgs = this.filteredLastFileMsgs(this.myAllFileMsgs);
          // console.log('this.myAllPubFileMsgs',this.myAllPubFileMsgs)
          this.selectedFileMsg = this.filterLastFileMsgs[0];
        }
      }));
    },

    // 假设这是一个方法定义在一个对象或类中
    async getAllMyShareAndPubFileMsgs() { // 确保这个方法是异步的
      const username = this.username;
      const usernogroup = `${username}nogroup`;
      let projectAcllist = [];
    
      projectAcllist = await this.getMyProjectAclList(username);
    
      if (projectAcllist&&projectAcllist.length > 0) {
        await Promise.all(projectAcllist.map(async (projectacl) => {
          const project = await this.getMyProjectListByIdfp(projectacl.project_idfp);
          if(project&&project.length>0){
            Object.assign(projectacl, project[0]); // 简化属性复制
          }
        }));
      }
    
      this.myProjectlist = projectAcllist.filter(item => item.idfp !== usernogroup);
      let pubfiles = [];
      let sharefiles = [];
    
      await Promise.all(this.myProjectlist.map(async (project) => {
        const resultPubFiles = await this.getPubfilesOfNoFolderByProject(project.idfp);
        if (resultPubFiles && resultPubFiles.length > 0) {
          pubfiles = [...pubfiles, ...resultPubFiles]; // 使用扩展运算符简化数组合并
        }
        const resultShareFiles = await this.getSharefilesOfNoFolderByProject(project.idfp);
        if (resultShareFiles && resultShareFiles.length > 0) {
          sharefiles = [...sharefiles, ...resultShareFiles]; // 使用扩展运算符简化数组合并
        }
      }));


    
      this.allMyPubFiles = this.getMyPubFiles(username, pubfiles);
      this.allMyShareFiles = sharefiles;
      
      let msg = [];
    
      // Process pubfiles
      await Promise.all(this.allMyPubFiles.map(async (file) => {
        const msgls = await this.getChatmsgsByReceiverAndAboutwhat(file.idff, 'filechat');

        if(msgls&&Array.isArray(msgls)&&msgls.length > 0){

          await Promise.all(msgls.map(async (msg) => {
            msg.receiverName = file.filename;
            msg.receiverUrl = file.url;
            msg.receiverIdff = file.idff;
            msg.fileidff=file.idff;
            if (msg.type === 'img') {
              const imgUrl = await this.showPic(msg.file_name, 'showmsgimg');
              msg.imgUrl = imgUrl;
            }
          }));

        }
    

    
        if (msgls&&Array.isArray(msgls)&&msgls.length > 0) {
          this.myAllFileMsgs = [...this.myAllFileMsgs, ...msgls]; // 使用扩展运算符简化数组合并
    
          this.filterLastFileMsgs = this.filteredLastFileMsgs(this.myAllFileMsgs);
          this.selectedFileMsg = this.filterLastFileMsgs[0];
        }
      }));
    
      // Process sharefiles
      await Promise.all(this.allMyShareFiles.map(async (file) => {
        const msgls = await this.getChatmsgsByReceiverAndAboutwhat1(file.idfsf, 'filechat');
    
        if(msgls&&Array.isArray(msgls)&&msgls.length > 0){
          await Promise.all(msgls.map(async (msg) => {
            msg.receiverName = file.filename;
            msg.receiverUrl = file.url;
            msg.receiverIdfsf = file.idfsf;
            msg.fileidfsf=file.idfsf;
            if (msg.type === 'img') {
              const imgUrl = await this.showPic(msg.file_name, 'showmsgimg');
              msg.imgUrl = imgUrl;
            }
          }));
        }

    
        if (msgls&&Array.isArray(msgls)&&msgls.length > 0) {
          this.myAllFileMsgs = [...this.myAllFileMsgs, ...msgls]; // 使用扩展运算符简化数组合并
    
          this.filterLastFileMsgs = this.filteredLastFileMsgs(this.myAllFileMsgs);
          this.selectedFileMsg = this.filterLastFileMsgs[0];
        }
      }));
    },



    mergeArrays(A, B) {
      if (!A) {
        A = [];
      }
      if (!B) {
        B = [];
      }
  
      const mapA = new Map();
      A.forEach(item => {
        mapA.set(item.idft, item);
      });
  
      const result = A.slice();
  
      B.forEach(item => {
        if (!mapA.has(item.idft)) {
          result.push(item);
        }
      });
  
      return result;
    },

    async getTaskinfoOnlyByIdft(idft) {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get('https://api2.itaskid.com' + '/my/gettaskinfoonlybyidft', {
          headers: {
            Authorization: token
          },
          params: { idft: idft }
        });

        

        // console.log('response',response)
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
        }
      } catch (error) {
        console.log(error);
      }
    },

    //以下是通过任务权限获取任务信息
    async getTasksByTaskacl() {
      try {
        const username = localStorage.getItem('username');
        const nogroup = `${username}nogroup`;
        let myTaskAcllist = [];
        const result = await this.getMyTaskAclList(username);
        
        if (result?.length > 0) {
          myTaskAcllist = result;
          const promises = myTaskAcllist.map(async (taskacl) => {
            const task = await this.getTaskinfoOnlyByIdft(taskacl.task_idft);
            if (task && task.length > 0){
              taskacl.name = task[0].name;
              taskacl.duration = task[0].duration;
              taskacl.endtime = task[0].endtime;
              taskacl.pretask = task[0].pretask;
              taskacl.level = task[0].level;
              taskacl.parent = task[0].parent;
              taskacl.project = (taskacl.project_idfp==''||taskacl.project_idfp==null||taskacl.project_idfp==undefined)?task[0].project:taskacl.project_idfp;
              taskacl.haschild = task[0].haschild;
              taskacl.done = task[0].done;
              taskacl.expanded = task[0].expanded;
              taskacl.id = task[0].id;
              taskacl.delete = task[0].delete;
              taskacl.starttime = task[0].starttime;
              taskacl.idft = task[0].idft;
              taskacl.record = task[0].record
              taskacl.orderid = task[0].orderid
              taskacl.position=task[0].position
              taskacl.unitotsk=task[0].unitotsk
              taskacl.qtyotsk=task[0].qtyotsk
              taskacl.qtyotsked=task[0].qtyotsked
              taskacl.starttimeatl=task[0].starttimeatl
              taskacl.endtimeatl=task[0].endtimeatl
              return taskacl
            }
          });
        }
        return myTaskAcllist
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },


    //与任务聊天记录相关的函数


  },

  computed: {

    // computedAllMyChatContacters(){
    //   return()=>{
    //     const allMyChatContacters =this.allMyChatContacters?.sort((a, b) => {  
    //     // 假设 last_interaction 是一个时间戳或可被转换为时间的字符串  
    //       return new Date(b.last_interaction) - new Date(a.last_interaction);  
    //     });
    //     console.log('allMyChatContacters',allMyChatContacters)
    //     return allMyChatContacters
    //   }
    // },

    computedLastMsg() {  
      return (contacter) => {  
        const chatMsgs = contacter.chatmsgs;  
  
        // 如果没有聊天记录，返回 null 或者其他默认值  
        if (!chatMsgs || chatMsgs.length === 0) {  
          return ''; // 或者可以返回一个空对象 {}  
        }  
  
        // 使用 reduce 找到 timestamp 最新的消息  
        const lastMsg = chatMsgs.reduce((latest, current) => {  
          return (new Date(current.timestamp) > new Date(latest.timestamp)) ? current : latest;  
        });  
  
        return lastMsg.message; // 返回最新的消息  
      };  
    },
    assistentMsgOption(){
      return(msg)=>{
        if(msg.message=='主人，有文件需要您的审批。'){
          return 1
        } else if(msg.message=='主人，有任务需要您的审批。'){
          return 2
        } else if(msg.message=='主人，有表单需要您的审批。'){
          return 3
        } else {
          return 'else'
        }
      }
    },
    showTimestamp(){
      return(index)=> {
        if(index==0) {
          return true
        } else {
          return false
        }
      }
    },


    friendNickname(){
      return(friendMsg)=>{
        const nickname=friendMsg.sender==this.username?friendMsg.receiver_nickname:friendMsg.sender_nickname
        return nickname
      }
    },

    showAltNickname(){
      return(friendMsg)=>{
        const nickname=friendMsg.sender==this.username?friendMsg.receiver_nickname:friendMsg.sender_nickname
        // const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
        // const altNikname = matchResult ? matchResult[0] : ''
        // return nickname
        if (typeof nickname !== undefined&&nickname) {
          const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
          const altNickname = matchResult ? matchResult[0] : '';
          return altNickname;
        } else {
          return '';
        }
        
        
      }
    },

    showContacterAltNickname(){
      return(nickname)=>{
        if (typeof nickname !== undefined&&nickname) {
          const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
          const altNickname = matchResult ? matchResult[0] : '';
          return altNickname;
        } else {
          return '';
        }
      }
    },

    showAltNickname1(){
      return(nickname)=>{
        if (typeof nickname !== undefined&&nickname) {
          const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
          const altNickname = matchResult ? matchResult[0] : '';
          return altNickname;
        } else {
          return '';
        }
      }
    },

    // showAltNickname1(){
    //   return(nickname)=>{
    //     if (typeof nickname !== 'undefined') {
    //       const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
    //       const altNickname = matchResult ? matchResult[0] : '';
    //       return altNickname;
    //     } else {
    //       return '';
    //     }
    //   }
    // },

    friendAvatarUrl(){
      return(friendMsg)=>{
        const avatarUrl=friendMsg.sender==this.username?friendMsg.receiver_avatarUrl:friendMsg.sender_avatarUrl
        return avatarUrl
      }
    },
    senderNickname(){
      return(msg)=>{
        const senderNickname=(this.username==msg.sender)?'我':msg.sender_nickname
        return senderNickname
      }
    },

    lastfilemsgsender(){
      return (fileMsg)=>{
        const username=localStorage.getItem('username')
        const sendernickname=(fileMsg.sender==username)?'我':fileMsg.nickname
        return sendernickname
      }
    },

    lasttaskmsgsender(){
      return (taskMsg)=>{
        const username=localStorage.getItem('username')
        const sendernickname=(taskMsg.sender==username)?'我':taskMsg.sendernickname
        return sendernickname
      }
    },

    // filterLastFileMsgs(){
    //   return()=>{
    //     const result = Object.values(this.myAllFileMsgs.reduce((acc, obj) => {
    //       if (!acc[obj.file_name] || obj.timestamp > acc[obj.file_name].timestamp) {
    //         acc[obj.file_name] = obj;
    //       }
    //       return acc;
    //     }, {}));
    //     const sortedResult = result.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    //     return sortedResult
    //   }
    // },


    

    filteredChats() {
      // 根据搜索框的内容过滤聊天列表
      return this.chats.filter(chat => {
        return chat.name.toLowerCase().includes(this.searchText.toLowerCase());
      });
    },




   
    
  },

  created() {
    // const data = this.$route.query.data;
    // if(data!==undefined){
    //   this.allMyChatContacters.push(data)
    //   console.log('this.allMyChatContacters',this.allMyChatContacters)
    //   this.selectFriendContacter(data)
    // }
  },


  mounted(){
    // this.runAndMergeArrays()
    //   .then((result)=>{
        
    //   })

    


    const token = localStorage.getItem('token')
    this.username=localStorage.getItem('username')
    const key=`user|${this.username}`
    const userinfo=localStorage.getItem(key)
    this.userNickname=userinfo.nickname

    this.getMyAllChatcontacters()

    this.getMyAllTasks()

    
    
    // this.getAllMyChatContactsAndUpdateLastMsg(this.username)  
    //   .then(async (res) => { // 将 res 声明为 async  
    //     this.allMyChatContacters = res;  
    //     const allMyFriendChatContacters = res.filter(item => item.contact_type === 'manchat');  
      
    //     if (allMyFriendChatContacters.length > 0) {  
    //       // 创建 promises 数组  
    //       const promises = allMyFriendChatContacters.map(async (contacter) => {  
    //         // 处理头像并推送到 myFriendsAvatars  
    //         contacter.contact_avatar = await this.showPic(contacter.contact_avatar, 'showavatar');  
    //         this.myFriendsAvatars.push({ username: contacter.contact_username, avatar: contacter.contact_avatar });  
    //       });  
    
    //       // 等待所有 promises 完成  
    //       await Promise.all(promises);  
    
    //       // 所有遍历操作完成后，输出 allMyFriendChatContacters 的值  
    //       this.allMyFriendChatContacters=allMyFriendChatContacters
    //     }  
    
    //     // 处理其他类型的联系人  
    //     this.allMyTaskChatContacters = res.filter(item => item.contact_type === 'taskchat');  
    //     this.allMyFileChatContacters = res.filter(item => item.contact_type === 'filechat');  
    //   })  
    //   .catch(err => {  
    //     console.error('Error fetching chat contacts:', err);  
    //   });

    // this.getAllMyChatContactsAndUpdateLastMsg(this.username)
    //   .then((res)=>{
    //     this.allMyChatContacters=res
    //     const allMyFriendChatContacters=res.filter(item=>item.contact_type=='manchat')
        
    //     if(allMyFriendChatContacters.length>0){
    //       const promises=allMyFriendChatContacters.map(async (contacter)=>{

    //         contacter.contact_avatar=await this.showPic(contacter.contact_avatar,'showavatar')
    //         this.myFriendsAvatars.push({username:contacter.contact_username,avatar:friendAvatar})
            
    //       })
    //       return Promise.all(promises)
    //     }


    //     // console.log('this.allMyFriendChatContacters',this.allMyFriendChatContacters)
    //     this.allMyTaskChatContacters=res.filter(item=>item.contact_type=='taskchat')
    //     this.allMyFileChatContacters=res.filter(item=>item.contact_type=='filechat')
    //   })
    
    
    this.userinfo=JSON.parse(userinfo)
   
    this.showPic(this.userinfo.avatar,'showavatar')
      .then((result)=>{
        this.avatarUrl_user=result
        console.log('this.avatarUrl_userx',this.avatarUrl_user)
      })
    
    // const keyOfLocalFriend=`user|${this.username}`
    // const localFriendship=JSON.parse(localStorage.getItem(keyOfLocalFriend))
    // this.chatto=localFriendship.friend_commentname
    
    
    // const userId=localStorage.getItem('userId')
    // const selectedFriendInfo=localStorage.getItem('selectedFriendInfo')
    // this.selectedFriendInfo=JSON.parse(selectedFriendInfo)

    // this.avatarUrl_friend=this.selectedFriendInfo?this.selectedFriendInfo.avatarUrl:''
    // this.friendUsername=this.selectedFriendInfo?this.selectedFriendInfo.friend_username:''

    // const assistant=`assistant|${this.username}`
    this.getMyAssitantMsgs(this.username)
      .then((result)=>{
        if(result.length>0){
          this.assistantMsgs=result
          // const lastElement = arr.pop();
          this.lastAssistantMsg=result[result.length-1]
        }

      })


    // let allMyMsgs=[]
    // let allMyMsgsWithFriends = {};
    // this.getAllMyFriendChatMsgs(this.username)  
    //   .then((result)=>{
    //     this.myAllFriendMsgs=result
    //   })
    
    // let friendships=[]
    // this.getFriendshipsByUserId(userId,token)
    //   .then((result)=>{
    //     friendships=result
    //     if(result){
    //       const promises=friendships.map(async (friendship)=>{
    //         let url='showavatar'
    //         const result=await this.showPic(friendship.friend_avatar, url)
    //         friendship.avatarUrl=result
    //       })
    //       return Promise.all(promises)
    //     } else {
    //       return []
    //     }
    //   })
    //   .then(()=>{
    //     this.friendships=friendships
    //     if(friendships){
    //       this.filterFriendships=friendships.filter(item=>item.ifshow===1&&item.status=='accept')
    //       //初始化选择第一个对象
    //       this.selectFriendship(this.filterFriendships[0])
    //     } else {
    //       this.filterFriendships=[]
    //     }

        
    //   })    
    
      // this.socket = new WebSocket("wss://api2.itaskid.com"); 

      // // 监听连接成功事件  
      // this.socket.onopen = () => {  
      //   console.log('Connected to WebSocket server');  
      // };  
      
      // // 监听服务器发送的消息  
      // this.socket.onmessage = (event) => {  
      //   const message = JSON.parse(event.data); // 解析收到的消息  
      
      //   if (this.optionView == 1) {  
      //     if (message.aboutwhat == 'manchat') {  
      //       if (message.receiver == this.username && message.sender == this.friendUsername) {  
      //         let lastmsg = '';  
      //         if (message.type == 'text') {  
      //           lastmsg = message.message;  
      //         } else if (message.type == 'img') {  
      //           lastmsg = '[图片]';  
      //         } else {  
      //           lastmsg = '[文件]';  
      //         }  
      //         this.myAllFriendMsgs.push(message);  
      //         this.filterMessages.push(message);  
      //         const newMsgs = this.filterLastFriendMsgs.filter(item => !(item.sender == message.receiver || item.receiver == message.receiver));  
      //         newMsgs.unshift(message);  
      //         this.filterLastFriendMsgs = newMsgs;  
      //         this.selectedFriendMsgIndex = 0;  
      //       } else if (message.receiver == this.friendUsername && message.sender == this.username) {  
      //         this.myAllFriendMsgs.push(message);  
      //         const newMsgs = this.filterLastFriendMsgs.filter(item => !(item.sender == message.receiver || item.receiver == message.receiver));  
      //         newMsgs.unshift(message);  
      //         this.filterLastFriendMsgs = newMsgs;  
      //         this.filterMessages.push(message);  
      //         this.selectedFriendMsgIndex = 0;  
      //       } else {  
      //         this.myAllFriendMsgs.push(message);  
      //         const newMsgs = this.filterLastFriendMsgs.filter(item => !(item.sender == message.receiver || item.receiver == message.receiver));  
      //         newMsgs.unshift(message);  
      //         this.filterLastFriendMsgs = newMsgs;  
      //         this.selectedFriendMsgIndex = 0;  
      //       }  
      //     }   
      //   } else if (this.optionView == 3) {  
      //     this.myAllTaskMsgs.push(message);  
      //     this.selectedTaskMsgs.push(message);  
      //     this.filterLastTaskMsgs = this.filterLastTaskMsgs.filter(item => item.receiver !== message.receiver);  
      //     this.filterLastTaskMsgs.unshift(message);  
      //     this.selectedTaskMsgIndex = 0;  
      //   } else if (this.optionView == 2) {  
      //     if (message.aboutwhat == 'filechat' && message.receiver == this.selectedFileMsg.receiver) {  
      //       this.myAllFileMsgs.push(message);  
      //       this.filterLastFileMsgs = this.filterLastFileMsgs.filter(item => item.receiver !== message.receiver);  
      //       this.filterLastFileMsgs.unshift(message);  
      //       this.filterMessages.push(message);  
      //       this.selectedFileMsgIndex = 0;  
      //     }  
      //   }  
      // };  
      
      // // 监听连接关闭事件  
      // this.socket.onclose = () => {  
      //   console.log('WebSocket disconnected');  
      // };  
      
      // // 监听错误事件  
      // this.socket.onerror = (error) => {  
      //   console.error('WebSocket error:', error);  
      // };


      this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
    });


    this.handleSocketMessge()

    
    
    // 监听服务器发送的消息
    
    // this.socket.on('message', (message) => {
    //   const contactUsernames = this.allMyChatContacters.map(contact => contact.contact_username);
    //   if (contactUsernames.includes(message.receiver)||contactUsernames.includes(message.sender)) {
    //     console.log('Received message:', message);

    //     if(message.type=='img'){
    //       message.imgUrl=await this.showPic(message.file_name,'showmsgimg')
    //     }
        
    //     this.allMyChatContacters.find(contacter => {
    //       if (contacter.contact_username === message.receiver||(contacter.contact_username === message.sender&&message.sender!==this.username)) {
    //         contacter.chatmsgs.push(message);
    //         this.selectedChatContacter=contacter
    //         this.filterMessages=contacter.chatmsgs
    //         this.selectFriendContacter(contacter)
    //       }
    //     })
    //   }else if(message.receiver==this.username){
    //     const newFriendContacter={
    //       idfcc:uuidv4(),
    //       myusername:this.username,
    //       contact_username:message.sender,
    //       last_interaction:this.datetimeConvert(new Date()),
    //       in_pin:0,
    //       is_muted:0,
    //       contact_type:'manchat',
    //       contact_nickname:message.sender_nickname,
    //       contact_avatar:message.sender_avatar,
    //       contact_remarkname:'',
    //       contact_lastmsg:'',
    //       contact_lastmsgtime:this.datetimeConvert(new Date()),
    //       contact_ifshow:1
    //     }
    //     this.allMyFriendChatContacters.push(newFriendContacter)
    //     this.allMyChatContacters.push(newFriendContacter)
    //     this.addChatContacter(newFriendContacter)
    //       .then((result)=>{
    //         this.selectSearchContacter(newFriendContacter);
    //       })
    //   }


    // });
    

    // this.getMyAllFileMsgs().catch(error => {
    //   console.error('An error occurred:', error);
    // });

    // this.getAllMyShareAndPubFileMsgs()


  },

  beforeRouteEnter(to, from, next) {  
    // 这里可以判断是否是从其他路由导航过来  
    next(vm => {  
      // vm.isNavigated = true; // 设置为 true，表示是通过导航进入 
      console.log('1')   
    });  
  },  

  beforeRouteUpdate(to, from, next) {  
    // 组件更新时的逻辑  
    // this.isNavigated = true; // 更新状态 
    next();  
  },  

  beforeDestroy() {
    // 断开socket.io连接
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.from === '/home/friendshipgroup') {
        console.log('路由来自于/home/friendshipgroup');
      
      }
    },

    // '$route.query.data': function(newData) {  
    //     if (newData !== undefined) {  
    //         this.allMyChatContacters.push(newData);  
    //         console.log('this.allMyChatContacters', this.allMyChatContacters);  
    //         this.selectFriendContacter(newData);  
    //     }  
    // },  

    searchText:function(){
      if(this.searchText==''){
        this.searchFiles=[]
        this.searchTasks=[]
        this.searchUsers=[]
      }
    }

},
}
</script>

<style lang="less" scoped>


li {
    list-style: none;
  }

  li:hover {
    background-color: rgb(200, 200, 200);
    cursor: pointer;
  }


.el-main {
  line-height: 20px !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 50px;
  background: none;
}

.info {
  display: inline;
  flex-direction: column;
}

.avatar {
  margin-right: 10px;
}

.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.message-container {   //ok
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:rgb(153, 153, 153);
  }

  .message {    //ok
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    // flex: 1; /* 占据剩余空间 */
    max-width: 240px;
    
  }


  .msg-time {
    display: flex;
    justify-content: center;
    background-color: rgb(218, 218, 218);
    width: fit-content;
    margin: 0 auto;
  }

  .search-input {
    height: 25px; 
    width: 285px;
    border:none;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
    padding-left:24px;
    // left:0px;
    // top:0px; 
    // height:25px;
  }

  .search-input::placeholder {  
    font-size: 12px; /* 设置 placeholder 字体大小 */  
    color: #999; /* 设置 placeholder 字体颜色 */  
  }

  .search-input:focus {
    outline: 0.5px solid rgba(64, 158, 255,0.7) !important;

  }

  .search-button {
    border:none;
    background-color: rgb(255, 255, 255);
    float: left; 
    height: 30px;
  }

  .selected {
    color:rgb(145, 170, 252);
    border-bottom: 1px solid rgb(145, 170, 252);;
  }

  .search-button:hover {
    background-color: rgb(226, 226, 226);
  }

  td.filemsgname {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
    max-width: 240px;
  }

  .selectedFileMsg {
    // color:rgb(71, 114, 250);
    background: rgb(228, 228, 230);
  }

  .searchItemsLabel {
    font-size: 12px;
    color:gray;
    margin:3px 0 3px 3px;
    text-align: left;
  }

  .msg-altavatar {
    width: 35px;
    height: 35px;
    border-radius: 10%;
    background: rgb(144, 82, 245);
    color:rgb(255, 255, 251);
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }
</style>