<template>
  <div class="login-container" style="position:relative;">
    <div class="avatar">
      <img src="../../assets/greenlogo.png" alt="avatar" style="width:100px;height:110px;">
      <!-- <img src="../../assets/yellowlogo.png" alt="avatar" style="width:100px;height:110px;"> -->
    </div>
    <div class="form">
      <div class="form-group">
        <label for="username">用户名：</label>
        <input type="text" id="username" v-model.trim="username" placeholder="请输入登录名称" autocomplete="off">
      </div>
      <div class="form-group">
        <label for="password">密&nbsp;&nbsp;&nbsp;&nbsp;码：</label>
        <input type="password" id="password" v-model="password" placeholder="请输入登录密码">
      </div>
      <div class="form-group">
        <button @click="login" style="width: 135px;">登录</button>
        <button @click="reset" style="width: 135px;">重置</button>
        <!-- <button>注册</button> -->
      </div>
      <div style="text-align: right;font-size:12px;">
        <!-- <label class="registerrouter" style="margin-right:10px; font-weight:normal;" @click="showResetPsd()">忘记密码</label> -->
        <router-link to="/resetpsw" class="registerrouter" style="margin-right:10px;">忘记密码</router-link>
        <router-link to="/register" class="registerrouter">注册账号</router-link>
      </div>
    </div>
    <div v-if="ifShowResetPsd" class="resetpsdcontainer">
      haod
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {mapState, mapMutations,mapActions} from 'vuex'
export default {
  data() {
    return {
      username: '',
      password: '',
      user: {id:'1',username:'xixi'},
      ifShowResetPsd:false,
    }
  },
  methods: {
    ...mapMutations([
      'updateUser',
      
    ]),
    ...mapActions({
      getAllMyChatContacters:'getAllMyChatContacters',
      getAllMyChatContactersWithMsgs:'getAllMyChatContactersWithMsgs',

    }),

    showResetPsd(){
      this.ifShowResetPsd=true
    },

    reset() {
      this.username = ''
      this.password = ''
    },

    async openImageInNewWindow(fileName, token) {  

      try {  
        const response = await axios.get(`http://localhost:8081/my/viewimage`, {  
          params: { fileName },  
          responseType: 'blob', // 确保响应类型为 blob  
          headers: {  
            Authorization: token // 确保 token 格式正确  
          }  
        });  
    
       
    
        const blob = response.data;  
    
        // 创建一个本地 URL  
        const objectURL = URL.createObjectURL(blob);  
    
        
        // 打开新窗口  
        window.open(objectURL, '_blank');  
    
        // 可选地，你可以在一定时间后撤销该 URL 以释放内存  
        // setTimeout(() => URL.revokeObjectURL(objectURL), 60000); // 1 分钟后撤销  
    
      } catch (error) {  
        console.error('Error opening image:', error);  
      }  
    },


    login() {
      axios({
        method: 'POST',
        url: 'https://api2.itaskid.com' + '/api/login',//这个接口在哪？
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          username: this.username,
          password: this.password,
        }
      })
      .then((response) => {
        // if (results.length !== 1) return res.cc('用户名未注册！')

        if(response.data.status) {
          alert(response.data.message)
        }
      
        const {id,username,token,email,avatar,nickname,location,telephone}=response.data
        const key=`user|${this.username}`
        const userinfo = { id, username, email, avatar, nickname, location, telephone };
        // const userinfo=response.data
        localStorage.setItem(key,JSON.stringify(userinfo))
        const user={username:this.username,id:response.data.id}
        this.updateUser(user)
        localStorage.setItem('token', token)
        localStorage.setItem('username',this.username)
        localStorage.setItem('userId',response.data.id)
        if(!response.data.status) {

          // window.open(`https://api1.itaskid.com/upload/pubfiles/1723681280697-abc.png`, '_blank');
          // this.openImageInNewWindow('1723515179119-1.png',token)

          // this.getAllMyChatContacters(this.username)
          //   .then((res)=>{
          //     res.sort((a, b) => {  
				  //       return new Date(b.contact_lastmsgtime) - new Date(a.contact_lastmsgtime);  
				  //     });  
          //     const allMyTaskChatContacters=res.filter(item=>item.contact_type==='taskchat')
          //     const allMyFileChatContacters=res.filter(item=>item.contact_type==='filechat')
          //     const allMyFriendChatContacters=res.filter(item=>item.contact_type==='manchat')
				  //     this.$store.commit('setMyChatContacters', res) 
          //     this.$store.commit('setMyFriendChatContacters', allMyFriendChatContacters)
          //     this.$store.commit('setMyFileChatContacters', allMyFileChatContacters)
          //     this.$store.commit('setMyTaskChatContacters', allMyTaskChatContacters)
          //     console.log('login',res)

          // })

          this.getAllMyChatContactersWithMsgs(this.username)
            .then((res)=>{
              const allMyTaskChatContacters=res.filter(item=>item.contact_type==='taskchat')
              const allMyFileChatContacters=res.filter(item=>item.contact_type==='filechat')
              const allMyFriendChatContacters=res.filter(item=>item.contact_type==='manchat')
				      this.$store.commit('setMyChatContacters', res) 
              this.$store.commit('setMyFriendChatContacters', allMyFriendChatContacters)
              this.$store.commit('setMyFileChatContacters', allMyFileChatContacters)
              this.$store.commit('setMyTaskChatContacters', allMyTaskChatContacters)
              this.$store.commit('setIfNeedGetChatContacters', false)
              
            })



          this.$router.push('/home')
          alert('登录成功！')
        }
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
    },
  },
  watch: {
    user: function(newVal, oldVal){
      // console.log('user changed from', oldVal, 'to', newVal)
    }
  }
}
</script>

<style lang="less" scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  border: 1px solid gray;
}

.avatar {
  margin-right: 24px;
  margin-bottom:54px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  width: 200px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="password"]:focus {
  outline: 0.5px solid rgba(64,158,255,0.7);
}

input[type="text"]:focus {
  outline: 0.5px solid rgba(64,158,255,0.7);
}

button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button + button {
  margin-left: 10px;
}

.registerrouter:hover {
  color:rgba(64,158,255,0.7)
}

.resetpsdcontainer {
  position:absolute;
  left:calc(50% + 220px);
  top:30px;
  width:calc(50% - 250px);
  height:90%;
  z-index: 99;
  border:solid,gray,1px; box-shadow: 1px 1px 2px 1px rgba(128, 128, 128, 0.95);
}
</style>
