<template>
  <div>
    <router-view></router-view>
    <div class="menu-mask" v-if="showMenuMask" @click="hideContextMenu"></div>
  </div>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
import { eventBus } from './main.js'
export default {
  computed: {
      ...mapState({
        showMenuMask: state=>state.showMenuMask
      })
  },
  methods:{
    ...mapMutations({
      switchMenuMask: 'switchMenuMask'
    }),
    hideContextMenu(){
      eventBus.$emit('closeContexMenu')
      this.switchMenuMask()
    }
  },
  mounted(){

  },
}
</script>

<style>

.menu-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.1);
  z-index: 99;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

/* 设置垂直滚动条宽度 */
::-webkit-scrollbar {
  width: 6px;
}

/* 设置滚动条滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: rgba(200, 200, 200, 0.5); 
  border-radius: 5px;
}
</style>