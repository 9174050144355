<template>
  <div>
    <div class="project-treecontainer" >
      <!-- <LoadTempProject v-if="ifShowTempProject"></LoadTempProject>    -->
      <el-col style="width: 350px;  border-right: 1px solid rgba(200, 200, 200, 0.9);">
        <div style="height: 85px;">
          <div style="display:flex;">
            <h3 class="selected-proj-name" style="text-align: left;">{{ projectTitle }}</h3>
            <!-- <button style="margin:10px 10px 10px auto;height:30px;border:none;"><i class="el-icon-circle-plus-outline"></i></button> -->
          </div>
          <div style="text-align: left;margin-left:25px;" v-show="notCheckingTasks">
            <input type="text" class="input-add-proj" v-if="!ifShowSearchInput" style="width:240px;"
              @keydown.enter="addTaskFromInput"
              :placeholder="placeholderStr"
              v-model="newTaskNameLv1">
            <input type="text"  class="input-add-proj" v-if="ifShowSearchInput"  placeholder="搜索任务"
              v-model="searchTaskText" @change="getSearchTasks()" style="width:255px;">
            <button style="width:30px;padding:0;margin-left:5px;" @click="showAddTasksFromTemp()" class="search-button" v-if="!ifShowSearchInput">
              <i class="el-icon-circle-plus-outline" style="line-height: 30px;"></i>
            </button>
            <button style="width:30px;padding:0;" @click="showSearchInput()" class="search-button" v-if="!ifShowSearchInput">
              <i class="el-icon-search" style="line-height: 30px;"></i>
            </button>

            <button class="search-button" v-if="ifShowSearchInput" @click="cancelSearchTask()">取消</button>
          </div>
        </div>
        <!-- //style="height: calc(100vh - 180px);" -->
        <div class="alltasks-container" v-if="ifShowSearchInput">
          <div class="project-container-leftcol">
            <div style="text-align:left;">
              <button style="border: none; background: none;height: 42px;" @click=showUndoneTaskToggle()>
                <i class="el-icon-arrow-right" v-show="!showUndoneProject"></i>
                <i class="el-icon-arrow-down" v-show="showUndoneProject"></i>
              </button>
              <label for="" style="padding-left: 0;height: 42px;">未完成</label>
            </div>     
            <EditTaskMenu
              v-if="showTaskEdit" 
              ref="edittaskmenu" @moveTasksToProject="moveTasksToProject"
              :selectedTask="selectedTask" :usernogroup="usernogroup" :selectedTaskAcls="selectedTaskAcls" :projectGroups="projectGroups"
              @cancelEditTask="cancelEditTask" :TaskeditMenuitems="TaskeditMenuitems" @updateTaskAcl="updateTaskAcl"
              @closeEditTask="closeEditTask" :parentNode="parentNode" @deleteTask="deleteTask()"
              @taskeditMenuitemClick="taskeditMenuitemClicked">
            </EditTaskMenu>
            <ul v-for="task1 in searchTasks" :key="task1.idft" ref="task-ul" v-show="showUndoneProject"
              @click="goToTaskDetail($event,task1)" class="ul-task1" 
              @contextmenu.prevent="taskEditMenu($event,task1)" >  
              <div class="task-container" style="position: relative;" v-if="ifShowTask(task1)">
                <button v-show="hasNoDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-right"></i>
                </button>
                <button v-show="hasNoDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-down"></i>
                </button>
                <button  v-show="!hasNoDoneChild(task1)">
                </button>
                <!-- <i class="el-icon-view" v-if="!permitLevel(task1)" style="position:absolute;
                  font-size:13px; left: 5px;top:-5px;"></i> -->
                <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task1)" :disabled="!permitLevel(task1)">
                <input type="text" class="input-taskname" :class="{ taskinputclicked: isInputClicked(task1.idft) }"
                  @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)" v-model="task1.name"  
                  :readonly="!permitLevel(task1)">
                <label for="date-picker" class="task-starttime" v-show="showTaskTime(task1)" @click="updateTaskdate(task1)">
                  {{ showTime(task1.starttime) }}
                </label>
                <el-date-picker
                  v-model="newStarttimeFromDatePicker"
                  type="datetime"
                  size="small"
                  popper-class="dateselect"
                  class="my-date-picker"
                  id="date-picker" style="width:0px; height: 0px; overflow: hidden; background: transparent;">  
                </el-date-picker>
              </div>
            </ul>
            <ShareTaskMenu v-if="showShareTaskMenu"></ShareTaskMenu>
          </div>
          <div class="projectdone-container">
            <div>
              <button style="border: none; background: none;height: 42px;" @click=showDoneTaskToggle()>
                <i class="el-icon-arrow-right" v-show="!showDoneProject"></i>
                <i class="el-icon-arrow-down" v-show="showDoneProject"></i>
              </button>
              <label for="" style="padding-left: 0;height: 42px;">已完成</label>
            </div>
            <div class="project-container-leftcol" v-show="showDoneProject">
            <ul v-for="task1 in searchTasksDone" :key="task1.idft" ref="task-ul" 
              @click="goToTaskDetail($event,task1)" class="ul-task1" 
              @contextmenu.prevent="taskEditMenu($event,task1)">  
              <div class="task-container" v-if="ifShowTask(task1)">
                <button v-show="hasDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-right"></i>
                </button>
                <button v-show="hasDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-down"></i>
                </button>
                <button  v-show="!hasDoneChild(task1)">
                </button>
                <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task1)" v-model="task1.done" 
                  :disabled="!permitLevel(task1)">
                <input type="text" class="input-taskname" v-model="task1.name"  readonly
                  @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)">
                <label for="" class="task-starttime" v-show="showTaskTime(task1)" @click="updateTaskdate(task1)">
                  {{ showTime(task1.starttime) }}
                </label>
                <el-date-picker
                  v-model="newStarttimeFromDatePicker"
                  type="datetime"
                  size="small"
                  popper-class="dateselect"
                  class="my-date-picker"
                  id="date-picker" style="width: 0px; height: 0px; overflow: hidden; background: transparent;">  
                </el-date-picker>
              </div>
            </ul>
            </div>
          </div>
          
        </div>
        <div class="alltasks-container" v-if="!ifShowSearchInput">
          <div class="project-container-leftcol">     
            <EditTaskMenu
              v-if="showTaskEdit" 
              ref="edittaskmenu"  @moveTasksToProject="moveTasksToProject"
              :selectedTask="selectedTask" :usernogroup="usernogroup" :selectedTaskAcls="selectedTaskAcls"
              @cancelEditTask="cancelEditTask" :TaskeditMenuitems="TaskeditMenuitems" @updateTaskAcl="updateTaskAcl"
              @closeEditTask="closeEditTask" :parentNode="parentNode" @deleteTask="deleteTask()"
              @taskeditMenuitemClick="taskeditMenuitemClicked" :projectGroups="projectGroups">
            </EditTaskMenu>
            <SummitTaskMenu v-if="showSummitTask" ref="summitTaskMenu" :selectedTask="selectedTask" @closeSummitTaskMenu=closeSummitTaskMenu
              :selectedProjectAcls="selectedProjectAcls" @addLocalTaskSummitMsg="addLocalTaskSummitMsg" @addLocalTaskFileData="addLocalTaskFileData">

            </SummitTaskMenu>
            <ul v-for="task1 in tasklist1" :key="task1.idft" ref="task-ul" 
              @click="goToTaskDetail($event,task1)" class="ul-task1" 
              @contextmenu.prevent="taskEditMenu($event,task1)" >  
              <div class="task-container" style="position: relative;" v-if="ifShowTask(task1)">
                <button v-show="hasNoDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)" style="margin-right:3px;">
                  <i class="el-icon-arrow-right"></i>
                </button>
                <button v-show="hasNoDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-down"></i>
                </button>
                <button  v-show="!hasNoDoneChild(task1)">
                </button>
                <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task1)" :disabled="!permitLevel(task1)">
                <input type="text" class="input-taskname" :class="{ taskinputclicked: isInputClicked(task1.idft) }"
                  @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)" v-model="task1.name"  
                  :readonly="!permitLevel(task1)">
                <label for="date-picker" class="task-starttime" v-show="showTaskTime(task1)" @click="updateTaskdate(task1)">
                  {{ showTime(task1.starttime) }}
                </label>
                <el-date-picker
                  v-model="newStarttimeFromDatePicker"
                  type="datetime"
                  size="small"
                  popper-class="dateselect"
                  class="my-date-picker"
                  id="date-picker" style="width: 0px; height: 0px; overflow: hidden; background: transparent;">  
                </el-date-picker>
              </div>
              <li  v-for="task2 in taskListWithLevel(2,task1)" :key="task2.idft" ref="task-li" 
                v-show="task1.expanded" @click="goToTaskDetail($event,task2)"
                @contextmenu.prevent="taskEditMenu($event,task2)" style="padding-left: 10px;">
                <div  class="task-container" v-if="ifShowTask(task2)">
                  <button v-show="hasNoDoneChild(task2)&&!task2.expanded" @click="toggleNode($event,task2)">
                    <i class="el-icon-arrow-right"></i>
                  </button>
                  <button v-show="hasNoDoneChild(task2)&&task2.expanded" @click="toggleNode($event,task2)">
                    <i class="el-icon-arrow-down"></i>
                  </button>
                  <button  v-show="!hasNoDoneChild(task2)">
                  </button>
                  <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task2)" :disabled="!permitLevel(task2)">
                  <input type="text" class="input-taskname" v-model="task2.name"  :readonly="!permitLevel(task2)" @change="updateTaskOfSql(task2)"
                    @keyup.enter="addBroTask($event,task2)"  :class="{ taskinputclicked: isInputClicked(task2.idft) }"> 
                  <label for="" class="task-starttime" v-show="showTaskTime(task2)">
                    {{ showTime(task2.starttime) }}
                  </label>
                </div>
                <ul v-for="task3 in taskListWithLevel(3,task2)" :key="task3.idft"  
                  ref="task-ul" v-show="task2.expanded" @click="goToTaskDetail($event,task3)"
                  @contextmenu.prevent="taskEditMenu($event,task3)" style="padding: 0 0 0 10px;">
                  <div  class="task-container" v-if="ifShowTask(task3)">
                    <button v-show="hasNoDoneChild(task3)&&!task3.expanded"  @click="toggleNode($event,task3)">
                      <i class="el-icon-arrow-right"></i>
                    </button>
                    <button v-show="hasNoDoneChild(task3)&&task3.expanded"  @click="toggleNode($event,task3)">
                      <i class="el-icon-arrow-down"></i>
                    </button>
                    <button  v-show="!hasNoDoneChild(task3)">
                    </button>
                    <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task3)" :disabled="!permitLevel(task3)">
                    <input type="text" class="input-taskname" v-model="task3.name" :readonly="!permitLevel(task3)"
                    @keyup.enter="addBroTask($event,task3)" @change="updateTaskOfSql(task3)"
                    :class="{ taskinputclicked: isInputClicked(task3.idft) }">
                    <label for="" class="task-starttime" v-show="showTaskTime(task3)">
                      {{ showTime(task3.starttime) }}
                    </label>
                  </div>
                  <li v-for="task4 in taskListWithLevel(4,task3)" :key="task4.idft"  
                    ref="task-li" v-show="task3.expanded" @click="goToTaskDetail($event,task4)"
                    @contextmenu.prevent="taskEditMenu($event,task4)" style="padding: 0 0 0 10px">
                    <div  class="task-container" v-if="ifShowTask(task4)">
                      <button v-show="hasNoDoneChild(task4)&&!task4.expanded" @click="toggleNode($event,task4)">
                        <i class="el-icon-arrow-right"></i>
                      </button>
                      <button v-show="hasNoDoneChild(task4)&&task4.expanded" @click="toggleNode($event,task4)">
                        <i class="el-icon-arrow-down"></i>
                      </button>
                      <button  v-show="!hasNoDoneChild(task4)">
                      </button>
                      <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task4)" :disabled="!permitLevel(task4)">
                      <input type="text" class="input-taskname" v-model="task4.name" :readonly="!permitLevel(task4)" 
                      :class="{ taskinputclicked: isInputClicked(task4.idft) }"
                      @keyup.enter="addBroTask($event,task4)" @change="updateTaskOfSql(task4)">
                      <label for="" class="task-starttime" v-show="showTaskTime(task4)">
                        {{ showTime(task4.starttime) }}
                      </label>
                    </div>
                    <ul v-for="task5 in taskListWithLevel(5,task4)" :key="task5.idft"  
                      ref="task-ul" v-show="task4.expanded" @click="goToTaskDetail($event,task5)"
                      @contextmenu.prevent="taskEditMenu($event,task5)"  style="padding: 0 0 0 10px">
                      <div  class="task-container" v-if="ifShowTask(task5)">
                        <button v-show="hasNoDoneChild(task5)&&!task5.expanded" @click="toggleNode($event,task5)">
                          <i class="el-icon-arrow-right"></i>
                        </button>
                        <button v-show="hasNoDoneChild(task5)&&task5.expanded" @click="toggleNode($event,task5)">
                          <i class="el-icon-arrow-down"></i>
                        </button>
                        <button  v-show="!hasNoDoneChild(task5)">
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                        <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task5)" :disabled="!permitLevel(task5)">
                        <input type="text" class="input-taskname" v-model="task5.name" :readonly="!permitLevel(task5)"
                          :class="{ taskinputclicked: isInputClicked(task5.idft) }"
                          @keyup.enter="addBroTask($event,task5)" @change="updateTaskOfSql(task5)" >
                        <label for="" class="task-starttime" v-show="showTaskTime(task5)">
                          {{ showTime(task5.starttime) }}
                        </label>
                      </div>
                      <li v-for="task6 in taskListWithLevel(6,task5)" :key="task6.idft" 
                        v-show="task5.expanded" @click="goToTaskDetail($event,task6)"
                        @contextmenu.prevent="taskEditMenu($event,task6)"  style="padding: 0 0 0 10px">
                        <div  class="task-container" v-if="ifShowTask(task6)">
                          <input type="checkbox" class="task-done" style="margin-left: 26px" @change="toggleTaskDone($event,task6)" :disabled="!permitLevel(task6)">
                          <input type="text" class="input-taskname" v-model="task6.name" :readonly="!permitLevel(task6)" 
                          :class="{ taskinputclicked: isInputClicked(task6.idft) }"
                            @keyup.enter.prevent="addBroTask($event,task6)" @change="updateTaskOfSql(task6)" >
                          <label for="" class="task-starttime" v-show="showTaskTime(task6)">
                            {{ showTime(task6.starttime)}}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </li>  
                </ul>
              </li>
            </ul>
            <ShareTaskMenu v-if="showShareTaskMenu"></ShareTaskMenu>
          </div>
          <div class="projectdone-container" v-if="showTaskDoneContainer">
            <div>
              <button style="border: none; background: none;height: 42px;" @click=showDoneTaskToggle()>
                <i class="el-icon-arrow-right" v-show="!showDoneProject"></i>
                <i class="el-icon-arrow-down" v-show="showDoneProject"></i>
              </button>
              <label for="" style="padding-left: 0;height: 42px;">已完成</label>
            </div>
            <div class="project-container-leftcol" v-show="showDoneProject">
            <ul v-for="task1 in tasklistDone1" :key="task1.idft" ref="task-ul" 
              @click="goToTaskDetail($event,task1)" class="ul-task1" 
              @contextmenu.prevent="taskEditMenu($event,task1)">  
            <div class="task-container" v-if="ifShowTask(task1)">
              <button v-show="hasDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)">
                <i class="el-icon-arrow-right"></i>
              </button>
              <button v-show="hasDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                <i class="el-icon-arrow-down"></i>
              </button>
              <button  v-show="!hasDoneChild(task1)">
              </button>
              <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task1)" v-model="task1.done" 
                :disabled="!permitLevel(task1)">
              <input type="text" class="input-taskname" v-model="task1.name"  readonly
                @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)">
              <label for="" class="task-starttime" v-show="showTaskTime(task1)" @click="updateTaskdate(task1)">
                {{ showTime(task1.starttime) }}
              </label>
              <el-date-picker
                v-model="newStarttimeFromDatePicker"
                type="datetime"
                size="small"
                popper-class="dateselect"
                class="my-date-picker"
                id="date-picker" style="width: 0px; height: 0px; overflow: hidden; background: transparent;">  
              </el-date-picker>
            </div>
              <li  v-for="task2 in taskListDoneWithLevel(task1)" :key="task2.idft" ref="task-li" 
                v-show="task1.expanded" @click="goToTaskDetail($event,task2)"
                @contextmenu.prevent="taskEditMenu($event,task2)" style="padding-left: 10px;">
                <div  class="task-container" v-if="ifShowTask(task2)">
                  <button v-show="hasDoneChild(task2)&&!task2.expanded" @click="toggleNode($event,task2)">
                    <i class="el-icon-arrow-right"></i>
                  </button>
                  <button v-show="hasDoneChild(task2)&&task2.expanded" @click="toggleNode($event,task2)">
                    <i class="el-icon-arrow-down"></i>
                  </button>
                  <button  v-show="!hasDoneChild(task2)">
                  </button>
                  <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task2)" v-model="task2.done" :disabled="!permitLevel(task2)">
                  <input type="text" class="input-taskname" v-model="task2.name"  readonly
                  @keyup.enter="addBroTask($event,task2)" @change="updateTaskOfSql(task2)"> 
                  <label for="" class="task-starttime" v-show="showTaskTime(task2)">
                    {{ showTime(task2.starttime) }}
                  </label>
                </div>
                <ul v-for="task3 in taskListDoneWithLevel(task2)" :key="task3.idft"  
                  ref="task-ul" v-show="task2.expanded" @click="goToTaskDetail($event,task3)"
                  @contextmenu.prevent="taskEditMenu($event,task3)" style="padding: 0 0 0 10px;">
                  <div  class="task-container" v-if="ifShowTask(task3)">
                    <button v-show="hasDoneChild(task3)&&!task3.expanded"  @click="toggleNode($event,task3)">
                      <i class="el-icon-arrow-right"></i>
                    </button>
                    <button v-show="hasDoneChild(task3)&&task3.expanded"  @click="toggleNode($event,task3)">
                      <i class="el-icon-arrow-down"></i>
                    </button>
                    <button  v-show="!hasDoneChild(task3)">
                    </button>
                    <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task3)" v-model="task3.done" :disabled="!permitLevel(task3)">
                    <input type="text" class="input-taskname" v-model="task3.name" readonly
                    @keyup.enter="addBroTask($event,task3)" @change="updateTaskOfSql(task3)">
                    <label for="" class="task-starttime" v-show="showTaskTime(task3)">
                      {{ showTime(task3.starttime) }}
                    </label>
                  </div>
                  <li v-for="task4 in taskListDoneWithLevel(task3)" :key="task4.idft"  
                    ref="task-li" v-show="task3.expanded" @click="goToTaskDetail($event,task4)"
                    @contextmenu.prevent="taskEditMenu($event,task4)" style="padding: 0 0 0 10px">
                    <div  class="task-container" v-if="ifShowTask(task4)">
                      <button v-show="hasDoneChild(task4)&&!task4.expanded" @click="toggleNode($event,task4)">
                        <i class="el-icon-arrow-right"></i>
                      </button>
                      <button v-show="hasDoneChild(task4)&&task4.expanded" @click="toggleNode($event,task4)">
                        <i class="el-icon-arrow-down"></i>
                      </button>
                      <button  v-show="!hasDoneChild(task4)">
                      </button>
                      <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task4)" v-model="task4.done" :disabled="!permitLevel(task4)">
                      <input type="text" class="input-taskname" v-model="task4.name" readonly
                      @keyup.enter="addBroTask($event,task4)" @change="updateTaskOfSql(task4)">
                      <label for="" class="task-starttime" v-show="showTaskTime(task4)">
                        {{ showTime(task4.starttime) }}
                      </label>
                    </div>
                    <ul v-for="task5 in taskListDoneWithLevel(task4)" :key="task5.idft"  
                      ref="task-ul" v-show="task4.expanded" @click="goToTaskDetail($event,task5)"
                      @contextmenu.prevent="taskEditMenu($event,task5)"  style="padding: 0 0 0 10px">
                      <div  class="task-container" v-if="ifShowTask(task5)">
                        <button v-show="hasDoneChild(task5)&&!task5.expanded" @click="toggleNode($event,task5)">
                          <i class="el-icon-arrow-right"></i>
                        </button>
                        <button v-show="hasDoneChild(task5)&&task5.expanded" @click="toggleNode($event,task5)">
                          <i class="el-icon-arrow-down"></i>
                        </button>
                        <button  v-show="!hasDoneChild(task5)">
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                        <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task5)" v-model="task5.done" :disabled="!permitLevel(task5)">
                        <input type="text" class="input-taskname" v-model="task5.name" readonly
                        @keyup.enter="addBroTask($event,task5)" @change="updateTaskOfSql(task5)" >
                        <label for="" class="task-starttime" v-show="showTaskTime(task5)">
                          {{ showTime(task5.starttime) }}
                        </label>
                      </div>
                      <li v-for="task6 in taskListDoneWithLevel(task5)" :key="task6.idft" 
                        v-show="task5.expanded" @click="goToTaskDetail($event,task6)"
                        @contextmenu.prevent="taskEditMenu($event,task6)"  style="padding: 0 0 0 10px">
                        <div  class="task-container" v-if="ifShowTask(task6)">
                          <input type="checkbox" class="task-done" style="margin-left: 26px" @change="toggleTaskUndone($event,task6)"
                            v-model="task6.done" :disabled="!permitLevel(task6)">
                          <input type="text" class="input-taskname" v-model="task6.name" readonly
                            @keyup.enter.prevent="addBroTask($event,task5)" @change="updateTaskOfSql(task6)" >
                          <label for="" class="task-starttime" v-show="showTaskTime(task6)">
                            {{ showTime(task6.starttime)}}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </li>  
                </ul>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </el-col>
      <el-col style="width: calc(100% - 355px); height: calc(100% - 5px)">
        <router-view 
          @upDateStarttime="upDateStarttime" :selectedTask="selectedTask" @updateTaskDone="updateTaskDone" :selectedTaskFiles=selectedTaskFiles
          :taskContents="taskContents"  :selectedProjectAcls="selectedProjectAcls"  :selectedTaskResPlans="selectedTaskResPlans"
          @sendTaskMessage="sendTaskMessage" @upDateEndtime="upDateEndtime" @updateTaskOfSql="updateTaskOfSql" :altNikname="altNikname"
          @addLocalTaskFileData=addLocalTaskFileData @deleteLocalTaskFile="deleteLocalTaskFile" :isTaskCharger="isTaskCharger"
          :taskProcessMsgs="taskProcessMsgs" :taskReports="taskReports" @deleteTaskReport="deleteTaskReport" @addTaskReport="addTaskReport"
          @editTaskReport="editTaskReport" @closeTaskDetail="closeTaskDetail" :selectedTaskChatContacter="selectedTaskChatContacter"
          @updateTaskThreedStatus="updateTaskThreedStatus">
        </router-view>

        <!-- 这里是显示点击饼图显示的列表   -->
        <div class="overlay" v-if="showTaskListFromPie" @click="closeTaskListFromPie"></div>  
        <div class="task-list" v-if="showTaskListFromPie">  
          <h3>任务列表</h3>  
          <table style="border-collapse: collapse; width: 100%; text-align: left; font-size: 12px; text-align: center;">  
            <thead style="position: sticky; top: 0; background: white; z-index: 10;">  
              <tr>  
                <th style="border: 0.5px solid grey; padding: 2px; width: 40px;">序号</th>  
                <th style="border: 0.5px solid grey; padding: 2px; width: 150px; overflow-x: hidden;">任务名称</th>  
                <th style="border: 0.5px solid grey; padding: 2px;">简报内容</th>  
              </tr>  
            </thead>  
            <tbody v-for="(task, index) in tasklistFromPartPie" :key="index">    
              <tr>  
                <td style="border: 0.5px solid grey; padding: 2px;">{{ index + 1 }}</td>  
                <td style="border: 0.5px solid grey; padding: 2px;" @mouseup.stop="goToTaskFromTaskPie(task)"  @mousedown.stop="getTaskDatasFromTaskPie(task)">
                  {{ task.name }}
                </td>  
                <td style="border: 0.5px solid grey; padding: 2px; line-height: 20px; text-align: left;">  
                  {{ computedTaskProgressOfReport(task) }}  
                </td>  
              </tr>  
            </tbody>  
          </table>  
        </div> 

        <div v-if="ifShowTaskDetail" class="taskDetailContainer">
          <DetailForHome :selectedProjectAcls="selectedProjectAcls" :selectedTaskResPlans="selectedTaskResPlansFromPie" :taskProcessMsgs="taskProcessMsgsFromPie"
            :selectedTask="selectedTask"  :taskContents="selectedTaskMsgs" :selectedTaskFiles="selectedTaskFiles" :taskReports="taskReports"
            @updateTaskRecord="updateTaskRecord" @upDateEndtime="upDateEndtime"  @sendTaskMessage="sendTaskMessage" @addLocalTaskSummitMsg="addLocalTaskSummitMsg"
            @addLocalTaskFileData=addLocalTaskFileData @upDateStarttime="upDateStarttime" @deleteTaskReport="deleteTaskReport"
            @editTaskReport="editTaskReport" @addTaskReport="addTaskReport" @deleteLocalTaskFile="deleteLocalTaskFile"> 
          </DetailForHome>
        </div>

        <div v-if="ifShowTaskReports">
          <div style="display: flex;margin-right:10px;border-bottom: 1px solid #B3C0D1;" >
            <div style="font-weight:bold;margin-left:10px;">项目概览</div>
            <div style="margin-left:auto;">
              <button @click="showPrjTasksReportsChartOfFinish" :style="{ color: reportChartOption === 4 ? 'rgb(71, 114, 250)' : 'initial' }">
                完成情况
              </button>
              <button @click="showPrjTasksReportsChartOfDistribution" :style="{ color: reportChartOption === 6 ? 'rgb(71, 114, 250)' : 'initial' }">
                任务分布
              </button>
              <button @click="showPrjTasksReportsChartOfStatus" :style="{ color: reportChartOption === 1 ? 'rgb(71, 114, 250)' : 'initial' }">
                简报统计
              </button>

              <button @click="showPrjTasksReportsChartOfRisk" :style="{ color: reportChartOption === 2 ? 'rgb(71, 114, 250)' : 'initial' }">
                简报分析
              </button>
              <button @click="showPrjTasksReportsSummit" :style="{ color: reportChartOption === 3 ? 'rgb(71, 114, 250)' : 'initial' }">
                提交情况
              </button>
              <button @click="showPrjTasksReportsList" :style="{ color: reportChartOption === 5 ? 'rgb(71, 114, 250)' : 'initial' }">
                简报列表
              </button>
            </div>
          </div>
          <div style="margin-top:10px;position:relative;">
            <div ID="main" style="height:400px;width:100%;margin-top:10px;"  v-if="reportChartOption < 5">
            </div>
            <!-- <div ID="main" :style="{ height: chartHeight, width: '100%', marginTop: '10px' }">
            </div> -->
            <div style="position:absolute;right:10px;top:0px;z-index:99;display:flex;height:30px;"  v-if="reportChartOption < 4">
               <div style="line-height:30px;">简报日期：</div>
               <div  style="line-height:30px;">
                 <el-select v-model="reportDateOpt" placeholder="请选择" size="small" style="width:100px;text-align: center;">
                   <el-option
                     v-for="item in reportDateOpts"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                   </el-option>
                 </el-select>
               </div>
            </div>

            <div v-if="reportChartOption == 6">
              <TaskPieChart :allTasks="myTasksWithThreedstatus" @goToTaskFromTaskPie="goToTaskFromTaskPie" 
              @getTaskDatasFromTaskPie="getTaskDatasFromTaskPie" :selectedPrjTasksReportsOfToday="selectedPrjTasksReportsOfToday">

              </TaskPieChart>

            </div>

            <div v-if="ifShowEditTaskReportMenu" class="reportinput">
              <div style="width:100%;text-align: center;font-size: 16px;font-weight: bold;line-height: 40px;">{{ editTaskReportTitle }}</div>
              <div>
                <div style="display:flex;">
                  <div>进度状态：</div>
                  <div >
                    <el-select v-model="editTaskStatus" placeholder="请选择" size="small" style="width:100px;text-align: center;">
                      <el-option
                        v-for="item in editTaskStatusOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div style="margin-left:20px;">风险等级：</div>
                  <div >
                    <el-select v-model="taskRiskLevel" placeholder="请选择" size="small" style="width:100px;text-align: center;">
                      <el-option
                        v-for="item in taskRiskLevelOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>

              </div>
              <textarea  v-model="newTaskReportProgress"  placeholder="请输入简报内容。。。" class="taskreportprogress">
              </textarea>
              <div style="float:right;margin-top:10px;margin-right:10px;">
                <button @click="summitEditTaskReport()">{{ editTaskReportButton }}</button>
                <button @click="cancelEditTaskReportMenu">取消</button>
              </div>
            </div>

            <div v-if="reportChartOption === 5" style="position:relative;">
  
              <div style="display:flex;height:30px;margin-bottom:10px;font-size: 12px;">
                <div style="display:flex;height:30px;margin-left:10px;">
                  <div style="line-height:30px;">简报日期：</div>
                  <div  style="line-height:30px;">
                    <el-select v-model="reportDateOpt1" placeholder="请选择" size="small" style="width:100px;text-align: center;">
                      <el-option
                        v-for="item in reportDateOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div style="display:flex;height:30px;margin-left: 10px;">
                  <div style="line-height:30px;">筛选条件：</div>
                  <div  style="line-height:30px;">
                    <el-select v-model="reportFilterOpt" placeholder="请选择" size="small" style="width:100px;text-align: center;">
                      <el-option
                        v-for="item in reportFilterOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div  style="line-height:30px;">
                    <el-select v-model="reportChargerOpt" placeholder="请选择" size="small" style="width:100px;text-align: center;">
                      <el-option
                        v-for="item in reportChargerOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>
  
              </div>
  
              <div  style="max-height: calc(100vh - 200px); overflow-y: auto;">
                <table style="border-collapse: collapse; width: 100%; text-align: left;font-size: 12px;text-align: center;">  
                  <thead style="position: sticky; top: 0; background: white; z-index: 10;">  
                    <tr>  
                      <th style="border: 0.5px solid grey; padding: 2px;width:40px;">序号</th>  
                      <th style="border: 0.5px solid grey; padding: 2px;width:150px;overflow-x: hidden;">任务名称</th>  
                      <th style="border: 0.5px solid grey; padding: 2px;">简报内容</th> 
                      <th style="border: 0.5px solid grey; padding: 2px;width:70px;">进度状态</th> 
                      <th style="border: 0.5px solid grey; padding: 2px;width:60px;">风险等级</th> 
                      <th style="border: 0.5px solid grey; padding: 2px;width:60px;">负责人</th>  
                      <th style="border: 0.5px solid grey; padding: 2px;width:120px;">汇报时间</th>  
                    </tr>  
                  </thead>  
                  <!-- <tbody v-for="(task, index) in selectedPrjNoChildTasksFiltered" :key="task.idft"> -->
                  <tbody v-for="(task, index) in selectedPrjNoChildTasks" :key="task.idft">   
                    <tr v-if="computedReportFilter(task)&&computedReportCharger(task)">
                    <!-- <tr v-for="(task, index) in selectedPrjNoChildTasks" :key="task.idft">    -->
                      <td style="border: 0.5px solid grey; padding: 2px;">{{ index + 1 }}</td>  
                      <td style="border: 0.5px solid grey; padding: 2px;" @click="showEditTaskReportMenu(task)">{{ task.name }}</td>  
                      <td class="reportprogress" :contenteditable="reportDateOpt1=='今天'" @blur.prevent="summitEditTaskReportByTable($event,task)"
                        @focus.prevent="startEditTaskReport(task)">
                        {{ task.report_progress }}
                      </td>
                      <td style="border: 0.5px solid grey; padding: 2px;width: 60px;">
                        <el-select v-model="task.status" size="mini" @change="updateTaskReportStatus(task)" :disabled="reportDateOpt1!=='今天'">
                          <el-option
                            v-for="item in editTaskStatusOpts"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </td>  
                      <td style="border: 0.5px solid grey; padding: 2px;">
                        <el-select v-model="task.risklevel" size="mini"  @change="updateTaskReportRiskLevel(task)" :disabled="reportDateOpt1!=='今天'">
                          <el-option
                            v-for="item in taskRiskLevelOpts"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </td> 
                      <td style="border: 0.5px solid grey; padding: 2px;">{{ task.report_nickname }}</td>  
                      <td style="border: 0.5px solid grey; padding: 2px;font-size: 12px;">{{ task.report_createdat }}</td>  
                    </tr>  
                  </tbody>  
                </table>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </div>
    <!-- <div v-if="projectType==='table'">
      xixi
    </div>
    <div v-if="projectType==='graphic'">
      xixi
    </div> -->
  </div>

 
</template>

<script>
import axios from 'axios'
import { mapState,mapMutations,mapActions} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import EditTaskMenu from '../menu/task/EditTaskMenu.vue'
import ShareTaskMenu from '../menu/task/ShareTaskMenu.vue'
import SummitTaskMenu from '../menu/task/SummitTaskMenu.vue'
import LoadTempProject from '../menu/task/LoadTempProject.vue'
import TaskPieChart from '../task/TaskPieChart.vue'
import StatusSelector from '../menu/task/StatusSelector.vue'
import DetailForHome from '../menu/task/DetailForHome.vue'

import { eventBus } from '../../main.js'
import dayjs from 'dayjs'
import { nextTick } from 'vue'
import io from 'socket.io-client'
import { tsvFormatValue } from 'd3'

export default {
  components: {
    EditTaskMenu,
    ShareTaskMenu,
    SummitTaskMenu,
    LoadTempProject,
    TaskPieChart,
    StatusSelector,
    DetailForHome
  },
  name: 'TaskProject',
  props: ['idfp','name','selectedProjectRoute','selectedProject','placeholderStr','projectTitle','myProjectAcls','projectType',
           'tasksWithIdfp','myProjectIdfpArray','myProjects','clickedIdfp','allMyCheckingTasks','allTasksByProjectAcl','loadedTempTasks',
           'projectGroups'],
  data(){
    return {
      chartHeight:'0px',


      notCheckingTasks:true,
      ifShowSearchInput:false,
      searchTaskText:'',
      searchTasksDone:[],
      searchTasks:[],

      altNikname:'',

      date:'',
      // aha:["yuennogroup","a82d4ec6-f6cc-4ea7-af41-b086810f3d5b","950c9ffe-391e-44c4-8bfa-058a35520bba"],

      //共同数据
      ifShowTaskReports:true,
      tasklistall:[],
      selectedPrjTasks:[],

      //tasktree数据
      newTaskNameLv1:'',
      tasklist:[],
      objTest:'',
      hisObj:'',
      nogroupTasklist:[],

      //以下是关于任务编辑右键菜单的数据
      showTaskEdit: false,
      parentNode:null,
      selectedTask:{},

      showTaskDateEdit:false,
      newStarttimeFromDatePicker:'',

      //以下是任务详情内容有关的数据
      taskContents:[],

      //以下是已完成任务相关数据
      showDoneProject: true,
      showUndoneProject:true,
      tasklistDone:[],

      testdoneTasks: [
        { idft: 10, name: 'ms2', level: 1, parent: 'roottask' },
        { idft: 11, name: 'ms3', level: 3, parent: 10 },
        { idft: 12, name: 'ms4', level: 4, parent: 11 },
        { idft: 13, name: 'mshao', level: 5, parent: 1 },
      ],

      //以下是任务权限、通过任务权限获取任务信息相关的函数
      myTasklistFromSql:[],

      //被点击的input
      clickedInputIdft:'',

      taskMessages:[],
      socket: null,
      username:'',
      userinfo:null,

      tasklistFromProject:[],
      myTaskAcllist:[],

      //共享任务的数据
      showShareTaskMenu:false,
      usernogroup:'',
      selectedProjectAcls:[],
      TaskeditMenuitems:[
        { id:1,label: '添加子任务' }, 
        {id:2,label:'指派给'},
        // { id:3,label: '置顶' },
        { id:3,label: '提交确认' },
        { id:4,label: '移动到' },
        // { id:5,label: '标签' }, 
        // { id:6,label: '复制链接' }, 
        // { id:7,label: '创建副本' },
        { id:8,label: '删除' }
      ],
      selectedTaskAcls:[],


      //与任务资源计划相关的任务
      selectedTaskResPlans:[],


      //提交和确认任务的数据
      showSummitTask:false,
      summitingTask:null,
      selectedTaskFiles:[],
      isTaskCharger:false,

      //这个是任务审批相关的记录数据
      taskProcessMsgs:[],

      taskReports:[],
      selectedProjectReports:[],
      myChart:null,
      selectedPrjTasksReportsOfToday:[],
      ifShowStatusChart:false,
      ifShowRiskChart:false,
      reportChartOption:1,

      reportDateOpts:[{
        value: '昨天',
        label: '昨天'
      }, {
        value: '今天',
        label: '今天'
      }, {
        value: '前天',
        label: '前天'
      }],
      reportDateOpt:'今天',

      reportDateOpt1:'今天',

      selecetdPrjTaskChargers:[],
      selectedPrjNoChildTasks:[],
      selectedPrjNoChildTasksFiltered:[],

      reportFilterOpts:[{
        value: '所有',
        label: '所有'
      }, {
        value: '有简报',
        label: '有简报'

      }, {
        value: '无简报',
        label: '无简报'
      }],
      reportFilterOpt:'',

      reportChargerOpts:[],
      reportChargerOpt:'所有人',

      ifShowEditTaskReportMenu:false,
      editTaskReportTitle:'',
      editTaskStatus:'',

      editTaskStatusOpts:[{
          value: '正常',
          label: '正常'
        }, {
          value: '超前',
          label: '超前'
        }, {
          value: '滞后',
          label: '滞后'
        },{
          value: '暂停',
          label: '暂停'
        },{
          value:'',
          label:'未定义'
        }],

      taskRiskLevel:'',
      taskRiskLevelOpts:[{
          value: '低',
          label: '低'
        }, {
          value: '中',
          label: '中'
        }, {
          value: '高',
          label: '高'
        },{
          value:'',
          label:'未定义'
        }],
      newTaskReportProgress:'',
      editTaskReportButton:'',
      selectedTaskReport:null,
      selectedTaskWithReport:null,


      //与添加模板任务相关的数据
      ifShowTempProject:false,


      //与聊天对象相关的数据
      selectedTaskChatContacter:null,

      //以下是关于任务分布饼图的数据
      myTasksWithThreedstatus: [    
      ], 
      
      //从饼图中选择的任务的任务详情相关的数据
      ifShowTaskDetail:false,
      selectedTaskFromPie:null,
      selectedTaskMsgsFromPie:[],
      selectedTaskResPlansFromPie:[],
      taskProcessMsgsFromPie:[],
      selectedTaskFilesFromPie:[],
      taskReportsFromPie:[],
      selectedTaskMsgs:[],

      
      showTaskListFromPie:false,
      myTasksIfFinished:[],
      tasklistFromPartPie:[],

      //按照是否到期和是否有简报来筛选任务
      myTasksWithReports:[],
      myTasksNotStarted:[],
      myTaskWithNoReport:[],
      myTasksYetFinishedNot:[],
      myTasksNoFinishDay:[],
      myTasksNotFinish:[],
      myTasksFinished:[],
      myTasksAhead:[],
      myTasksBehind:[],
      myTasksNormal:[],
      myTasksNoReport:[],
      myTasksNoStart:[],
      myTasksPaused:[],

    }
  },
  
  computed: {

    isTaskReportEditable(){
      return (reportDatetime)=>{
        const date=new Date(reportDatetime)
        const today=new Date()
        // 判断是否为同一天  
        const isSameDay=(date.toDateString()==today.toDateString())
        if(isSameDay&&this.selectedTask.permit_level=='charger'){
          return true
        }else {
          return false
        }
      }
    },

    computedTaskProgressOfReport(){
      return(task)=>{
        const taskReport=this.selectedPrjTasksReportsOfToday.find(item=>task.idft==item.task_idft)
        if(taskReport){
          return taskReport.progress
        }else {
          return ''
        }
      }
    },

    computedReportFilter(){
      return (task)=>{
        if (this.reportFilterOpt=='所有') {
          return true
        } else if (this.reportFilterOpt=='有简报') {
          return task.report_progress!==''
        } else if(this.reportFilterOpt=='无简报'){
          return task.report_progress==''
        }else {
          return false
        }
      }
    },

    computedReportCharger(){
      return (task)=>{
        if(this.reportChargerOpt=='所有人'){
          return true
        }else {
          const chargerNames=task.report_nickname.split(";")
          if(chargerNames.includes(this.reportChargerOpt)){
            return true
          }else{
            return false
          }
        }
      }
    },

    //计算我对一个项目的权限是什么
    // permitLevel(){
    //   return (task)=>{
    //     if(this.selectedProject.permit_level=='charger') {
    //       return true
    //     } else {
    //       const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)
    //       const isCharger=(taskAcl.length==0)?false:(taskAcl[0].permit_level=='charger'?true:false)
    //       return isCharger
    //     }
    //   }
    // },


    permitLevel(){
      return (task)=>{
        //以下赋权方式是任务的负责人就可以确定任务完成
        // const taskAcl=this.myTaskAcllist.find((item)=>{
        //   if(item.task_idft==task.idft){
        //     return item
        //   }
        // })
        // if(task.permit_level=='charger'||(taskAcl&&taskAcl.permit_level=='charger')) {
        //   return true
        // } else {
        //   return false
        // }

        //以下赋权方式是项目负责人或者任务的创建者才可以确认任务是否完成
        const selectedProject=this.myProjects.find(item=>item.idfp==task.project)
        if(selectedProject&&(selectedProject.permit_level=='charger'||task.creater==this.username)){
          return true
        }else {
          return false
        }
      }
    },

    ifShowTask() {
      return (task)=>{
        const taskAcl=this.myTaskAcllist.find((item)=>{
          if(item.task_idft==task.idft){
            return item
          }
        })
        const isCharger=(taskAcl&&(taskAcl.permit_level=='viewer'||taskAcl.permit_level=='charger'))?true:false
        if(task.permit_level=='parter'&&!isCharger) {
          return false
        } else {
          return true
        }
      }
    },

    // taskListWithLevel() {
    //   const id=this.$route.params.idfp
    //   if(id=='today'||id=='week'){
    //     return (level,parentTask) => this.tasklist.filter(task => task.parent===parentTask.idft&&task.done===0)
    //   }else {
    //     return (level,parentTask) => this.tasklist.filter(task => task.parent===parentTask.idft&&task.level==level&&task.done===0)
    //   }
    // },

    taskListWithLevel() {  
        const id = this.$route.params.idfp;  
        
        if (id == 'today' || id == 'week') {  
            return (level, parentTask) => {  
                const filteredTasks = this.tasklist.filter(task =>   
                    task.parent === parentTask.idft && task.done === 0  
                );  
                
                // 按照 starttime 排序  
                filteredTasks.sort((a, b) => new Date(a.starttime) - new Date(b.starttime));  
                
                return filteredTasks;  
            };  
        } else {  
            return (level, parentTask) => {  
                const filteredTasks = this.tasklist.filter(task =>   
                    task.parent === parentTask.idft && task.level == level && task.done === 0  
                );  
                
                // 按照 starttime 排序  
                filteredTasks.sort((a, b) => new Date(a.starttime) - new Date(b.starttime));  
                
                return filteredTasks;  
            };  
        }  
    },  
    taskListDoneWithLevel(){
      return (parentTask) => this.tasklistDone.filter(task => task.parent===parentTask.idft)
    },
    hasNoDoneChild:function() {
      return function(task) {
        const noDoneChilds = this.tasklist.filter(i => i.parent === task.idft && i.done === 0);
        if (noDoneChilds.length < 1) {
          return 0;
        } else {
          return 1;
        }
      }
    },

    showTaskDoneContainer:function(){
      if(this.tasklistDone.length>0) {
        return 1
      }else {
        return 0
      }
    },
    hasDoneChild:function() {
      return function(task) {
        const doneChilds = this.tasklistDone.filter(i => i.parent === task.idft && i.done === 1);
        if (doneChilds.length < 1) {
          return 0;
        } else {
          return 1;
        }
      }
    },
    ...mapState({
      selectProjName: 'selectProjName',
      selectProjGroup: 'selectProjGroup',
      selectProjIdfp: 'selectProjIdfp',
      user:'user'
    }),


     //以下是通过任务权限获取任务，再进行分级计算
    tasklist1: function(){
      const id=this.$route.params.idfp
      let tasklist01
      if(id=='today'){
        const today=new Date()
        tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&new Date(i.starttime) > today)))
        // return tasklist01
      }else if(id=='week') {
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6)
        tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&new Date(i.starttime) > oneWeekLater&&i.done===0)))
        // return tasklist01
      }else if(id=='checking'){

        return this.allMyCheckingTasks
      } else {
        // let tasklist01=this.tasklistFromProject.filter(

        tasklist01 = this.tasklist.filter(
        function(task) {
          return task.level === 1&&task.done==0
        })
        // return tasklist01
      }

      // 对 tasklist01 按照 starttime 排序  
      tasklist01.sort((a, b) => {  
          return new Date(a.starttime) - new Date(b.starttime);  
      });  
  
      return tasklist01; 

    },
    //以上是通过任务权限获取任务，再进行分级计算

    tasklistDone1: function(){
      const doneTasks=this.tasklistDone
      const doneTasks1=[]
      for(var i=0; i<doneTasks.length; i++){
        if(doneTasks[i].level===1){
          doneTasks1.push(doneTasks[i])
        }else {
          const parentTask=doneTasks.find(task=>task.idft===doneTasks[i].parent)
          if(!parentTask) {
            doneTasks1.push(doneTasks[i])
          }
        }
      }
      return doneTasks1
    },

    showTime(){
      return (dateTime)=>{
        const today=new Date()
        const thisYear=today.getFullYear()
        const taskDate=new Date(dateTime)
        const date = new Date(dateTime);
        const year = date.getFullYear();
        var taskYear=taskDate.getFullYear()
        var taskMonth=taskDate.getMonth()+1
        var taskDay=taskDate.getDate()
        const todayOfStr=this.convertDate(new Date())
        var taskDateOfStr=this.convertDate(dateTime)
        var lastDate
        if(todayOfStr==taskDateOfStr){
          lastDate='今天'
        } else if(taskYear==thisYear){
          lastDate=`${taskMonth}月${taskDay}日`
        }else if(year=='2999'){
          lastDate=''
        }else {
          lastDate=`${taskYear}-${taskMonth}-${taskDay}`
        }
        return lastDate
      }
    },

    showTaskTime(){
      return (task)=>{
        
        const today=new Date()
        const thisYear=today.getFullYear()
        const taskDate=new Date(task.starttime)
        var taskYear=taskDate.getFullYear()
        var taskMonth=taskDate.getMonth()+1
        var taskDay=taskDate.getDate()
        const todayOfStr=this.convertDate(new Date())
        var taskDateOfStr=this.convertDate(task.starttime)
        var lastDate
        if(todayOfStr==taskDateOfStr){
          lastDate=true
        } else if(taskYear==thisYear){
          lastDate=true
        }else if(taskDateOfStr=='2999/12/31'){
          lastDate=false
        }else {
          lastDate=true
        }
        return lastDate
      }
    },

    taskList(){
      return this.tasklist
    },

    //判断input是否被点击
    isInputClicked(){
      return (idft)=>{
        if(this.clickedInputIdft==idft) {
          return true
        } else {
          return false
        }
      }
    },


  },

  created() {
    this.username=localStorage.getItem('username')


    const username=localStorage.getItem('username')
    const key=`user|${username}`
    const userinfoStr=localStorage.getItem(key)
    const userinfo=JSON.parse(userinfoStr)
    const nickname = userinfo.nickname;
    const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
    this.altNikname = matchResult ? matchResult[0] : '';

    


  },
  mounted(){
    // this.projectGroups=this.projectGroups
    
    
    // this.drawChartOfTasksReports()

    // this.runAndMergeArrays()






    // this.runAndMergeArraysOfToday()
    this.getAllProjectTasks()

    // this.getAllProjectTasksLocal()





 


    //输出化任务数据为今天的任务
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    










    const username=localStorage.getItem('username')
    const key=`user|${this.username}`
    const userinfo=localStorage.getItem(key)
    this.userinfo=JSON.parse(userinfo)

    this.getMyTaskAclList(username)
      .then((result)=>{
        this.myTaskAcllist=result
      })
    
    
    
      // this.$refs.addProjInput.placeholder='添加"今天"的任务至"未分类"'
    this.newStarttimeFromDatePicker=new Date()


  
    // this.getTasks(this.selectedProjectRoute)


    // // 创建 WebSocket 客户端实例并连接服务器  
    // this.socket = new WebSocket("wss://api2.itaskid.com");  
    
    // // 监听连接成功事件  
    // this.socket.onopen = () => {  
    //   console.log('Connected to WebSocket server');  
    // };  
    
    // // 监听服务器发送的消息  
    // this.socket.onmessage = (event) => {  
    //   const message = JSON.parse(event.data); // 解析收到的消息  
    //   if (message.receiver == this.selectedTask.idft) {  
    //     this.taskContents.push(message);  
    //   }  
    // };  
    
    // // 监听连接关闭事件（可选）  
    // this.socket.onclose = () => {  
    //   console.log('WebSocket disconnected');  
    // };  
    
    // // 监听错误事件（可选）  
    // this.socket.onerror = (error) => {  
    //   console.error('WebSocket error:', error);  
    // };   
    

    this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
      
    });

    
    
    // 监听服务器发送的消息
    this.socket.on('message', (message) => {
      if(message.receiver==this.selectedTask.idft){
        this.taskContents.push(message)
      }
    });

  },



  watch:{
    // reportChartOption:function(){
    //   if(this.reportChartOption>4){
    //     this.myChart=null
    //   }else {
    //     this.chartHeight='400px'
    //   }
    // },

    '$route.params.idfp':function(){
      const selectedProj=localStorage.getItem('selectedProjectRouteLocal')
      const id=this.$route.params.idfp
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`

      if(id=="today"){
        this.notCheckingTasks=true
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
        this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
        this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));

        this.selectedPrjTasks=this.tasklistall.filter(task=>(new Date(task.starttime))<=today)

        const tasklist1=this.tasklist1Ofmethod()
        this.selectedPrjTasksReportsOfToday=[]
        this.showPrjTasksReportsChartOfFinish()
        this.reportChartOption=4
   
      }else if(id=="week"){
        this.notCheckingTasks=true
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
        this.tasklist = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 0);
        });
        
        this.tasklistDone = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 1);
        });

        this.selectedPrjTasks = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater);
        });

        

        this.nogroupTasklist = this.tasklistall.filter(task => {
          return (task.project === nogroup) && (task.done === 0);
        });

        this.selectedPrjTasksReportsOfToday=[]
        this.showPrjTasksReportsChartOfFinish()
        this.reportChartOption=4
      }else if(id=="inbox"){
        this.notCheckingTasks=true
        this.tasklist=this.tasklistall.filter(task=>task.project==nogroup&&task.done===0)
        this.tasklistDone=this.tasklistall.filter(task=>task.project==nogroup&&task.done===1)
        this.selectedPrjTasks=this.tasklistall.filter(task=>task.project==nogroup)
        this.showPrjTasksReportsChartOfFinish()
        this.reportChartOption=4
      } else if(id=='checking'){
        this.notCheckingTasks=false
        this.selectedPrjTasksReportsOfToday=[]
      } else {
        this.notCheckingTasks=true
        this.tasklist=this.tasklistall.filter(task=>task.project==id&&task.done===0)
        this.myTasksWithThreedstatus=this.tasklist.filter(task=>task.threedstatus)
        this.tasklistDone=this.tasklistall.filter(task=>task.project==id&&task.done===1)
        this.selectedPrjTasks=this.tasklistall.filter(task=>task.project==id)
        // this.reportChargerOpts=[]
        this.getTaskReportsByIdfp(id)
          .then((result)=>{
            if(result.length>0){
              this.selectedProjectReports=result
              
              // 获取今天的日期  
              const today = new Date();  
              // 设置时间为0，以便只比较日期部分  
              today.setHours(0, 0, 0, 0);  
              // 筛选出 report_date 等于今天的数据   
              const todayReports = result.filter(report => {  
                  const reportDate = new Date(report.report_date);  
                  // 设置 reportDate 的时间为0，以便只比较日期部分  
                  reportDate.setHours(0, 0, 0, 0);  
                  return reportDate.getTime() === today.getTime();  
              });  
  
              // 保留每个任务最新的简报  
              const latestReports = todayReports.reduce((acc, report) => {  
                  const taskId = report.task_idft; // 假设 task_idft 是任务的唯一标识  
                  // 如果该任务的最新报告不存在或当前报告更晚，则更新  
                  if (!acc[taskId] || new Date(report.created_at) > new Date(acc[taskId].created_at)) {  
                      acc[taskId] = report;  
                  }  
                  return acc;  
              }, {});  
  
              // 将对象转换为数组  
              this.selectedPrjTasksReportsOfToday = Object.values(latestReports);
              console.log('this.selectedPrjTasksReportsOfToday',this.selectedPrjTasksReportsOfToday)
              // this.showPrjTasksReportsChartOfStatus()
              this.showPrjTasksReportsChartOfFinish()
              this.reportChartOption=4
            } else {
              this.selectedPrjTasksReportsOfToday=[]
              this.showPrjTasksReportsChartOfStatus()
            }
          })
          // this.reportChartOption=4
      }

      const now=new Date()
      const expiredTasklist=this.tasklist.filter(item=>new Date(item.starttime)<=new Date(now))
      this.getChargersForTasks(expiredTasklist)
        .then((result)=>{
          const result1 = result.reduce((acc, { username, task_idft, nickname }) => {  
              // 检查当前用户是否已经在结果对象中  
              if (!acc[username]) {  
                  // 如果没有，初始化一个新对象  
                  acc[username] = {  
                      username,  
                      nickname,  
                      taskArray: []  
                  };  
              }  
              // 将 task_idft 添加到对应用户的 taskidftArray 中  
              acc[username].taskArray.push(task_idft);  
              return acc;  
          }, {});  
          
          // 将结果对象转换为数组  
          const finalResult = Object.values(result1);
          this.selecetdPrjTaskChargers=finalResult
          const allCharger={label:'所有人',value:'所有人'}
          this.reportChargerOpts=[]
          this.reportChargerOpts.push(allCharger)
          this.selecetdPrjTaskChargers.forEach(charger=>{
            const reportCharger={label:charger.nickname,value:charger.nickname}
            this.reportChargerOpts.push(reportCharger)
          })


          

          // const uniqueReportChargerOpts = this.reportChargerOpts.filter((item, index, self) =>  
          //  index === self.findIndex((t) => (  
          //  t.label === item.label && t.value === item.value ))  
          // ); 

          // this.reportChargerOpts=uniqueReportChargerOpts

          // this.reportChargerOpt='all'

        })
      

    },

    loadedTempTasks: function(){
      if(this.loadedTempTasks.length>0){

       

        // 创建一个映射来存储任务关系  
        const taskMap = new Map();  
        const username = localStorage.getItem('username');
        const nogroup=`${username}nogroup`
        let aTasks=this.loadedTempTasks
        const id=this.$route.params.idfp
        const project= (id=='inbox'||id=='checking'||id=='today'||id=='week')?nogroup:id  
  
        // 处理任务列表  
        aTasks = aTasks.map(task => {  
          // 赋值操作并转换日期  
          task.starttime = this.convertDate(task.starttime);  
          task.endtime = this.convertDate(task.endtime);  
          
          const newTask = {  
            ...task, // 保留原有属性  
            idft: uuidv4(),  
            project: project,  
            starttimeatl: '2999/12/31',  
            endtimeatl: '2999/12/31',  
            done: 0,  
            creater: username,  
            permit_level:'charger'
          };  
  
          // 将新任务添加到映射中  
          taskMap.set(newTask.idftt, newTask);  
  
          return newTask; // 返回新的任务对象  
        });  
  
        // 创建一个数组来存储任务和 ACL 添加的 Promise  
        // const taskPromises = [];  
  
        // 更新父任务 ID 并将任务添加到 SQL  
        for (const task of aTasks) {  
          if (task.parent !== 'roottask') {  
            const parentTask = taskMap.get(task.parent); // 快速查找父任务  
            if (parentTask) {  
              task.parent = parentTask.idft; // 替换父任务 ID  
            }  
          }  
  
          // 将任务添加到 SQL 
          this.addTaskToSql(task)

  
          // 创建并添加任务 ACL，存储 Promise  
          const newTaskAcl = {  
            idftacl: uuidv4(),  
            permit_level: 'charger',  
            username: username,  
            task_idft: task.idft,  
            taclcreater: username,  
          };
          this.addTaskAclToSql(newTaskAcl)  
        }





        // this.tasklistall=this.loadedTempTasks
        // console.log('this.$route.params.idfp',this.$route.params.idfp)
        // this.loadedTempTasks.forEach(task=>{
        //   task.project=this.$route.params.idfp
        //   task.idft=uuidv4()
        //   task.done=0
        //   task.permit_level='charger'
        // })
        this.tasklistall = aTasks.concat(this.tasklistall)
        // this.tasklist=this.tasklistall.filter(task=>task.project==this.$route.params.idfp)
        if(id=="today"){
          // this.notCheckingTasks=true
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
          this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
          // this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));
  
          // this.selectedPrjTasks=this.tasklistall.filter(task=>(new Date(task.starttime))<=today)
  
          // const tasklist1=this.tasklist1Ofmethod()
          // this.selectedPrjTasksReportsOfToday=[]
          // this.showPrjTasksReportsChartOfFinish()
          // this.reportChartOption=1
     
        }else if(id=="week"){
          // this.notCheckingTasks=true
          const today=new Date()
          today.setHours(0, 0, 0, 0)
          const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
          this.tasklist = this.tasklistall.filter(task => {
            const taskStartTime = new Date(task.starttime);
            return (taskStartTime <= oneWeekLater) && (task.done === 0);
          });
          
          this.tasklistDone = this.tasklistall.filter(task => {
            const taskStartTime = new Date(task.starttime);
            return (taskStartTime <= oneWeekLater) && (task.done === 1);
          });
  
          // this.selectedPrjTasks = this.tasklistall.filter(task => {
          //   const taskStartTime = new Date(task.starttime);
          //   return (taskStartTime <= oneWeekLater);
          // });
  
          
  
          // this.nogroupTasklist = this.tasklistall.filter(task => {
          //   return (task.project === nogroup) && (task.done === 0);
          // });
  
          // this.selectedPrjTasksReportsOfToday=[]
          // this.showPrjTasksReportsChartOfFinish()
          // this.reportChartOption=1
        }else if(id=="inbox"){
          // this.notCheckingTasks=true
          this.tasklist=this.tasklistall.filter(task=>task.project==nogroup&&task.done===0)
          this.tasklistDone=this.tasklistall.filter(task=>task.project==nogroup&&task.done===1)
          // this.selectedPrjTasks=this.tasklistall.filter(task=>task.project==nogroup)
          // this.showPrjTasksReportsChartOfFinish()
          // this.reportChartOption=1
        } else if(id=='checking'){
          // this.notCheckingTasks=false
          this.selectedPrjTasksReportsOfToday=[]
        } else {
          // this.notCheckingTasks=true
          this.tasklist=this.tasklistall.filter(task=>task.project==id&&task.done===0)
          this.tasklistDone=this.tasklistall.filter(task=>task.project==id&&task.done===1)
          // this.selectedPrjTasks=this.tasklistall.filter(task=>task.project==id)
          // this.reportChargerOpts=[]
          // this.getTaskReportsByIdfp(id)
          //   .then((result)=>{
          //     if(result.length>0){
          //       this.selectedProjectReports=result
                
          //       // 获取今天的日期  
          //       const today = new Date();  
          //       // 设置时间为0，以便只比较日期部分  
          //       today.setHours(0, 0, 0, 0);  
          //       // 筛选出 report_date 等于今天的数据   
          //       const todayReports = result.filter(report => {  
          //           const reportDate = new Date(report.report_date);  
          //           // 设置 reportDate 的时间为0，以便只比较日期部分  
          //           reportDate.setHours(0, 0, 0, 0);  
          //           return reportDate.getTime() === today.getTime();  
          //       });  
    
          //       // 保留每个任务最新的简报  
          //       const latestReports = todayReports.reduce((acc, report) => {  
          //           const taskId = report.task_idft; // 假设 task_idft 是任务的唯一标识  
          //           // 如果该任务的最新报告不存在或当前报告更晚，则更新  
          //           if (!acc[taskId] || new Date(report.created_at) > new Date(acc[taskId].created_at)) {  
          //               acc[taskId] = report;  
          //           }  
          //           return acc;  
          //       }, {});  
    
          //       // 将对象转换为数组  
          //       this.selectedPrjTasksReportsOfToday = Object.values(latestReports);
          //       this.showPrjTasksReportsChartOfStatus()
          //     } else {
          //       this.selectedPrjTasksReportsOfToday=[]
          //       this.showPrjTasksReportsChartOfStatus()
          //     }
          //   })
          //   this.reportChartOption=1
        }
      }
    },


    allTasksByProjectAcl: function(){
      if(this.allTasksByProjectAcl.length>0){
        this.tasklistall=this.allTasksByProjectAcl

        // this.$router.push({
        //     name: 'project',
        //     params: {
        //       idfp: 'index'
        //     }
        //   }).catch(err => {
        //     if (err.name !== 'NavigationDuplicated') {
        //       throw err
        //     }
        //   })


      }
    },

    myProjects: function(){
      
    },

    reportFilterOpt: function(){
      if(this.reportFilterOpt=='所有'){
        this.selectedPrjNoChildTasksFiltered=this.selectedPrjNoChildTasks
      }else if(this.reportFilterOpt=='有简报'){
        this.selectedPrjNoChildTasksFiltered=this.selectedPrjNoChildTasks.filter(task=>task.report_progress!=='')
      }else if(this.reportFilterOpt=='无简报'){
        this.selectedPrjNoChildTasksFiltered=this.selectedPrjNoChildTasks.filter(task=>task.report_progress=='')
      }
    },


    reportDateOpt: function(){
      console.log('reportDateOpt',this.reportDateOpt)
      var selectedDate
      // 获取今天的日期  
      const today = new Date();  
      today.setHours(0, 0, 0, 0); // 将时间设置为 00:00:00  
      
      // 获取昨天的日期  
      const yesterday = new Date(today);  
      yesterday.setDate(today.getDate() - 1); // 将日期减去 1  
      
      // 获取前天的日期  
      const dayBeforeYesterday = new Date(today);  
      dayBeforeYesterday.setDate(today.getDate() - 2); // 将日期减去 2 

      if(this.reportDateOpt=='今天'){
        selectedDate=today
      }else if(this.reportDateOpt=='昨天'){
        selectedDate=yesterday
      }else if(this.reportDateOpt=='前天'){
        selectedDate=dayBeforeYesterday
      }
      // this.selectedProjectReports=result
      // 获取今天的日期  
      // const today = new Date();  
      // // 设置时间为0，以便只比较日期部分  
      // today.setHours(0, 0, 0, 0);  
      // 筛选出 report_date 等于今天的数据   
      const todayReports = this.selectedProjectReports.filter(report => {  
          const reportDate = new Date(report.report_date);  
          // 设置 reportDate 的时间为0，以便只比较日期部分  
          reportDate.setHours(0, 0, 0, 0);  
          return reportDate.getTime() >= selectedDate.getTime();  
      });  
      
      // 保留每个任务最新的简报  
      const latestReports = todayReports.reduce((acc, report) => {  
          const taskId = report.task_idft; // 假设 task_idft 是任务的唯一标识  
          // 如果该任务的最新报告不存在或当前报告更晚，则更新  
          if (!acc[taskId] || new Date(report.created_at) > new Date(acc[taskId].created_at)) {  
              acc[taskId] = report;  
          }  
          return acc;  
      }, {});  
      
      // 将对象转换为数组  
      this.selectedPrjTasksReportsOfToday = Object.values(latestReports);
      if(this.reportChartOption === 4){
        this.showPrjTasksReportsChartOfFinish()
      }else if(this.reportChartOption === 1){
        this.showPrjTasksReportsChartOfStatus()
      }else if(this.reportChartOption === 2){
        this.showPrjTasksReportsChartOfRisk()
      }else if(this.reportChartOption === 3){
        this.showPrjTasksReportsSummit()
      }
      
      // this.showPrjTasksReportsChartOfStatus()
    },

    reportDateOpt1: function(){
      var selectedDate
      // 获取今天的日期  
      const today = new Date();  
      today.setHours(0, 0, 0, 0); // 将时间设置为 00:00:00  
      
      // 获取昨天的日期  
      const yesterday = new Date(today);  
      yesterday.setDate(today.getDate() - 1); // 将日期减去 1  
      
      // 获取前天的日期  
      const dayBeforeYesterday = new Date(today);  
      dayBeforeYesterday.setDate(today.getDate() - 2); // 将日期减去 2 

      if(this.reportDateOpt1=='今天'){
        selectedDate=today
      }else if(this.reportDateOpt1=='昨天'){
        selectedDate=yesterday
      }else if(this.reportDateOpt1=='前天'){
        selectedDate=dayBeforeYesterday
      }
      const todayReports = this.selectedProjectReports.filter(report => {  
          const reportDate = new Date(report.report_date);  
          // 设置 reportDate 的时间为0，以便只比较日期部分  
          reportDate.setHours(0, 0, 0, 0);  
          return reportDate.getTime() >= selectedDate.getTime();  
      });  
      
      // 保留每个任务最新的简报  
      const latestReports = todayReports.reduce((acc, report) => {  
          const taskId = report.task_idft; // 假设 task_idft 是任务的唯一标识  
          // 如果该任务的最新报告不存在或当前报告更晚，则更新  
          if (!acc[taskId] || new Date(report.created_at) > new Date(acc[taskId].created_at)) {  
              acc[taskId] = report;  
          }  
          return acc;  
      }, {});  
      
      // 将对象转换为数组  
      this.selectedPrjTasksReportsOfToday = Object.values(latestReports);
      this.selectedPrjNoChildTasks=this.getNewNoChildTasks()
    },
    
  },


  
  methods: {

    //计算某个任务有没有子任务
    hasNoDoneChildOfMethod(task) {
      const noDoneChilds = this.tasklist.filter(i => i.parent === task.idft && i.done === 0);
      if (noDoneChilds.length < 1) {
        return false;
      } else {
        return true;
      }
    },
    //与任务提交相关的函数
    closeSummitTaskMenu(){
      this.showSummitTask=false
    },

    addLocalTaskSummitMsg(msg){
      this.taskProcessMsgs.push(msg)
    },

    addLocalTaskFileData(file){
      this.selectedTaskFiles.push(file)
    },

    // addLocalTaskFileData(newTaskFileData){
    //   this.selectedTaskFiles.unshift(newTaskFileData)
    // },

    deleteLocalTaskFile(file){
      this.selectedTaskFiles=this.selectedTaskFiles.filter(item=>item.idftf!==file.idftf)
    },

    deleteTaskReport(taskReport){
      this.taskReports=this.taskReports.filter(item=>item.idftr!==taskReport.idftr)
    },

    addTaskReport(taskReport){
      this.taskReports.unshift(taskReport)
      const task=this.tasklistall.find(item=>item.idft==taskReport.task_idft)
      task.report_progress=taskReport.progress
      task.status=taskReport.statuss
      task.risklevel=taskReport.risklevel
      task.report_createdat=taskReport.created_at


      const index = this.selectedPrjNoChildTasks.findIndex(item => item.idft === taskReport.task_idft);
      if (index !== -1) {
        this.selectedPrjNoChildTasks[index]=this.selectedTaskWithReport
      } else {
        console.log('Task not found');
      }

    },

    editTaskReport(newTaskReport){
      const taskReport=this.taskReports.find(item=>item.idftr==newTaskReport.idftr)
      taskReport.progress=newTaskReport.progress
      taskReport.status=newTaskReport.status
      taskReport.updated_at=newTaskReport.updated_at
      taskReport.risklevel=newTaskReport.risklevel
    },

    editTaskReport1(newTaskReport){
      const taskReport=this.selectedPrjTasksReportsOfToday.find(item=>item.idftr==newTaskReport.idftr)
      taskReport.progress=newTaskReport.progress
      taskReport.status=newTaskReport.status
      taskReport.updated_at=newTaskReport.updated_at
      taskReport.risklevel=newTaskReport.risklevel

      
      this.selectedTaskWithReport.report_progress=newTaskReport.progress
      this.selectedTaskWithReport.status=newTaskReport.status
      this.selectedTaskWithReport.risklevel=newTaskReport.risklevel
      this.selectedTaskWithReport.report_createdat=newTaskReport.created_at

      const index = this.selectedPrjNoChildTasks.findIndex(item => item.idft === this.selectedTaskWithReport.idft);
      if (index !== -1) {
        this.selectedPrjNoChildTasks[index]=this.selectedTaskWithReport
      } else {
        console.log('Task not found');
      }

      // this.selectedPrjNoChildTasks.find(item=>{
      //   if(item.idft==this.selectedTaskWithReport.idft){
      //     item.report_progress=newTaskReport.progress
      //     item.status=newTaskReport.status
      //     item.risklevel=newTaskReport.risklevel
      //     item.report_createdat=newTaskReport.created_at
      //   }
      // })
    },


    getPermitLevel(task){
        const taskAcl=this.myTaskAcllist.find((item)=>{
          if(item.task_idft==task.idft){
            return item
          }
        })
        if(task.permit_level=='charger'||(taskAcl&&taskAcl.permit_level=='charger')) {
          return true
        } else {
          return false
        }
    },

    showSearchInput(){
      this.ifShowSearchInput=true
    },
    cancelSearchTask(){
      this.searchTaskText=''
      this.searchTasks=[]
      this.searchTasksDone=[]
      this.ifShowSearchInput=false
    },

    getSearchTasks(){
      const searchTasksAll=this.tasklistall.filter(task=>task.name.includes(this.searchTaskText))
      this.searchTasks=searchTasksAll.filter(task=>task.done==0)
      this.searchTasksDone=searchTasksAll.filter(task=>task.done==1)
    },

    showAddTasksFromTemp(){
      this.$emit('showAddTasksFromTemp','task')
    },

    tasklist1Ofmethod(){
      const id=this.$route.params.idfp
      if(id=='today'){
        const today=new Date()
        // let tasklist01=this.selectedTasks.filter(item=>item.parent=='roottask'||
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&new Date(i.starttime) > today)))
        return tasklist01
      }else if(id=='week') {
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6)
        // let tasklist01=this.selectedTasks.filter(item=>item.parent=='roottask'||
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&new Date(i.starttime) > oneWeekLater&&i.done===0)))
        return tasklist01
      }else {
        // let tasklist01 = this.selectedTasks.filter(
          let tasklist01 = this.tasklist.filter(
        function(task) {
          return task.level === 1&&task.done==0
        })
        return tasklist01
      }

    },

    //合并两个数组的函数
    mergeArrays(A, B) {
      if (!A) {
        A = [];
      }
      if (!B) {
        B = [];
      }

      const mapA = new Map();
      A.forEach(item => {
        mapA.set(item.idft, item);
      });
    
      const result = A.slice();
    
      B.forEach(item => {
        if (!mapA.has(item.idft)) {
          result.push(item);
        }
      });
    
      return result;
    },


    testsdf(task){

      const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)

      const isCharger=taskAcl.length==0?'viewer':(taskAcl[0].permit_level=='charger'?'charger':'viewer')


    },
    ...mapActions({
      addTaskToSql:'addTaskToSql',
      getMyTaskAclList:'getMyTaskAclList',
      getTaskinfoByIdft: 'getTaskinfoByIdft',
      getUserInfoBySearchText:'getUserInfoBySearchText',
      // showPic:'showPic',
      getTasksByIdfp:'getTasksByIdfp',
      getTasksByIdfpFromTasklist:'getTasksByIdfpFromTasklist',
      getTasksByPojectAcl:'getTasksByPojectAcl',
      showTimePub:'showTimePub',
      addTaskAclToSql:'addTaskAclToSql',
      getMyProjectAclList:'getMyProjectAclList',
      deleteTaskAclbyIdft:'deleteTaskAclbyIdft',
      getTaskAclsByTaskIdft:'getTaskAclsByTaskIdft',
      updateTaskAclByUsernameAndIdft:'updateTaskAclByUsernameAndIdft',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      getUserInfoByUsername:'getUserInfoByUsername',
      getMsgsByReceiver:'getMsgsByReceiver',
      getTaskMsgsByReceiver:'getTaskMsgsByReceiver',
      updateTaskOrderidOfSql:'updateTaskOrderidOfSql',
      getTasksByTaskProjects:'getTasksByTaskProjects',
      // getResPlanlistByTask:'getResPlanlistByTask',
      getTaskResorcePlanByIdft:'getTaskResorcePlanByIdft',
      getTaskReportsByIdft:'getTaskReportsByIdft',
      getTaskReportsByIdfp:'getTaskReportsByIdfp',
      addTaskReportToSql:'addTaskReportToSql',
      updateTaskReportByIdftr:'updateTaskReportByIdftr',
      getChatMsgsWithSenderInfoByReceiver:'getChatMsgsWithSenderInfoByReceiver',
      // updateTaskThreedstatusByIdft:'updateTaskThreedstatusByIdft',
      // getSelectedTaskChatContacterWithMsgs:'getSelectedTaskChatContacterWithMsgs'
    }),

    async getResPlanlistByTask(task) {
      const token = localStorage.getItem('token');
      const data = { resp_idft: task.idft };
      try {
        const response = await axios.get('https://api2.itaskid.com'+'/my/getresplanlistbytask', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {resp_idft:task.idft}
        });
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
          return []; // 返回一个空数组表示数据为空
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
        return []; // 返回一个空数组表示发生错误
      }
    },

    //数据初始化有关的函数
    // async getTasksFromProjects(project) {
    //   let tasklistByProject = [];
    //   let promises = this.aha.map(idfp => {
    //     return this.getTasksByIdfp(idfp);
    //   });
    
    //   return Promise.all(promises)
    //     .then((results) => {
    //       results.forEach((result) => {
    //         tasklistByProject = tasklistByProject.concat(result);
    //       });
    //       // return tasklistByProject;
    //       // this.tasklistall=tasklistByProject
    //       return tasklistByProject
    //     });
    // },





    // 调用两个异步函数并合并数组
    async runAndMergeArrays() {
      const myTaskAcllist= await this.getMyTaskAclList(this.username);
      var tasklistByProjectAcl=[]
      this.getMyProjectAclList(this.username)
        .then((result)=>{
          if (!Array.isArray(result)) {
            alert('账号未认证，请重新登录dsss！')
            window.location.href = "https://www.itaskid.com" + "/login";
            return
          }
          const n=result.length
          var i
          var promises = []
          for(i=0;i<n;i++){
            var promise=this.getTasksByTaskProjects(result[i])
            promises.push(promise)
          }
          return Promise.all(promises)
        })
        .then((results) => {
          results.forEach((result) => {
            tasklistByProjectAcl=tasklistByProjectAcl.concat(result)
          })
          // const mergedArray = this.updateTaskPermitLevelbyTaskacl(myTaskAcllist, tasklistByProjectAcl)
          // tasklistByProjectAcl.sort((a, b) => a.orderid - b.orderid)
          if (tasklistByProjectAcl && Array.isArray(tasklistByProjectAcl)) {
            tasklistByProjectAcl.sort((a, b) => a.orderid - b.orderid);
          } 
          this.tasklistall=tasklistByProjectAcl 
        })
    },

    // 调用两个异步函数并合并数组,并初始化任务数据为今天的任务
    // async runAndMergeArraysOfToday() {
    //   // const myTaskAcllist = await this.getTasksByTaskacl()
    //   const myTaskAcllist= await this.getMyTaskAclList(this.username);
      
    //   var tasklistByProjectAcl=[]
    //   this.getMyProjectAclList(this.username)
    //     .then((result)=>{


    //       if (!Array.isArray(result)) {
    //         alert('账号未认证，请重新登录dsss！')
    //         window.location.href = "https://www.itaskid.com" + "/login";
    //         return
    //       }

    //       const n=result.length
          
    //       var i
    //       var promises = []
    //       for(i=0;i<n;i++){
    //         if(!(result[i].permit_level=='parter')){
    //           var promise=this.getTasksByTaskProjects(result[i])
    //           promises.push(promise)
    //         }
    //       }
    //       return Promise.all(promises)
    //     })
    //     .then((results) => {
    //       results.forEach((result) => {
    //         tasklistByProjectAcl=tasklistByProjectAcl.concat(result)

    //       })
    //       const mergedArray = this.updateTaskPermitLevelbyTaskacl(myTaskAcllist, tasklistByProjectAcl)
    //       mergedArray.sort((a, b) => a.orderid - b.orderid)
    //       this.tasklistall=mergedArray 

    //       const today = new Date();
    //       today.setHours(0, 0, 0, 0);
    //       this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
    //       this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
    //       const username=localStorage.getItem('username')
    //       this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));
    //       this.$router.push({
    //         name: 'project',
    //         params: {
    //           idfp: 'today'
    //         }
    //       }).catch(err => {
    //         if (err.name !== 'NavigationDuplicated') {
    //           throw err
    //         }
    //       })
    //     })
    // },

    async getAllProjectTasks() {
      var tasklistByProjectAcl=[]
      this.getMyProjectAclList(this.username)
        .then((result)=>{
          if (!Array.isArray(result)) {
            alert('账号未认证，请重新登录！')
            window.location.href = "https://www.itaskid.com" + "/login";
            return
          }

          const n=result.length
          var i
          var promises = []
          for(i=0;i<n;i++){
              var promise=this.getTasksByTaskProjects(result[i])
              // var promise=this.getTasksByIdfpFromTasklist(result[i])
              promises.push(promise)
          }
          return Promise.all(promises)
        })
        .then((results) => {
          results.forEach((result) => {
            tasklistByProjectAcl=tasklistByProjectAcl.concat(result)

          })
          // const mergedArray = this.updateTaskPermitLevelbyTaskacl(myTaskAcllist, tasklistByProjectAcl)
          if (tasklistByProjectAcl && Array.isArray(tasklistByProjectAcl)) {
            tasklistByProjectAcl.sort((a, b) => a.orderid - b.orderid);
          } 
          this.tasklistall=tasklistByProjectAcl
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
          this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
          const username=localStorage.getItem('username')
          this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));
          this.$router.push({
            name: 'project',
            params: {
              idfp: 'today'
            }
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err
            }
          })
        })
    },

    getAllProjectTasksLocal(){
      this.tasklistall=this.allTasksByProjectAcl
      console.log('this.allTasksByProjectAcl',this.allTasksByProjectAcl)
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
      this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
      const username=localStorage.getItem('username')
      this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));
      // this.
      this.$router.push({
        name: 'project',
        params: {
          idfp: 'today'
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    },

    // updateTaskPermitLevelbyTaskacl(myTaskAcls,myTasksByProjectAcl){
    //   myTasksByProjectAcl.forEach(task => {
    //     const taskAcl = myTaskAcls.find(item => item.task_idft === task.idft);
    //     if (taskAcl) {
    //         task.permit_level = taskAcl.permit_level;
    //     }
    //   });
    //   return myTasksByProjectAcl
    // },

    doneTaskFirst (doneTasks) {
      const doneTasks1 = [];
      for (var i = 0; i < doneTasks.length; i++) {
        if (doneTasks[i].level === 1) {
          doneTasks1.push(doneTasks[i]);
        } else {
          const parentTask = doneTasks.find(task => task.idft === doneTasks[i]?.parent);
          if (!parentTask) {
            doneTasks1.push(doneTasks[i]);
          }
        }
      }
      return doneTasks1;
    },


    //以下是与任务右键编辑菜单有关的方法
    taskEditMenu(event,task){
      this.usernogroup=`${this.username}nogroup`
      this.selectedTask=task
      const selectedProject=this.myProjects.find(item=>item.idfp==task.project)
      if(selectedProject.permit_level=='charger'){
        console.log('i am project charger here')
        if(task.level==1){
          this.TaskeditMenuitems=[
            { id:1,label: '添加子任务' }, 
            {id:2,label:'指派给'},
            { id:3,label: '提交确认' },
            { id:4,label: '移动到' },
            { id:8,label: '删除' }
          ]
        }else {
          this.TaskeditMenuitems=[
            { id:1,label: '添加子任务' }, 
            {id:2,label:'指派给'},
            { id:3,label: '提交确认' },
            { id:8,label: '删除' }
          ]
        }

      }else {
        console.log('i am not project charger here')
        // 根据任务权限和创建者判断  
        if (task.permit_level === 'charger') {  
            if (task.creater === this.username) {  
                this.TaskeditMenuitems = [  
                  { id:1,label: '添加子任务' }, 
                  {id:2,label:'指派给'},
                  { id:3,label: '提交确认' },
                  // { id:4,label: '移动到' },
                  { id:8,label: '删除' }  
                ];  
            } else {  
                this.TaskeditMenuitems = [  
                  { id:1,label: '添加子任务' }, 
                  {id:2,label:'指派给'},
                  { id:3,label: '提交确认' },
                ];  
            }  
        } else {  
          this.TaskeditMenuitems = [  
            { id:1,label: '添加子任务' }, 
            { id:3,label: '提交确认' },
          ];  
        }

      }

      //获取选中的任务对应的所有关
      // this.selectedProjectAcls=this.selectedProjectParter
      // if(this.selectedProject.permit_level=='charger') {
      //       this.selectedTask.permit_level='charger'
      // } else {
      //       const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)
      //       this.selectedTask.permit_level=taskAcl.length==0?'viewer':(taskAcl[0].permit_level=='charger'?'charger':'viewer')
      // }
      event.preventDefault()
      event.stopPropagation()
      this.getProjectPartersFromTask(task)
        // .then((result)=>{
          // this.getSelectedTaskAcls()
          //   .then((result)=>{
          //     this.showTaskEdit=true
          //     this.switchMenuMask()
          //     const top=(window.innerHeight-event.clientY)<230?(event.clientY-230):event.clientY+10
          //     const left=(window.innerWidth-event.clientX)<112?(event.clientX-112):event.clientX+10
          //     this.parentNode ={clientY:top,clientX:left}
          //     eventBus.$on('closeContexMenu', () => {
          //     this.showTaskEdit=false
          //     })
          //   })


      this.getTaskAclsByTaskIdft(task.idft)
        .then((result)=>{
          this.selectedTaskAcls=result
          this.showTaskEdit=true
          this.switchMenuMask()
          const top=(window.innerHeight-event.clientY)<200?(event.clientY-200):event.clientY+10
          const left=(window.innerWidth-event.clientX)<112?(event.clientX-112):event.clientX+10
          this.parentNode ={clientY:top,clientX:left}
          eventBus.$on('closeContexMenu', () => {
          this.showTaskEdit=false
          })
        })

            

          
      // })
    },
    
    cancelEditTask(){

    },

    // moveTasksToProject(value){
    //   console.log('valueInTasks',value)
    //   const oldtp_idfp=this.selectedTask.project
    //   const newtp_idfp=value[1]
    //   const newTaskLevel=this.selectedTask.level
    //   this.selectedTask.project=value[1]
  
    //   //这里要进行一个task_project的操作
    //   this.updateTaskProjectIdfp(this.selectedTask.idft,oldtp_idfp,newtp_idfp,newTaskLevel)

    //   const id=this.$route.params.idfp
    //   const isTodayOrWeek = (id === 'today' || id === 'week');
    //   if (!isTodayOrWeek) {
    //     this.tasklist=this.tasklist.filter(item=>item.idft!==this.selectedTask.idft)
    //   }
    //   this.showTaskEdit=false
    //   this.switchMenuMask()
    // },

    moveTasksToProject(value) {  
      console.log('valueInTasks', value);  
      const oldtp_idfp = this.selectedTask.project;  
      const newtp_idfp = value[1];  
      const newTaskLevel = this.selectedTask.level;  
  
      // 更新选中的任务的项目  
      this.selectedTask.project = newtp_idfp;  

      // 这里要进行一个task_project的操作  
      this.updateTaskProjectIdfp(this.selectedTask.idft, oldtp_idfp, newtp_idfp, newTaskLevel);  
  
      // 递归函数：更新任务及其所有子任务，并移除已移动的任务  
      const updateAndRemoveTasks = (parentTask) => {  
          // 筛选出当前任务的所有子任务  
          const childTasks = this.tasklist.filter(task => task.parent === parentTask.idft);  
  
          // 如果没有子任务，直接移除当前任务  
          if (childTasks.length === 0) {  
              this.tasklist = this.tasklist.filter(item => item.idft !== parentTask.idft);  
              return;  
          }  
  
          // 更新每个子任务的项目  
          childTasks.forEach(childTask => {  
            const oldChildProject = childTask.project;  
                childTask.project = newtp_idfp; // 更新项目  
                this.updateTaskProjectIdfp(childTask.idft, oldChildProject, newtp_idfp, childTask.level);  
    
                // 如果子任务的级别小于6，递归更新其子任务  
                if (childTask.level < 6) {  
                    updateAndRemoveTasks(childTask);  
                }  
            });  
    
            // 移除当前任务  
            this.tasklist = this.tasklist.filter(item => item.idft !== parentTask.idft);  
        };  

        // 更新选中任务的所有子任务并移除已移动的任务  
        updateAndRemoveTasks(this.selectedTask);  
    
        // 处理任务列表，移除已移动的任务及其所有子任务  
        const id = this.$route.params.idfp;  
        const isTodayOrWeek = (id === 'today' || id === 'week');  
        if (!isTodayOrWeek) {  
            // 这里不需要再调用移除函数，因为已经在更新函数中处理了  
        }  
    
        this.showTaskEdit = false;  
        this.switchMenuMask();  
    },

    async updateTaskProjectIdfp(idft, oldIdfp, newIdfp, newTaskLevel) {  
        const token = localStorage.getItem('token');  
        try {  
            const response = await axios({  
                method: 'put',  
                url: 'https://api2.itaskid.com/my/updatetaskprojectidfp',  
                headers: {  
                    Authorization: token,  
                    'Content-Type': 'application/x-www-form-urlencoded'  
                },  
                data: { idft: idft, oldIdfp: oldIdfp, newIdfp: newIdfp, newTaskLevel: newTaskLevel }  
            });  
    
            console.log('修改任务项目关系成功！', response.data);  
        } catch (error) {  
            console.error('修改任务项目关系失败:', error);  
        }  
    },

    //更新任务关系记录，即我在某个任务上的记录
    updateTastAclRecord(newTask){
      this.updateTastAclRecordToSql(newTask)
    },



    closeEditTask(){
    },
    deleteTask(){
      this.tasklist=this.tasklist.filter(item=>item.idft!==this.selectedTask.idft)
      this.deleteTaskbyIdft(this.selectedTask.idft)
      this.showTaskEdit=false
    },
    taskeditMenuitemClicked(item){
      switch (item.id){
        case 1: {
          if(this.selectedTask.level<6) {
            const id=this.$route.params.idfp
            const today=this.convertDate(new Date())
            const starttime=(id=='today'||id=='week')?today:'2999/12/31'
            this.selectedTask.haschild=1
            const idft=uuidv4()
            const childTask={
              idft: idft,
              name:'',
              level: this.selectedTask.level+1,
              charger: this.username,
              parent:this.selectedTask.idft,
              starttime: starttime,
              endtime: starttime,
              starttimeatl:'2999/12/31',
              endtimeatl:'2999/12/31',
              pretask:'',
              haschild: null,
              expanded: 1,
              done: 0,
              project: this.selectedTask.project,
              permit_level:'charger',
              creater:this.username,
              sharedrange:'project',
              isshared:1
            }
            this.tasklistall.unshift(childTask)
            this.tasklist.unshift(childTask)
            this.addTaskToSql(childTask)
              .then((res)=>{
                console.log('res',res)
                const newOrderidTasks=this.tasklistall.filter(item=>item.project==childTask.project&&item.level==childTask.level)
                for(let i=0;i<newOrderidTasks.length;i++){
                  newOrderidTasks[i].orderid=i
                  this.updateTaskOrderidOfSql(newOrderidTasks[i])
                }
              })
            this.selectedTask.starttime=this.convertDate(this.selectedTask.starttime)
            this.updateTaskOfSql(this.selectedTask)
            const newTaskAcl={idftacl:uuidv4(),permit_level:'charger',username:this.username,task_idft:idft,taclcreater:this.username}
            this.addTaskAclToSql(newTaskAcl)
              .then((res)=>{
                this.myTaskAcllist.push(res)
              })
            
          }
          break
        }
        case 2: {

          break
        }
        case 3: {
          this.showTaskEdit=false
          this.showSummitTask=true
          break
        }
        case 8: {
          this.showTaskEdit=false
          if(this.selectedTask.permit_level==='charger') {
            this.tasklist=this.tasklist.filter(item=>item.idft!==this.selectedTask.idft)
            this.tasklistall=this.tasklistall.filter(item=>item.idft!==this.selectedTask.idft)
            this.deleteTaskbyIdft(this.selectedTask.idft)
            this.delChildTask(this.tasklist,this.selectedTask)
          } else {
            alert('您不具有删除权限！')
          }
          break
        }}
        
    },

    updateTaskAcl(taskAcl){
      this.updateTaskAclByUsernameAndIdft(taskAcl)
      this.myTaskAcllist = this.myTaskAcllist.map(item => {  
        if (item.username === taskAcl.username&&item.task_idft === taskAcl.task_idft) {
          return {
            ...item,
            permit_level: taskAcl.permit_level
          };
        }
        return item;
        });
    },

    delChildTask(tasks, parentTask) {
      let hasChildTasks = false;
      tasks.forEach((task) => {
        if (task.parent === parentTask.idft) {
          this.deleteTaskbyIdft(task.idft);
          const leftChildTasks = tasks.filter((item) => item.parent === task.idft);
          if (leftChildTasks.length > 0) {
            hasChildTasks = true;
            this.delChildTask(tasks, task);
          }
        }
      });
      if (!hasChildTasks) {
        return;
      }
    },
    //以上是与任务右键编辑菜单有关的方法

   //以下是任务增删改查相关操作的函数
   handleKeyDown(event,task) {
      if (event.target.value === '') {
        this.deleteTaskbyIdft(task.idft)
        this.delChildTask(this.tasklist,task)
      }
    },
   
    

    //以下是与任务时间编辑相关的方法


    //以下是任务详情相关的操作
    upDateStarttime(value){
      const starttime=this.convertDate(value)
      this.selectedTask.starttime=starttime
      this.updateTaskOfSql(this.selectedTask)
    },

    upDateEndtime(value){
      const endtime=this.convertDate(value)
      this.selectedTask.endtime=endtime
      this.updateTaskOfSql(this.selectedTask)
    },

    updateTaskdate(task) {
      this.$nextTick(function (task) {
      })
    },


    //以下是处理任务信息相关的函数
    sendTaskMessage(newTaskMessage){
      // this.socket.send(JSON.stringify(newTaskMessage));
      this.socket.emit('message', newTaskMessage, (response) => {
        })
    },

    //以下是通过任务权限获取任务信息
    getTasks(project){
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`
      let myTaskAcllist=[]
      this.getMyTaskAclList(username)
        .then((result)=>{
          if(result.length>0) {
            myTaskAcllist=result            
            const promises=myTaskAcllist.map(async (taskacl)=>{
              const task=await this.getTaskinfoByIdft(taskacl.task_idft)
              console.log('getTasks',task)  
              taskacl.name=task[0].name
              taskacl.duration=task[0].duration
              taskacl.endtime=task[0].endtime
              taskacl.pretask=task[0].pretask
              taskacl.level=task[0].level
              taskacl.parent=task[0].parent
              taskacl.project=(taskacl.project_idfp==''||taskacl.project_idfp==null||taskacl.project_idfp==undefined)?task[0].project:taskacl.project_idfp;
              taskacl.haschild=task[0].haschild
              taskacl.done=task[0].done
              taskacl.expanded=task[0].expanded
              taskacl.id=task[0].id
              taskacl.delete=task[0].delete
              taskacl.starttime=task[0].starttime
              taskacl.idft=task[0].idft
            })
            return Promise.all(promises)
          }
        })
          .then(()=>{
            if (Array.isArray(myTaskAcllist)){
              
              this.tasklistall=myTaskAcllist.reverse()
              const today=new Date()
              today.setHours(0, 0, 0, 0)
              if(project=='today'){
              this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
              this.tasklistDone=this.tasklistall.filter(task=>(task.project==nogroup)&&task.done===1)
              }else if (project=='week') {
                today.setHours(0, 0, 0, 0)
                const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
                this.tasklist=this.tasklistall.filter(task=>(JSON.stringify(new Date(task.starttime)&&task.done===0)
                ==JSON.stringify(oneWeekLater))||((new Date(task.starttime)<oneWeekLater)&&(task.starttime)))
                this.nogroupTasklist=this.tasklistall.filter(task=>(task.project==nogroup)&&task.done===0)
                this.tasklistDone=this.tasklistall.filter(task=>(task.project==nogroup)&&task.done===1)
              }else if(project=='inbox'){
                this.tasklist=this.tasklistall.filter(task=>task.project==nogroup&&task.done===0)
                this.tasklistDone=this.tasklistall.filter(task=>task.project==nogroup&&task.done===1)
              }else {
                
                this.tasklist=this.tasklistall.filter(task=>task.project==project&&task.done===0)
                
                
                this.tasklistDone=this.tasklistall.filter(task=>task.project==project&&task.done===1)
              }
              console.log('获取任务列表成功！')
            } 


          }) 
          .catch(function (error) {
            console.log(error?.response?.data?.message || 'An error occurred.');
          }) 
    },

    //以下是通过任务权限获取任务信息
    async getTasksByTaskacl() {
      try {
        const username = localStorage.getItem('username');
        const nogroup = `${username}nogroup`;
        let myTaskAcllist = [];
        const result = await this.getMyTaskAclList(username); 
        if (result.length > 0) {
          myTaskAcllist = result;
          const promises = myTaskAcllist.map(async (taskacl) => {
            const task = await this.getTaskinfoByIdft(taskacl.task_idft);
            console.log('getTasksByTaskacl',task)
            
            taskacl.name = task[0].name;
            taskacl.duration = task[0].duration;
            taskacl.endtime = task[0].endtime;
            taskacl.pretask = task[0].pretask;
            taskacl.level = task[0].level;
            taskacl.parent = task[0].parent;
            taskacl.project = (taskacl.project_idfp==''||taskacl.project_idfp==null||taskacl.project_idfp==undefined)?task[0].project:taskacl.project_idfp;
            taskacl.haschild = task[0].haschild;
            taskacl.done = task[0].done;
            taskacl.expanded = task[0].expanded;
            taskacl.id = task[0].id;
            taskacl.delete = task[0].delete;
            taskacl.starttime = task[0].starttime;
            taskacl.idft = task[0].idft;
            taskacl.record = task[0].record
            taskacl.orderid = task[0].orderid
            taskacl.position=task[0].position
            taskacl.unitotsk=task[0].unitotsk
            taskacl.qtyotsk=task[0].qtyotsk
            taskacl.qtyotsked=task[0].qtyotsked
            taskacl.starttimeatl=task[0].starttimeatl
            taskacl.endtimeatl=task[0].endtimeatl
            return taskacl
          });
        }
        return myTaskAcllist
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },
    
    async getTasksFromProject(project) {
      try {
        await this.getUsername();
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const user = { charger: username };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: user
        });
    
        if (Array.isArray(response.data)) {
          this.tasklistall = response.data.reverse();
          const today = new Date();
          today.setHours(0, 0, 0, 0);
    
          if (project == 'today') {
            this.tasklist = this.tasklistall.filter(task => new Date(task.starttime) <= today && task.done === 0);
            this.tasklistDone = this.tasklistall.filter(task => task.project == `${username}nogroup` && task.done === 1);
          } else if (project == 'week') {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
    
            this.tasklist = this.tasklistall.filter(task =>
              (JSON.stringify(new Date(task.starttime)) && task.done === 0) ==
              JSON.stringify(oneWeekLater) ||
              (new Date(task.starttime) < oneWeekLater && task.starttime)
            );
    
            this.nogroupTasklist = this.tasklistall.filter(task =>
              task.project == `${username}nogroup` && task.done === 0
            );
    
            this.tasklistDone = this.tasklistall.filter(task =>
              task.project == `${username}nogroup` && task.done === 1
            );
          } else {
            this.tasklist = this.tasklistall.filter(task =>
              task.project == project && task.done === 0
            );
    
            this.tasklistDone = this.tasklistall.filter(task =>
              task.project == project && task.done === 1
            );
          }
    
          console.log('获取任务列表成功！');
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    ...mapMutations({
      updateSelectProjName:'updateSelectProjName',
      updateUser: 'updateUser',
      switchMenuMask: 'switchMenuMask',
    }),

    async getSelectedTaskAcls() {
      let selectedTaskAcls = [];
    
      // 构建任务关系数组
      this.selectedProjectAcls.forEach((item) => {
        const taskAcl = {
          username: item.username,
          nickname: item.nickname,
          permit_level: item.permit_level,
          project_idfp: item.project_idfp,
          avatarUrl: item.avatarUrl,
          task_idft: this.selectedTask.idft
        };
        selectedTaskAcls.push(taskAcl);
      });
      try {
        // 获取指定任务ID的任务关系
        const results = await this.getTaskAclsByTaskIdft(this.selectedTask.idft);

        // 根据结果更新任务关系数组
        results.forEach((result) => {
          // 查找是否存在相同的 username
          const existingAclIndex = selectedTaskAcls.findIndex((acl) => acl.username === result.username);
          if (existingAclIndex !== -1) {
            // 如果存在相同的 username，则用 result 替换该元素
            result.avatarUrl = selectedTaskAcls[existingAclIndex].avatarUrl;
            selectedTaskAcls[existingAclIndex] = result;
          }
        });
    
        this.selectedTaskAcls = selectedTaskAcls;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },


    //只获取任务关系中的数据，而不通过项目关系来推导
    async getSelectedTaskAcls1() {
      // let selectedTaskAcls = [];
    
      // // 构建任务关系数组
      // this.selectedProjectAcls.forEach((item) => {
      //   const taskAcl = {
      //     username: item.username,
      //     nickname: item.nickname,
      //     permit_level: item.permit_level,
      //     project_idfp: item.project_idfp,
      //     avatarUrl: item.avatarUrl,
      //     task_idft: this.selectedTask.idft
      //   };
      //   selectedTaskAcls.push(taskAcl);
      // });
      try {
        // 获取指定任务ID的任务关系
        const results = await this.getTaskAclsByTaskIdft(this.selectedTask.idft);
        this.selectedTaskAcls = results;
        console.log('this.selectedTaskAcls',this.selectedTaskAcls)
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },

    closeTaskDetail(){
      this.ifShowTaskReports=true
    },

    async getSelectedTaskChatContacterWithMsgs(myusername, contact_username) {
    try {
      const token = localStorage.getItem('token'); // 使用 localStorage 获取 token  
      const data = {
        myusername: myusername,
        contact_username: contact_username
      };

      const response = await axios.get('https://api2.itaskid.com/my/gettaskchatcontacterwithmsgs', {
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data // 使用 params 传递 GET 请求参数  
      });

      if (response.data) {
        return response.data; // 返回聊天对象数组  
      } else {
        alert('账号未认证，无法获取数据！'); // 提示用户  
        return []; // 返回一个空数组表示数据为空  
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.'); // 打印错误信息  
      return []; // 返回一个空数组表示发生错误  
    }
  },

    
    goToTaskDetail(e,task){
      this.ifShowTaskReports=false
      this.isTaskCharger=this.getPermitLevel(task)
      //获取选中的任务对应的所有关系
      // this.getSelectedTaskAcls1()
      this.getTaskAclsByTaskIdft(task.idft)
        .then((result)=>{
          this.selectedTaskAcls=result
        })
      

      this.getResPlanlistByTask(task)
        .then((result)=>{
          if(result&&Array.isArray(result)){
            this.selectedTaskResPlans=result
          } else {
            this.selectedTaskResPlans=[]
          }
        })

      this.getProjectPartersFromTask(task)
      this.clickedInputIdft=task.idft
      this.selectedTask=task

      e.stopPropagation()
      localStorage.setItem('selectedTaskLocal',task.name)
      const oldtask=localStorage.getItem('selectedTaskLocal')
      this.$router.push({
        name: 'detail',
        params: {
          idft:task.idft,
          name: task.name
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })

      // this.getTaskMsgsByReceiver(task.idft)
      //   .then(async (result)=>{
      //     const processedResult = await this.processTaskMsgs(result);
      //     processedResult.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      //     this.taskContents = processedResult;
      //   })
      this.getSelectedTaskChatContacterWithMsgs(this.username,task.idft)
       .then(async (result)=>{
          console.log('result',result)
          if(result&&Array.isArray(result)&&result.length>0){
            this.selectedTaskChatContacter=result[0]
          } else {
            this.selectedTaskChatContacter=null
          }
          const processedResult = await this.processTaskMsgs(result[0]?.chatmsgs);
          processedResult.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
          this.taskContents = processedResult;
        })

      this.getTaskFilesByIdft(task.idft)
        .then((result)=>{
          if(result&&Array.isArray(result)){
            this.selectedTaskFiles=result
          } else {
            this.selectedTaskFiles=[]
          }
        })

      this.getTaskReportsByIdft(task.idft)
        .then((result)=>{
          if(result&&Array.isArray(result)){
            this.taskReports=result.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          } else {
            this.taskReports=[]
          }
        })

      
    },

    // async getTaskFilesByIdft(task_idft) {
    //   try {
    //     const token = localStorage.getItem('token');
    //     const data = { task_idft: task_idft };
    //     const response = await axios({
    //       method: 'get',
    //       url: 'https://api2.itaskid.com' + '/my/gettaskfilesbyidft',
    //       headers: {
    //         Authorization: token,
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       params: data
    //     });
  
    //     if (Array.isArray(response.data)) {
    //       return response.data;
    //     } else {
    //       alert('账号未认证，无法获取数据！');
    //       window.location.href = "https://www.itaskid.com" + "/login";
    //       return
    //     }
    //   } catch (error) {
    //     console.log(error?.response?.data?.message || 'An error occurred.');
    //   }
    // },

    async getTaskFilesByIdft(task_idft) {
      try {
        const token = localStorage.getItem('token');
        const data = { task_idft: task_idft };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com/my/gettaskfilesbyidft',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
    
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          // if (!response.data) {
          //   alert('未获取到任何数据，请检查输入的任务ID是否正确。');
          // } else {
          //   alert('账号未认证，无法获取数据！');
          //   window.location.href = "https://www.itaskid.com/login";
          // }
          return []; // 返回空数组作为默认值表示数据为空
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred');
        return []; // 返回空数组作为默认值表示发生错误
      }
    },

    // async getTaskSummitMsgs(task) {
    //   try {
    //     const token = localStorage.getItem('token');
    //     const data = { task_idft: task.idft };
    //     const response = await axios({
    //       method: 'get',
    //       url: 'https://api2.itaskid.com' + '/my/gettasksummitmsgsbyidft',
    //       headers: {
    //         Authorization: token,
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       params: data
    //     });
  
    //     if (Array.isArray(response.data)) {
    //       return response.data;
    //     } else {
    //       // alert('账号未认证，无法获取数据qia！');
    //       // window.location.href = "https://www.itaskid.com" + "/login";
    //       return []
    //     }
    //   } catch (error) {
    //     console.log(error?.response?.data?.message || 'An error occurred.');
    //   }
    // },

    async getTaskSummitMsgs(task) {
      try {
        const token = localStorage.getItem('token');
        const data = { task_idft: task.idft };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com/my/gettasksummitmsgsbyidft',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async processTaskMsgs(result) {
      // 确保 result 是一个可迭代的对象  
      if (result === null || result === undefined || !Array.isArray(result) || result.length === 0) {  
          return []  
      } 

      for (const msg of result) {
        if (msg.type === 'img') {
          const imgUrl = await this.showPic(msg.file_name, 'showmsgimg');
          msg.imgUrl = imgUrl;
        }
      }
      return result;  // Optionally return the modified result
    },

    async showPic(imgname,url) {
      if(imgname==null||imgname=='null'||imgname==''){
        return undefined
      } else {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api1.itaskid.com' + `/my/${url}`,
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }
    }, 



    async getProjectPartersFromTask(task) {
      try {
        // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
        var selectedProjectAcls = await this.getProjectAclListByIdfp(task.project);

        //以下部分代码合并了没有共享项目，但是直接参与了某个任务的任务关系
        var selectedTaskAcls=await this.getTaskAclsByTaskIdft(task.idft)
        selectedProjectAcls=selectedProjectAcls.concat(selectedTaskAcls)
        const uniqueAcls = []; // 用于存放去重后的数组
        const seenUsernames = {}; // 用于跟踪已经处理过的 username
        for (const acl of selectedProjectAcls) {
          if (!seenUsernames[acl.username]) {
            // 如果这个 username 还没有被处理过，则添加到结果数组中
            uniqueAcls.push(acl);
            // 标记这个 username 为已处理
            seenUsernames[acl.username] = true;
          }
        }
        // 此时，uniqueAcls 包含了去重后的元素
        selectedProjectAcls = uniqueAcls; // 用去重后的数组覆盖原数组（如果需要的话）
        //以上部分代码合并了没有共享项目，但是直接参与了某个任务的任务关系
        
        const promises = selectedProjectAcls.map(async (projectacl) => {
          const result = await this.getUserInfoByUsername(projectacl.username);
          const avatarUrl = await this.showPic(result.avatar,'showavatar');
          const nickname = result.nickname;
          projectacl.avatarUrl = avatarUrl;
          projectacl.nickname = nickname;
          // result.avatarUrl=avatarUrl
          // return result
        });
        await Promise.all(promises);
        this.selectedProjectAcls = selectedProjectAcls;
        return selectedProjectAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },


    addTaskFromInput(){
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`
      
      var newTask={}
      if(this.newTaskNameLv1.trim()!==''){
        const id=this.$route.params.idfp
        const idft=uuidv4()
        if(id=='today'||(id=='week')) {
          const today=new Date()
          const starttime=this.convertDate(today)
          newTask={
            idft:idft,
            name:this.newTaskNameLv1,
            duration: 1,
            starttime: starttime,
            endtime:starttime,
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:1,
            parent: 'roottask',
            project:nogroup,
            haschild:0,
            done:0,
            expanded:1,
            permit_level:'charger',
            creater:username,
            charger:username,
            sharedrange:'project',
            isshared:1
          }
        } else if(id=='inbox'){
          newTask={
            idft:idft,
            name:this.newTaskNameLv1,
            duration: null,
            starttime: '2999/12/31',
            endtime:'2999/12/31',
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:1,
            parent: 'roottask',
            project:nogroup,
            haschild:0,
            done:0,
            expanded:1,
            permit_level:'charger',
            creater:username,
            charger:username,
            sharedrange:'project',
            isshared:1
          }
        }
        else {
          newTask={
            idft:idft,
            name:this.newTaskNameLv1,
            duration: null,
            starttime: '2999/12/31',
            endtime:'2999/12/31',
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:1,
            parent: 'roottask',
            project:id,
            haschild:0,
            done:0,
            expanded:1,
            permit_level:'charger',
            creater:username,
            charger:username,
            sharedrange:'project',
            isshared:1
          }
        }
        this.tasklist.unshift(newTask)
        this.tasklistall.unshift(newTask)
        
        
        this.nogroupTasklist.unshift(newTask)
        this.addTaskToSql(newTask)  
          .then(()=>{
            const newOrderidTasks=this.tasklistall.filter(item=>item.project==newTask.project&&item.level==newTask.level)
            for(let i=0;i<newOrderidTasks.length;i++){
              newOrderidTasks[i].orderid=i
              this.updateTaskOrderidOfSql(newOrderidTasks[i])
            }
            // this.runAndMergeArrays()
          })
        const newTaskAcl={idftacl:uuidv4(),permit_level:'charger',username:this.username,task_idft:idft,taclcreater:this.username}
        this.addTaskAclToSql(newTaskAcl)
          .then((res)=>{
            this.myTaskAcllist.push(res)
          })
        
        
        this.newTaskNameLv1=''

        
        
      }
    },
   
  
    toggleNode(event,task){
      event.stopPropagation()
      task.expanded=!task.expanded
    },

    
    addBroTask(e,task) {
      if(task.name.trim()!==''){
        const username=localStorage.getItem('username')
        const nogroup=`${username}nogroup`
        const id=this.$route.params.idfp
        const idft=uuidv4()
        var newTask={}
        if(id=='today'||(id=='week')) {
          const today=new Date()
          const starttime=this.convertDate(today)
          newTask={
            idft:idft,
            name:'',
            duration: 1,
            starttime: starttime,
            endtime: starttime,
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:task.level,
            parent: task.parent,
            haschild:0,
            done:0,
            expanded:1,
            charger:username,
            project: task.project,
            permit_level:'charger',
            creater:username,
            sharedrange:'project',
            isshared:1
          }
        } else {
          newTask={
            idft:idft,
            name:'',
            duration: 1,
            starttime: '2999/12/31',
            endtime:'2999/12/31',
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:task.level,
            parent: task.parent,
            project:task.project,
            haschild:0,
            done:0,
            expanded:1,
            permit_level:'charger',
            creater:username,
            sharedrange:'project',
            isshared:1
          }
        }
        const taskIndex=this.tasklist.findIndex(item=>item.idft===task.idft)
        this.tasklist.splice(taskIndex+1,0,newTask)
        const index = this.tasklistall.findIndex(item => item.idft === task.idft)
        this.tasklistall.splice(index+1,0,newTask)
        this.addTaskToSql(newTask)
          .then(()=>{
            const newOrderidTasks=this.tasklistall.filter(item=>item.project==newTask.project&&item.level==newTask.level)
            for(let i=0;i<newOrderidTasks.length;i++){
              newOrderidTasks[i].orderid=i
              this.updateTaskOrderidOfSql(newOrderidTasks[i])
            }
          })
        const newTaskAcl={idftacl:uuidv4(),permit_level:'charger',username:this.username,task_idft:idft,taclcreater:this.username}
        this.addTaskAclToSql(newTaskAcl)
          .then((res)=>{
            this.myTaskAcllist.push(res)
          })
      }
      
    },
    
    

    // 数据库tasklist表的增删改查
    getUsername() {
      const token = localStorage.getItem('token')
      axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
        headers: { Authorization: token} // 在 headers 中添加 Authorization Token
      })
      .then(response => {
        this.username = response.data.username;
        const user={username: this.username}
        this.updateUser(user)
      })
      .catch(error => {
        console.log(error?.response?.data?.message || 'An error occurred.');
      });
    },

    // 添加某个任务
    addTaskSql(newTask){
      const token = localStorage.getItem('token')
      const idft=uuidv4()
      newTask.idft=(newTask.idft==undefined)?idft:newTask.idft
      newTask.starttime=this.convertDate(newTask.starttime)
      newTask.endtime=this.convertDate(newTask.endtime)
      axios({
        method:'post',
        url: 'https://api2.itaskid.com' + '/my/tasklist',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newTask
        })
        .then((response)=>{
          
          console.log('添加任务成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
        

    },

    //根据idft删除数据库中的数据
    deleteTaskbyIdft(idft){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/tasklist/deletebyidft',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {idft:idft}
        })
        .then((response)=>{
          console.log('删除任务成功！')
          this.deleteTaskAclbyIdft(idft)
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
        this.switchMenuMask()
    },


    //删除某个任务
    deleteTaskSql(url,data,tasks){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        // url: 'https://api2.itaskid.com' + '/my/tasklist',
        url: url,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: data
        })
        .then((response)=>{

          console.log('删除任务成功！')
          for(var i=0;i<tasks.length;i++){
            this.addTaskSql(tasks[i])
          }
          // this.addTasksRecursively(tasks, 0)
        })
        .catch(error => {
          // alert(error.response.data.message)
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
    },
    

    //通过parent属性删除任务并更新
    deleteTaskSqlByParent(url,data,tasks){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: url,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
        })
        .then((response)=>{
          console.log('删除任务成功！')
          for(var i=0;i<tasks.length;i++){
            this.addTaskSql(tasks[i])
          }
        })
        .catch(error => {
          // alert(error.response.data.message)
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
    },

    addTasksRecursively(tasks, index) {
      // 递归终止条件：当索引超出任务数组的长度时，停止递归
      if (index >= tasks.length) {
        console.log("所有任务添加完成");
        return;
      }
      // 添加当前索引对应的任务
      this.addTaskSql(tasks[index]);
      // 递归调用自身，将索引加一，继续添加下一个任务
      addTasksRecursively(tasks, index + 1);
    },

    updateTaskDone(task){
      if(task.done==1){
        this.doneTask(task)
        this.updateTaskOfSql(task)
      } else {
        task.done=0
        this.undoneTask(task)
        this.updateTaskOfSql(task)
      }

    },

    updateTaskThreedstatusByIdft(idft, threedstatus) {
      const token = localStorage.getItem('token')
      axios({
        method: 'put',
        url: 'https://api2.itaskid.com' + '/my/updatetaskthreedstatus',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: { idft: idft, threedstatus: threedstatus }
      })
        .then(response => {
          // console.log(response.data)
          console.log('修改项目三维状态成功！', response)
        })
        .catch(error => {
          console.log(error);
        });
    },

    updateTaskThreedStatus(status){
      this.selectedTask.threedstatus=status
      console.log('sdfasdfasdfasdf',status)
      this.updateTaskThreedstatusByIdft(this.selectedTask.idft,status)
      // console.log('this.selectedTask',this.selectedTask)
    },
    

    updateTaskOfSql(task){
      task.starttime=this.convertDate(task.starttime)
      task.endtime=this.convertDate(task.endtime)
      task.starttimeatl=this.convertDate(task.starttimeatl)
      task.endtimeatl=this.convertDate(task.endtimeatl)
      const token = localStorage.getItem('token')
   
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: task
          })
          .then((response)=>{
            console.log('修改任务成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },



    //以下是任务详情相关的增删改查的函数

    async getTaskContents(task) {
      try {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const user = { charger: username };
    
        const response = await axios.get('https://api2.itaskid.com' + '/my/taskcontents', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: { task_idft: task.idft }
        });

        this.taskContents = response.data
        return this.taskContents
    
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

      
      deleteTaskContentsBytaskid(task){
        const token = localStorage.getItem('token')
        const data={taskid: task.idft}
        axios({
          method:'delete',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
          })
          .then((response)=>{
            
            console.log('删除任务内容详情成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
      },      
    // //以上是任务相关的数据库增删改查

    updateTasksSqlByProjectAndParent(newTasks, project,parent) {
     
      // newTasks.forEach((task)=>{
      //   task.endtime=this.convertDate(task.endtime)
      // })
      if(newTasks.length>1) {
        const id=newTasks[newTasks.length-1].id
        const data={project:project, parent: parent}
        const url='https://api2.itaskid.com' + '/my/tasklist/deltaskbyprojectandparent'
        this.deleteTaskSql(url,data,newTasks)
      }else {
        for(var i=0;i<newTasks.length;i++){
        this.addTaskSql(newTasks[i])
      }
      } 
    },

    updateTasksSqlByParent(newTasks, parent) {
      if(newTasks.length>1) {
        const id=newTasks[newTasks.length-1].id
        const data={parent:parent}
        const url='https://api2.itaskid.com' + '/my/tasklist/deltaskbyparent'
        this.deleteTaskSql(url,data,newTasks)
      }else {
        for(var i=0;i<newTasks.length;i++){
        this.addTaskSql(newTasks[i])
      }
      } 
    },


    //把标准时间转换成普通的字符串时间
    // convertDate(targetDate){
    //   if(targetDate==''){
    //     targetDate='2999/12/31'
    //   }
    //   const date = new Date(targetDate);
    //   const year = date.getFullYear();
    //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //   const day = date.getDate().toString().padStart(2, '0');
      
    //   const formattedDate = `${year}/${month}/${day}`;
    //   return formattedDate
      
    // },

    convertDate(targetDate) {
      if (targetDate == '') {
        targetDate = '2999/12/31 23:59:59';  // 设置默认时间为23:59:59
      }
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
    
      const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },

    //以下是与完成任务相关的函数
    showDoneTaskToggle(){
      this.showDoneProject=!this.showDoneProject
    },
    showUndoneTaskToggle(){
      this.showUndoneProject=!this.showUndoneProject
    },

    toggleTaskDone(e,task){
      if(e.target.checked) {
        this.doneTask(task)
      } else {
        task.done=0
        this.updateTaskOfSql(task)
      }
    },

    doneTask(task){
      task.done=1
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`
      const today=new Date()
      if(this.clickedIdfp=='today') {
        this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
        this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
      } else if(this.clickedIdfp=='week'){
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
        this.tasklist = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 0);
        })

        this.tasklistDone = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 1);
        })
      } else {
        this.tasklistDone=this.tasklistall.filter(item=>item.done===1&&item.project==task.project)
        this.tasklist=this.tasklistall.filter(item=>item.done===0&&item.project==task.project)
      }
      this.updateTaskOfSql(task)
      if(task.level<6){
        this.tasklist.forEach((item)=>{
          if(item.parent===task.idft){
            this.doneTask(item)
          }else {
            return
          }
        })
      }
    },
  
    toggleTaskUndone(e,task){
      if(!e.target.checked) {
        this.undoneTask(task)
  
      } else {
        task.done=1
        this.updateTaskOfSql(task)
      }      
    },
    undoneTask(task){
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`
      const today=new Date()
      task.done=0
      
      if(this.clickedIdfp=='today') {
        this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
        this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
      } else if(this.clickedIdfp=='week'){
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
        this.tasklist = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 0);
        })

        this.tasklistDone = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 1);
        })
      }  else {
        this.tasklistDone=this.tasklistall.filter(item=>item.done===1&&item.project==task.project)
        this.tasklist=this.tasklistall.filter(item=>item.done===0&&item.project==task.project)
      }

      // this.tasklistDone=this.tasklistDone.filter(item=>item.done==1)
      // this.tasklist=this.tasklistall.filter(item=>item.project===task.project&&item.done==0)


      this.updateTaskOfSql(task)
      if(task.level>1){
        this.tasklistDone.forEach((item)=>{
          if(item.idft===task.parent){
            this.undoneTask(item)
          }else {
            return
          }
        })
      }
    },


    showPrjTasksReportsChartOfRisk(){
      this.reportChartOption=2
      // var reportRisk=[
      //   {value:0,name:'低风险'},
      //   {value:0,name:'中风险'},
      //   {value:0,name:'高风险'},
      //   {value:0,name:'无简报'},
      //   {value:0,name:'未到期'}
      // ]

      var reportProgress=[
        {value:0,name:'超前'},
        {value:0,name:'正常'},
        {value:0,name:'滞后'},
        {value:0,name:'无简报'},
        {value:0,name:'未开始'},
        {value:0,name:'暂停'},
      ]
      this.myTasksAhead=[]
      this.myTasksBehind=[]
      this.myTasksNormal=[]
      this.myTasksNoReport=[]
      this.myTasksNoStart=[]
      this.myTasksPaused=[]
      const myTasksReportsIdfts=this.selectedPrjTasksReportsOfToday.map(item=>item.task_idft)
      this.tasklist.forEach((item)=>{
        if(myTasksReportsIdfts.includes(item.idft)){
          const report=this.selectedPrjTasksReportsOfToday.find(item2=>item2.task_idft==item.idft)
          if(report.status=='超前'){
            reportProgress[0].value=reportProgress[0].value+1
            this.myTasksAhead.push(item)
          }else if(report.status=='正常'){
            reportProgress[1].value=reportProgress[1].value+1
            this.myTasksNormal.push(item)
          }else if(report.status=='滞后'){
            reportProgress[2].value=reportProgress[2].value+1
            this.myTasksBehind.push(item)
          }else if(report.status=='暂停'){
            reportProgress[5].value=reportProgress[5].value+1
            this.myTasksPaused.push(item)
          }
        }else{

          const now = new Date();  
          // 创建一个新的日期对象，设置时间为00:00:00  
          const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());  
          const taskStartDate = new Date(item.starttime);  
          // 将任务开始时间的时间部分设置为00:00:00  
          const taskStartDay = new Date(taskStartDate.getFullYear(), taskStartDate.getMonth(), taskStartDate.getDate());  
      
          // 比较日期  
          if (today>=taskStartDay) { 
            this.myTasksNoReport.push(item)
            reportProgress[3].value++ 
              // tasksNoStartSum++ 
          }else {
            this.myTasksNoStart.push(item)
            reportProgress[4].value++ 
          } 
        }
      })

      this.$nextTick(()=>{
        this.drawChartOfTasksReports(reportProgress,'任务进展情况')
      })
      
    },

    showPrjTasksReportsChartOfDistribution(){
      this.reportChartOption=6
      console.log('this.myChart6',this.myChart)
    },

    showPrjTasksReportsChartOfStatus(){
      
      this.reportChartOption=1
      var reportStatus=[
        {value:0,name:'未提交'},
        {value:0,name:'未到期'},
        {value:0,name:'已提交'}
      ]



      this.myTasksWithReports=[]
      this.myTasksNotStarted=[]
      this.myTaskWithNoReport=[]
      const todayReportIdfts=this.selectedPrjTasksReportsOfToday.map(item=>item.task_idft)
      this.tasklist.forEach((item)=>{
        const now=new Date()
        if(todayReportIdfts.includes(item.idft)){
          reportStatus[2].value++
          this.myTasksWithReports.push(item)
        }else if(new Date(item.starttime).getFullYear()==2999){
          reportStatus[1].value++
          this.myTasksNotStarted.push(item)
        }else if(new Date(item.starttime)>new Date(now)){
          reportStatus[1].value++
          this.myTasksNotStarted.push(item)
        }else {
          this.myTaskWithNoReport.push(item)
        }
      })




      reportStatus[0].value=this.tasklist.length-reportStatus[2].value-reportStatus[1].value
      this.$nextTick(()=>{
        this.drawChartOfTasksReports(reportStatus,'简报提交情况统计')
      })
      
    },


    showPrjTasksReportsChartOfFinish(){
      this.reportChartOption=4
      var reportFinished=[
        {value:0,name:'应完已完'},
        {value:0,name:'应完未完'},
        {value:0,name:'未到期'},
        {value:0,name:'无完成时间'}
      ]
      this.myTasksYetFinishedNot=[]
      this.myTasksNoFinishDay=[]
      this.myTasksNotFinish=[]
      this.myTasksFinished=[]

      reportFinished[0].value=this.selectedPrjTasks.length-this.tasklist.length
      this.myTasksFinished=this.selectedPrjTasks.filter(item=>item.done)
      this.tasklist.forEach((item)=>{
        const now=new Date()
        if(new Date(item.endtime)<=new Date(now)){
          reportFinished[1].value++
          this.myTasksYetFinishedNot.push(item)
        }else if(new Date(item.endtime).getFullYear()==2999){
          reportFinished[3].value++
          this.myTasksNoFinishDay.push(item)
        }else if((new Date(item.endtime)>new Date(now))) {
          reportFinished[2].value++
          this.myTasksNotFinish.push(item)
        }else {
          reportFinished[3].value++
          this.myTasksNoFinishDay.push(item)
        }
      })
      this.$nextTick(()=>{
        this.drawChartOfTasksReports(reportFinished,'任务完成情况')
      })
      // this.drawChartOfTasksReports(reportFinished,'任务完成情况')
    },

    drawChartOfTasksReports(data,subtext) {
      const text=this.selectedProject.groupname=='收集箱'?this.projectTitle:`${this.selectedProject.groupname}-${this.projectTitle}`
      var option = {
        title: {
          text: text,
          subtext: subtext,
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            data:data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show:true,
              formatter: '{b}: {c} ({d}%)',
              position:'outside'
            }
          }
        ]
      };
      // var myChart = this.$echarts.init(document.getElementById('main'));
      //检查myChart是否存在，若已存在，则把其销毁。

      if (this.myChart && typeof this.myChart.dispose === 'function') {  
        this.myChart.dispose(); // 销毁旧的图表实例  
      }


      // const reportChartOption=this.reportChartOption
      this.myChart = this.$echarts.init(document.getElementById('main'));
      console.log('this.myChart',this.myChart)
      this.myChart.on('click', (params)=> {
          console.log('params,',params,this.reportChartOption)
          const keyWord=params.name
          switch(this.reportChartOption){
            case 1: {
              
              if(keyWord=='未到期'){
                this.tasklistFromPartPie=this.myTasksNotStarted
              }else if(keyWord=='已提交'){
                this.tasklistFromPartPie=this.myTasksWithReports
              }else if(keyWord=='未提交'){
                this.tasklistFromPartPie=this.myTaskWithNoReport
              }

              this.showTaskListFromPie=true
              break
            }

            case 2: {
              if(keyWord=='超前'){
                this.tasklistFromPartPie=this.myTasksAhead
              }else if(keyWord=='正常'){
                this.tasklistFromPartPie=this.myTasksNormal
              }else if(keyWord=='滞后'){
                this.tasklistFromPartPie=this.myTasksBehind
              }else if(keyWord=='无简报'){
                this.tasklistFromPartPie=this.myTasksNoReport
              }else if(keyWord=='未开始'){
                this.tasklistFromPartPie=this.myTasksNoStart
              }else if(keyWord=='暂停'){
                this.tasklistFromPartPie=this.myTasksPaused
              }
              this.showTaskListFromPie=true
              break
            }

            case 4: {
              if(keyWord=='应完已完'){
                this.tasklistFromPartPie=this.myTasksFinished
              }else if(keyWord=='应完未完'){
                this.tasklistFromPartPie=this.myTasksYetFinishedNot
              }else if(keyWord=='未到期'){
                this.tasklistFromPartPie=this.myTasksNotFinish
              }else if(keyWord=='无完成时间'){
                this.tasklistFromPartPie=this.myTasksNoFinishDay
              }
              this.showTaskListFromPie=true
              break
            }
          }

      });
      //4.使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
    },


    drawChart(option) {
      // 检查 myChart 是否已经存在，如果存在则销毁它 
      if (this.myChart && typeof this.myChart.dispose === 'function') {  
          this.myChart.dispose(); // 销毁旧的图表实例  
      }  

      this.myChart = this.$echarts.init(document.getElementById('main'));
      this.myChart.on('click', (params)=>{
        const selectedCharger=this.selecetdPrjTaskChargers.find((charger)=>{
          if(charger.username==params.data.username){
            return charger
          }
        })
        const chargerIdfts=selectedCharger.taskArray
        const selectedChargerTasks=this.tasklist.filter((task)=>{
          if(chargerIdfts.includes(task.idft)){
            return task
          }
        })
        this.tasklistFromPartPie=selectedChargerTasks
        this.showTaskListFromPie=true
          // 控制台打印数据的名称
      });
      //4.使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
    },

    async getChargersForTasks(tasklist) {  
      const chargers = [];  

      // 遍历任务数组  
      for (const task of tasklist) {  
        const taskId = task.idft;  

        try {  
          const token = localStorage.getItem('token');  
          const response = await axios({  
            method: 'get',  
            url: 'https://api2.itaskid.com/my/getchargersbytaskidft',  
            headers: {  
              Authorization: token,  
              'Content-Type': 'application/x-www-form-urlencoded'  
            },  
            params: { task_idft: taskId }  
          }); 

          if (Array.isArray(response.data)) {  
            // 将每个负责人的信息添加到 chargers 数组中  
            response.data.forEach(charger => {  
              chargers.push({  
                task_idft: taskId,  
                username: charger.username,  
                nickname: charger.nickname  
              });  
            });  
          } else {  
            alert('账号未认证，无法获取数据！');  
            window.location.href = "https://www.itaskid.com/login";  
            return;  
          }  
        } catch (error) {  
          console.log(error?.response?.data?.message || 'An error occurred.');  
        }  
      }  

      this.selecetdPrjTaskChargers = chargers; // 更新组件的 chargers 数据  
      return chargers
    } ,


    async getChargersByTaskId(taskId) {  
        try {  
            const token = localStorage.getItem('token'); // 从 localStorage 获取 token  
            const response = await axios({  
                method: 'get',  
                url: 'https://api2.itaskid.com/my/getchargersbytaskidft', // 替换为实际的 API URL  
                headers: {  
                    Authorization: token,  
                    'Content-Type': 'application/x-www-form-urlencoded'  
                },  
                params: { task_idft: taskId } // 传递任务 ID  
            });
            
            console.log('responsesss',response)
    
            if (Array.isArray(response.data)) {  
                return response.data; // 返回负责人的信息数组  
            } else {  
                alert('账号未认证，无法获取数据！');  
                window.location.href = "https://www.itaskid.com/login"; // 重定向到登录页面  
                return;  
            }  
        } catch (error) {  
            console.error(error?.response?.data?.message || 'An error occurred.');  
        }  
    },

    getNoChildAndExpiredTasks(){
      const now=new Date()
      const noChildTasks=this.tasklist.filter(item=>{
        const hasChild=this.hasNoDoneChildOfMethod(item)
        if(!hasChild&&new Date(item.starttime)<=new Date(now)){
          return item
        }
      })


      const reportsIdfts=this.selectedPrjTasksReportsOfToday.map(item=>item.idft)
      const noReportTasks=noChildTasks.filter(item=>!reportsIdfts.includes(item.idft))

      noChildTasks.forEach((item)=>{
        const taskReportToday=this.selectedPrjTasksReportsOfToday.find(report=>report.task_idft==item.idft)
        if(taskReportToday){
          item.report_progress=taskReportToday.progress
          item.risklevel=taskReportToday.risklevel
          item.report_createdat=this.convertDate(taskReportToday.created_at)
          item.status=taskReportToday.status
          const reporters=this.selecetdPrjTaskChargers.filter(charger=>charger.taskArray.includes(item.idft))
          const nicknamesString = reporters.map(reporter => reporter.nickname).join(';');
          item.report_nickname=nicknamesString
        }else{
          item.report_progress=''
          item.risklevel='未知'
          item.report_createdat=''
          const reporters=this.selecetdPrjTaskChargers.filter(charger=>charger.taskArray.includes(item.idft))
          const nicknamesString = reporters.map(reporter => reporter.nickname).join(';');
          item.report_nickname=nicknamesString
          item.status='未知'
        }
      })

      return noChildTasks

      // this.selectedPrjNoChildTasks=noChildTasks
      // this.selectedPrjNoChildTasksFiltered=noChildTasks
    },
    
    
    showPrjTasksReportsSummit(){
      this.reportChartOption=3
      const taskIdftArray = this.selectedPrjTasksReportsOfToday.map(report => report.task_idft);

     //要计算没有子任务的，并且开始时间已到期的任务组
     const now=new Date()
     const noChildTasks=this.tasklist.filter(item=>{
       const hasChild=this.hasNoDoneChildOfMethod(item)
       if(!hasChild&&new Date(item.starttime)<=new Date(now)){
         return item
       }
     })
     const noChildTasksIdfts=noChildTasks.map(item=>item.idft)

     // 筛选出有任务的负责人  
     this.selecetdPrjTaskChargers = this.selecetdPrjTaskChargers.filter(item => item.taskArray.length > 0); 


      const summitRate=[]

      //生成每个负责人的提交率
      const chargerData = this.selecetdPrjTaskChargers.map(charger => {  
        const taskArray = charger.taskArray;  
        const commonCount = taskIdftArray.filter(taskIdft => taskArray.includes(taskIdft)).length;  
        const noChildAndExpiredChargeredTasks = taskArray.filter(taskIdft => noChildTasksIdfts.includes(taskIdft));  
        const myRate = commonCount / (noChildAndExpiredChargeredTasks.length || 1) * 100; // 避免除以 0  
        const myRateFormatted = myRate.toFixed(2);  
        summitRate.push(myRateFormatted);  
    
        // 返回包含 nickname 和 username 的对象  
        return {  
          nickname: charger.nickname,  
          username: charger.username,  
          rate: myRateFormatted  
        };  
      });  

      const text=this.selectedProject.groupname=='收集箱'?this.projectTitle:`${this.selectedProject.groupname}-${this.projectTitle}`

      var option = {
        title : {
          // text: '项目简报提交情况',  
          text:text,
          subtext: '任务负责人简报提交情况',  
          left: 'center'
        },
        tooltip:{
          formatter: params=>{
            const data = chargerData[params.dataIndex]
            return `负责人：${data.nickname}<br>提交率：${data.rate}%`
          }
        },
        xAxis: {
          type: 'category',
          data: chargerData.map(charger => charger.nickname)
        },
        yAxis: {
          min:0,
          max:100,
          axisLabel: {
            formatter: '{value}%'
          },
        },

        series: [
          {
            data: chargerData.map(charger => ({  
              value: charger.rate,  
              username: charger.username // 将 username 绑定到数据中  
            })),
            type: 'bar',
            label: {  
              show: true, // 显示数据标签  
              position: 'top', // 标签位置在柱子上方  
              formatter: '{c}%', // 格式化标签为百分比  
            },
          }

        ]
      };
      this.$nextTick(()=>{
        this.drawChart(option)
      })
    },

    showPrjTasksReportsList(){
      this.reportChartOption=5
      if (this.myChart && typeof this.myChart.dispose === 'function') {  
        this.myChart.dispose(); // 销毁旧的图表实例  
      }

      const allPrjTaskReports=[]
      
      const now=new Date()
      const noChildTasks=this.tasklist.filter(item=>{
        const hasChild=this.hasNoDoneChildOfMethod(item)
        if(!hasChild&&new Date(item.starttime)<=new Date(now)){
          return item
        }
      })
      const reportsIdfts=this.selectedPrjTasksReportsOfToday.map(item=>item.idft)
      const noReportTasks=noChildTasks.filter(item=>!reportsIdfts.includes(item.idft))

      noChildTasks.forEach((item)=>{
        const taskReportToday=this.selectedPrjTasksReportsOfToday.find(report=>report.task_idft==item.idft)
        if(taskReportToday){
          item.report_progress=taskReportToday.progress
          item.risklevel=taskReportToday.risklevel
          item.report_createdat=this.convertDate(taskReportToday.created_at)
          item.status=taskReportToday.status
          const reporters=this.selecetdPrjTaskChargers.filter(charger=>charger.taskArray.includes(item.idft))
          const nicknamesString = reporters.map(reporter => reporter.nickname).join(';');
          item.report_nickname=nicknamesString
          item.report_idftr=taskReportToday.idftr
        }else{
          item.report_progress=''
          item.risklevel=''
          item.report_createdat=''
          const reporters=this.selecetdPrjTaskChargers.filter(charger=>charger.taskArray.includes(item.idft))
          const nicknamesString = reporters.map(reporter => reporter.nickname).join(';');
          item.report_nickname=nicknamesString
          item.status=''
        }
      })

      this.selectedPrjNoChildTasks=noChildTasks
      this.reportFilterOpt='所有'
      this.selectedPrjNoChildTasksFiltered=noChildTasks
    },

    getNewNoChildTasks(){
      const noChildTasks=this.tasklist.filter(item=>{
        const hasChild=this.hasNoDoneChildOfMethod(item)
        if(!hasChild){
          return item
        }
      })


      noChildTasks.forEach((item)=>{
        const taskReportToday=this.selectedPrjTasksReportsOfToday.find(report=>report.task_idft==item.idft)
        if(taskReportToday){
          item.report_progress=taskReportToday.progress
          item.risklevel=taskReportToday.risklevel
          item.report_createdat=this.convertDate(taskReportToday.created_at)
          item.status=taskReportToday.status
          const reporters=this.selecetdPrjTaskChargers.filter(charger=>charger.taskArray.includes(item.idft))
          const nicknamesString = reporters.map(reporter => reporter.nickname).join(';');
          item.report_nickname=nicknamesString
        }else{
          item.report_progress=''
          item.risklevel='未知'
          item.report_createdat=''
          const reporters=this.selecetdPrjTaskChargers.filter(charger=>charger.taskArray.includes(item.idft))
          const nicknamesString = reporters.map(reporter => reporter.nickname).join(';');
          item.report_nickname=nicknamesString
          item.status='未知'
        }
      })
      return noChildTasks
    },


    showEditTaskReportMenu(task){
      this.selectedTaskWithReport=task
      if(task.report_progress==''){
        this.editTaskReportTitle='添加任务简报'
        this.editTaskStatus='正常'
        this.taskRiskLevel='低'
        this.newTaskReportProgress=''
        this.editTaskReportButton='提交'
      }else {
        this.editTaskReportTitle='修改任务简报'
        this.editTaskStatus=task.status
        this.taskRiskLevel=task.risklevel
        this.newTaskReportProgress=task.report_progress
        this.editTaskReportButton='修改'
      }
      this.ifShowEditTaskReportMenu=true
    },

    addOrUpdateTaskReport(event,task){
      const newProgress=event.target.innerText
      console.log('hen',newProgress,task.report_progress)

      //先判断任务的简报是否存在，如何存在，则更新简报，否则添加简报
      if(task.report_progress){
        task.report_progress=newProgress
        
      }else {
        task.report_progress=newProgress
        task.report_createdat=this.convertDate(new Date())
        task.status=''
        task.risklevel=''
        task.report_nickname=this.userinfo.nickname
      }
    },

    updateTaskReportStatus(task){
      if(task.report_progress){
        this.selectedPrjNoChildTasks = [...this.selectedPrjNoChildTasks]
        const taskReport=this.selectedPrjTasksReportsOfToday.find(item=>item.task_idft==task.idft)
        taskReport.status=task.status
        taskReport.updated_at=this.datetimeConvert(new Date())
        this.updateTaskReportByIdftr(taskReport)
      }else {
        
      }
        
    },

    updateTaskReportRiskLevel(task){
      if(task.report_progress){
        this.selectedPrjNoChildTasks = [...this.selectedPrjNoChildTasks]
        const taskReport=this.selectedPrjTasksReportsOfToday.find(item=>item.task_idft==task.idft)
        taskReport.risklevel=task.risklevel
        taskReport.updated_at=this.datetimeConvert(new Date())
        this.updateTaskReportByIdftr(taskReport)
      }else {
        
      }
    },

    startEditTaskReport(task){
      // const { report_progress, status, risklevel } = task;  
      
      // if (report_progress || (status && risklevel)) {  
      //   return;  
      // }  
      
      // alert('请先选择进度状态和风险等级'); 
    },

    summitEditTaskReportByTable(e,task){
      const newProgress=e.target.innerText
       if(task.report_progress){
        const taskReport=this.selectedPrjTasksReportsOfToday.find(item=>item.task_idft==task.idft)
        taskReport.task_idft=task.idft
        taskReport.progress=newProgress
        taskReport.status=task.status
        taskReport.risklevel=task.risklevel
        taskReport.reporter_username=this.username
        taskReport.updated_at=this.datetimeConvert(new Date())
        this.updateTaskReportByIdftr(taskReport)
       }else if(newProgress!=''){
        const now=new Date()
        const taskStatus=(task.status=='')?'正常':task.status
        const taskRiskLevel=(task.risklevel=='')?'低':task.risklevel
        const taskReport={
          idftr:uuidv4(),
          task_idft:task.idft,
          report_date:this.convertDate(now),
          status:taskStatus,
          progress:newProgress,
          created_at:this.datetimeConvert(now),
          updated_at:this.datetimeConvert(now),
          reporter_username:this.username,
          risklevel:taskRiskLevel,
          reporter_nickname:this.userinfo.nickname
        }
        task.status=taskStatus
        task.risklevel=taskRiskLevel
        task.report_progress=newProgress
        task.report_createdat=this.datetimeConvert(now)
        this.selectedPrjNoChildTasks = [...this.selectedPrjNoChildTasks]
        console.log('addTaskReport',taskReport)
        // this.addTaskReport(taskReport)
        this.selectedPrjTasksReportsOfToday.push(taskReport)
        this.addTaskReportToSql(taskReport)
       }else {
        console.log('bucaozuo')
        return
       } 
    },

    summitEditTaskReport(){
       if(this.editTaskReportTitle=='修改任务简报'){
        const taskReport=this.selectedPrjTasksReportsOfToday.find(item=>item.task_idft==this.selectedTaskWithReport.idft)
        taskReport.task_idft=this.selectedTaskWithReport.idft
        taskReport.progress=this.newTaskReportProgress
        taskReport.status=this.editTaskStatus
        taskReport.risklevel=this.taskRiskLevel
        taskReport.reporter_username=this.username
        taskReport.report_date=this.convertDate(taskReport.report_date)
        taskReport.created_at=this.datetimeConvert(taskReport.created_at)
        taskReport.updated_at=this.datetimeConvert(taskReport.updated_at)
        
        this.editTaskReport1(taskReport)
        this.updateTaskReportByIdftr(taskReport)
       }else if(this.editTaskReportTitle=='添加任务简报'){
        const now=new Date()
        const taskReport={
          idftr:uuidv4(),
          task_idft:this.selectedTaskWithReport.idft,
          report_date:this.convertDate(now),
          status:this.editTaskStatus,
          progress:this.newTaskReportProgress,
          created_at:this.datetimeConvert(now),
          updated_at:this.datetimeConvert(now),
          reporter_username:this.username,
          risklevel:this.taskRiskLevel,
          reporter_nickname:this.userinfo.nickname
        }
        this.addTaskReport(taskReport)
        this.addTaskReportToSql(taskReport)
       } 
       this.cancelEditTaskReportMenu()
    },

    cancelEditTaskReportMenu(){
      this.editTaskReportTitle=''
      this.editTaskReportButton=''
      this.newTaskReportProgress=''
      this.editTaskStatus=''
      this.taskRiskLevel=''
      this.ifShowEditTaskReportMenu=false
    },


    datetimeConvert(datetime){
      const date = new Date(datetime);
      const options = {timeZone: 'Asia/Shanghai', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
      const formattedTime = date.toLocaleString('zh-CN', options);
      return formattedTime;
    },

    //从饼图导航到任务列表->具体任务的函数
    goToTaskFromTaskPie(task){
      this.ifShowTaskDetail=true
      this.switchMenuMask()
      eventBus.$on('closeContexMenu', () => {
        this.ifShowTaskDetail=false
      })
    },



    getTaskDatasFromTaskPie(task){
        this.selectedTaskFromPie=task
        this.selectedTask=task
        console.log('sd',task)
        // this.getSelectedTaskAcls()
        this.getProjectPartersFromTask(task)
  
  
        this.getChatMsgsWithSenderInfoByReceiver(task.idft)
          .then((res)=>{
            console.log('msgs',res)
            this.selectedTaskMsgs=res
          })

  
        this.getResPlanlistByTask(task)
          .then((result)=>{
            this.selectedTaskResPlans=result
          })
  
        this.getTaskSummitMsgs(task)
          .then((result)=>{
            result.sort((a, b) => {
              return new Date(a.timestamp) - new Date(b.timestamp);
            });
            this.taskProcessMsgs = result;
          })
  
        this.getTaskFilesByIdft(task.idft)
          .then((result)=>{
            this.selectedTaskFiles=result
          })
        
        this.getTaskReportsByIdft(task.idft)
          .then((result)=>{
            if(result&&Array.isArray(result)){
              this.taskReports=result.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            } else {
              this.taskReports=[]
            }
          })
      },

    updateTaskRecord(task){
      const token = localStorage.getItem('token')
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/updatetaskrecord',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: task
          })
          .then((response)=>{
    
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },

    closeTaskListFromPie(){
      this.showTaskListFromPie=false
    },



    // 调整 textarea 高度  
    adjustTextareaHeight(event) {  
      // const textarea = event.target;  
      // textarea.style.height = 'auto'; // 重置高度  
      // textarea.style.height = textarea.scrollHeight + 'px'; // 根据内容动态调整高度  
    }, 
    
    handleTaskReportBlur(task){

    },

    // 处理失焦事件  
    // handleTaskReportBlur(task) {  
    //   if (!task.report_progress || task.report_progress.trim() === '') {  
    //     this.$set(task, 'report_progress', ''); // 强制清空以触发双向绑定  
    //     alert('简报内容不能为空！');  
    //     return;  
    //   }  

    //   // 新增或更新简报  
    //   if (!task.report_createdat) {  
    //     // 新增简报（新增的简报需要补充汇报时间）  
    //     this.addNewReport(task);  
    //   } else {  
    //     // 更新简报  
    //     this.updateReport(task);  
    //   }  
    // },  
    
    // // 处理进度状态下拉框改变  
    // handleTaskStatusChange(task) {  
    //   if (!task.status) {  
    //     alert('进度状态不能为空！');  
    //   }  
    // },  

    // // 处理风险等级下拉框改变  
    // handleTaskRiskLevelChange(task) {  
    //   if (!task.risklevel) {  
    //     alert('风险等级不能为空！');  
    //   }  
    // },  



    

    


    // showReportRiskChart(){
    //   var reportRisk=[
    //     {value:0,name:'低风险'},
    //     {value:0,name:'中风险'},
    //     {value:0,name:'高风险'},
    //     {value:0,name:'无简报'}
    //   ]
    //   this.selectedPrjTasksReportsOfToday.forEach((item)=>{
    //     if(item.risklevel=='低'){
    //       reportRisk[0].value=reportRisk[0].value+1
    //     }else if(item.risklevel=='中'){
    //       reportRisk[1].value=reportRisk[1].value+1
    //     }else if(item.risklevel=='高'){
    //       reportRisk[2].value=reportRisk[2].value+1
    //     }
    //   })
    //   reportRisk[3].value=this.tasklist.length-this.selectedPrjTasksReportsOfToday.length
    //   this.drawChartOfTasksReports(reportRisk,'任务风险等级')
    // },
  },
}
</script>

<style lang="less" scoped>

.project-treecontainer {
  // position:relative;
  overflow: hidden;
  .router-link:hover {
  text-decoration: none;
  overflow: hidden;
}

h3  {
  height: 30px;
  margin: 10px 0 10px 20px;
}

.input-add-proj { 
  margin-left:0;
  margin-bottom: 10px;
  padding-left:10px;
  width: 220px;
  height: 30px;
  border: none;
  background-color: rgba(250, 250, 250, 0.9);
  border-radius: 5px;
}

input.input-add-proj:focus {
  // border: 1px solid rgb(64, 158, 255) !important;
  outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
}
.project-container-leftcol {
  // width: 300px;
  overflow: auto;
  // height: 100vh;
  // border-right: 1px solid rgba(200, 200, 200, 0.9);

  .taskinputclicked {
    color: rgb(71, 114, 250);
  }

  .task-container {
    height: 30px;
    display: flex;
    
    .el-icon-arrow-right {
      line-height: 5px;
    }

    .el-icon-arrow-down {
      line-height: 5px;
    }    

    .tasktime-container {
      display: inline;
      height: 30px;
      line-height: 30px;
    }

    .my-date-picker {
      width: 100px;
      height: 30px;
    }

    // .taskinfo-container {
    //   // width: 280px;
    //   line-height: 30px;
    //   height: 30px;
    //   text-align: justify;;
    //   box-sizing: border-box;
    //   display: inline flex;
    //   white-space: nowrap;
    //   overflow: hidden;
    // }
    
    // margin-right:5px;
    .input-taskname { 
      flex: 1;
      float: left;
      line-height: 29px;
      height: 29px;
      background-color: transparent;
      border: none;
      outline:none;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 0px;
      padding: 0px !important;
      border-bottom: 1px solid rgba(200, 200, 200, 0.9);
    }
    .task-starttime {
      height: 29px;
      line-height: 30px;
      float: right;
      flex: 0 0 auto;
      text-align: right;
      border-bottom: 1px solid rgba(200, 200, 200, 0.9);
      font-size: 12px;
      color: rgb(71, 114, 250)
      
    }
  
  }
  .task-container:hover {
    background-color: rgb(246, 248, 255);
    border-radius: 3px;
  }
  .task-done {
    margin: 3px;
  }

  button {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 3px
  }
  .task-title {
    line-height: 30px;
    display: inline-block;
    text-align: left;
    border-bottom: 0.5px solid rgba(200, 200, 200, 0.9);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .task-title:focus {
    outline: none;
  }



  ul {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
    margin: 0;
  }

  li {
    list-style: none;
    padding-inline-start: 10px;
    
  }
  // li:hover {
  //   background-color: rgb(200, 200, 200);
  // }

  button {
    border:none;
    background: none;
    color: gray;
  }
  button:hover {
    color: black;
  }
  .routerlink {
    height: 30px;
  }

  .my-date-picker {
    width: 0;
    height: 0;
    overflow: hidden;
    background: transparent;
  }
}

  
.projectdone-container {
  text-align: left;
  color: gray;
  .input-taskname {
    color: gray;
  }
  .task-done:checked input {
    background-color: gray !important;
  }
}
.alltasks-container {
  height: calc(100vh - 200px);
  overflow: auto;
}

.search-button {
  border:none;
  background-color: rgb(250, 250, 250);
  height: 30px;
  float:right;
  border-radius: 10%;
}

.add-button {
  border:none;
  background-color: rgb(250, 250, 250);
  height: 30px;
  float:left;
  border-radius: 10%;
}

.search-button:hover {
  background-color: rgb(241, 243, 244);
}

}

.reportinput {
  position:absolute;
  padding: 10px;
  z-index: 100;
  border-radius: 5px;
  top:calc(50% - 50px);
  left:calc(50% - 200px);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Adding shadow */  
}

.taskreportprogress {
    width:100%;
    min-height:100px;
    margin-top:10px; 
    outline:rgb(228, 231, 237);
  }

.taskreportprogress:focus {  
  border-color: lightblue; /* 设置焦点时的边框颜色为浅蓝色 */  
  outline: none; /* 去掉默认的轮廓 */  
}

.taskDetailContainer {
    position: absolute;  
    text-align: center;
    top: 70px;  
    left: 150px;  
    z-index: 1001;  
    width: 1000px;  
    height: calc(100vh - 100px);  
    line-height: 30px;
    background: #f9f9f9; /* 更新为柔和的背景色 */  
    border: 1px solid #ccc; /* 添加边框 */  
    border-radius: 8px; /* 添加圆角 */  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */  
    overflow: auto; /* 允许溢出内容滚动 */ 
  }

  //点击饼图显示的任务列表菜单的样式
  .overlay {  
    position: fixed;  
    top: 0;  
    left: 0;  
    width: 100%;  
    height: 100%;  
    background: rgba(0, 0, 0, 0.5);  
    z-index: 99;  
  }

  .task-list {  
    position: absolute;  
    width: 600px;  
    max-height: calc(100vh - 150px);  
    top: 50%;  
    left: 50%;  
    transform: translate(-50%, -50%); 
    background: #fff;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  
    border-radius: 8px;  
    padding: 10px;  
    z-index: 999;  
    overflow-y: auto;  
  }  
  .task-list ul {  
    list-style: none;  
    padding: 0;  
    margin: 0;  
  }  
  .task-list li {  
    margin: 5px 0;  
  } 



  .reportprogress {
    border: 0.5px solid grey; 
    padding: 2px;
    line-height:20px;
    height:100%;
    text-align: left;
  }

  .reportprogress:focus {
    outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
  }


</style>