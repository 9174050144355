<template>
  <el-container class="home-container" >
    <el-header style="position:relative;">
      <div>
        <!-- <div v-if="userinfo.avatar=='null'">dsdf</div> -->
        <div class="useravatar-container" @click="triggerFileUpload">
          <img :src="myAvatarUrl" alt="" v-if="myAvatarUrl!==undefined">
          <div v-if="myAvatarUrl==undefined" class="alt-avatar">
            {{ altNikname }}
          </div>
          <i class="el-icon-camera"></i>
          <input type="file" ref="fileInput" @change="handleFileChange" style="display: none;" accept="image/*">
        </div>
        <div style="width: 600px;margin: auto; display: inline;">
          <el-input placeholder="请输入内容" v-model="input3" class="input-with-select" style="width:600px;"
             @change="switchSearchInput" @focus="handleFocus">
            <el-select v-model="select" slot="prepend"   style="width: 100px;" >
              <el-option label="任务" value="任务"></el-option>
              <!-- <el-option label="信息" value="信息"></el-option> -->
              <el-option label="文件" value="文件"></el-option>
              <!-- <el-option label="表单" value="5"></el-option> -->
              <el-option label="好友" value="好友"></el-option>
            </el-select>
            <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
          </el-input>
        </div>
      </div>
      <div v-if="ifShowTaskDetail" class="taskDetailContainer">
        <DetailForHome :selectedProjectAcls="selectedProjectAcls" :selectedTaskResPlans="selectedTaskResPlans" :taskProcessMsgs="taskProcessMsgs"
          :selectedTask="selectedTask"  :taskContents="selectedTaskMsgs" :selectedTaskFiles="selectedTaskFiles" :taskReports="taskReports"
          @updateTaskRecord="updateTaskRecord" @upDateEndtime="upDateEndtime"  @sendTaskMessage="sendTaskMessage" @addLocalTaskSummitMsg="addLocalTaskSummitMsg"
          @addLocalTaskFileData=addLocalTaskFileData @upDateStarttime="upDateStarttime" @deleteTaskReport="deleteTaskReport"
          @editTaskReport="editTaskReport" @addTaskReport="addTaskReport" @deleteLocalTaskFile="deleteLocalTaskFile"> 
        </DetailForHome>
      </div>
      <div v-if="input3!==''&&ifShowSearchResult" class="searchResultContainer">
        <div v-if="select=='好友'">
          <div class="searchItemsLabel">好友</div>
          <table style="overflow:auto;">
            <tr v-for="friendship in  allMySearchedFriendShips" :key="friendship.id" class="searchFriendRow" @click="goToFriendChatContacter(friendship)">
              <td>
                <img :src="friendship.avatarUrl" alt="av" :style="{ width: '40px', height: '40px', borderRadius: '5%' }"
                  v-if="friendship.avatarUrl!==undefined">
                <div v-if="friendship.avatarUrl==undefined" class="friendmsg-altavatar">
                 {{ showAltNickname(friendship.friend_nickname) }}
                </div>
              </td>
              <td>&nbsp;&nbsp;{{ friendship.friend_commentname }}</td>
            </tr>
          </table>
        </div>
        <div v-if="select=='任务'" style="height:calc(100vh - 150px);overflow:auto;">
          <!-- <table class="filecontenttable">
            <tr class="tableheader">
              <td>完成状态</td>
              <td>任务名称</td>
              <td style="margin-left:10px;">项目组名</td>
              <td style="margin-left:10px;">项目名称</td>
            </tr>
          </table> -->
          <table class="filecontenttable">
            <tr class="tableheader" >
              <td style="margin-left:10px;">完成状态</td>
              <td>任务名称</td>
              <td style="margin-left:10px;">项目组</td>
              <!-- <el-dropdown trigger="click" style="z-index: 9999;">  
                <el-button>  
                  筛选 <i class="el-icon-arrow-down"></i>  
                </el-button>  
                <el-dropdown-menu slot="dropdown">  
                  <el-dropdown-item  
                    v-for="(option, index) in filterOptions"  
                    :key="index"  
                    @click="filter(option.value)"  
                  >  
                    {{ option.label }}  
                  </el-dropdown-item>  
                </el-dropdown-menu>  
              </el-dropdown>   -->
              <td style="margin-left:10px;">项目名称</td>
            </tr>
            <tr v-for="task in allMySearchedTasks" :key="task.idft">
              <td>
                <input type="checkbox" v-model="task.done" disabled>
              </td>
              <td class="searchFileName" @mouseup="goToTaskDetail(task)"  @mousedown.stop="handleMousedownTask($event,task)" style="max-width:300px;">{{ task.name }}</td>
              <td style="margin-left:10px;">{{ task.prjgroup_altname }}</td>
              <td style="margin-left:10px;">{{ task.project_altname }}</td>
              <!-- <td>
                <i class="el-icon-chat-dot-square" style="margin-left:20px;"></i>
                <i class="el-icon-share" style="margin-left:20px;"></i>
                <i></i>
              </td> -->
            </tr>
          </table>
        </div>
        <div v-if="select=='文件'" >
          <table class="filecontenttable">
            <tr class="tableheader">
              <td style="width: 100px;">文件类型</td>
              <td style="width: 350px;">文件名</td>
              <td>操作</td>
            </tr>
            <tr v-for="file in allMySearchedPrivateFiles" :key="file.idfpf" >
              <td style="width: 100px;">个人文件</td>
              <td class="searchFileName">{{file.filename}}</td>
              <td colspan="2">
                <i class="el-icon-download" @click="downLoadPrivateFiles(file)"></i>
                <!-- <i class="el-icon-chat-dot-square" style="margin-left:20px;"></i>
                <i class="el-icon-share" style="margin-left:20px;"></i> -->
              </td>
            </tr>
            <tr v-for="file in allMySearchedPubFiles" :key="file.idff" >
              <td style="width: 100px;">发布文件</td>
              <td  class="searchFileName">{{file.filename}}</td>
              <td colspan="2">
                <i class="el-icon-download"  @click="downLoadPubFiles(file)"></i>
                <i class="el-icon-chat-dot-square" style="margin-left:20px;"></i>
                <!-- <i class="el-icon-share" style="margin-left:20px;"></i> -->
              </td>
            </tr>
            <tr v-for="file in allMySearchedTaskFiles" :key="file.idftf" >
              <td style="width: 100px;">任务文件</td>
              <td  class="searchFileName">{{file.filename}}</td>
              <td colspan="2">
                <i class="el-icon-download"   @click="downLoadTaskFiles(file)"></i>
                <!-- <i class="el-icon-chat-dot-square" style="margin-left:20px;"></i> -->
                <!-- <i class="el-icon-share" style="margin-left:20px;"></i> -->
              </td>
            </tr>
            <tr v-for="file in allMySearchedShareFiles" :key="file.idfsf" >
              <td style="width: 100px;">共享文件</td>
              <td  class="searchFileName"  @click="openShareFileUrl(file)">{{file.filename}}</td>
              <td colspan="2" @click="copyShareFileUrl(file)">
                <i class="el-icon-share"></i>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </el-header>
    <el-container style="background-color: rgba(35,44,73,0.5);">
      <el-aside style="width: 60px; background-color: rgb(90, 96, 119);" >
        <div style="height: 10px;"></div>
        <div class="box" v-for="(tag,index) in tags" :key="index" style="height: 60px;" @click="showBox(index)">
          <i :class="tag.class" :style="{ color: tag.color }"></i><br>
          <router-link :to="tag.to" :style="{ color: tag.color }">{{tag.name}}</router-link>
        </div>
      </el-aside>
      <el-main style="padding:8px;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
  </template>
  
  <script>
  import axios from 'axios'
  import MyAside from './MyAside.vue'
  import MyHeader from './MyHeader.vue'
  import DetailForHome   from './menu/task/DetailForHome.vue'
  import { mapState , mapMutations,mapActions} from 'vuex'
  import { eventBus } from '../main.js'
  import io from 'socket.io-client'
  import { v4 as uuidv4 } from 'uuid'

  
  export default {
    name: 'Home',
    data() {
      return {
        editable: true,
        tags: [
          {class:'el-icon-chat-dot-square',to:'/home/friendshipgroup',name: '信息',color: 'rgb(255,255,255)'},
          {class:'el-icon-circle-check',to:'/home/taskgroup',name: '任务',color: 'rgb(255,255,255)'},
          {class:'el-icon-date',to:'/home/calendar',name: '日历',color: 'rgb(255,255,255)'},
          {class:'el-icon-edit-outline',to:'/home/help',name: '计划',color: 'rgb(255,255,255)'},
          
          {class:'el-icon-suitcase',to:'/home/filegroup',name: '文件',color: 'rgb(255,255,255)'},
          // {class:'el-icon-suitcase',to:'/home/filegroup',name: '表单',color: 'rgb(255,255,255)'},
          {class:'el-icon-coin',to:'/home/plan', name: '资源',color: 'rgb(255,255,255)'},
          {class:'el-icon-user',to:'/home/connect',name: '通讯',color: 'rgb(255,255,255)'},
          // {class:'el-icon-setting',to:'/home/setting',name: '设置',color: 'rgb(255,255,255)'},
          // {class:'el-icon-setting',to:'/home/user',name: '用户',color: 'rgb(255,255,255)'},
        ],
        activeTagIndex: -1,
        input1: '',
        input2: '',
        input3: '',
        select: '任务',
        myAvatarUrl:'',
        userinfo:null,
        altNikname:'',
        ifShowSearchResult:false,
        allMySearchedPrivateFiles:[],
        allMySearchedPubFiles:[],
        allMySearchedTaskFiles:[],
        allMySearchedShareFiles:[],
        allMySearchedTasks:[],
        myTasks:[],
        pubfiles:[],
        myPubFiles:[],
        taskfiles:[],
        myTaskFiles:[],
        shareFiles:[],
        myShareFiles:[],

        ifShowTaskDetail:false,
        selectedTask:null,
        selectedTaskResPlans:[],
        selectedTaskMsgs:[],
        selectedProjectAcls:[],
        selectedTaskResPlans:[],
        taskProcessMsgs:[],
        selectedTaskFiles:[],
        taskReports:[],
        socket: null,

        myFriendShips:[],

        filterOptions: [  
          { label: '选项 1', value: 'option1' },  
          { label: '选项 2', value: 'option2' },  
          { label: '选项 3', value: 'option3' },  
          { label: '选项 4', value: 'option4' }  
        ],
      }
    },
    components: {
      MyAside,
      MyHeader,
      DetailForHome
    },

    methods: {
      ...mapMutations({
        switchMenuMask: 'switchMenuMask',
        updateMyItemsProjects: 'updateMyItemsProjects' // 映射 mutation
      }),

      ...mapActions({
        // downloadFileFromServer:'downloadFileFromServer',
        // getFileinfoByIdff:'getFileinfoByIdff',
        // addFileUserAclSql: 'addFileUserAclSql',
        getProjectAclListByIdfp:'getProjectAclListByIdfp',
        getUserInfoByUsername:'getUserInfoByUsername',
        // showPic:'showPic',
        getPubfilesByProject:'getPubfilesByProject',
        getSharefilesByProject:'getSharefilesByProject',
        getMyProjectAclList:'getMyProjectAclList',
        getTasksByTaskProjects:'getTasksByTaskProjects',
        getResPlanlistByTask:'getResPlanlistByTask',
        // getMyProjectListByIdfp:'getMyProjectListByIdfp',
        // addChatmsg:'addChatmsg',
        updatePubfileByIdff:'updatePubfileByIdff',
        // getChatmsgsByReceiverAndAboutwhat:'getChatmsgsByReceiverAndAboutwhat',
        getChatMsgsWithSenderInfoByReceiver:'getChatMsgsWithSenderInfoByReceiver',
        getTaskSummitMsgs:'getTaskSummitMsgs',
        getTaskReportsByIdft:'getTaskReportsByIdft',
        getTaskFilesByIdft:'getTaskFilesByIdft',
        addChatContacter:'addChatContacter',
        // getFriendships:'getFriendships'
      }),

      async getFriendshipsByUserId(id,token) {
        try {
          const data = { user_id: id };
          const response = await axios.get('https://api2.itaskid.com' + '/my/friendshipsbyuserid', {
            headers: {
              Authorization: token,
              // 'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: data
          });
          if (Array.isArray(response.data)) {
            return response.data
          } else {
            return []
          }
  
        } catch (error) {
          console.log(error?.response?.data?.message || 'An error occurred.')
        }
      },


      openShareFileUrl(file){
        if (file && file.url) {
          // 验证 URL 是否完整
          let url = file.url;
          if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'http://' + url; // 或 'https://'（取决于你的需求）
          }
          window.open(url, '_blank');
        } else {
          console.error('No URL selected');
        }
      },

      copyShareFileUrl(file){
        // console.log('file',file)
        navigator.clipboard.writeText(file.url)
      },

      deleteLocalTaskFile(file){
        this.selectedTaskFiles=this.selectedTaskFiles.filter(item=>item.idftf!==file.idftf)
      },

      upDateStarttime(value){
        const starttime=this.convertDate(value)
        this.selectedTask.starttime=starttime
        this.updateTaskOfSql(this.selectedTask)
      },

      addLocalTaskSummitMsg(msg){
        this.taskProcessMsgs.push(msg)
      },

      addLocalTaskFileData(file){
        this.selectedTaskFiles.push(file)
      },

      addTaskReport(taskReport){
        this.taskReports.unshift(taskReport)
      },

      sendTaskMessage(newTaskMessage){
        newTaskMessage.sockettype='taskchat'
        
        this.socket.emit('message', newTaskMessage, (response) => {
  
        })
        
      },

      deleteTaskReport(taskReport){
        this.taskReports=this.taskReports.filter(item=>item.idftr!==taskReport.idftr)
      },

      editTaskReport(newTaskReport){
        const taskReport=this.taskReports.find(item=>item.idftr==newTaskReport.idftr)
        taskReport.progress=newTaskReport.progress
        taskReport.status=newTaskReport.status
        taskReport.updated_at=newTaskReport.updated_at
        taskReport.risklevel=newTaskReport.risklevel
      },

      addTaskReport(taskReport){
        this.taskReports.unshift(taskReport)
      },

      updateTaskOfSql(task){
        task.starttime=this.convertDate(task.starttime)
        task.endtime=this.convertDate(task.endtime)
        task.starttimeatl=this.convertDate(task.starttimeatl)
        task.endtimeatl=this.convertDate(task.endtimeatl)
        const token = localStorage.getItem('token')
        axios({
            method:'put',
            url: 'https://api2.itaskid.com' + '/my/tasklist',
            headers: {
              Authorization: token,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: task
            })
            .then((response)=>{
            })
            .catch(error => {
              alert(error?.response?.data?.message || 'An error occurred.')
            })
      },

      convertDate(targetDate) {
        if (targetDate == '') {
          targetDate = '2999/12/31 23:59:59';  // 设置默认时间为23:59:59
        }
        const date = new Date(targetDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
      
        const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
      },

      upDateEndtime(value){
        const endtime=this.convertDate(value)
        this.selectedTask.endtime=endtime
        this.updateTaskOfSql(this.selectedTask)
      },

      updateTaskRecord(task){
        const token = localStorage.getItem('token')
        axios({
            method:'put',
            url: 'https://api2.itaskid.com' + '/my/updatetaskrecord',
            headers: {
              Authorization: token,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: task
            })
            .then((response)=>{
      
            })
            .catch(error => {
              alert(error?.response?.data?.message || 'An error occurred.')
            })
      },

      async getProjectPartersFromTask(task) {
        try {
          
          // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
          const selectedProjectAcls = await this.getProjectAclListByIdfp(task.project);
          
          const promises = selectedProjectAcls.map(async (projectacl) => {
            const result = await this.getUserInfoByUsername(projectacl.username);
            // console.log('avatarUrl',result.avatar)
            const avatarUrl = await this.showPic(result.avatar,'showavatar');
            console.log('avatarUrl',avatarUrl)
            const nickname = result.nickname;
            projectacl.avatarUrl = avatarUrl;
            projectacl.nickname = nickname;
          });
          await Promise.all(promises);
          this.selectedProjectAcls = selectedProjectAcls;
          return selectedProjectAcls
        } catch (error) {
          // 处理错误
          console.error(error);
        }
      },

      handleMousedownTask(event,task){
        this.selectedTask=task
        // this.getSelectedTaskAcls()
        this.getProjectPartersFromTask(task)
  
  
        this.getChatMsgsWithSenderInfoByReceiver(task.idft)
          .then((res)=>{
            console.log('msgs',res)
            this.selectedTaskMsgs=res
          })

  
        this.getResPlanlistByTask(task)
          .then((result)=>{
            this.selectedTaskResPlans=result
          })
  
        this.getTaskSummitMsgs(task)
          .then((result)=>{
            result.sort((a, b) => {
              return new Date(a.timestamp) - new Date(b.timestamp);
            });
            this.taskProcessMsgs = result;
          })
  
        this.getTaskFilesByIdft(task.idft)
          .then((result)=>{
            this.selectedTaskFiles=result
          })
        
        this.getTaskReportsByIdft(task.idft)
          .then((result)=>{
            if(result&&Array.isArray(result)){
              this.taskReports=result.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            } else {
              this.taskReports=[]
            }
          })
      },

      goToTaskDetail(task){
        // this.ifShowTaskDetail=true
        this.selectedTask=task
        console.log('task',task)
        this.ifShowSearchResult=false
        this.ifShowTaskDetail=true
        // this.switchMenuMask()

      },

      async getFriendChatContacterByFriendUsername(username,contact_username) {
      try {
        const token = localStorage.getItem('token'); // 使用 localStorage 获取 token  
        const data = {
          myusername: username,
          contact_username: contact_username
        };
  
        const response = await axios.get('https://api2.itaskid.com/my/getfriendchatcontacterbyfriendusername', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data // 使用 params 传递 GET 请求参数  
        });
  
        if (Array.isArray(response.data)) {
          return response.data; // 返回聊天对象数组  
        } else {
          alert('账号未认证，无法获取数据！'); // 提示用户  
          return []; // 返回一个空数组表示数据为空  
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.'); // 打印错误信息  
        return []; // 返回一个空数组表示发生错误  
      }
    },

      goToFriendChatContacter(friendship){
        const friendUsername = friendship.friend_username;  
      const myChatContacters = this.$store.state.myChatContacters;  
    
      // 创建新的聊天联系人对象  
      const createNewChatContacter = () => {  
        return {  
          chatmsgs: [],  
          idfcc: uuidv4(),  
          myusername: this.username,  
          contact_username: friendUsername,  
          last_interaction: this.datetimeConvert(new Date()),  
          in_pin: 0,  
          is_muted: 0,  
          contact_type: 'manchat',  
          contact_nickname: this.selectedFriendship.friend_nickname,  
          contact_avatar: this.selectedFriendship.friend_avatar,  
          contact_remarkname: '',  
          contact_lastmsg: '',  
          contact_lastmsgtime: this.datetimeConvert(new Date()),  
          contact_ifshow: 1  
        };
      };  
    
      const navigateToChat = (chatContacter) => {  
        this.$router.push({  
          path: '/home/friendshipgroup/msgrecord/' + chatContacter.idfcc,  
          query: { data: chatContacter }  
        });  
      };  
    
      if (this.$store.state.ifNeedGetChatContacters) {  
        this.getFriendChatContacterByFriendUsername(this.username, friendUsername)  
          .then((result) => {  
            const filterChatContacter = result[0];  
            if (filterChatContacter) {  
              navigateToChat(filterChatContacter);  
            } else {  
              const newChatContacter = createNewChatContacter(); 
              this.addChatContacter(newChatContacter)
                .then((result)=>{
                console.log('resu1',result)
              })
              navigateToChat(newChatContacter);  
            }  
          });  
      } else {  
        const filterChatContacter = myChatContacters?.find((item) => item.contact_username === friendUsername);  
        if (filterChatContacter) {  
          navigateToChat(filterChatContacter);  
        } else {  
          const newChatContacter = createNewChatContacter();
          this.addChatContacter(newChatContacter)
            .then((result)=>{
                console.log('resu2',result)
              })  
          navigateToChat(newChatContacter);  
        }  
      }
      },

      downLoadPrivateFiles(file){
        const urlObject = new URL(file.url);
        const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
        this.downloadPrivatefileFromServer(fileName,'downloadmyownfile',file.filename)
      },

      downLoadTaskFiles(file){
        const urlObject = new URL(file.url);
        const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
        this.downloadPrivatefileFromServer(fileName,'downloadtaskfile',file.filename)
      },

      downLoadPubFiles(file){
        const newFile=file
        newFile.downloadtimes=file.downloadtimes+1
        this.updatePubfileByIdff(newFile)
        const urlObject = new URL(file.url);
        const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
        this.downloadPubfileFromServer(fileName,'downloadpubfile')
      },

      downloadPubfileFromServer(url,downloadUrl) {
        const token = localStorage.getItem('token')
        const data = { url: url }
        axios({
          method: 'get',
          url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
          headers: {
            Authorization: token,
          },
          params: data,
          responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
        })
          .then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
            const link = document.createElement('a') // 创建一个 <a> 元素
            link.href = downloadUrl // 设置下载链接
            link.setAttribute('download', url) // 设置下载文件的文件名
            document.body.appendChild(link) // 将 <a> 元素添加到页面中
            link.click() // 模拟点击下载
            document.body.removeChild(link) // 下载完成后移除 <a> 元素
            window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
          })
          .catch(function (error) {
            console.log(error?.response?.data?.message || 'An error occurred.');
          })
      },

      downloadPrivatefileFromServer(url,downloadUrl,newFileName) {
        const token = localStorage.getItem('token')
        const data = { url: url }
        axios({
          method: 'get',
          url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
          headers: {
            Authorization: token,
          },
          params: data,
          responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
        })
          .then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
            const link = document.createElement('a') // 创建一个 <a> 元素
            link.href = downloadUrl // 设置下载链接
            // link.setAttribute('download', url) // 设置下载文件的文件名
            link.setAttribute('download', newFileName)
            document.body.appendChild(link) // 将 <a> 元素添加到页面中
            link.click() // 模拟点击下载
            document.body.removeChild(link) // 下载完成后移除 <a> 元素
            window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
          })
          .catch(function (error) {
            console.log(error?.response?.data?.message || 'An error occurred.');
          })
      },

      async getAllProjectTasks() {
        const username=localStorage.getItem('username')
        var tasklistByProjectAcl=[]
        this.getMyProjectAclList(username)
          .then((result)=>{
            console.log('resultx',result)
            if (!Array.isArray(result)) {
              alert('账号未认证，请重新登录！')
              window.location.href = "https://www.itaskid.com" + "/login";
              return
            }
  
            const n=result.length
            var i
            var promises = []
            for(i=0;i<n;i++){
                var promise=this.getTasksByTaskProjects(result[i])
                // var promise=this.getTasksByIdfpFromTasklist(result[i])
                promises.push(promise)
            }
            return Promise.all(promises)
          })
          .then((results) => {
            results.forEach((result) => {
              tasklistByProjectAcl=tasklistByProjectAcl.concat(result)
  
            })
            // const mergedArray = this.updateTaskPermitLevelbyTaskacl(myTaskAcllist, tasklistByProjectAcl)
            if (tasklistByProjectAcl && Array.isArray(tasklistByProjectAcl)) {
              tasklistByProjectAcl.sort((a, b) => a.orderid - b.orderid);
            } 
            this.myTasks=tasklistByProjectAcl
          })
      },

      async getTaskfilesByProject(project) {
        try {
          const token = localStorage.getItem('token');
          const data = { project: project };
          const response = await axios({
            method: 'get',
            url: 'https://api2.itaskid.com' + '/my/gettaskfilesbyproject',
            headers: {
              Authorization: token,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: data
          });
    
          if (Array.isArray(response.data)) {
            return response.data;
          } else {
            alert('账号未认证，无法获取数据！');
          }
        } catch (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        }
      },

      //获取我参与项目的已发布文件、我发起的文件和待我审批的文件
      getMyPubFiles(username){
        var myPubFiles=[]
        this.pubfiles.forEach((file)=>{
          var checkers=[]
          if(!(file.checker=='')){
            checkers=file.checker.split(';')
          }
          if(!(file.approver=='')){
            checkers.push(file.approver)
          }
          if(file.filestatus=='已发布'||checkers.includes(username)||file.publisher==username){
            myPubFiles.push(file)
          }
        })
        this.myPubFiles=myPubFiles
        return myPubFiles
      },

      getAllMyPubFiles(){
        const username=localStorage.getItem('username')
        var pubfiles = [];
        var promises = this.myItemsProjects.map(async (project) => {
          const result=await this.getPubfilesByProject(project.idfp)
          if(result&&result.length>0){
            pubfiles=pubfiles.concat(result)
          }
        });
        
        Promise.all(promises)
          .then(() => {
            this.pubfiles = pubfiles;
            this.myPubFiles=this.getMyPubFiles(username)
          })
          .catch((error) => {
            console.error('An error occurred while fetching pubfiles:', error);
          });
      },

      getAllMyTaskFiles(){
        const username=localStorage.getItem('username')
        var taskfiles = [];
          var promises = this.myItemsProjects.map(async (project) => {
            const result=await this.getTaskfilesByProject(project.idfp)
            if(result&&result.length>0){
              taskfiles=taskfiles.concat(result)
            }
          });
          
          Promise.all(promises)
            .then(() => {
              this.taskfiles = taskfiles;
              this.myTaskFiles=this.taskfiles.sort((a, b)=>new Date(b.updatetime) - new Date(a.updatetime))
            })
            .catch((error) => {
              console.error('An error occurred while fetching pubfiles:', error);
            });
      },

      getAllMyShareFiles(){
        const username=localStorage.getItem('username')
        var sharefiles = [];
          var promises = this.myItemsProjects.map(async (project) => {
            const result=await this.getSharefilesByProject(project.idfp)
            if(result.length>0){
              sharefiles=sharefiles.concat(result)
            }
          });
          Promise.all(promises)
            .then(() => {
              this.shareFiles = sharefiles
              this.myShareFiles=sharefiles.filter(file=>file.isfolder==0)
            })
            .catch((error) => {
              console.error('An error occurred while fetching pubfiles:', error);
            });
      },


      async searchAllMyPrivateFilesByName(username,str) {
        try {
          const token = localStorage.getItem('token');
          const data = { username: username,filename:str };
          const response = await axios({
            method: 'get',
            url: 'https://api2.itaskid.com' + '/my/searchallmyprivatefilesbyname',
            headers: {
              Authorization: token,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: data
          });
    
          if (Array.isArray(response.data)) {
            return response.data;
          } else {
            alert('账号未认证，无法获取数据！');
            window.location.href = "https://www.itaskid.com" + "/login";
            return
          }
        } catch (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        }
      },

      handleFocus(){
        // this.ifShowSearchResult=true
        if(this.select=='文件'){
          this.getAllMyPubFiles()
          this.getAllMyTaskFiles()
          this.getAllMyShareFiles()
        }else if(this.select=='任务'){
          this.getAllProjectTasks()
        }else if(this.select=='好友'){
          const token = localStorage.getItem('token')
          let friendships=[]
          this.getFriendshipsByUserId(this.userinfo.id,token)
            .then((result)=>{
              friendships=result
              if(friendships){
                const promises=friendships.map(async (friendship)=>{
                  const result=await this.showPic(friendship.friend_avatar,'showavatar')
                  friendship.avatarUrl=result
                })
                return Promise.all(promises)
              }else {
                return []
              }
            })
            .then(()=>{
              if(friendships){
                this.myFriendShips=friendships
              } else {
                this.myFriendShips=[]
              }
            })
        
        }
      },


      switchSearchInput(){
        if(this.select==='文件'){
          const username=localStorage.getItem('username')
          this.searchAllMyPrivateFilesByName(username,this.input3)
            .then((res)=>{
              this.allMySearchedPrivateFiles=res
            })

          this.allMySearchedPubFiles=this.myPubFiles.filter((file)=>{
            return file.filename.includes(this.input3)
          })

          this.allMySearchedTaskFiles=this.myTaskFiles.filter((file)=>{
            return file.filename.includes(this.input3)
          })

          this.allMySearchedShareFiles=this.myShareFiles.filter((file)=>{
            return file.filename.includes(this.input3)
          })
        }else if (this.select=='任务'){
          this.allMySearchedTasks=this.myTasks.filter((task)=>{
            return task.name.includes(this.input3)
          })
        }else if(this.select=='好友'){
          this.allMySearchedFriendShips=this.myFriendShips?.filter(friend=>friend.friend_nickname.includes(this.input3)||friend.friend_username.includes(this.input3))
        }


        this.ifShowSearchResult=true
        this.switchMenuMask();
        eventBus.$on('closeContexMenu', () => {
          this.input3=''
          this.ifShowSearchResult=false
          this.ifShowTaskDetail=false
        })
      },


      showBox(index){
        for (let i = 0; i < this.tags.length; i++) {
          if (i === index) {
            this.tags[i].color = 'rgb(71, 114, 250)';
            // this.select=this.tags[i].name
          } else {
            this.tags[i].color = 'rgb(255,255,255)';
          }

        }

        if(index==0){
          this.select='好友'
        }else if(index==4){
          this.select='文件'
        }else if(index==6){
          this.select='好友'
        }else {
          this.select='任务'
        }
      },

      async showPic(imgname,url) {
        
        if(!imgname||imgname=='null'||imgname==''){
          return undefined
          
        } else {
          const token = localStorage.getItem('token');
          const data = { url: imgname };
          try {
            const response = await axios({
              method: 'get',
              responseType: 'arraybuffer',
              url: 'https://api1.itaskid.com' + `/my/${url}`,
              headers: {
                Authorization: token,
              },
              params: data,
            });
            const contentType = response.headers['content-type'];
            const blob = new Blob([response.data], { type: contentType });
            const blobUrl = window.URL.createObjectURL(blob);
            return blobUrl;
          } catch (error) {
            console.log(error);
          }
        }
      }, 

      //更换头像函数
      triggerFileUpload(){
        this.$refs.fileInput.click();
      },

      // 处理文件选择
      handleFileChange(event) {
        const file = event.target.files[0];
        if (!file) {
            return; // 没有文件被选中
        }

        this.uploadMyAvatar(file)
          .then((result)=>{
            const avatar=result.filename
            this.updateMyAvatarOfSql(avatar)
            this.showPic(avatar,'showavatar')
              .then((resultxd)=>{
                console.log('resultxd',resultxd)
                this.myAvatarUrl=resultxd
              })

          })
      },

      async uploadMyAvatar(file){
        try {
          const token = localStorage.getItem('token')
          const formData = new FormData();
          const encodedFileName = encodeURIComponent(file.name);
          formData.append('file', file, encodedFileName);
          const response = await axios.post(
            'https://api2.itaskid.com' + '/my/uploadavatar',
            formData,
            {
              headers: {
                Authorization: token,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          // console.log(response.data.data.url)
          return response.data.data;
        } catch (error) {
          console.log(error);
        }
      
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();

      },


        //修改服务器中的任务关系
      updateMyAvatarOfSql(avatar) {
        const token = localStorage.getItem('token')
        axios({
          method: 'put',
          url: 'https://api2.itaskid.com' + '/my/update/avatar',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: {id:this.userinfo.id,avatar:avatar}
        })
          .then((response) => {
            console.log('修改用户头像成功!', response)
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
      },
    },

    computed:{ 

      // 使用 mapState 辅助函数来获取 myItemsProjects  
      ...mapState({  
        myItemsProjects: state => state.myItemsProjects  
      }),
      
      showAltNickname(){
        return(nickname)=>{
          const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
          const altNikname = matchResult ? matchResult[0] : ''
          return altNikname
        }
      },
    },


    created(){
      this.userAvatarUrl='../assets/小秘书头像3.svg'

      const username=localStorage.getItem('username')
      const key=`user|${username}`
      const userinfoStr=localStorage.getItem(key)
      this.userinfo=JSON.parse(userinfoStr)
      const nickname = this.userinfo.nickname;
      const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
      this.altNikname = matchResult ? matchResult[0] : '';
      this.showPic(this.userinfo.avatar,'showavatar')
        .then((result)=>{
          this.myAvatarUrl=result
        })
    },
    mounted(){
      this.socket = io("https://api2.itaskid.com"); 
    
      // 监听连接成功事件
      this.socket.on('connect', () => {
        console.log('Connected to socket.io server');
        
      });

    
    
      // // 监听服务器发送的消息
      this.socket.on('message', (message) => {
        if(this.selectedTask&&message.receiver==this.selectedTask.idft){
          this.selectedTaskMsgs.push(message)
        }
      }); 

      var tags = this.$el.querySelectorAll('.box');

      tags.forEach(function(tag) {
        tag.addEventListener('click', function() {
          // 如果当前标签已经处于激活状态，则直接返回
          if (this.classList.contains('active')) {
            return;
          }
          // 移除其他标签的特定类名
          tags.forEach(function(otherTag) {
            otherTag.classList.remove('active');
          });
  
          // 为当前点击的标签添加特定类名
          this.classList.add('active');
        });
      });


     


    }
  }
  </script>
  
  <style lang="less" scoped>
    .el-header {
      background-color: rgba(35,44,73,0.5); 
      color: #333;
      text-align: center;
      line-height: 60px;
    }
    
    .home-container {
      background-color: rgba(35,44,73,0.5) !important; 
    }
    /* .el-container {
      height: calc(100% - 300px); 
    } */
    
    .el-aside {
      background-color: rgba(35,44,73,0.5);  
      /* background-color: rgba(145, 149, 164,0.7); */
      text-align: center;
      height: calc(100vh - 60px);
      width: 50px;
      color: rgb(255,255,255);
    }
    
    .el-main {
      /* position: relative; */
      /* background-color: #E9EEF3; */
      background-color: rgb(255,255,255);
      color: #333;
      text-align: center;
      line-height: 25px;
      border-top-left-radius: 12px;
    }
    
    body > .el-container {
      margin-bottom: 40px;
      background-color: rgba(35,44,73,0.5);
    }
    .el-button {
      width: 60px;
      height: 60px;
      margin:0.5px 2.5px;
      border: none !important;
    }
  
    .clicked {
      background-color: rgb(127, 127, 127) !important;
    }
    .el-button:hover {
      background-color: rgb(127, 127, 127) !important;
    }
  
    .el-button:focus {
      background-color: rgb(127, 127, 127) !important;
    }
  
    div .box {
      border: none;
      
    }
    .box:hover {
      background-color: rgba(38,48,70,0.5);
    }
    
    .box:active {
      color: #f00; /* 点击后的颜色 */
    }
  
    i {
      font-size: 18px;
    }
  
    p {
      font-size: 12px;
      font-weight: bold;
    }

  
  .no-contextmenu {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: auto; /* 只禁用右键事件 */
  }  
  
  a{
  text-decoration: none;
  }
  .router-link-active {
    text-decoration: none;
  }
  i {
    margin-top: 10px;
  }

  /* .el-select .el-input {
    width: 90px;
    height: 36px;
  } */

  .el-button {
    height: 36px;
  }

  .useravatar-container {
    position: relative;
    float:left;
    width:35px;
    height:35px;
    display: inline;
    margin: 8px 0px 0px -10px;
    // left:-8px;
    // top:8px;

    img {
      position: absolute;
      top:0px;
      left:0px;
      width: 35px;
      height:35px;
      margin-top:5px;
      border-radius: 50%;
    }

    .alt-avatar {
      position: absolute;
      top:0px;
      left:0px;
      width: 35px;
      height:35px;
      font-size: 13.5px;
      color:rgb(255, 255, 251);
      line-height: 35px;
      margin-top:5px;
      border-radius: 50%;
      background: rgb(144, 82, 245);
    }

    i {
      position: absolute;
      top: 36%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      display: none;
    }
  }

  .useravatar-container:hover i {
    display: block;
  }

  // .cameraicon-container {
  //   width:35px;
  //   height:35px;
  //   position: absolute;
  //   left:-8px;
  //   top:8px;
    
  // }

  .searchResultContainer {
    position: absolute;  
    padding:10px;
    top: 65px;  
    left: calc(50% - 400px);  
    z-index: 9999;  
    width: 800px;  
    height: calc(100vh - 150px);  
    line-height: 30px;
    background: #f9f9f9; /* 更新为柔和的背景色 */  
    border: 1px solid #ccc; /* 添加边框 */  
    border-radius: 8px; /* 添加圆角 */  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */  
    // overflow: auto; /* 允许溢出内容滚动 */ 

    .searchItemsLabel {
      font-size: 12px;
      color:gray;
      margin:3px 0 3px 3px;
      text-align: left;
    }

    .searchFriendRow {
      width:500px;
    }

    .searchFriendRow:hover {
      background-color: rgb(224, 232, 246);
      cursor: pointer;
    }
  }

  .taskDetailContainer {
    position: absolute;  
    text-align: center;
    top: 65px;  
    left: calc(50% - 500px);  
    z-index: 999;  
    width: 1000px;  
    height: calc(100vh - 100px);  
    line-height: 30px;
    background: #f9f9f9; /* 更新为柔和的背景色 */  
    border: 1px solid #ccc; /* 添加边框 */  
    border-radius: 8px; /* 添加圆角 */  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */  
    overflow: auto; /* 允许溢出内容滚动 */ 
  }

  .tableheader {
    height: 30px;
    padding: 0px;
    

    position: sticky;  
    top: 0;  
    background-color: #f1f1f1; /* 表头背景色 */  
    z-index: 10; /* 确保表头在内容上方 */ 
    
  }

  .filecontenttable {
    margin-left: 10px; 
    margin-right: 10px;
    border-collapse: collapse;
    // max-height: calc(100% - 180px);
    // overflow:auto;
    td {
      border-bottom: 0.5px solid rgb(205, 205, 205);
    }

    .searchFileName {
      width: 350px;
      max-width:500px;
      white-space: nowrap; 
      overflow: hidden; 
      text-overflow: ellipsis;
      padding:0px 15px;
    }
  }

  .searchFileName:hover {  
    color: rgb(89, 127, 250); /* 鼠标悬停时文字颜色变为浅蓝色 */
    cursor: pointer;  
  }

  .friendmsg-altavatar {
    width: 40px;
    height: 40px;
    border-radius: 5%;
    background: rgb(144, 82, 245);
    color:rgb(255, 255, 251);
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }



  </style>
  