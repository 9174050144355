<template>
  <!-- <div class="menu-container" v-draggable> -->
  <div class="menu-container">
    <div class="menu">
      <h3 style="text-align: center; margin-bottom: 20px;">任务编辑</h3>
      <Detail @upDateStarttime="upDateStarttime" :selectedTask="selectedTask"
        :taskMessages="taskMessages"  :taskContents="taskContents"  :selectedProjectAcls="selectedProjectAcls"
        @sendTaskMessage="sendTaskMessage" @upDateEndtime="upDateEndtime" @updateTaskOfSql="updateTaskOfSql">
      </Detail>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import Detail from '../../task/Detail.vue'
import { mapState, mapMutations} from 'vuex'
import axios from 'axios'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        // el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "EditTaskMenu",
    props: [],
    data() {
        return {
        };
    },
    // props:['selectProjName'],
    computed: {
      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        for(var i=0; i<this.myProjects.length;i++) {
          if(!myfolderobj[this.myProjects[i].groupname]) {
            myfolderobj[this.myProjects[i].groupname]=i+1
            const folder={id: Id, name: this.myProjects[i].groupname}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },

      
 
    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      }
    },
    prop: [],
    methods: {

      ...mapMutations({
          switchMenuMask: "switchMenuMask"
      }),

    },
    mounted(){
      


     

    },

    created(){

    },

    components: { Row, Detail }
}
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: calc(100% - 100px);
  height: calc(100% - 50px);
  border: 1px solid gray;
  z-index: 100;
  cursor: move;

  .menu {
    position:relative;
  }

  form {
    height: auto;
  }



  button {
    border:none;
    background: rgba(250,250,250,1);
    height: 45px;
    width: 50px;
    line-height: 45px;
    float:right;
    margin-right:10px;
    padding:0;
  }

  button:hover {
    color: rgb(71, 114, 250);
  }


}






  
</style>