<template>
  <div style="display: flex;overflow:hidden;">
    <el-col style="width: 220px; border-right: 1px solid rgba(200, 200, 200, 0.9); flex-shrink: 0;">
      <div @contextmenu.prevent @mousedown.right.prevent="disableContextMenu">
        <div class="taskgroup-header" style="text-align: left; margin-bottom: 0px">
          <ul style="margin:0 0 5px 18px;padding: 0px">
            <li @click="goToProject('today')" class="project-container">
              <div class="header-link" :class="{ projectclicked: isprojectClicked('today') }">  
                <i class="el-icon-date"></i>
                今天
              </div>
            </li>
            <li @click="goToProject('week')" class="project-container">
              <div class="header-link" :class="{ projectclicked: isprojectClicked('week') }">
                <i class="el-icon-date"></i>
                最近七天
              </div>
            </li>
            <li @click="goToProject('checking')" class="project-container">
              <div class="header-link" :class="{ projectclicked: isprojectClicked('checking') }" style="position:relative;">
                <i class="el-icon-document-checked"></i>
                待确认
                <label for="" class="badget" :style="{color: allMyCheckingTasks.length > 0 ? 'rgb(64, 158, 255)' : ''}" v-show="allMyCheckingTasks.length > 0">
                  {{ allMyCheckingTasks.length }}
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div class="taskgroup-body">
          
          <div style="text-align: left; margin-top: 5px 5px;height: 35px;">
              <button style="border: none; background: none;height: 35px;" @click=toggle()>
                <i class="el-icon-arrow-right" v-show="!expended"></i>
                <i class="el-icon-arrow-down" v-show="expended"></i>
              </button>
              <span for="" style="padding-left: 0; height: 42px;">所有项目</span>
              <button style="margin-left: 50px; border: none; height: 42px;" 
                @click="showAddProMenu" class="btn-addproject">
                <i class="el-icon-circle-plus-outline" style="font-size: 14px;"> 添加</i>
              </button>

              <!-- 弹出菜单的代码 -->
              <AddProjectMenu  v-if="isShowMenu" @closeMenu="shutMenu"  @updateLocalItems="updateLocalItems" 
                @addProjectWithAclToSql="addProjectWithAclToSql" @closeAddProjectMenu="closeAddProjectMenu" @loadTemplateProject="loadTemplateProject">
              </AddProjectMenu>
              <!-- 弹出菜单的代码 -->

              <LoadTempProject v-if="ifShowLoadTempProject" @closeLoadTempProjectMenu="closeLoadTempProjectMenu" :items="items"
                @loadTempProject="loadTempProject" :addTempProjectOrTasks="addTempProjectOrTasks" @loadTempTasks="loadTempTasks">

              </LoadTempProject>
              <AddTempProject v-if="ifShowAddTempProject" :addTempProjectTasks="addTempProjectTasks" :addedTempProject="addedTempProject"
                @closeAddTempProjectMenu="closeAddTempProjectMenu">

              </AddTempProject>


          </div>
          <div style="height: calc(100vh - 240px);overflow: auto;">
            <div style="text-align: left; height: 30px;" v-show="expended" ref="task-group">
                <div @click="goToProject('inbox',nogroupProject)" class="inbox-container" 
                  :class="{ projectclicked: isprojectClicked('inbox') }">
                  <h4  style="margin-left: 18px;">
                    <span>
                      <i class="el-icon-folder-opened"></i>  
                    </span>
                    收集箱
                  </h4>
                </div>  
                <!-- 暂时不考虑收集箱可以编辑 -->
                <!-- <EditProject
                        v-if="showProjEdit"
                        @cancelEditPro="cancelEditPro"
                        @closeEditProj="closeEditProj"
                        @updateLocalItems="editLocalItems"
                        v-bind:myProjects="items">
                </EditProject> -->
            </div>
            <div style="text-align: left; " v-show="expended" ref="task-group">
                <div v-for="(items, group) in groupedItems" :key="group">
                  <h4 @click="toggleGroup(group)" class="prjgroup-title">
                    <span v-if="!isGroupExpanded(group)">
                      <i class="el-icon-arrow-down"></i>
                      <i class="el-icon-folder-opened"></i>  
                    </span>
                    <span v-else>
                      <i class="el-icon-arrow-right"></i>
                      <i class="el-icon-folder"></i>
                    </span>
                    {{ group }}
                  </h4>
                  <ul v-show="!isGroupExpanded(group)" style="margin:0px !important;">
                    <li v-for="item in items" :key="item.idfp" class="project-container"
                      @contextmenu.prevent="projectEditMenu($event,item)" @click="goToProject(item.idfp,item)">
                      <!-- <i class="el-icon-s-order" style="margin-bottom: 8px"></i> -->
                      <i class="el-icon-edit" style="margin-bottom: 8px" v-if="item.permit_level=='charger'"></i>
                      <i class="el-icon-view" style="margin-bottom: 8px" v-if="item.permit_level=='viewer'"></i>
                      <i class="el-icon-s-promotion" style="margin-bottom: 8px" v-if="item.permit_level=='parter'"></i>
                      <span style="margin-bottom: 8px" :class="{ projectclicked: isprojectClicked(item.idfp) }">
                        {{ item.project_altname }}
                      </span>
                      
                
                    </li>
                    <!-- 添加编辑项目的右键菜单 -->
                    <EditProjectMenu 
                      v-if="showProjEditMenu"
                      :projeditMenuitems="projeditMenuitems" 
                      :event="event" 
                      :parentNode="parentNode"
                      @projeditMenuitemClick="projeditMenuitemClicked">
                    </EditProjectMenu>
                  
                   
                  </ul>
                </div>
                <EditProject
                  v-if="showProjEdit" :selectedProjAcl="selectedProjAcl"
                  @cancelEditPro="cancelEditPro"
                  @closeEditProj="closeEditProj"
                  @updateLocalItems="editLocalItems"
                  @editProjectAcl="editProjectAcl"
                  v-bind:myProjects="items">
                </EditProject>
                <ShareProjectMenu v-if="showShareProjectMenu" :myProjects="myProjects" @closeShareProjectMenu="cancelSharePro"
                  :selectedProJidfpByContext="selectedProjAcl.idfp" :selectedUsers="selectedUsers" @upadatePrjAclPermitLevel="upadatePrjAclPermitLevel"
                  @shareProjectToUser="shareProjectToUser" @deleteSelectedProjectAclById="deleteSelectedProjectAclById">
                </ShareProjectMenu>
            </div>
          </div>
          
        </div>
      </div>
    </el-col>
    <el-col style="border-right: 1px solid rgba(200, 200, 200, 0.9); width: calc(100% - 220px); ">
      <router-view 
        :selectedProjectRoute="selectedProjectRoute" :clickedIdfp="clickedIdfp" :allTasksByProjectAcl="allTasksByProjectAcl"
        :selectedProject="selectedProject"  :myProjects="myProjects" :allMyCheckingTasks="allMyCheckingTasks" :loadedTempTasks="loadedTempTasks"
        :projectTitle="projectTitle" :placeholderStr="placeholderStr" :myProjectAcls="myProjectAcls" :projectType="projectType"
        :tasksWithIdfp="tasksWithIdfp" :myProjectIdfpArray="myProjectIdfpArray" @showAddTasksFromTemp="showAddTasksFromTemp"
        :projectGroups="projectGroups">
      </router-view>
      <!-- <TaskPieChart></TaskPieChart> -->
    </el-col>
  </div>
</template>

<script>
import AddProjectMenu from '../menu/task/AddProjectMenu.vue'
import EditProject from '../menu/task/EditProject.vue'
import ShareProjectMenu from '../menu/task/ShareProjectMenu.vue'
import LoadTempProject from '../menu/task/LoadTempProject.vue'
import AddTempProject from '../menu/task/AddTempProject.vue'
import EditProjectMenu from '../menu/task/EditProjectMenu.vue'
// import TaskPieChart from '../task/TaskPieChart.vue'
import axios from 'axios'
import { mapState , mapMutations, mapActions} from 'vuex'
import { eventBus } from '../../main.js'
import { v4 as uuidv4 } from 'uuid'
export default {
  components: {
    AddProjectMenu,
    EditProjectMenu,
    EditProject,
    ShareProjectMenu,
    LoadTempProject,
    AddTempProject,
    // TaskPieChart
  },
  data () {
    return {
      isShowMenu: false,
      ifShowLoadTempProject:false,
      expended: true,
      isContextMenuOpen:[{general: 'false'}],
      idfg: 4,
      items: [],
      itemsAll:[],
      projectdata:[],
      nextId: 5,
      expandedGroups: {},
      newItemText: '',
      newGroup: '',
      is_group: true,
      username:'',
      token:'',
      newProject:[],
      myProjectList:[],
      myProjects:[],
      //以下是处理项目编辑菜单的函数
      showProjEditMenu: false,
      projeditMenuitems: null,
      event: null,
      parentNode: null,
      selectedProj:'',
      myProjectAcls:[],
      projectGroups:[],
      //以上是处理项目编辑菜单的函数

      //以下是项目编辑的数据
      showProjEdit:false,
      selectedProjectRoute:'',
      selectedProject:'',
      //以下是任务显示相关的数据
      projectTitle:'',
      placeholderStr:'',

      //以下是项目、任务权限控制的数据
      myProjectAclList:[],
      showShareProjectMenu: false,
      selectedProjAcl:null,
      selectedUsers:[],
      newTasksFromTempProject:[],
      addTempProjectOrTasks:'',
      loadedTempTasks:[],


      //以下是显示项目类型，任务树、任务表、还是任务图的数据
      projectType:'',

      //被点击的项目idfp
      clickedIdfp:'',

      tasksWithIdfp:[],

      myProjectIdfpArray:[],
      nogroupProject: {},
      selectedProjectParter:[],

      allMyCheckingTasks:[],
      allTasksByProjectAcl:[],

      //以下是添加模板项目相关的数据
      ifShowAddTempProject:false,
      addTempProjectTasks:[],
      addedTempProject:null,

      

    }
  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
      updateSelectProjName: 'updateSelectProjName',
      updateUser: 'updateUser',
      updateMyItemsProjects: 'updateMyItemsProjects',
    }),

    ...mapActions({
      getMyProjectAclList:'getMyProjectAclList',
      getMyTaskAclList:'getMyTaskAclList',
      addProjectToSql:'addProjectToSql',
      // addTaskAclToSql:'addTaskAclToSql',
      addProjectAclToSql:'addProjectAclToSql',
      getTasksByIdfp:'getTasksByIdfp',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      updateProjectAcl:'updateProjectAcl',
      getTasksByTaskProjects:'getTasksByTaskProjects',
      addTaskToSql:'addTaskToSql',
      addTaskAclToSql:'addTaskAclToSql',
      // updateProjNameAndGroupByIdfp:'updateProjNameAndGroupByIdfp'
    }),

    //根据项目数组，得出项目组数组，项目组由对象{groupname:'',children:[项目1，项目2]}组成
    // getResultArray(items){
    //   //在这里通过this.items，得出文件夹->项目的数据
    //   const groups = {}
    //   items.forEach(item => {
    //     if (!groups[item.prjgroup_altname]) {
    //       groups[item.prjgroup_altname] = []
    //     }
    //     groups[item.prjgroup_altname].push(item)
    //   })

    //   // 将 groups 对象转换为数组  
    //   const resultArray = Object.keys(groups).map(groupname => ({  
    //     value: groupname, label:groupname, 
    //     children: groups[groupname]  
    //   }));  
      
    //   return resultArray;
    // },

    getResultArray(items) {  
  // 在这里通过 items，得出文件夹->项目的数据  
  const groups = {};  
  
  // 将项目按项目组分组  
  items.forEach(item => {  
    if (!groups[item.prjgroup_altname]) {  
      groups[item.prjgroup_altname] = [];  
    }  
    groups[item.prjgroup_altname].push(item);  
  });  

  // 将 groups 对象转换为数组，并转换 children 的格式  
  const resultArray = Object.keys(groups).map(groupname => ({  
    label: groupname,
    value:groupname,  
    children: groups[groupname].map(item => ({  
      value: item.idfp,  // 将 idfp 的值赋给 value  
      label: item.name   // 将 name 的值赋给 label  
    }))  
  }));  

  return resultArray;  
} ,

    //以下都是添加任务到某个项目的函数
    showAddTasksFromTemp(str){
      this.ifShowLoadTempProject=true
      this.addTempProjectOrTasks=str
      this.switchMenuMask()
    },


    async getAllProjectTasks() {
      var tasklistByProjectAcl=[]
      this.getMyProjectAclList(this.username)
        .then((result)=>{
          if (!Array.isArray(result)) {
            alert('账号未认证，请重新登录！')
            window.location.href = "https://www.itaskid.com" + "/login";
            return
          }

          const n=result.length
          var i
          var promises = []
          for(i=0;i<n;i++){
              var promise=this.getTasksByTaskProjects(result[i])
              // var promise=this.getTasksByIdfpFromTasklist(result[i])
              promises.push(promise)
          }
          return Promise.all(promises)
        })
        .then((results) => {
          results.forEach((result) => {
            tasklistByProjectAcl=tasklistByProjectAcl.concat(result)

          })
          // const mergedArray = this.updateTaskPermitLevelbyTaskacl(myTaskAcllist, tasklistByProjectAcl)
          if (tasklistByProjectAcl && Array.isArray(tasklistByProjectAcl)) {
            tasklistByProjectAcl.sort((a, b) => a.orderid - b.orderid);
          } 
          this.allTasksByProjectAcl=tasklistByProjectAcl
          return tasklistByProjectAcl
        })
    },

    convertDate(targetDate) {
      if (targetDate == '') {
        targetDate = '2999/12/31 23:59:59';  // 设置默认时间为23:59:59
      }
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
    
      const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },


    // async getAllCheckingTasks() {
    //   // const myTaskAcllist = await this.getTasksByTaskacl()
    
    //   var tasklistByProjectAcl=[]
    //   this.getMyProjectAclList(this.username)
    //     .then((result)=>{
    //       if (!Array.isArray(result)) {
    //         alert('账号未认证，请重新登录！')
    //         window.location.href = "https://www.itaskid.com" + "/login";
    //         return
    //       }
    //       const chargerProjects=result.filter(item=>item.permit_level=='charger')
    //       const n=chargerProjects.length
    //       var i
    //       var promises = []
    //       for(i=0;i<n;i++){
    //           var promise=this.getTasksByTaskProjects(chargerProjects[i])
    //           promises.push(promise)
    //       }
    //       return Promise.all(promises)
    //     })
    //     .then((results) => {
    //       results.forEach((result) => {
    //         tasklistByProjectAcl=tasklistByProjectAcl.concat(result)
    //       })
    //     })
    // },

    async getAllCheckingTasks() {
      var tasklistByProjectAcl = [];
      await this.getMyProjectAclList(this.username)
        .then((result) => {
          if (!Array.isArray(result)) {
            alert('账号未认证，请重新登录！');
            window.location.href = "https://www.itaskid.com" + "/login";
            return;
          }
    
          const chargerProjects = result.filter(item => item.permit_level === 'charger');
          const promises = chargerProjects.map(chargerProject => this.getTasksByTaskProjects(chargerProject));
          
          return Promise.all(promises)
            .then(results => {
              results.forEach(result => {
                tasklistByProjectAcl = tasklistByProjectAcl.concat(result);
              });
              
              return tasklistByProjectAcl; // Return the completed task list
            });
        })
        .then((tasklist) => {
          this.allMyCheckingTasks=tasklist.filter(task=>task.taskstatus=='checking'&&task.done==0)
        });
    },

    editProjectAcl(newProjectAcl){
      const newItems=this.items.map(i=>{
        if(i.idfpacl==newProjectAcl.idfpacl){
          return newProjectAcl
        } else {
          return i
        }
      })
      this.items=newItems
      this.updateProjectAcl(newProjectAcl)
      if(this.selectedProjAcl.permit_level=='charger'){
        this.updateProjNameAndGroupByIdfp(newProjectAcl.project_idfp,newProjectAcl.project_altname,newProjectAcl.prjgroup_altname)
      }
      
    },

    updateProjNameAndGroupByIdfp(idfp, projectName, projectGroup) {
    const token = localStorage.getItem('token')
    axios({
      method: 'put',
      url: 'https://api2.itaskid.com' + '/my/updateprojnameandgroupbyidfp',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: { idfp: idfp, name: projectName, groupname: projectGroup }
    })
      .then(response => {
        console.log('修改项目成功！')
      })
      .catch(error => {
        console.log(error);
      });
  },

    //以下是新增项目菜单的处理函数
    showAddProMenu(){
      this.isShowMenu=!this.isShowMenu
      this.switchMenuMask()
      
    },
    shutMenu(){
      this.isShowMenu=false
    },
    //这个函数是把前端对服务器的增删改查在组件未更新前先更新本地化数据，以达到视图及时更新的目的
    updateLocalItems(newItem) {  
      this.items.push(newItem)
    },

    //以下是处理编辑菜单的函数
    projectEditMenu(event,item) {
      if(item.permit_level!=='charger'){
        return
      }
      this.selectedProjAcl=item
      this.projeditMenuitems = [
        { id:1,label: '编辑' }, 
        // { id:2,label: '置顶' },
        // { id:3,label: '复制' },
        { id:4,label: '共享协作' }, 
        // { id:5,label: '归档' },
        { id:6,label: '删除' },
        {id:7,label:'添加模板'}
      ]
      this.showProjEditMenu=true
      this.switchMenuMask()
      this.event={...event}  
      
      const top=(window.innerHeight-event.clientY)<189?(event.clientY-189):event.clientY
      const left=(window.innerWidth-event.clientX)<76?(event.clientX-76):event.clientX
      this.parentNode ={clientY:top,clientX:left}
      eventBus.$on('closeContexMenu', () => {
        this.showProjEditMenu=false
      })
      //更新vuex中被选中的项目名
      this.updateSelectProjName(item)
    },
    projeditMenuitemClicked(item) {
      //在这里处理右键菜单对应的选项
      this.showProjEditMenu=false
      const selectedProjAcl=this.selectedProjAcl
      //以下是项目编辑右键菜单的处理分支
      switch (item.id){
        case 1: {
          this.showProjEdit=true
          break
        }
        case 2: {
          this.switchMenuMask()
          break
        }
        case 3: {
          this.switchMenuMask()
          break
        }
        case 4: {
          // this.showProjEdit=true
          let selectedUsers=[]
          const username=localStorage.getItem('username')
          this.getProjectAclListByIdfp(this.selectedProjAcl.project_idfp)
            .then((result)=>{
              selectedUsers=result.filter(item=>item.username!==username)
              const promises=selectedUsers.map(async (selectedUser)=>{
                // const user=await this.getUserInfoByUsername(selectedUser.username)
                selectedUser.isChecked=false
                await this.getUserInfoByUsername(selectedUser.username)
                  .then(async (result)=>{
                    const avatarUrl= await this.showPic(result.avatar)
                    selectedUser.avatarUrl=avatarUrl
                    selectedUser.nickname=result.nickname
                  })
              })
              return Promise.all(promises)
            })
              .then(()=>{
                this.selectedUsers=selectedUsers
                this.showShareProjectMenu=true
              })
          
          
          // this.switchMenuMask()
          break
        }
        case 5: {
          this.switchMenuMask()
          break
        }
        case 6: {
          if(selectedProjAcl.permit_level=='charger') {
          // const id=`${this.user.username}nogroup`
          const targetDataIndex=this.items.findIndex(item=>item.idfp===this.$store.state.selectProjIdfp)
          this.items.splice(targetDataIndex, 1)
          this.deleteProject(this.selectProjIdfp)
          this.deleteProjectAclByIdfpacl(selectedProjAcl.idfpacl)
          } else {
            const targetDataIndex=this.items.findIndex(item=>item.idfp===this.$store.state.selectProjIdfp)
            this.items.splice(targetDataIndex, 1)
            this.deleteProjectAclByIdfpacl(selectedProjAcl.idfpacl)
          }

          const username=localStorage.getItem('username')
          var nogroup=`${username}nogroup`
          const localProject={username:username,project_idfp:nogroup,permit_level:'charger',name:'收集箱'}
          localStorage.setItem('myLocalProject', JSON.stringify(localProject))
          this.switchMenuMask()

          break
        }

        case 7: {
          this.getTasksByIdfp(this.selectedProjAcl.project_idfp)
            .then((res)=>{

            // 创建一个映射来存储任务关系  
            const taskMap = new Map();  
            const username = localStorage.getItem('username');
            let targetTasks=res
            
            let targetProject={
              idfp:uuidv4(),
              name:this.selectedProjAcl.name,
              status:'未发布',
              createdtime:'',
              updatetime:'',
              version:'1.0.0',
              description:'',
              availability:'',
              price:'',
              rating:5,
              usage_instruction:'',
              creater:username
            }
    
            // 处理任务列表  
            targetTasks = targetTasks.map(task => {  
              // 赋值操作并转换日期  
              task.starttime = this.convertDate(task.starttime);  
              task.endtime = this.convertDate(task.endtime);  
              
              const newTask = {  
                ...task, // 保留原有属性  
                idftt: uuidv4(),  
                project: targetProject.idfp,  
                starttimeatl: '2999/12/31',  
                endtimeatl: '2999/12/31',  
                done: 0,  
                creater: username,
                record:'',
                expanded:1,
                checked:1
              };  
      
              // 将新任务添加到映射中  
              taskMap.set(newTask.idft, newTask);  
      
              return newTask; // 返回新的任务对象  
            });  
      
  
      
            // 更新父任务 ID 并将任务添加到 SQL  
            for (const task of targetTasks) {  
              if (task.parent !== 'roottask') {  
                const parentTask = taskMap.get(task.parent); // 快速查找父任务  
                if (parentTask) {  
                  task.parent = parentTask.idftt; // 替换父任务 ID  
                }  
              }   
            }

            this.addTempProjectTasks=targetTasks
            this.addedTempProject=targetProject
            this.ifShowAddTempProject=true
              
            })

          break
        }
      }
    },
    //以上是编辑项目菜单的函数
   

    //以下是编辑项目的处理函数
    cancelEditPro(){
      this.showProjEdit=false
      this.switchMenuMask()
    },
    closeEditProj(){
      this.showProjEdit=false
      this.switchMenuMask()
    },
    editLocalItems(projectinfo) {
      this.updateProject(projectinfo)
      const targetDataIndex = this.items.findIndex(item => item.idfp === projectinfo.idfp)
      const targetData = this.items[targetDataIndex];  //targetData是编辑前的items中对应idfp的数据，编辑项目idfp不改变
      // targetData.name = projectinfo.name;  //已经修改了项目名称
      if (projectinfo.groupname === this.$store.state.selectProjGroup) {
        // 没有修改分组
        targetData.name = projectinfo.name;
      } else {
        // 修改了分组
        // targetData.groupname = projectinfo.groupname;
        const groupData = this.items.filter(item => item.groupname === projectinfo.groupname);
        if (groupData.length === 0) {
          targetData.groupname = projectinfo.groupname
          targetData.name = projectinfo.name
          const newData=targetData
          this.items.splice(targetDataIndex, 1)  
          //this.items.splice(targetDataIndex, 1)操作后，targetData为空，所以必须用newData接收
          this.items.unshift(newData)
        } else {   //改变分组后要选择插入的地方，
          const index = this.items.indexOf(groupData[0])
          targetData.groupname = projectinfo.groupname
          targetData.name = projectinfo.name
          const newData=targetData
          this.items.splice(targetDataIndex, 1)
          if(index>targetDataIndex) {
            this.items.splice(index-1, 0, newData);
          }else {
            this.items.splice(index, 0, newData);
          }
        }
      }
      
    },
    //以上是编辑项目的处理函数

    //以下是共享项目的处理函数
    cancelSharePro(){
      this.showShareProjectMenu=false
      this.switchMenuMask()
    },

    upadatePrjAclPermitLevel(projectAcl){
      this.updateProjectAcl(projectAcl)
    },

    shareProjectToUser(newProjectAcls){
      newProjectAcls.forEach((item)=>{
        if(item.isChecked) {
          this.selectedUsers.push(item)
          this.addProjectAclToSql(item)
        }
      })
    },

    deleteSelectedProjectAclById(id){
      this.selectedUsers=this.selectedUsers.filter(item=>item.idfpacl!==id)
      this.deleteProjectAclByIdfpacl(id)
    },

    addProjectWithAclToSql(newProjectAcl){
      this.addProjectAclToSql(newProjectAcl)
      this.items.push(newProjectAcl)
    },

    closeAddProjectMenu(){
      this.isShowMenu=false

    },

    loadTemplateProject(){
      this.isShowMenu=false
      this.ifShowLoadTempProject=true
      this.addTempProjectOrTasks='project'
      
    },

    closeLoadTempProjectMenu(){
      this.ifShowLoadTempProject=false
      this.switchMenuMask()
    },

    closeAddTempProjectMenu(){
      this.ifShowAddTempProject=false
      this.switchMenuMask()
    },

    loadTempTasks(tasks){
      this.loadedTempTasks=tasks
      this.ifShowLoadTempProject = false;  
      this.switchMenuMask();
    },


    loadTempProject(newProjectAcl, tasklist) {  
      // 将新项目 ACL 添加到 items 数组  
      this.items.push(newProjectAcl);  
      
      // 使用 .then() 处理 addProjectAclToSql 的结果  
      this.addProjectAclToSqlAsync(newProjectAcl)  
        .then(() => {  
          // 创建一个映射来存储任务关系  
          const taskMap = new Map();  
          const username = localStorage.getItem('username');  
    
          // 处理任务列表  
          tasklist = tasklist.map(task => {  
            // 赋值操作并转换日期  
            task.starttime = this.convertDate(task.starttime);  
            task.endtime = this.convertDate(task.endtime);  
            
            const newTask = {  
              ...task, // 保留原有属性  
              idft: uuidv4(),  
              project: newProjectAcl.project_idfp,  
              starttimeatl: '2999/12/31',  
              endtimeatl: '2999/12/31',  
              done: 0,  
              creater: username,  
            };  
    
            // 将新任务添加到映射中  
            taskMap.set(newTask.idftt, newTask);  
    
            return newTask; // 返回新的任务对象  
          });  
    
          // 创建一个数组来存储任务和 ACL 添加的 Promise  
          const taskPromises = [];  
    
          // 更新父任务 ID 并将任务添加到 SQL  
          for (const task of tasklist) {  
            if (task.parent !== 'roottask') {  
              const parentTask = taskMap.get(task.parent); // 快速查找父任务  
              if (parentTask) {  
                task.parent = parentTask.idft; // 替换父任务 ID  
              }  
            }  
    
            // 将任务添加到 SQL，并存储 Promise  
            const taskPromise = this.addTaskToSql(task);  
            taskPromises.push(taskPromise);  
    
            // 创建并添加任务 ACL，存储 Promise  
            const newTaskAcl = {  
              idftacl: uuidv4(),  
              permit_level: 'charger',  
              username: username,  
              task_idft: task.idft,  
              taclcreater: username,  
            };  
            taskPromises.push(this.addTaskAclToSql(newTaskAcl));  
          }  
    
          // 等待所有任务和 ACL 添加完成  
          return Promise.all(taskPromises);  
        })  
        .then(() => {  
          // 所有操作完成后，执行最终操作  
          this.ifShowLoadTempProject = false;  
          this.switchMenuMask();  
          this.getAllProjectTasks()
            .then((res) => {
            });  
        })  
        .catch(error => {  
          // 处理可能的错误  
          console.error('发生错误:', error);  
        });  
    },  

    // loadTempProject(newProjectAcl,tasklist){
    //   this.items.push(newProjectAcl)
    //   // console.log('hongyue',newProjectAcl,tasklistAcl,tasklist)
    //   this.addProjectAclToSql(newProjectAcl)

    //   //以下是添加项目关系成功后再添加任务？
    //   // 首先对任务列表进行处理，赋值并构建父任务映射  
    //   const taskMap = new Map(); // 用于存储任务的映射关系  
    //   const username=localStorage.getItem('username')
        
    //   tasklist = tasklist.map(task => {  
    //     // 赋值操作
    //     task.starttime=this.convertDate(task.starttime)
    //     task.endtime=this.convertDate(task.endtime)  
    //     const newTask = {  
    //       ...task, // 保留原有属性  
    //       idft: uuidv4(),  
    //       project: newProjectAcl.project_idfp,  
    //       starttimeatl: '2999/12/31',  
    //       endtimeatl: '2999/12/31',  
    //       done: 0,  
    //       creater: username,  
    //     };  
      
    //     // 将新任务添加到映射中  
    //     taskMap.set(newTask.idftt, newTask);  
      
    //     return newTask; // 返回新的任务对象  
    //   });  
      
    //   // 第二步：更新父任务的 idft  
    //   tasklist.forEach(task => {  
    //     if (task.parent !== 'roottask') {  
    //       const parentTask = taskMap.get(task.parent); // 使用映射快速查找父任务  
    //       if (parentTask) {  
    //         task.parent = parentTask.idft; // 替换父任务的 idft  
    //       }
    //       this.addTaskToSql(task)
    //       const newTaskAcl={idftacl:uuidv4(),permit_level:'charger',username:username,task_idft:task.idft,taclcreater:username}
    //       this.addTaskAclToSql(newTaskAcl)
    //       console.log('this.addTaskAclToSql',newTaskAcl)
    //     }else {
    //       this.addTaskToSql(task)
    //       const newTaskAcl={idftacl:uuidv4(),permit_level:'charger',username:username,task_idft:task.idft,taclcreater:username}
    //       this.addTaskAclToSql(newTaskAcl)
    //     }  
    //   });
    //   this.ifShowLoadTempProject=false
    //   this.switchMenuMask()
    // },

    //以上是共享项目的处理函数

    goToProject(id,project){
      this.clickedIdfp=id
      localStorage.setItem('selectedProjectRouteLocal',id)
      const currentRoute = this.$router.currentRoute
      if (currentRoute.name !== 'project' || currentRoute.params.idfp !== id){
        this.$router.push({
        name: 'project',
        params: {
          idfp: id
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
      let selectedProjIndex
      if(id=="inbox"||id=="today"||id=="week") {
        selectedProjIndex=this.myProjects.findIndex(item => item.idfp === this.usernogroup)
        const selectedProj=this.myProjects[selectedProjIndex]
        localStorage.setItem('selectedProjectNameLocal',this.usernogroup)
        localStorage.setItem('selectedProjectLocal',JSON.stringify(selectedProj))
        // this.updateSelectProjName(selectedProj)
        this.selectedProjectRoute=`${this.username}nogroup`
        this.projectTitle=(id=='inbox'?'收集箱':(id=='today'?'今天':'最近七天'))  
        localStorage.setItem('selectedProjectTitleLocal',this.projectTitle)
        this.placeholderStr=(id=='inbox')?'添加任务至"收集箱"，回车即可创建':'添加"今天"的任务至"收集箱"'
        this.nogroupProject=selectedProj
        this.selectedProject=selectedProj
        // this.selectedProject=this.nogroupProject


        
      } else if(id=='checking'){
        this.projectTitle='待确认' 
      } else {
        localStorage.setItem('selectedProjectNameLocal',project.name)
        localStorage.setItem('selectedProjectLocal',JSON.stringify(project))
        // this.updateSelectProjName(project)
        this.selectedProjectRoute=id
        this.projectTitle=project.project_altname?project.project_altname:project.name
        this.selectedProject=project
        localStorage.setItem('selectedProjectTitleLocal',this.projectTitle)
        this.placeholderStr=`添加任务至"${this.projectTitle}"，回车即可创建`

        this.getTasksByIdfp(project.idfp)
          .then((result)=>{
            this.tasksWithIdfp=result
          })
        
        let selectedProjectParter=[]
        this.getProjectAclListByIdfp(project.idfp)
          .then((projectacls)=>{
            selectedProjectParter=projectacls
            const promises=selectedProjectParter.map(async (projectacl)=>{
                await this.getUserInfoByUsername(projectacl.username)
                  .then(async (result)=>{
                    const avatarUrl= await this.showPic(result.avatar)
                    const nickname=result.nickname
                    projectacl.avatarUrl=avatarUrl
                    projectacl.nickname=nickname
                  })
              })
              return Promise.all(promises)
          })
            .then(()=>{
              this.selectedProjectParter=selectedProjectParter
            })
      }
      }
      
    },

    // 以下是操作服务器数据库的方法

    // async showPic(imgname) {
    //   const token = localStorage.getItem('token');
    //   const data = { url: imgname };
    //   try {
    //     const response = await axios({
    //       method: 'get',
    //       responseType: 'arraybuffer',
    //       url: 'https://api2.itaskid.com' + '/my/showavatar',
    //       headers: {
    //         Authorization: token,
    //       },
    //       params: data,
    //     });
    //     const contentType = response.headers['content-type'];
    //     const blob = new Blob([response.data], { type: contentType });
    //     const blobUrl = window.URL.createObjectURL(blob);
    //     return blobUrl;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    async showPic(imgname) {
      if(imgname==null||imgname=='null'||imgname==''){
        return undefined
      } else {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api2.itaskid.com' + '/my/showavatar',
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }
    }, 

    async getUserInfoByUsername(username) {
      const token=localStorage.getItem('token')
      try {
        const data = {username:username};
        const response = await axios.get('https://api2.itaskid.com' + '/my/userinfobyusername', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/json'
          },
          params: data
        });
        if(response.data.status==0){
          return response.data.data
        }else {
          alert('该用户不存在');
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.')
      }
    },



    deleteProject(idfp){
      // const token = localStorage.getItem('token')
      axios.delete('https://api2.itaskid.com' + '/my/deleteproject', { 
        headers: { 
          Authorization: this.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        }, // 在 headers 中添加 Authorization Token
        data:{idfp}
      })
      .then(response => {
        // console.log(response.data)
        console.log('删除项目成功！')
      })
      .catch(error => {
        console.log(error);
      });
    },

    //根据id删除数据库中的项目权限
    deleteProjectAclByIdfpacl(idfpacl){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/deleteprojectaclbyidfpacl',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {idfpacl:idfpacl}
        })
        .then((response)=>{
          console.log('删除项目权限成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除项目权限失败！')
        })
    },

    updateProject(project){
      // const token = localStorage.getItem('token')
      axios({
          method:'patch',
          url: 'https://api2.itaskid.com' + '/my/updateproject',
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
            
          },
          data: {idfp:project.idfp, name: project.name, groupname: project.groupname}
          })
      .then(response => {
        // console.log(response.data)
        console.log('修改项目成功！')
      })
      .catch(error => {
        console.log(error);
      });
    },



    // async getMyProjectAclList(username) {
    //   const token=localStorage.getItem('token')
    //   try {
    //     const response = await axios.get('https://api2.itaskid.com' + '/my/getmyprojectacllist', {
    //       headers: {
    //         Authorization: token
    //       },
    //       params: { username: username }
    //     });
    //     if (Array.isArray(response.data)) {
    //       return response.data;
    //     } else {
    //       alert('账号未认证，无法获取数据！');
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },



    async getMyProjectListByIdfp(idfp) {
      const token=localStorage.getItem('token')
      try {
        const response = await axios.get('https://api2.itaskid.com' + '/my/getprojectinfobyidfp', {
          headers: {
            Authorization: token
          },
          params: { idfp: idfp }
        });
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error);
      }
    },
    
    // 向服务器添加项目关系
    addProjectAclToSql(newProjectAcl){
      const token = localStorage.getItem('token')
      axios({
        method:'post',
        url: 'https://api2.itaskid.com' + '/my/addprojectacl',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newProjectAcl
        })
        .then((response)=>{
          
          console.log('添加项目关系成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    },



    // 向服务器添加项目关系  
    async addProjectAclToSqlAsync(newProjectAcl) {  
      const token = localStorage.getItem('token');  
      
      try {  
        const response = await axios({  
          method: 'post',  
          url: 'https://api2.itaskid.com/my/addprojectacl',  
          headers: {  
            Authorization: token,  
            'Content-Type': 'application/x-www-form-urlencoded'  
          },  
          data: newProjectAcl  
        });  
    
        console.log('添加项目关系成功');  
        return response; // 如果需要，可以返回响应  
      } catch (error) {  
        alert(error?.response?.data?.message || 'An error occurred.');  
        throw error; // 重新抛出错误以便调用者处理  
      }  
    },
    // 以上是操作服务器数据库的方法
    disableContextMenu(event) {
      // 禁用默认行为
      event.preventDefault();
    },


    toggle(){
      this.expended=!this.expended
    },


    toggleGroup(group) {
      // this.expandedGroups[group] = !this.expandedGroups[group]
      this.$set(this.expandedGroups, group, !this.expandedGroups[group])
    },
    isGroupExpanded(group) {
      return this.expandedGroups[group]===true
    },


  },
  computed: {
    ...mapState({
      selectProjName: 'selectProjName',
      selectProjGroup: 'selectProjGroup',
      selectProjIdfp: 'selectProjIdfp',
      user:'user'
    }),

    showCheckingNum(){
      return()=>{

      }
    },

    usernogroup: function(){
      return `${this.username}nogroup`
    },
    usertoday: function(){
      return `${this.username}today`
    },
    userweek: function(){
      return `${this.username}week`
    },
    day: function() {
      var today = new Date(); // 获取当前日期
      return today.getDate(); // 返回当前日期中的日
    },

    groupExpanded(){
    return this.isGroupExpanded()
    },
    groupedItems() {
      const groups = {}
      this.items.forEach(item => {
        if (!groups[item.prjgroup_altname]) {
          groups[item.prjgroup_altname] = []
        }
        groups[item.prjgroup_altname].push(item)
      })
      return groups
    },

    //判断某个项目是否被点击
    isprojectClicked(){
      return(idfp)=>{
        if(idfp==this.clickedIdfp) {
          return true
        }else {
          return false
        }
      }
    },
    
  },


  mounted(){


    //初始化数组，选中项目为今天
    this.clickedIdfp='today'

    // this.getAllProjectTasks()


    const username=localStorage.getItem('username')
    this.getMyProjectAclList(username)
      .then((result) => {
        if (Array.isArray(result)) {
          this.myProjectAcls = result;
          const nogroup = `${username}nogroup`;
          const nogroupProjectArray = result.filter(item => item.idfp == nogroup);
          this.nogroupProject = nogroupProjectArray[0];
        } else {
          // 如果 result 不是一个数组，初始化为一个空数组
          this.myProjectAcls = [];
          this.nogroupProject = null; // 或者根据需要设置其他默认值
        }
      })
      .catch((error) => {
        // 处理请求错误
        console.error('Error fetching project ACL list:', error);
        this.myProjectAcls = [];
        this.nogroupProject = null; // 或者根据需要设置其他默认值
      });

    this.getAllCheckingTasks()

  },
  created() {
    //获取用户名和token
    const username=localStorage.getItem('username')
    this.username=username
    const token = localStorage.getItem('token')
    this.token=token
    
    //看看有没有历史的选择项目，有则选，无则选今天
    this.selectedProjectRoute=localStorage.getItem('selectedProjectRouteLocal')?localStorage.getItem('selectedProjectRouteLocal'):'today'
    this.clickedIdfp=this.selectedProjectRoute

    // this.projectTitle=localStorage.getItem('selectedProjectTitleLocal')?localStorage.getItem('selectedProjectTitleLocal'):'今天' 
    this.projectTitle='今天'
    const title=this.projectTitle
    this.placeholderStr=(title=='最近七天'||title=='今天')?'添加"今天"的任务至"收集箱"':`添加任务至"${this.projectTitle}"，回车即可创建`
    const usernogroup=`${username}nogroup`
    let projectAcllist=[]
    this.getMyProjectAclList(username)
      .then((result)=>{
        projectAcllist=result

        if(projectAcllist !== undefined&&projectAcllist.length>0) {

          const promises=projectAcllist.map(async (projectacl)=>{
            const project=await this.getMyProjectListByIdfp(projectacl.project_idfp)
            projectacl.name=project[0].name
            projectacl.prjgroup_altname=projectacl.prjgroup_altname?projectacl.prjgroup_altname:project[0].groupname
            projectacl.project_altname=projectacl.project_altname?projectacl.project_altname:project[0].name
            projectacl.groupname=project[0].groupname
            projectacl.status=project[0].status
            projectacl.is_delete=project[0].is_delete
            projectacl.createdTime=project[0].createdTime
            projectacl.creater=project[0].creater
            projectacl.permit_type=project[0].permit_type
            projectacl.idfp=project[0].idfp
          })
          return Promise.all(promises)

        }
        

      })
        .then(()=>{
          // this.myProjects=projectAcllist
          this.myProjects = projectAcllist || [];
          if(Array.isArray(this.myProjects)){
            this.items=this.myProjects.filter(item=>(!(item.idfp==usernogroup)))
            this.updateMyItemsProjects(this.items)





            // this.projectGroups=this.getResultArray(this.items)
            this.projectGroups=this.getResultArray(this.myProjects)
            




            this.myProjects.forEach((project)=>{
              this.myProjectIdfpArray.push(project.idfp)
            })
          }else {
            this.items=[]
          }

          // const groups = {}
          // this.items.forEach(item => {
          //   if (!groups[item.prjgroup_altname]) {
          //     groups[item.prjgroup_altname] = []
          //   }
          //   groups[item.prjgroup_altname].push(item)
          // })


        })
  }    //错误，还不知道如何修改
}
</script>

<style lang="less" scoped>
.addproject-menu {
  position: absolute;
  height: 0;
  width: 0;
  overflow: visible;
}
.el-col {
  max-height: 100% !important;
  overflow: auto !important;
}
.taskgroup-header {
  border-bottom: 1px solid gray;
  margin-top:0px;
  margin-bottom:0px;
  text-align: left;
  div {
    line-height:30px;
  }
  button {
    border: 0;
    background: none;
    padding-left: 10px;
  }
  .header-link {
    line-height: 30px;
  }
  .today {
     font-size: 14px;
  }
  label:hover {
    cursor: pointer;
  }
  .icon {
    position: relative; 
    display: inline-block;
    margin-top: 7px;
    margin-right:5px;
    background-image: url('../../assets/calendar.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    width: 16px;
    height: 16px;
    .today{
      position: absolute;
      top: 50%; 
      left: 50%;
      transform: translate(-50%, -40%);
      line-height: 18px; 
      text-align: center;
    }
}
}
.taskgroup-body {
  border-bottom: 1px solid gray;
  height: calc(100vh - 190px);
  border-bottom: none;
  // overflow: auto;
  

  .btn-addproject:hover {
    color: rgb(71, 114, 250);
  }
  ul {
    margin: 5px 0px 5px 0px;
    padding-left: 20px; 
  }
  
  label:hover {
    cursor: pointer;
  }
  h4 {
    height: 30px !important;
    line-height: 30px;
    padding-left: 10px;
    margin: 0 0;
    font-weight: normal;
  }
  button {
    background-color: transparent;
    border: none;
  }
}

li {
    height: 30px;
    list-style: none;
    padding-left: 10px;
    line-height: 30px;
    margin-right: 5px;
  }
  li:hover {
    background-color: rgb(246, 248, 255);
    cursor: pointer;
    color: rgb(71, 114, 250);
  }
  .project-container {
    line-height: 30px;
    background-color: transparent;
  }

.context-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.el-main {
  line-height: 20px !important;
}


.menu-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.5);
  z-index: 99;
}
::-webkit-scrollbar {
  width: 8px;
  height: 200px;
  background: none;
}

div.inbox-container:hover {
  cursor: pointer;
  color: rgb(71, 114, 250); 
}

.projectclicked {
  color: rgb(71, 114, 250); 
}

.prjgroup-title {
  /* 让文本在需要时换行 */
  word-wrap: break-word; /* 或 overflow-wrap: break-word; */
  word-break: break-all;

  /* 禁止文本被选择 */
  user-select: none;

  /* 以下是额外的样式，确保内容不会超出容器宽度 */
  // max-width: 100%; /* 限制最大宽度，防止溢出 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 在文本溢出时显示省略号 */
}

.badget {
    position:absolute;
    font-size: 12px;
    padding-top:1px;
    width:22px;
    height:14px;
    border-radius: 6px;
    left:70px;
    top:-8px;
    // background-color: rgb(245, 108, 108);
  }
</style>