<template>
  <div class="page flex-center">
    <h3 class="title">密码重置</h3>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="*账户" prop="username">
        <el-input type="text" v-model="ruleForm.username" autocomplete="off" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="*手机号码" prop="telephone" v-model="ruleForm.telephone">
        <el-input type="text" v-model="ruleForm.telephone" autocomplete="off的" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <el-form-item label="*短信验证码" prop="verificationcode" v-model="ruleForm.verificationcode" style="text-align:left;">
        <el-input type="text" v-model="ruleForm.verificationcode" autocomplete="off的" placeholder="验证码"  style="width:190px;">
        </el-input>
        <el-button type="primary" class="verificate">
          获取验证码
        </el-button>
      </el-form-item>
      <!-- <el-form-item label="姓名" prop="nickname">
        <el-input type="text" v-model="ruleForm.nickname" autocomplete="off" placeholder="请输入姓名"></el-input>
      </el-form-item> -->
      <el-form-item label="*新密码" prop="pass">
        <el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="请输入密码">
        </el-input>
      </el-form-item>
      <el-form-item label="*确认新密码" prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请确认密码"></el-input>
      </el-form-item>
      <el-form-item size="small" style="text-align:right;">
        <el-button type="primary" @click="submitForm()">确认</el-button>
        <el-button type="primary" @click="resetForm('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState , mapMutations, mapActions} from 'vuex'
export default {
  name: 'ResetRsw',
  data() {
    var validateUsername = (rule, value, callback) => {
      const usernameRegex = /^[A-Za-z0-9_]+$/;
      if (value === '') {
        callback(new Error('请输入用户名！'));
      } else if (!usernameRegex.test(this.ruleForm.username)) {
        callback(new Error('仅可使用字母、数字及下划线！'));
      } else {
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
      if (value === '') {
        callback(new Error('请输入邮箱地址'));
      } else if (!emailRegex.test(this.ruleForm.email)) {
        callback(new Error('请输入正确的邮箱地址!'));
      } else {
        callback();
      }
    };
    var validateTelephone = (rule, value, callback) => {
      const telephoneRegex = /^1\d{10}$/;
      if (value === '') {
        callback(new Error('请输入手机号码'));
      } else if (!telephoneRegex.test(this.ruleForm.telephone)) {
        callback(new Error('请输入11位手机号码!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        username: '',
        pass: '',
        checkPass: '',
        email: '',
        nickname:'',
        // location:'',
        telephone:'',
        verificationcode:'',
      },
      rules: {
        username:[
          {validator: validateUsername, trigger: 'blur'}
        ],
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        email: [
          {validator: validateEmail, trigger: 'blur'}
        ],
        telephone: [
          {validator: validateTelephone, trigger: 'blur'}
        ],
      },
      };
    },
    methods: {
      ...mapActions({
        addProjectAclToSql:'addProjectAclToSql',
        addProjectToSql:'addProjectToSql'
      }),

      submitForm() {
        this.$refs.ruleForm.validate(valid => {
        })
      },

      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
}
</script>

<style lang="less" scoped>
  .flex-center {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    text-align: center;
  }
  .toggle-password {
    font-size: 16px;
    cursor: pointer;
    color: #c0c4cc;
  }
  .toggle-password.is-active {
    color: #409eff;
  }

  .demo-ruleForm{
    width:410px;
  }

  .verificate {
    width:107px;
    margin-left:10px;
    background: rgb(255, 255, 255);
    color:gray;
    border: 0.5px solid rgb(228, 230, 236);
  }
</style>