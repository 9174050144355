<template>
  <div class="menu-container" v-draggable>
    <div class="menu">
      <h5 style="text-align: center;line-height: 20px;">申请添加朋友</h5>
      <div>
        <div style="border-top: solid rgb(205,205,205) 0.5px;"></div>
        <label>发送添加朋友申请</label>
        <input :value="applyText" @change="updateApplyText">
        <br>
        <label>备注名</label>
        <input :value="friendNickname" @change="updateFriendNickname">
        <label>标签</label>
        <input placeholder="搜索、添加标签">
      </div>
      <div style="border-bottom: solid rgb(205,205,205) 0.5px;;"></div>
      <div style="text-align: center;margin-top:15px;">
        <button style="margin-right: 20px;" class="menu-button" @click="sendAddFriendApply">确定</button>
        <button class="menu-button" @click="cancelApply">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations} from 'vuex'
import axios from 'axios'
import draggableDirective from '../../../plugin/draggable.js'
export default {
    directives: {
      draggable: draggableDirective
    },
    name: "AddFriend",
    props: ['myProjects','friendNickname','applyText'],
    data() {
        return {
            projectName: "",
            projectType: "type1",
            username:'',
            saveOrNot:true,
            showSearchText:false,
            searchUser:null,
            newApplyText:'',
            friendCommentName:'',
        };
    },
    // props:['selectProjName'],
    computed: {



      
 
    },
    watch :{
      searchText: function(){
        if(!this.searchText=='')
        this.showSearchText=true
      }
    },
    prop: ["menuVisible"],
    methods: {
      ...mapMutations({
          switchMenuMask: "switchMenuMask"
      }),

      cancelAddFriend(){
        this.$emit('cancelAddFriend')
      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },

      gotoFriend(){
        const searchText=this.searchText
        this.getUserinfoBySearchText(searchText)
      },

      async getUserinfoBySearchText(text){   //今天到此位置
        try {
          const response = await axios.get('https://api2.itaskid.com' + '/my/friendships', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          console.log('friendship', response.data);
          // this.friendships = response.data;
          return response.data
        } else {
          alert('账号未认证，无法获取数据！');
        }
        console.log(response.data);
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.')
      }

      },

      sendAddFriendApply(){
        const applyText=this.newApplyText
        const commentName=this.friendCommentName
        this.$emit('sendAddFriendApply',applyText,commentName)
      },

      cancelApply(){
        this.$emit('cancelApply')
      },

      updateApplyText(event){
        this.newApplyText=event.target.value
      },

      updateFriendNickname(event){
        this.friendCommentName=event.target.value
      },

    },
    mounted(){
      this.getUsername();
      const token = localStorage.getItem('token')
      this.newApplyText=this.applyText
      this.friendCommentName=this.friendNickname
    },

    components: { Row }
}
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(240, 240, 240, 0.8);
  width: 400px;
  height: 370px;
  // border: 0.5px solid rgb(209,209,209);
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  label {
    line-height: 35px;
    font-size: small;
    margin-left: 16px;
  }
  
  
  input {
    width: 360px;
    height: 30px;
    margin-left: 16px;
    margin-bottom: 10px;
    border:none;
    outline: none;
    font-size: 16px;
  }
  .form-group {
  width: 450px;
  margin: 10px 0;
  height: 30px;
  padding-left: 20px;
  }
  .menu-button {
    height: 35px;
    font-size: 14px;
    border:none;
    background-color: rgb(56, 205, 127);
    border-radius: 5%;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}

  
</style>